import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import enLocale from 'date-fns/locale/en-GB';

const Picker = ({ value, onChange, label }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
            <DatePicker
                mask="__/__/____"
                inputFormat="dd/MM/yyyy"
                disableFuture
                value={value}
                onChange={date => onChange(date)}
                views={['day']}
                renderInput={params => (
                    <TextField
                        {...params}
                        size="small"
                        label={label}
                        sx={{ mr: 2, width: 180 }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

const RangePicker = ({ fromDate, toDate, onChangeFromDate, onChangeToDate }) => {
    return (
        <>
            <Picker value={fromDate} onChange={onChangeFromDate} label="From Date" />
            <Picker value={toDate} onChange={onChangeToDate} label="To Date" />
        </>
    );
};

const ExportCampaignsDialog = ({ customerId, onClose, onComplete }) => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isValid, setIsValid] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        onComplete(fromDate, toDate);
    };

    useEffect(() => {
        setIsValid(fromDate !== null && toDate !== null);
    }, [fromDate, toDate]);

    return (
        <Dialog onClose={onClose} open={true}>
            <form onSubmit={e => isValid && handleSubmit(e)}>
                <DialogTitle>Export Campaigns</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Export Campaigns for Customer ID: <strong>{customerId}</strong>.
                    </DialogContentText>
                    <RangePicker
                        fromDate={fromDate}
                        toDate={toDate}
                        onChangeFromDate={setFromDate}
                        onChangeToDate={setToDate}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={!isValid}>Export Campaigns</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ExportCampaignsDialog;