import React, { useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Chart from 'chart.js/auto';
import IconTooltip from './IconTooltip';
import renderPercentage from './renderPercentage';
import useTheme from '@mui/material/styles/useTheme';

const CampaignSummary = ({ campaign, subscriberStatus, onChangeSubscriberStatus }) => {
    const theme = useTheme();
    const unopened = campaign.sent - campaign.uniqueOpens - campaign.bounced;

    const handleBuildChart = () => {
        const data = {
            labels: ['Unique Opens', 'Unopened', 'Bounced', 'Failed'],
            datasets: [{
                data: [campaign.uniqueOpens, unopened, campaign.bounced, campaign.failed],
                backgroundColor: [theme.palette.success.light, theme.palette.grey[300], theme.palette.warning.main, theme.palette.error.main]
            }]
        };

        const options = {
            plugins: {
                legend: {
                    position: 'right'
                }
            },
            aspectRatio: 2
        };

        const dataChart = document.getElementById('dataCanvas');

        let chartStatus = Chart.getChart('dataCanvas');

        chartStatus !== undefined && chartStatus.destroy();

        new Chart(dataChart, {
            type: 'pie',
            data,
            options
        });
    };

    useEffect(() => {
        handleBuildChart();
    }, []);

    return (
        <Paper sx={{ margin: 2 }}>
            <Toolbar variant="dense">
                Campaign Summary
            </Toolbar>
            <Divider />
            <Box sx={{ padding: 3, display: 'flex' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                <TableRow
                                    hover={true}
                                    selected={subscriberStatus === 'selected'}
                                    onClick={() => onChangeSubscriberStatus('selected')}
                                >
                                    <TableCell component="th" sx={{ whiteSpace: 'nowrap' }}>Selected</TableCell>
                                    <TableCell width="100%">
                                        <IconTooltip title="Number of contacts selected to be sent to" />
                                    </TableCell>
                                    <TableCell align="right">{campaign.selected}</TableCell>
                                    <TableCell align="right">{renderPercentage(campaign.selected, campaign.selected)}</TableCell>
                                </TableRow>
                                <TableRow
                                    hover={true}
                                    selected={subscriberStatus === 'sent'}
                                    onClick={() => onChangeSubscriberStatus('sent')}
                                >
                                    <TableCell component="th" sx={{ whiteSpace: 'nowrap' }}>Sent</TableCell>
                                    <TableCell width="100%">
                                        <IconTooltip title="Total number of emails successfully sent" />
                                    </TableCell>
                                    <TableCell align="right">{campaign.sent}</TableCell>
                                    <TableCell align="right">{renderPercentage(campaign.sent, campaign.selected)}</TableCell>
                                </TableRow>
                                <TableRow
                                    hover={true}
                                    selected={subscriberStatus === 'delivered'}
                                    onClick={() => onChangeSubscriberStatus('delivered')}
                                >
                                    <TableCell component="th" sx={{ whiteSpace: 'nowrap' }}>Delivered</TableCell>
                                    <TableCell width="100%">
                                        <IconTooltip title="Total number of emails successfully delivered (sent minus bounced)" />
                                    </TableCell>
                                    <TableCell align="right">{campaign.sent - campaign.bounced}</TableCell>
                                    <TableCell align="right">{renderPercentage((campaign.sent - campaign.bounced), campaign.selected)}</TableCell>
                                </TableRow>
                                <TableRow
                                    hover={true}
                                    selected={subscriberStatus === 'opened'}
                                    onClick={() => onChangeSubscriberStatus('opened')}
                                >
                                    <TableCell component="th" sx={{ whiteSpace: 'nowrap' }}>Unique Opens</TableCell>
                                    <TableCell width="100%">
                                        <IconTooltip title="The number of unique contacts to have opened the campaign at least once" />
                                    </TableCell>
                                    <TableCell align="right">{campaign.uniqueOpens}</TableCell>
                                    <TableCell align="right">{renderPercentage(campaign.uniqueOpens, campaign.selected)}</TableCell>
                                </TableRow>
                                <TableRow
                                    hover={true}
                                    selected={subscriberStatus === 'clicked'}
                                    onClick={() => onChangeSubscriberStatus('clicked')}
                                >
                                    <TableCell component="th" sx={{ whiteSpace: 'nowrap' }}>Unique Clicks</TableCell>
                                    <TableCell width="100%">
                                        <IconTooltip title="The number of unique contacts to have clicked a link or links in the campaign at least once" />
                                    </TableCell>
                                    <TableCell align="right">{campaign.uniqueClicks}</TableCell>
                                    <TableCell align="right">{renderPercentage(campaign.uniqueClicks, campaign.uniqueOpens)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" sx={{ whiteSpace: 'nowrap' }}>Total Opens</TableCell>
                                    <TableCell width="100%">
                                        <IconTooltip title="The number of times the campaign was opened, including multiple opens by the same contact" />
                                    </TableCell>
                                    <TableCell align="right">{campaign.totalOpens}</TableCell>
                                    <TableCell align="right">{renderPercentage(campaign.totalOpens, campaign.selected)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" sx={{ whiteSpace: 'nowrap' }}>Total Clicks</TableCell>
                                    <TableCell width="100%">
                                        <IconTooltip title="The number of times links in the campaign were clicked, including multiple clicks by the same contact" />
                                    </TableCell>
                                    <TableCell align="right">{campaign.totalClicks}</TableCell>
                                    <TableCell align="right">{renderPercentage(campaign.totalClicks, campaign.totalOpens)}</TableCell>
                                </TableRow>
                                <TableRow
                                    hover={true}
                                    selected={subscriberStatus === 'unopened'}
                                    onClick={() => onChangeSubscriberStatus('unopened')}
                                >
                                    <TableCell component="th" sx={{ whiteSpace: 'nowrap' }}>Unopened</TableCell>
                                    <TableCell width="100%">
                                        <IconTooltip title="Contacts who have not opened the campaign" />
                                    </TableCell>
                                    <TableCell align="right">{unopened}</TableCell>
                                    <TableCell align="right">{renderPercentage(unopened, campaign.selected)}</TableCell>
                                </TableRow>
                                <TableRow
                                    hover={true}
                                    selected={subscriberStatus === 'bounced'}
                                    onClick={() => onChangeSubscriberStatus('bounced')}
                                >
                                    <TableCell component="th" sx={{ whiteSpace: 'nowrap' }}>Bounced</TableCell>
                                    <TableCell width="100%">
                                        <IconTooltip title="Total number of emails that couldn't be delivered to a contact" />
                                    </TableCell>
                                    <TableCell align="right">{campaign.bounced}</TableCell>
                                    <TableCell align="right">{renderPercentage(campaign.bounced, campaign.selected)}</TableCell>
                                </TableRow>
                                <TableRow
                                    hover={true}
                                    selected={subscriberStatus === 'failed'}
                                    onClick={() => onChangeSubscriberStatus('failed')}
                                >
                                    <TableCell component="th" sx={{ whiteSpace: 'nowrap' }}>Failed</TableCell>
                                    <TableCell width="100%">
                                        <IconTooltip title="Total number of emails that couldn't be sent" />
                                    </TableCell>
                                    <TableCell align="right">{campaign.failed}</TableCell>
                                    <TableCell align="right">{renderPercentage(campaign.failed, campaign.selected)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{ marginLeft: 3, width: '300px' }}>
                    <canvas id="dataCanvas" />
                </Box>
            </Box>
        </Paper>
    );
};

export default CampaignSummary;