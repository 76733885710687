import React, { useEffect, useState } from 'react';
import { HubConnectionState } from '@microsoft/signalr';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import buildUrl from '../../buildUrl';
import useSearch from '../../hooks/useSearch';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import DatePicker from '../../DatePicker';
import { lighten } from '@mui/system';
import useTheme from '@mui/material/styles/useTheme';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InputLabel from '@mui/material/InputLabel';
import ConfirmRevertToDraftDialog from '../dialogs/ConfirmRevertToDraftDialog';
import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog';
import RenameDialog from '../dialogs/RenameDialog';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TopicFilter from '../../topics/TopicFilter';
import Picker from '../../picker/Picker';
import LockIcon from '@mui/icons-material/Lock';
import WebIcon from '@mui/icons-material/Web';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import BarChartIcon from '@mui/icons-material/BarChart';
import useApi from '../../hooks/useApi';
import useEditorHubConnection from '../hooks/useEditorHubConnection';
import useSnackbar from '../../hooks/useSnackbar';
import DashboardInUseDialog from '../dialogs/DashboardInUseDialog';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Snackbar from '@mui/material/Snackbar';
import useAccount from './../../hooks/useAccount';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CopyToAccountsDialog from '../dialogs/CopyToAccountsDialog';

const actionsCell = { width: '7%' };

const iconStyle = {
    marginRight: 2,
    verticalAlign: 'middle'
};

const ToolbarItem = ({ children }) => {
    return <Box sx={{ display: 'inline-flex', margin: 0.5, marginRight: 0 }}>{children}</Box>;
};

const EmptyTable = ({ isLoading, filtersApplied }) => {
    if (isLoading) {
        return null;
    }

    return (
        <Box sx={{ padding: 6, textAlign: 'center' }}>
            {filtersApplied ? (
                <Typography>No data</Typography>
            ) : (
                <>
                    <Typography>You haven't created any Landing Pages yet.</Typography>
                    <Typography>Click "New Landing Page" to create your first one!</Typography>
                </>
            )}
        </Box>
    );
};

const PageMenu = ({
    onDelete,
    onRename,
    onCopy,
    published,
    onEdit,
    onViewReport,
    onRevertToDraft,
    onOpenLink,
    onCopyLink,
    qrCodeAssigned,
    userAccounts,
    onCopyToAccounts,
    isBeingUsed = false
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(null);
    };

    const handleAction = (e, action) => {
        e.stopPropagation();

        action();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick} size="small" disabled={isBeingUsed}>
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={e => handleAction(e, onEdit)}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                {published && (
                    <MenuItem onClick={e => handleAction(e, onViewReport)}>
                        <ListItemIcon>
                            <BarChartIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>View Report</ListItemText>
                    </MenuItem>
                )}
                {published && (
                    <MenuItem onClick={e => handleAction(e, onOpenLink)}>
                        <ListItemIcon>
                            <OpenInNewIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Open Page in New Window</ListItemText>
                    </MenuItem>
                )}
                {published && (
                    <MenuItem onClick={e => handleAction(e, onCopyLink)}>
                        <ListItemIcon>
                            <ContentPasteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Copy Link to Clipboard</ListItemText>
                    </MenuItem>
                )}
                <MenuItem onClick={e => handleAction(e, onRename)}>
                    <ListItemIcon>
                        <DriveFileRenameOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Rename</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleAction(e, onCopy)}>
                    <ListItemIcon>
                        <ContentCopyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Create Copy</ListItemText>
                </MenuItem>
                {userAccounts.length > 1 && (
                    <MenuItem onClick={e => handleAction(e, onCopyToAccounts)}>
                        <ListItemIcon>
                            <CopyAllIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Copy to Account(s)</ListItemText>
                    </MenuItem>
                )}
                {published && (
                    <MenuItem
                        onClick={e => handleAction(e, onRevertToDraft)}
                        disabled={qrCodeAssigned}
                    >
                        <ListItemIcon>
                            <WebIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Unpublish</ListItemText>
                    </MenuItem>
                )}
                <MenuItem onClick={e => handleAction(e, onDelete)}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

const CreateNewMenu = () => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCreateNew = method => {
        history.push(`/editor/page/${method}`);
        handleClose();
    };

    return (
        <>
            <Button
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                sx={{ mr: 1, whiteSpace: 'pre' }}
            >
                New Landing Page
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleCreateNew('new')}>From Template</MenuItem>
                <MenuItem onClick={() => handleCreateNew('upload')}>Upload HTML</MenuItem>
            </Menu>
        </>
    );
};

const rowsPerPageOptions = [10, 25, 50];

const Pages = () => {
    const { topics, user, handleCreateTopic } = useAccount();
    const history = useHistory();
    const [isInitialising, setIsInitialising] = useState(true);
    const [items, setItems] = useState([]);
    const [count, setCount] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState('Modified');
    const [orderDesc, setOrderDesc] = useState(true);
    const [tempSearchValue, setTempSearchValue] = useSearch(setSearchValue);
    const [isLoading, setIsLoading] = useState(true);
    const [itemToRename, setItemToRename] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToRevertToDraft, setItemToRevertToDraft] = useState(null);
    const [itemToCopy, setItemToCopy] = useState(null);
    const [itemToCopyToAccounts, setItemToCopyToAccounts] = useState(null);
    const [isCopying, setIsCopying] = useState(false);
    const [published, setPublished] = useState(null);
    const [filterTopics, setFilterTopics] = useState([]);
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();
    const { showSnackbar } = useSnackbar();
    const theme = useTheme();
    const { connection, usersEditing, setUsersEditing } = useEditorHubConnection();
    const [showInUseDialog, setShowInUseDialog] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(null);

    const handleFetchCount = async () => {
        const params = {
            search: searchValue,
            ...(fromDate && { modifiedFromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { modifiedToDate: format(toDate, 'yyyy-MM-dd') }),
            ...(filterTopics.length > 0 && { topicIds: filterTopics.map(e => e.id) }),
            ...(published !== null && { published })
        };

        const url = buildUrl('pages/count', params);
        const response = await handleGet(url);

        if (!response.ok) {
            showSnackbar('Error fetching count', 'error');
            return;
        }

        const data = await response.json();

        setCount(data);
    };

    const handleFetchItems = async () => {
        const params = {
            search: searchValue,
            ...(fromDate && { modifiedFromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { modifiedToDate: format(toDate, 'yyyy-MM-dd') }),
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending: orderDesc,
            ...(filterTopics.length > 0 && { topicIds: filterTopics.map(e => e.id) }),
            ...(published !== null && { published })
        };

        const url = buildUrl('pages', params);
        const response = await handleGet(url);

        if (!response.ok) {
            showSnackbar('Error fetching pages', 'error');
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setItems(data);
        setIsLoading(false);
    };

    const handleSort = (field, defaultOrder) => {
        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(defaultOrder);
        }
    };

    const handleUpdateItem = async ({ item, topics = null, name = null }) => {
        const topicIds = topics?.map(e => e.id);

        const params = {
            ...item,
            ...(name && { name }),
            ...(topicIds && { topicIds })
        };

        const response = await handlePut(`pages/${item.id}`, params);

        if (!response.ok) {
            showSnackbar('Page not found', 'error');
        }

        setItemToRename(null);
        handleFetchItems();
    };

    const handleCopy = async name => {
        setIsCopying(true);

        const response = await handlePost('pages', {
            id: itemToCopy.id,
            name
        });

        if (response.ok) {
            showSnackbar('Page copied successfully', 'success');
            handleFetchItems();
            handleFetchCount();
        }
        else {
            showSnackbar('Page could not be copied', 'error');
        }

        setIsCopying(false);
        setItemToCopy(null);
    };

    const handleCopyToAccounts = async (name, userAccounts) => {
        setIsCopying(true);

        const response = await handlePost('pages', {
            id: itemToCopyToAccounts.id,
            name,
            userAccounts
        });

        if (response.ok) {
            const message = userAccounts.length === 1
                ? 'Page copied successfully'
                : 'Page successfully copied to accounts';

            showSnackbar(message, 'success');
            handleFetchItems();
            handleFetchCount();
        }

        setIsCopying(false);
        setItemToCopyToAccounts(null);
    };

    const handleDeleteItem = async id => {
        const response = await handleDelete(`pages/${id}`);

        if (!response.ok) {
            showSnackbar('Page not found', 'error');
        }

        setItemToDelete(null);
        handleFetchCount();
        handleFetchItems();
    };

    const handleRevertToDraft = async id => {
        const response = await handlePut(`pages/${id}`, {
            ...itemToRevertToDraft,
            publish: false
        });

        if (!response.ok) {
            console.error(response);
            showSnackbar('Page not found', 'error');
        }

        setItemToRevertToDraft(null);
        handleFetchItems();
    };

    const handleViewReport = id => {
        history.push(`/reports/page/${id}`);
    };

    const handleEditItem = id => {
        history.push(`/editor/page/${id}`);
    };

    const handleClickRow = item => {
        if (item.published) {
            handleViewReport(item.id);
        }
        else {
            handleEditItem(item.id);
        }
    };

    const handleChangeTopicsFilter = topic => {
        if (!topic) {
            setFilterTopics([]);
            return;
        }

        if (Boolean(filterTopics.find(e => e.id === topic.id))) {
            setFilterTopics(filterTopics.filter(e => e.id !== topic.id));
        }
        else {
            setFilterTopics([...filterTopics, topic]);
        }
    };

    const handleInit = async () => {
        await handleFetchItems();
        await handleFetchCount();
        setIsInitialising(false);
    };

    const getUsersEditing = async () => {
        try {
            await connection.start().then(() => {
                connection.invoke('GetEditorsInUse');
            });

            connection.on('GetEditorsInUse', usersEditing => {
                setUsersEditing(usersEditing);
            });
        }
        catch (err) {
            console.error(err);
        }
    };

    const handleCopyLinkToClipboard = link => {
        navigator.clipboard.writeText(link);
        setSnackbarMessage('Link copied to clipboard');
    };

    const handleSubmitCreateTopic = async ({ name, description, colour }) => {
        await handleCreateTopic({
            name,
            description,
            colour
        });
    };

    useEffect(() => {
        if (isInitialising) {
            return;
        }

        handleFetchCount();
    }, [searchValue, fromDate, toDate, published, filterTopics]);

    useEffect(() => {
        setPage(0);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, published, filterTopics]);

    useEffect(() => {
        setIsLoading(true);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, page, published, filterTopics]);

    useEffect(() => {
        if (isInitialising) {
            return;
        }

        isLoading && handleFetchItems();
    }, [isLoading]);

    useEffect(() => {
        handleInit();
    }, []);

    useEffect(() => {
        if (connection && connection.state !== HubConnectionState.Connected) {
            getUsersEditing();
        }
    }, [connection]);

    if (isInitialising) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <Toolbar disableGutters sx={{ mx: 1, paddingTop: 0.5, paddingBottom: 0.5 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <ToolbarItem>
                        <OutlinedInput
                            value={tempSearchValue}
                            onChange={e => setTempSearchValue(e.target.value)}
                            placeholder="Search..."
                            size="small"
                            sx={{ mr: 1 }}
                            endAdornment={(
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setTempSearchValue('')}
                                        edge="end"
                                        disabled={!Boolean(tempSearchValue)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )}
                        />
                    </ToolbarItem>
                    <ToolbarItem>
                        <TopicFilter
                            availableTopics={topics}
                            selectedTopics={filterTopics}
                            onAddTopic={topic => handleChangeTopicsFilter(topic)}
                            onRemoveTopic={topic => handleChangeTopicsFilter(topic)}
                            onClearTopics={() => handleChangeTopicsFilter()}
                        />
                    </ToolbarItem>
                    <ToolbarItem>
                        <DatePicker value={fromDate} onChange={setFromDate} label="Modified from date" />
                    </ToolbarItem>
                    <ToolbarItem>
                        <DatePicker value={toDate} onChange={setToDate} label="Modified to date" />
                    </ToolbarItem>
                    <ToolbarItem>
                        <FormControl margin="none" size="small">
                            <InputLabel id="filter-status-label">Filter Status</InputLabel>
                            <Select
                                value={published}
                                onChange={e => setPublished(e.target.value)}
                                sx={{ minWidth: 160 }}
                                labelId="filter-status-label"
                                label="Filter Status"
                            >
                                <MenuItem value={null}>All</MenuItem>
                                <MenuItem value={true}>
                                    <WebIcon fontSize="small" color="success" sx={iconStyle} />
                                    Published
                                </MenuItem>
                                <MenuItem value={false}>
                                    <WebIcon fontSize="small" color="disabled" sx={iconStyle} />
                                    Draft
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </ToolbarItem>
                </Box>
                <ToolbarItem>
                    <CreateNewMenu />
                </ToolbarItem>
            </Toolbar>
            <Divider />
            {items.length > 0 ? (
                <>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" scope="row" align="left" sx={{ width: '30%' }}>
                                        <TableSortLabel
                                            active={orderBy === 'Name'}
                                            direction={orderDesc ? 'desc' : 'asc'}
                                            onClick={() => handleSort('Name', false)}
                                        >
                                            Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">Published Date</TableCell>
                                    <TableCell sx={actionsCell} />
                                    <TableCell component="th" scope="row" align="left" sx={{ width: '30%' }}>
                                        Topics
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="left">
                                        Activity
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        <TableSortLabel
                                            active={orderBy === 'Modified'}
                                            direction={orderDesc ? 'desc' : 'asc'}
                                            onClick={() => handleSort('Modified', false)}
                                        >
                                            Last Modified
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sx={actionsCell} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map(e => {
                                    const beingUsedBy = usersEditing.filter(editor => editor.emailId === e.id);
                                    const isBeingUsed = !!beingUsedBy.length;

                                    return (
                                        <TableRow
                                            key={e.id}
                                            hover={!isBeingUsed}
                                            onClick={() =>
                                                !isBeingUsed ? handleClickRow(e) : setShowInUseDialog(true)
                                            }
                                            sx={
                                                isBeingUsed
                                                    ? { bgcolor: lighten(theme.palette.warning.light, 0.7) }
                                                    : {}
                                            }
                                        >
                                            <TableCell align="left">
                                                {isLoading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : (
                                                    <>
                                                        <Tooltip
                                                            title={`${e.published ? 'Published' : 'Draft'}${isBeingUsed ? ' (locked)' : ''}`}
                                                            arrow
                                                        >
                                                            <span>
                                                                {isBeingUsed ? (
                                                                    <LockIcon
                                                                        fontSize="small"
                                                                        color="warning"
                                                                        sx={iconStyle}
                                                                    />
                                                                ) : (
                                                                    <WebIcon
                                                                        fontSize="small"
                                                                        color={e.published ? 'success' : 'disabled'}
                                                                        sx={iconStyle}
                                                                    />
                                                                )}
                                                            </span>
                                                        </Tooltip>
                                                        {e.name}
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {e.publishedDateTime ? format(new Date(e.publishedDateTime), 'dd/MM/yyyy HH:mm') : '-'}
                                            </TableCell>
                                            <Picker
                                                availableItems={topics}
                                                selectedItems={e.topics}
                                                onChangeItems={topics => handleUpdateItem({ item: e, topics })}
                                                onClickItem={topic => handleChangeTopicsFilter(topic)}
                                                isBeingUsed={isBeingUsed}
                                                onCreateTopic={handleSubmitCreateTopic}
                                            />
                                            <TableCell align="left">
                                                {beingUsedBy.map(user => (
                                                    <Tooltip
                                                        key={user.email}
                                                        title={`${user.name || user.email} is currently editing`}
                                                    >
                                                        <Avatar src={user.avatarURL} sx={{ width: 30, height: 30 }} />
                                                    </Tooltip>
                                                ))}
                                            </TableCell>
                                            <TableCell align="right">
                                                {e.modified ? format(new Date(e.modified), 'dd/MM/yyyy HH:mm') : '-'}
                                            </TableCell>
                                            <TableCell align="right" sx={actionsCell}>
                                                <PageMenu
                                                    published={e.published}
                                                    onDelete={() => setItemToDelete(e)}
                                                    onRename={() => setItemToRename(e)}
                                                    onCopy={() => setItemToCopy(e)}
                                                    onEdit={() => handleEditItem(e.id)}
                                                    onViewReport={() => handleViewReport(e.id)}
                                                    onRevertToDraft={() => setItemToRevertToDraft(e)}
                                                    onOpenLink={() => window.open(e.viewLink, '_blank')}
                                                    onCopyLink={() => handleCopyLinkToClipboard(e.viewLink)}
                                                    isBeingUsed={isBeingUsed}
                                                    qrCodeAssigned={Boolean(e.qrCodes?.filter(q => !q.endDate || new Date(q.endDate) <= new Date()).length > 0)}
                                                    userAccounts={user.accounts}
                                                    onCopyToAccounts={() => setItemToCopyToAccounts(e)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => setPage(newPage)}
                        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                    />
                </>
            ) : (
                <EmptyTable
                    isLoading={isLoading}
                    filtersApplied={searchValue || fromDate || toDate || published || filterTopics.length > 0}
                />
            )}
            {isLoading && <LoadingOverlay />}

            {itemToDelete && (
                <ConfirmDeleteDialog
                    onClose={() => setItemToDelete(null)}
                    onDelete={handleDeleteItem}
                    item={itemToDelete}
                    type="page"
                />
            )}
            {itemToRevertToDraft && (
                <ConfirmRevertToDraftDialog
                    onClose={() => setItemToRevertToDraft(null)}
                    onRevertToDraft={handleRevertToDraft}
                    item={itemToRevertToDraft}
                    type="page"
                />
            )}
            {itemToRename && (
                <RenameDialog
                    onClose={() => setItemToRename(null)}
                    onSubmit={name => handleUpdateItem({ item: itemToRename, name })}
                    itemName={itemToRename.name}
                    type="page"
                />
            )}
            {itemToCopy && (
                <RenameDialog
                    onClose={() => setItemToCopy(null)}
                    onSubmit={handleCopy}
                    itemName={itemToCopy.name}
                    isCopy={true}
                    isCopying={isCopying}
                    type="page"
                />
            )}
            {itemToCopyToAccounts && (
                <CopyToAccountsDialog
                    onClose={() => setItemToCopyToAccounts(null)}
                    onSubmit={handleCopyToAccounts}
                    itemName={itemToCopyToAccounts.name}
                    isCopying={isCopying}
                    type="page"
                />
            )}
            {showInUseDialog && <DashboardInUseDialog onClose={() => setShowInUseDialog(false)} type="page" />}
            <Snackbar
                open={Boolean(snackbarMessage)}
                onClose={() => setSnackbarMessage(null)}
                autoHideDuration={3000}
                message={snackbarMessage}
            />
        </>
    );
};

export default Pages;
