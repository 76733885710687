/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import useApi from '../../hooks/useApi';
import usePrevious from '../../hooks/usePrevious';
import useInputDate from '../../hooks/useInputDate';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import { parseISO, isValid, format, formatISO } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import enLocale from 'date-fns/locale/en-GB';
import { v4 as uuidv4 } from 'uuid';
import standardFields from '../../standardFields';
import { stringOperators, numberOperators, dateOperators } from '../../conditionOperators';

const ValueField = ({ value, onChange, type }) => {
    const handleChangeValue = event => {
        onChange(event.target.value);
    };

    //avoid display issue when switching date/dob type (picker value populated with invalid value)
    const inputDate = useInputDate(type === 'dateofbirth' ? value : '');
    const inputDateTime = useInputDate(type === 'date' ? value : '');

    const handleChangeValidInput = inputValue => {
        if (!inputValue) {
            return;
        }

        isValid(inputValue) && onChange(formatISO(inputValue));
    };

    useEffect(() => {
        handleChangeValidInput(inputDate?.value);
    }, [inputDate.value]);

    useEffect(() => {
        handleChangeValidInput(inputDateTime?.value);
    }, [inputDateTime.value]);

    const previousType = usePrevious(type);

    useEffect(() => {
        if (previousType && (previousType !== type)) {
            onChange('');
            inputDate.onChange('');
            inputDateTime.onChange('');
        }
    }, [type]);

    if (type === 'dateofbirth') {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                <DatePicker
                    mask="__/__/____"
                    inputFormat="dd/MM/yyyy"
                    renderInput={(props) => <TextField fullWidth {...props} />}
                    label="Value"
                    value={inputDate.value}
                    onChange={inputDate.onChange}
                    ampm
                />
            </LocalizationProvider>
        );
    }

    if (type === 'date') {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                <DateTimePicker
                    mask="__/__/____ __:__"
                    inputFormat="dd/MM/yyyy HH:mm"
                    renderInput={(props) => <TextField fullWidth {...props} />}
                    label="Value"
                    value={inputDateTime.value}
                    onChange={inputDateTime.onChange}
                    ampm
                />
            </LocalizationProvider>
        );
    }

    if (type === 'bool') {
        return (
            <FormControl fullWidth id="conditionValueSelect">
                <InputLabel id="value-label">Value</InputLabel>
                <Select
                    labelId="value-label"
                    inputProps={{
                        id: 'conditionValueSelect'
                    }}
                    native={true}
                    value={value}
                    label="Value"
                    onChange={handleChangeValue}
                    //todo fix label collision with value without empty option hack below
                >
                    <option value="" />
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Select>
            </FormControl>
        );
    }

    return (
        <TextField
            id="conditionValueField"
            label="Value"
            fullWidth
            value={value}
            required
            type={type === 'number' ? 'number' : 'string'}
            onChange={handleChangeValue}
        />
    );
};

const handleEscapeDoubleQuotes = s => s.replace(/[\"]/g, '\\$&');

const ConditionPickerDialog = ({ onSubmit, onClose, appliedCondition }) => {
    const { handleGet } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [field, setField] = useState(appliedCondition?.field ?? standardFields[0].name);
    const [value, setValue] = useState(appliedCondition?.value ?? '');
    const [operator, setOperator] = useState(appliedCondition?.operator ?? stringOperators[0].name);
    const [displayOperator, setDisplayOperator] = useState(operator);
    const [operators, setOperators] = useState(stringOperators);
    const [displayDateValue, setDisplayDateValue] = useState(null);
    const [customFields, setCustomFields] = useState(null);
    const [type, setType] = useState(null);

    const handleGetCustomFields = async () => {
        const response = await handleGet('custom-fields');
        const data = await response.json();

        const customFields = data?.filter(e => e.display !== false).map(e => ({
            name: e.name,
            displayName: e.name,
            type: e.type
        }));

        setCustomFields(customFields);
    };

    const fieldName = standardFields.find(e => e.name === field)?.displayName ?? customFields?.find(e => e.name === field)?.displayName ?? field;

    const commentId = uuidv4().split('-')[0];

    const escapedJsonField = handleEscapeDoubleQuotes(JSON.stringify({ field }));
    const escapedJsonOperator = handleEscapeDoubleQuotes(JSON.stringify({ operator }));
    const escapedJsonValue = handleEscapeDoubleQuotes(JSON.stringify({ value }));

    const conditions = `\[${escapedJsonField},${escapedJsonOperator},${escapedJsonValue}\]`;

    const condition = {
        name: `${fieldName}: ${displayDateValue ?? value}`,
        description: `Only contacts whose data for ${fieldName} ${displayOperator} ${displayDateValue ?? value} will receive this content.`,
        beforeScript: `<!--nz-dynamic conditions="${conditions}" id="${commentId}"-->`,
        afterScript: `<!--end nz-dynamic id="${commentId}"-->`,
        field,
        operator,
        value
    };

    const handleSubmit = e => {
        e.preventDefault();

        onSubmit(condition);
        onClose();
    };

    const handleChangeField = event => {
        setField(event.target.value);
    };

    const handleChangeOperator = event => {
        setOperator(event.target.value);
    };

    useEffect(() => {
        if (type === 'date' || type === 'dateofbirth') {
            isValid(parseISO(value)) ? type === 'date' ? setDisplayDateValue(format(parseISO(value), 'dd/MM/yyyy HH:mm')) : setDisplayDateValue(format(parseISO(value), 'dd/MM/yyyy')) : setDisplayDateValue(null);
        }
        else {
            setDisplayDateValue(null);
        }
    }, [value]);

    useEffect(() => {
        customFields && setType(standardFields.find(e => e.name === appliedCondition?.field)?.type ?? customFields?.find(e => e.name === appliedCondition?.field)?.type ?? 'string');
    }, [customFields]);

    useEffect(() => {
        customFields && setType(standardFields.find(e => e.name === field)?.type ?? customFields?.find(e => e.name === field)?.type ?? 'string');
    }, [field]);

    useEffect(() => {
        (type && customFields) && setIsLoading(false);
    }, [type]);

    useEffect(() => {
        let validDateOperator, dateDisplayOperator, validNumberOperator, numberDisplayOperator, validStringOperator, stringDisplayOperator;

        switch (type) {
            case 'bool':
                setOperator('equals');
                setDisplayOperator('Equals');
                break;
            case 'date':
            case 'dateofbirth':
                setOperators(dateOperators);
                validDateOperator = dateOperators.find(e => e.name === operator);

                if (!validDateOperator) {
                    setOperator(dateOperators[0].name);
                }

                dateDisplayOperator = dateOperators.find(e => e.name === operator)?.displayName ?? operator;
                setDisplayOperator(dateDisplayOperator);
                break;
            case 'number':
                setOperators(numberOperators);
                validNumberOperator = numberOperators.find(e => e.name === operator);

                if (!validNumberOperator) {
                    setOperator(numberOperators[0].name);
                }

                numberDisplayOperator = numberOperators.find(e => e.name === operator)?.displayName ?? operator;
                setDisplayOperator(numberDisplayOperator);
                break;
            case 'string':
                setOperators(stringOperators);
                validStringOperator = stringOperators.find(e => e.name === operator);

                if (!validStringOperator) {
                    setOperator(stringOperators[0].name);
                }

                stringDisplayOperator = stringOperators.find(e => e.name === operator)?.displayName ?? operator;
                setDisplayOperator(stringDisplayOperator);
        }
    }, [type, operator]);

    useEffect(() => {
        handleGetCustomFields();
    }, []);

    if (isLoading) {
        return <LoadingOverlay />;
    }

    return (
        <Dialog
            onClose={onClose}
            open={true}
            onClick={e => e.stopPropagation()}
        >
            <DialogTitle>
                Dynamic Content: Choose Condition
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth id="conditionFieldPicker">
                    <InputLabel id="field-label">Field</InputLabel>
                    <Select
                        labelId="field-label"
                        inputProps={{
                            id: 'conditionFieldPickerInput'
                        }}
                        native={true}
                        value={field}
                        label="Field"
                        onChange={handleChangeField}
                    >
                        <optgroup label="Standard Fields">
                            {standardFields.map(e => <option key={e.name} value={e.name}>{e.displayName}</option>)}
                        </optgroup>
                        <optgroup label="Custom Fields">
                            {customFields.map(e => <option key={e.name} value={e.name}>{e.displayName}</option>)}
                        </optgroup>
                    </Select>
                </FormControl>
                {type !== 'bool' && (
                    <FormControl fullWidth id="operatorPicker">
                        <InputLabel id="operator-label">Operator</InputLabel>
                        <Select
                            labelId="operator-label"
                            inputProps={{
                                id: 'operatorPickerInput'
                            }}
                            native={true}
                            value={operator}
                            label="Operator"
                            onChange={handleChangeOperator}
                        >
                            {operators.map(e => <option key={e.name} value={e.name}>{e.displayName}</option>)}
                        </Select>
                    </FormControl>
                )}
                <ValueField
                    value={value}
                    onChange={value => setValue(value)}
                    type={type}
                />
                <DialogContentText sx={{ marginTop: 2 }}>
                    Only Contacts whose data for '{fieldName}' {value ? `${displayOperator} ${displayDateValue ?? value}` : `${displayOperator} the value you set`} will be sent this content. Values are case-insensitive.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} disabled={!value}>Set Condition</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConditionPickerDialog;