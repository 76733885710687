import React from 'react';
import { TextField } from '@mui/material';

const AccountDetailsField = props => {
    return (
        <TextField
            {...props}
            variant="outlined"
            size="small"
            fullWidth
            margin="none"
        />
    );
};

export default AccountDetailsField;
