import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useApi from '../../hooks/useApi';

const nameRegex = /[a-zA-Z0-9\s-]/g;

const GroupSubscribersDailog = ({ open, onClose, onSubmit, count }) => {
    const [groups, setGroups] = useState([]);
    const [option, setOption] = useState('existing');
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);
    const [newGroup, setNewGroup] = useState('');
    const [isGroupNameValid, setIsGroupNameValid] = useState(false);
    const { handleGet } = useApi();

    const handleGetGroups = async () => {
        const response = await handleGet('groups');

        if (!response.ok) {
            return;
        }

        setGroups(await response.json());
    };

    const handleSubmit = () => {
        onSubmit({
            ...(option === 'existing' && { groupIds: selectedGroupIds }),
            ...(option === 'new' && { groupName: newGroup })
        });
    };

    const handleToggle = id => {
        if (selectedGroupIds.includes(id)) {
            setSelectedGroupIds(prevIds => prevIds.filter(selectedId => selectedId !== id));
        }
        else {
            setSelectedGroupIds(prevIds => [...prevIds, id]);
        }
    };

    useEffect(() => {
        if (newGroup.trim() !== '' && (newGroup.match(nameRegex)?.length === newGroup.length)) {
            const existingGroup = groups?.find(e => e.name.trim().toLowerCase() === newGroup.trim().toLowerCase());

            setIsGroupNameValid(!existingGroup);
        }
        else {
            setIsGroupNameValid(false);
        }
    }, [newGroup]);

    useEffect(() => {
        handleGetGroups();
    }, []);

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Group {count} Contact{count > 1 && 's'}</DialogTitle>
            <DialogContent>

                <FormControlLabel
                    label="Choose from existing groups"
                    disabled={groups.length === 0}
                    control={(
                        <Radio
                            checked={option === 'existing'}
                            onChange={e => setOption(e.target.value)}
                            value="existing"
                            name="radio-buttons"
                        />
                    )}
                />

                <Paper sx={{ my: 1, height: 276, overflowY: 'auto' }}>
                    {groups.length > 0 ? (
                        <List dense disablePadding>
                            {groups.map(g => (
                                <ListItem key={g.id}
                                    disablePadding
                                >
                                    <ListItemButton
                                        onClick={() => handleToggle(g.id)}
                                        disabled={option !== 'existing'}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={selectedGroupIds.includes(g.id)}
                                                disableRipple
                                                size="small"
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={g.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography
                            variant="body1"
                            sx={{ padding: 2, color: 'rgba(0,0,0,0.38)' }}
                        >
                            No groups available
                        </Typography>
                    )}
                </Paper>

                <FormControlLabel
                    label="Add to new group"
                    control={(
                        <Radio
                            checked={option === 'new'}
                            onChange={e => setOption(e.target.value)}
                            value="new"
                            name="radio-buttons"
                        />
                    )}
                />

                <Box sx={{ mt: 1 }}>
                    <TextField
                        value={newGroup}
                        onChange={e => setNewGroup(e.target.value)}
                        label="Group Name"
                        disabled={option !== 'new'}
                        fullWidth
                        margin="none"
                        error={option === 'new' && !isGroupNameValid}
                        helperText="Group name must be unique. Alphanumeric characters, hyphens and spaces are allowed."
                    />
                </Box>

            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    sx={{ marginRight: 1 }}
                    disabled={(option === 'existing' && selectedGroupIds.length === 0) || (option === 'new' && !isGroupNameValid)}
                >
                    Group
                </Button>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default GroupSubscribersDailog;