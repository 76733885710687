import React from 'react';
import AppBar from '@mui/material/AppBar';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';

const AlertBar = ({ children, shown = true, severity = 'info', action, secondaryAction = null, positionTop = 0, variant = 'standard', mb = null }) => {
    return (
        <AppBar
            position="sticky"
            color="transparent"
            elevation={1}
            sx={{ top: positionTop, ...(shown && { mb }) }}
        >
            <Collapse in={shown}>
                <Alert
                    severity={severity}
                    variant={variant}
                    action={secondaryAction ? (
                        <Stack direction="row" spacing={1}>
                            {action}
                            {secondaryAction}
                        </Stack>
                    ) : action}
                    square
                >
                    {children}
                </Alert>
            </Collapse>
        </AppBar>
    );
};

export default AlertBar;