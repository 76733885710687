import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FilteredGroupsSelector from '../../contacts/FilteredGroupsSelector';
import Alert from '@mui/material/Alert';

const DeleteContactsDialog = ({ onClose, onConfirm, group, deleteFromGroup, deleteFromMultipleGroups }) => {
    const [selectedGroups, setSelectedGroups] = useState([]);

    const handleConfirm = e => {
        e.preventDefault();
        onConfirm();
        onClose();
    };

    const handleConfirmMultipleDelete = e => {
        e.preventDefault();
        onConfirm(selectedGroups);
        onClose();
    };

    return (
        <>
            {deleteFromMultipleGroups ?
                (
                    <Dialog
                        onClose={(e, reason) => (reason !== 'backgroupClick') && onClose}
                        open={true}
                    >
                        <DialogTitle>Choose Group(s) to delete from</DialogTitle>
                        <FilteredGroupsSelector
                            selectedGroups={selectedGroups}
                            setSelectedGroups={setSelectedGroups}
                            newGroup={false}
                            spacing={3}
                            popperGutters={3}
                        />
                        <DialogActions>
                            <Button onClick={handleConfirmMultipleDelete}>Confirm Delete</Button>
                            <Button variant="outlined" onClick={onClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                )
                :
                (
                    <Dialog onClose={onClose} open={true}>
                        <DialogTitle>Delete Contacts from {deleteFromGroup ? ' Group' : 'Account'}</DialogTitle>
                        <DialogContent>
                            <Alert severity="warning">
                    Are you sure you want to delete the selected contacts from {deleteFromGroup ? group.name : 'your Account'}
                            </Alert>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleConfirm}>Confirm Delete</Button>
                            <Button variant="outlined" onClick={onClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    );
};

export default DeleteContactsDialog;