import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TitleBar from '../../titleBar/TitleBar';
import buildUrl from '../../buildUrl';
import useApi from '../../hooks/useApi';
import useInterval from '../../hooks/useInterval';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import formatFileSize from '../../formatFileSize';
import { add } from 'date-fns';
import useTheme from '@mui/material/styles/useTheme';
import { lighten } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';

const fields = [
    { property: 'id', type: 'number', label: 'Import ID', align: 'right', sortable: true },
    { property: 'customerId', type: 'number', label: 'CID', align: 'right', sortable: true },
    { property: 'status', type: 'status', label: 'Status' },
    { property: 'createdDateTime', type: 'date', label: 'Created Date', sortable: true },
    { property: 'completedDateTime', type: 'date', label: 'Completed Date', sortable: true },
    { property: 'ipAddress', type: 'text', label: 'IP Address' },
    { property: 'fileName', type: 'text', label: 'File Name' },
    { property: 'fileType', type: 'text', label: 'File Type' },
    { property: 'fileSize', type: 'fileSize', label: 'File Size', align: 'right' },
    { property: 'totalContacts', type: 'number', label: 'Total Contacts', align: 'right' },
    { property: 'totalAdded', type: 'number', label: 'Added', align: 'right' },
    { property: 'totalUpdated', type: 'number', label: 'Updated', align: 'right' },
    { property: 'totalFailed', type: 'number', label: 'Failed', align: 'right' },
    { property: 'reportFilePath', type: 'link', label: 'Report' }
];

const availableFilters = [
    { field: 'customerId', label: 'CID' }
];

const rowsPerPageOptions = [10, 20, 50];
const cellStyles = { whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

const DownLoadReport = ({ reportFilePath, completedDateTime }) => {
    if (!completedDateTime || !reportFilePath) {
        return '-';
    }

    const expiry = add(new Date(completedDateTime), { hours: 167 });

    const hasExpired = Date.now() > expiry;

    return (
        <Button onClick={() => window.open(reportFilePath)} disabled={hasExpired} variant="text" size="small">
            {hasExpired ? 'Report Expired' : 'Download Report'}
        </Button>
    );
};

const Imports = ({ showHamburger, onClickHamburger }) => {
    const { handleGet } = useApi();
    const theme = useTheme();
    const [count, setCount] = useState(0);
    const [imports, setImports] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);

    const [selectedField, setSelectedField] = useState('customerId');
    const [tempValue, setTempValue] = useState('');
    const [filterValue, setFilterValue] = useState('');

    const [orderBy, setOrderBy] = useState('createdDateTime');
    const [isDescending, setIsDescending] = useState(true);

    const handleFetchCount = async () => {
        const url = buildUrl('superadmin/imports/count', {
            ...(filterValue && { field: selectedField, value: filterValue })
        });

        const response = await handleGet(url);

        if (response) {
            const data = await response.json();
            setCount(data);
        }
    };

    const handleFetchImports = async () => {
        const url = buildUrl('superadmin/imports', {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending,
            ...(filterValue && { field: selectedField, value: filterValue })
        });

        const response = await handleGet(url);

        if (response) {
            const data = await response.json();
            setImports(data);
        }
    };

    const handleSort = field => {
        if (orderBy === field) {
            setIsDescending(!isDescending);
        }
        else {
            // todo introduce field types
            setIsDescending(field === 'createdDateTime');
        }

        setOrderBy(field);
    };

    const intervalRef = useInterval(() => {
        handleFetchImports();
        handleFetchCount();

        return () => {
            clearInterval(intervalRef.current);
        };
    }, 10000);

    useEffect(() => {
        handleFetchCount();
    }, [filterValue]);

    useEffect(() => {
        setPage(0);
    }, [filterValue, orderBy, isDescending, rowsPerPage]);

    useEffect(() => {
        handleFetchImports();
    }, [filterValue, orderBy, isDescending, rowsPerPage, page]);

    // Reset table

    const handleReset = e => {
        e.preventDefault();

        // clear state
        setPage(0);
        setOrderBy('customerId');
        setIsDescending(true);
        setSelectedField('customerId');
        setFilterValue('');
        setTempValue('');
    };

    const handleSubmitFilter = e => {
        e.preventDefault();
        setFilterValue(tempValue);
    };

    const getStatusStyle = status => {
        let style = { backgroundColor: 'inherit' };

        switch (status) {
            case 'Completed':
                style = { backgroundColor: lighten(theme.palette.success.light, 0.7) };
                break;
            case 'Queued':
                style = { backgroundColor: lighten(theme.palette.warning.light, 0.7) };
                break;
            case 'In Progress':
                style = { backgroundColor: lighten(theme.palette.info.light, 0.9) };
                break;
            case 'Cancelled':
                style = { backgroundColor: theme.palette.grey[400] };
        }

        return style;
    };

    return (
        <>
            <TitleBar showHamburger={showHamburger} onClickHamburger={onClickHamburger}>
                <Breadcrumbs>
                    <Link href="/#/superadmin">Super Admin</Link>
                    <Typography>
                        <strong>Imports</strong>
                    </Typography>
                </Breadcrumbs>
            </TitleBar>

            <Paper sx={{ m: 2 }}>
                <Toolbar
                    disableGutters
                    sx={{ px: 2 }}
                >
                    <Box style={{ flexGrow: 1 }}>
                        <form onSubmit={handleSubmitFilter}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <FormControl margin="none">
                                        <Select
                                            value={selectedField}
                                            onChange={e => setSelectedField(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                        >
                                            {availableFilters.map(({ field, label }) => (
                                                <MenuItem value={field} key={field}>{label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl margin="none">
                                        <TextField
                                            value={tempValue}
                                            onChange={e => setTempValue(e.target.value)}
                                            variant="outlined"
                                            margin="none"
                                            size="small"
                                            placeholder="Search..."
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            disableRipple
                                                            edge="end"
                                                            onClick={handleReset}
                                                            disabled={!Boolean(tempValue)}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        type="submit"
                                        variant="outlined"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Toolbar>
                <Divider />
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {fields.map(({ property, label, align, sortable }) => (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        component="th"
                                        sx={cellStyles}
                                    >
                                        {sortable ? (
                                            <TableSortLabel
                                                active={orderBy === property}
                                                direction={isDescending ? 'desc' : 'asc'}
                                                onClick={() => handleSort(property)}
                                            >
                                                {label}
                                            </TableSortLabel>
                                        ) : label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {imports.map(e => {
                                const cells = fields.map(({ property, type, align }) => type === 'date' ? (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        {e[property] ? format((new Date(e[property])), 'dd/MM/yyyy HH:mm:ss') : '-'}
                                    </TableCell>
                                ) : type === 'link' ? (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        <DownLoadReport
                                            completedDateTime={e.completedDateTime}
                                            reportFilePath={e.reportFilePath}
                                        />
                                    </TableCell>
                                ) : type === 'number' ? (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        {e[property]}
                                    </TableCell>
                                ) : type === 'fileSize' ? (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        {formatFileSize(e[property])}
                                    </TableCell>
                                ) : (type === 'status' ? (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        sx={{ ...cellStyles, ...getStatusStyle(e[property]) }}
                                    >
                                        {e[property]}
                                        {e[property] === 'In Progress' && (
                                            <CircularProgress color="success" size={14} sx={{ marginLeft: 1, verticalAlign: 'middle' }}
                                            />
                                        )}
                                        {e[property] === 'Queued' && (
                                            <CircularProgress color="warning" size={14} sx={{ marginLeft: 1, verticalAlign: 'middle' }}
                                            />
                                        )}
                                    </TableCell>
                                ) : (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        {e[property] || '-'}
                                    </TableCell>
                                )));
                                return (
                                    <TableRow
                                        key={e.id}
                                    >
                                        {cells}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                />
            </Paper>
        </>
    );
};

export default Imports;