import React, { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ClearIcon from '@mui/icons-material/Clear';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateEditTagDialog from './dialogs/CreateEditTagDialog';
import DeleteTagDialog from './dialogs/DeleteTagDialog';
import Tag from '../../tags/Tag';
import useAccount from '../../hooks/useAccount';

const actionsCell = { width: '7%' };

const TagMenu = ({ onDelete, onUpdate }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(null);
    };

    const handleAction = (e, action) => {
        e.stopPropagation();

        action();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                size="small"
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={e => handleAction(e, onUpdate)}>Edit</MenuItem>
                <MenuItem onClick={e => handleAction(e, onDelete)}>Delete</MenuItem>
            </Menu>
        </>
    );
};

const Tags = () => {
    const [dialog, setDialog] = useState(null);
    const { tags, handleCreateTag, handleUpdateTag, handleDeleteTag } = useAccount();
    const [searchValue, setSearchValue] = useState('');
    const [filteredTags, setFilteredTags] = useState(tags);
    const [sort, setSort] = useState({ by: 'name', desc: false });

    const clearFilterTopics = () => {
        setSearchValue('');
        setFilteredTags(tags);
    };

    const handleSubmitCreateTag = async ({ name, description, colour }) => {
        await handleCreateTag({
            name,
            description,
            colour
        });

        setDialog(null);
    };

    const handleSubmitUpdateTag = async tag => {
        await handleUpdateTag(tag.id, {
            name: tag.name,
            description: tag.description,
            colour: tag.colour
        });

        setDialog(null);
    };

    const handleSubmitDeleteTag = async id => {
        await handleDeleteTag(id);
        setDialog(null);
    };

    const handleSort = async field => {
        if (field === sort.by) {
            setSort({ ...sort, desc: !sort.desc });
        }
        else {
            setSort({ by: field, desc: !sort.desc });
        }
    };

    useEffect(() => {
        let sortedTags = filteredTags;

        if (sort.by === 'name') {
            if (sort.desc) {
                sortedTags.sort((a, b) => {
                    const aValue = a.name.toLowerCase();
                    const bValue = b.name.toLowerCase();

                    if (aValue < bValue) {
                        return 1;
                    }

                    if (aValue > bValue) {
                        return -1;
                    }

                    return 0;
                });
            }
            else {
                sortedTags.sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    if (nameA < nameB) {
                        return -1;
                    }

                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
            }
        }

        if (sort.by === 'description') {
            if (sort.desc) {
                sortedTags.sort((a, b) => {
                    const aValue = (a.description || '').toLowerCase();
                    const bValue = (b.description || '').toLowerCase();

                    if (aValue < bValue) {
                        return 1;
                    }

                    if (aValue > bValue) {
                        return -1;
                    }

                    return 0;
                });
            }
            else {
                sortedTags.sort((a, b) => {
                    const nameA = (a.description || '').toLowerCase();
                    const nameB = (b.description || '').toLowerCase();

                    if (nameA < nameB) {
                        return -1;
                    }

                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
            }
        }

        setFilteredTags([...sortedTags]);
    }, [searchValue, sort, tags]);

    useEffect(() => {
        if (searchValue) {
            setFilteredTags(tags.filter(e => e.name.toLowerCase().includes(searchValue.toLowerCase())));
        }
        else {
            setFilteredTags(tags);
        }
    }, [tags, searchValue]);

    return (
        <>
            <Box sx={{ px: 2, my: 2 }}>
                <Toolbar disableGutters sx={{ mx: 1 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <OutlinedInput
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            placeholder="Search Tags..."
                            size="small"
                            sx={{ mx: 1 }}
                            endAdornment={(
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={clearFilterTopics}
                                        edge="end"
                                        disabled={!Boolean(searchValue)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )}
                        />
                    </Box>
                    <Button
                        onClick={() => setDialog(
                            <CreateEditTagDialog
                                onClose={() => setDialog(false)}
                                onSubmit={handleSubmitCreateTag}
                            />
                        )}
                    >
                        Add Tag
                    </Button>
                </Toolbar>
                <Divider />
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row" align="left" sx={{ width: '40%' }}>
                                    <TableSortLabel
                                        active={sort.by === 'name'}
                                        direction={sort.desc ? 'desc' : 'asc'}
                                        onClick={() => handleSort('name')}
                                    >
                                        Tag
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell component="th" scope="row" align="left" sx={{ width: '40%' }}>
                                    <TableSortLabel
                                        active={sort.by === 'description'}
                                        direction={sort.desc ? 'desc' : 'asc'}
                                        onClick={() => handleSort('description')}
                                    >
                                        Description
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={actionsCell} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredTags.map(tag => (
                                <TableRow key={tag.id}>
                                    <TableCell align="left">
                                        <Tag
                                            colour={tag.colour}
                                            name={tag.name}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        {tag.description}
                                    </TableCell>
                                    <TableCell align="right" sx={actionsCell}>
                                        <TagMenu
                                            onUpdate={() => {
                                                setDialog(
                                                    <CreateEditTagDialog
                                                        onClose={() => setDialog(false)}
                                                        onSubmit={handleSubmitUpdateTag}
                                                        tag={tag}
                                                    />
                                                );
                                            }}
                                            onDelete={() => {
                                                setDialog(
                                                    <DeleteTagDialog
                                                        onClose={() => setDialog(false)}
                                                        onDelete={handleSubmitDeleteTag}
                                                        tag={tag}
                                                    />
                                                );
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {dialog}
        </>
    );
};

export default Tags;