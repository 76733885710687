/*eslint no-invalid-regexp: ["error", { "allowConstructorFlags": ["v", "g"] }]*/
import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import enLocale from 'date-fns/locale/en-GB';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ListSubheader from '@mui/material/ListSubheader';
import CampaignSelectButton from '../campaign/CampaignSelectButton';
import { filters as columns, types } from './contactFilters';

dayjs.extend(customParseFormat);

// todo can we do this using grid/stack?
const fieldStyle = { width: 218 };

const stringValueRegex = new RegExp('[a-zA-Z0-9\\-_\\(\\).@\\s]', 'gv');

const handleGetColumn = ({ field, customFields }) => columns.find(c => c.name.toLowerCase() === field.toLowerCase()) || customFields.find(f => f.name.toLowerCase() === field.toLowerCase());

const handleCheckFieldValidity = ({ type, value }) => {
    if (value === null) {
        return false;
    }

    if (type === 'string') {
        return value?.toString().match(stringValueRegex)?.length === value.length && value.trim().length > 0;
    }

    if (type === 'date') {
        const date = new Date(value);

        if (isNaN(date.getTime())) {
            return false;
        }

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate.length === 10;
    }

    return true;
};

const ValueField = ({ customFields, field, operator, value, setValue }) => {
    const column = handleGetColumn({ field, customFields });
    const type = column?.type;
    const isValid = handleCheckFieldValidity({ type, value });

    if (type === 'bool') {
        return (
            <FormControl sx={fieldStyle} size="small" margin="none">
                <InputLabel id="value-bool-label">Value</InputLabel>
                <Select
                    labelId="value-bool-label"
                    id="value-bool-select"
                    label="Value"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                </Select>
            </FormControl>
        );
    }

    if (type === 'number' || type === 'range') {
        return (
            <FormControl sx={fieldStyle}>
                <TextField
                    id="value-numberfield"
                    label="Value"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    inputProps={{ //text-type workaround to known MUI issue
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    size="small"
                    margin="none"
                    disabled={operator === 'ever' || operator === 'never'}
                />
            </FormControl>
        );
    }

    if (type === 'date') {
        const isValidDate = value => {
            if (!value) return false;

            const date = new Date(value);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const formattedDate = `${day}/${month}/${year}`;

            return formattedDate.length === 10;

        };

        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                <DatePicker
                    mask="__/__/____"
                    renderInput={props => (
                        <TextField size="small"
                            {...props}
                            sx={fieldStyle}
                            error={Boolean(value && !isValidDate(value))}
                            helperText={!value || isValidDate(value) ? null : 'Invalid date format'}
                        />
                    )}
                    label="Value"
                    value={value}
                    onChange={value => setValue(value)}
                    showToolbar
                />
            </LocalizationProvider>
        );
    }

    if (type === 'select') {
        return (
            <FormControl sx={fieldStyle} size="small" margin="none">
                <InputLabel id="value-select-label">Value</InputLabel>
                <Select
                    labelId="value-select-label"
                    id="value-select-select"
                    label="Value"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                >
                    {column.options.map(e => <MenuItem key={e.value} value={e.value}>{e.displayValue}</MenuItem>)}
                </Select>
            </FormControl>
        );
    }

    if (type === 'campaign') {
        return (
            <CampaignSelectButton
                value={value}
                onSetValue={value => setValue(value)}
                fieldStyle={fieldStyle}
            />
        );
    }

    return (
        <FormControl sx={fieldStyle}>
            <TextField
                id="value-stringfield"
                label="Value"
                value={value}
                onChange={e => setValue(e.target.value)}
                size="small"
                margin="none"
                error={Boolean(value && !isValid)}
                helperText={!value || isValid ? null : 'Unfilterable character'}
            />
        </FormControl>
    );
};

const Filter = ({ onApplyFilter, onDeleteFilter, isSegment, customFields, filter = null, numberOfFiltersApplied = 0, isFirst = false }) => {
    const [field, setField] = useState('');
    const [operator, setOperator] = useState('');
    const [value, setValue] = useState('');
    const [operators, setOperators] = useState([]);
    const [unappliedChanges, setUnappliedChanges] = useState(false);
    // const [isValid, setIsValid] = useState(false);

    const handleApplyFilter = e => {
        e.preventDefault();

        const filter = {
            field,
            operator,
            value
        };

        onApplyFilter(filter);
    };

    useEffect(() => {
        setField(filter?.field);
        setOperator(filter?.operator);
        setValue(filter?.value);
    }, [filter]);

    useEffect(() => {
        let type;

        const column = columns.find(c => c.name.toLowerCase() === field.toLowerCase());

        if (column) {
            type = types.find(t => t.name === column.type);
        }
        else if (customFields) {
            const customField = customFields.find(f => f.name.toLowerCase() === field.toLowerCase());

            if (customField) {
                type = types.find(t => t.name === customField.type);
            }
        }

        if (type) {
            const { operators, defaultValue } = type;
            setOperators(operators);

            if (!operators.find(o => o.value === operator)) {
                setOperator(operators[0].value);
            }

            // alternatively we could retain the field if type is the same

            if (field !== filter.field) {
                setValue(defaultValue);
            }
        }
    }, [field]);

    useEffect(() => {
        setUnappliedChanges(field !== filter?.field || operator !== filter?.operator || value !== filter?.value);
    }, [filter, field, operator, value]);

    const column = handleGetColumn({ field, customFields });
    const type = column?.type;
    const isValid = handleCheckFieldValidity({ type, value });

    return (
        <>
            <form onSubmit={handleApplyFilter}>
                <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ marginBottom: 2 }} alignItems="flex-start">
                    {!isFirst && (
                        <IconButton
                            onClick={onDeleteFilter}
                            disabled={numberOfFiltersApplied === 0}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                    <FormControl sx={fieldStyle} size="small" margin="none">
                        <InputLabel id="field-label">Field</InputLabel>
                        <Select
                            labelId="field-label"
                            id="field-select"
                            value={field.toLowerCase()}
                            label="Field"
                            onChange={e => setField(e.target.value)}
                        >
                            {columns.filter(f => f.category === 'standard').map(f => (
                                <MenuItem
                                    key={f.name}
                                    value={f.name.toLowerCase()}
                                >
                                    {f.displayName}
                                </MenuItem>
                            ))}
                            {customFields.length > 0 && (
                                <ListSubheader color="primary">Custom Fields</ListSubheader>
                            )}
                            {customFields.length > 0 && customFields.map(f => (
                                <MenuItem
                                    key={f.id}
                                    value={f.name.toLowerCase()}
                                >
                                    {f.name}
                                </MenuItem>
                            ))}
                            {isSegment && <ListSubheader color="primary">Behaviour</ListSubheader>}
                            {isSegment && columns.filter(f => f.category === 'behaviour').map(f => (
                                <MenuItem
                                    key={f.name}
                                    value={f.name.toLowerCase()}
                                >
                                    {f.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={fieldStyle} size="small" margin="none">
                        <InputLabel id="operator-label">Operator</InputLabel>
                        <Select
                            labelId="operator-label"
                            id="operator-select"
                            value={operator}
                            label="Operator"
                            onChange={e => setOperator(e.target.value)}
                        // disabled={!operators || operators.length <= 1}
                        >
                            {operators && operators.map(o => (
                                <MenuItem key={o.value} value={o.value}>{o.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <ValueField
                        customFields={customFields}
                        field={field}
                        operator={operator}
                        value={value}
                        setValue={setValue}
                    />
                    <Button
                        type="submit"
                        disabled={!(isValid && unappliedChanges) || !isValid}
                        sx={{ minWidth: 80 }}
                    >
                        {/* {filter?.field ? 'Update' : 'Apply'} */}
                        Apply
                    </Button>
                </Stack>
            </form>
        </>
    );
};

export default Filter;