import React, { useState } from 'react';
import { format } from 'date-fns';
// Material UI
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useMediaQuery } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PaletteIcon from '@mui/icons-material/Palette';
import GroupsIcon from '@mui/icons-material/Groups';
import SendIcon from '@mui/icons-material/Send';
import ContactsIcon from '@mui/icons-material/Contacts';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BarChartIcon from '@mui/icons-material/BarChart';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AvatarGroup from '@mui/material/AvatarGroup';

const greyColor = grey[600];

const cellStyles = {
    whiteSpace: 'nowrap'
};

const UserActionIcons = ({ name, action }) => {
    const iconMapping = {
        'Edit': <ModeEditIcon />,
        'Delete': <DeleteIcon />,
        'Add Feature': <AddIcon />
    };

    return (
        <>
            <Button sx={{ color: greyColor }} variant="text" onClick={action} startIcon={iconMapping[name]}>
                {name}
            </Button>
        </>
    );
};

const UserMenu = ({ onEdit, onDelete, onAddFeature }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(null);
    };

    const handleAction = (e, action) => {
        e.stopPropagation();

        action();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick} size="small" sx={{ marginRight: 1 }}>
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={e => handleAction(e, onAddFeature)}>
                    <ListItemIcon>
                        <AddIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Add Feature</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleAction(e, onEdit)}>
                    <ListItemIcon>
                        <ModeEditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleAction(e, onDelete)}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

const iconMapping = {
    'Users': { icon: <PersonAddIcon /> },
    'Brand': { icon: <PaletteIcon /> },
    'Teams': { icon: <GroupsIcon /> },
    'Create & Send': { icon: <SendIcon /> },
    'Contacts': { icon: <ContactsIcon /> },
    'Automations': { icon: <AutorenewIcon /> },
    'Reports': { icon: <BarChartIcon /> },
    'API': { icon: <VpnKeyIcon /> }
};

const UserPermissionIcons = ({ permissions }) => {
    const icons = permissions.map((permission, index) => {
        const iconData = iconMapping[permission.label];

        if (!iconData) {
            return null;
        }

        return (
            <Tooltip title={permission.label} key={index} arrow>
                <Avatar key={index} sx={{ backgroundColor: permission.colour }}>
                    {iconData.icon}
                </Avatar>
            </Tooltip>
        );
    }).filter(Boolean);

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <AvatarGroup max={4}>
                {icons}
            </AvatarGroup>
        </Box>
    );
};

const UsersList = ({ users, userLimit, onAdd, onEdit, onDelete, toggleFeature }) => {
    const theme = createTheme({
        breakpoints: {
            values: {
                lg: 1570
            }
        }
    });

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const UsersTable = () => {
        if (users && users.length > 0) {
            return (
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {!isSmallScreen ? (
                                    <>
                                        <TableCell component="th" sx={{ ...cellStyles, width: 75 }}>Avatar</TableCell>
                                        <TableCell component="th" sx={cellStyles}>Name</TableCell>
                                        <TableCell component="th" sx={{ ...cellStyles, width: '15%' }}>Email Address</TableCell>
                                        <TableCell component="th" sx={cellStyles}>Provider</TableCell>
                                        <TableCell component="th" sx={cellStyles}>Added</TableCell>
                                        <TableCell component="th" sx={cellStyles}>Last Login</TableCell>
                                        <TableCell component="th" sx={{ ...cellStyles, width: 15 }}>User Permissions</TableCell>
                                        <TableCell component="th" sx={{ ...cellStyles, width: 15 }} align="right" />
                                        <TableCell component="th" sx={{ ...cellStyles, width: 15 }} align="right" />
                                        <TableCell component="th" sx={{ ...cellStyles, width: 15 }} align="right" />
                                    </>
                                ) : (
                                    <>
                                        <TableCell component="th" sx={cellStyles}>Name</TableCell>
                                        <TableCell component="th" sx={{ ...cellStyles, width: '10%' }}>Email Address</TableCell>
                                        <TableCell component="th" sx={cellStyles}>Added</TableCell>
                                        <TableCell component="th" sx={cellStyles}>Last Login</TableCell>
                                        <TableCell component="th" sx={cellStyles} align="right" />
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map(user => (
                                <TableRow key={user.id}>
                                    {!isSmallScreen ? (
                                        <>
                                            <TableCell><Avatar alt={user.name} src={user.avatarURL} /></TableCell>
                                            <TableCell sx={cellStyles}>{user.name ?? '-'}</TableCell>
                                            <TableCell sx={cellStyles}>{user.emailAddress}</TableCell>
                                            <TableCell sx={cellStyles}>{user.provider ?? 'Username & Password'}</TableCell>
                                            <TableCell sx={cellStyles}>{format(new Date(user.createdDateTime), 'dd/MM/yyyy HH:mm')}</TableCell>
                                            <TableCell sx={cellStyles}>{user.lastLoggedIn ? format(new Date(user.lastLoggedIn), 'dd/MM/yyyy HH:mm') : '-'}</TableCell>
                                            <TableCell sx={cellStyles}>
                                                {user.permissions.length > 0 ? (
                                                    <UserPermissionIcons permissions={user.permissions} />
                                                ) : (
                                                    '-'
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ ...cellStyles, paddingX: 2 }} align="right">
                                                <UserActionIcons name="Edit" action={() => onEdit(user)} />
                                            </TableCell>
                                            <TableCell sx={{ ...cellStyles, paddingX: 2 }} align="right">
                                                <UserActionIcons name="Delete" action={() => onDelete(user)} />
                                            </TableCell>
                                            <TableCell sx={{ ...cellStyles, pr: 1 }} align="right">
                                                <UserActionIcons name="Add Feature" action={() => toggleFeature(user)} />
                                            </TableCell>
                                        </>
                                    ) : (
                                        <>
                                            <TableCell sx={{ ...cellStyles, maxWidth: 350 }}>
                                                <Chip
                                                    avatar={<Avatar alt={user.name} src={user.avatarURL} />}
                                                    label={user.name}
                                                    variant="outlined"
                                                    sx={{ height: 'auto', border: 'none', fontSize: '14px', '& .MuiChip-label': { display: 'block', whiteSpace: 'normal' } }}
                                                />
                                            </TableCell>
                                            <TableCell sx={cellStyles}>
                                                {user.emailAddress}
                                                <Tooltip arrow title={`Provider : ${user.provider ?? 'Username & Password'}`}>
                                                    <Chip sx={{ marginLeft: 2 }} label={user.provider ?? 'Username & Password'} size="small" variant="outlined" />
                                                </Tooltip>
                                            </TableCell>
                                            <Tooltip arrow title={format(new Date(user.createdDateTime), 'dd/MM/yyyy HH:mm')}>
                                                <TableCell sx={cellStyles}>
                                                    {format(new Date(user.createdDateTime), 'dd/MM/yyyy')}
                                                </TableCell>
                                            </Tooltip>
                                            <Tooltip arrow title={user.lastLoggedIn ? format(new Date(user.lastLoggedIn), 'dd/MM/yyyy HH:mm') : ''}>
                                                <TableCell sx={cellStyles}>
                                                    {user.lastLoggedIn ? format(new Date(user.lastLoggedIn), 'dd/MM/yyyy HH:mm') : '-'}
                                                </TableCell>
                                            </Tooltip>
                                            <TableCell sx={cellStyles} align="right">
                                                <UserMenu
                                                    onEdit={() => onEdit(user)}
                                                    onDelete={() => onDelete(user)}
                                                    onAddFeature={() => toggleFeature(user)}
                                                />
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }

        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="body2">This account has no users.</Typography>
            </Box>
        );
    };

    return (
        <>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{ px: 2 }}
            >
                <div style={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">
                        Users
                    </Typography>
                </div>
                <Button
                    size="small"
                    onClick={onAdd}
                    startIcon={<AddIcon />}
                    disabled={users.length >= userLimit}
                >
                    Add User
                </Button>
            </Toolbar>
            <Divider />
            <UsersTable />
        </>
    );
};

export default UsersList;
