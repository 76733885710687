import React, { useState, useEffect } from 'react';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import useApi from '../../hooks/useApi';
import TitleBar from '../../titleBar/TitleBar';
import Gauge from './charts/Gauge';

const Products = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState();
    const { handleGet } = useApi();

    const getProducts = async () => {
        setIsLoading(true);
        const response = await handleGet('account/products');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        setProducts(data);
        setIsLoading(false);
    };

    useEffect(() => {
        getProducts();
    }, []);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    if (products.length === 0) {
        return (
            <Box sx={{ padding: 2 }}>
                <Typography variant="body1">Your account has no active products.</Typography>
            </Box>
        );
    }

    const availableProducts = products
        .filter(p => p.used !== true)
        .map(p => (p.usageLimit - p.emailsSent));

    const totalProducts = products
        .filter(p => (p.used !== true) && (p.emailsSent < p.usageLimit))
        .map(p => (p.usageLimit));

    const total = totalProducts.length > 0 ? totalProducts.reduce((acc, curr) => acc + curr, 0) : 0;

    const quota = availableProducts.length > 0 ? availableProducts.reduce((total, remaining) => total + remaining) : 0;

    return (
        <>
            <TitleBar title="Usage & Products" />
            <Box sx={{ p: 2 }}>
                <Paper>
                    <Gauge total={total} quota={quota} />
                </Paper>
                <Paper sx={{ mt: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row">Product Name</TableCell>
                                <TableCell component="th" scope="row">Start Date/Time</TableCell>
                                <TableCell component="th" scope="row">Renewal Date/Time</TableCell>
                                <TableCell component="th" scope="row" align="right">Emails Sent</TableCell>
                                <TableCell component="th" scope="row" align="right">Invoice No.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map(product => (
                                <TableRow key={product.id}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product.startDate ? new Date(product.startDate).toLocaleString('en-GB') : '-'}</TableCell>
                                    <TableCell>{product.renewalDate ? new Date(product.renewalDate).toLocaleString('en-GB') : '-'}</TableCell>
                                    <TableCell align="right">{product.usageLimit > 0 ? `${product.emailsSent}/${product.usageLimit}` : '-'}</TableCell>
                                    <TableCell align="right">{product.invoiceNumber ? <Link href={product.invoiceUrl} target="_blank">{product.invoiceNumber}</Link> : '-'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Box>
        </>
    );
};

export default Products;