import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

const AllowedRecipientsList = ({ allowedRecipients }) => {
    return (
        <Alert severity="info">
            <AlertTitle>Allowed Recipients</AlertTitle>
            <Typography variant="body2" marginBottom={1}>
                Your account can only send emails to internal contacts. Contacts that do not match the below will fail when attempting to be imported.
            </Typography>
            {allowedRecipients.map(e => <Chip key={e.id} label={e.pattern} sx={{ marginBottom: 1, marginRight: 1 }} />)}
        </Alert>
    );
};

export default AllowedRecipientsList;