import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Picker from '../../picker/Picker';
import { IconButton, Typography, TableBody, TableRow, Table } from '@mui/material';
import useAccount from '../../hooks/useAccount';
import CreateEditTagDialog from '../../account/tags/dialogs/CreateEditTagDialog';

const nameRegex = /[a-zA-Z0-9\s-]/g;

const EditSegmentDialog = ({ onClose, onSubmit, segment }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isValid, setIsValid] = useState(false);

    const [showDialog, setShowDialog] = useState(false);
    const [selectedTags, setSelectedTags] = useState(segment.tags ?? []);
    const { tags, handleCreateTag } = useAccount();

    const handleSubmit = async e => {
        e.preventDefault();

        await onSubmit({
            name,
            groupId: segment.groupId,
            id: segment.id,
            filters: segment.filters,
            ...(description && { description }),
            ...(selectedTags && { tags: selectedTags })
        });

        onClose();
    };

    const handleAddTag = async ({ name, description, colour }) => {
        await handleCreateTag({
            name,
            description,
            colour
        });

        setShowDialog(false);
    };

    useEffect(() => {
        segment && setName(segment.name);
        segment?.description && setDescription(segment.description);
    }, [segment]);

    useEffect(() => {
        setIsValid(name.trim() !== '' && (name.match(nameRegex)?.length === name.length));
    }, [name]);

    return (
        <>
            <Dialog onClose={onClose} open={true}>
                <DialogTitle>Edit Segment</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Stack spacing={2}>
                            <TextField
                                autoFocus
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                value={name}
                                required
                                onChange={e => setName(e.target.value)}
                                margin="none"
                                error={!isValid}
                                helperText="Name must be alphanumeric. Hyphens and spaces are allowed."
                            />
                            <TextField
                                id="description"
                                label="Description"
                                type="text"
                                fullWidth
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                margin="none"
                            />
                        </Stack>
                        <div>
                            <Stack direction="row" justifyContent="space-between">
                                <Typography sx={{ mt: 1 }}>
                                Tag this segment
                                </Typography>
                                <Typography title="Add New Tag">
                                    <IconButton
                                        onClick={() => setShowDialog(true)}
                                        color="primary"
                                        size="small"
                                    >
                                        <AddCircleIcon />
                                    </IconButton>
                                </Typography>
                            </Stack>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <Picker
                                            availableItems={tags}
                                            selectedItems={selectedTags}
                                            singleInstance={true}
                                            isTags={true}
                                            onChangeItems={selectedTags => setSelectedTags(selectedTags)}
                                            border={false}
                                        />
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" disabled={!isValid}>Save Changes</Button>
                        <Button variant="outlined" onClick={onClose}>Cancel</Button>
                    </DialogActions>
                </form>
            </Dialog>
            {showDialog && (
                <CreateEditTagDialog
                    onClose={() => setShowDialog(false)}
                    onSubmit={handleAddTag}
                />
            )}
        </>
    );
};

export default EditSegmentDialog;