import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { Alert } from '@mui/material';

const DeleteAllowedRecipientDialog = ({ pattern, customerId, onSubmit, onCancel }) => {
    return (
        <Dialog
            open={true}
            onClose={onCancel}
            maxWidth="sm"
        >
            <DialogTitle>Delete Allowed Recipient</DialogTitle>
            <DialogContent dividers>
                <Alert severity="error" style={{ marginBottom: '16px' }}>
                    You are about to permanently delete the allowed recipient <strong>{pattern}</strong> from account <strong>{customerId}</strong>.
                </Alert>
                <DialogContentText>
                    Are you sure you want to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="outlined">Back to Safety</Button>
                <Button onClick={onSubmit} color="error">Delete Allowed Recipient</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteAllowedRecipientDialog;