import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Start from '../start/Start';
import Contacts from '../contacts/Contacts';
import Reports from '../reports/Reports';
import Account from '../account/Account';
import Editor from '../editor/Editor';
import Automations from '../automations/Automations';
import SuperAdmin from '../superAdmin/SuperAdmin';

const Workarea = () => {
    return (
        <Switch>
            <Route path="/reports">
                <Reports />
            </Route>
            <Route path="/account">
                <Account />
            </Route>
            <Route path="/editor">
                <Editor />
            </Route>
            <Route path="/contacts">
                <Contacts />
            </Route>
            <Route path="/automations">
                <Automations />
            </Route>
            <Route path="/superadmin">
                <SuperAdmin />
            </Route>
            <Route path="/">
                <Start />
            </Route>
        </Switch>
    );
};

export default Workarea;