import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const EditFieldDialog = ({ field, onSubmit, onClose }) => {
    const [name, setName] = useState(field.name);
    const [display, setDisplay] = useState(field.display);

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Edit Custom Field</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are about to edit '{field.name}'.
                </DialogContentText>
                <TextField
                    autoFocus
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={name}
                    onChange={e => setName(e.target.value)}
                    margin="dense"
                />
                <FormControlLabel
                    control={(
                        <Switch
                            checked={display}
                            onChange={e => setDisplay(e.target.checked)}
                            name="enabled"
                        />
                    )}
                    label="Enabled"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onSubmit(name, display)}>OK</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditFieldDialog;