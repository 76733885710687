import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

const ContentInUseDialog = ({ usersEditing, type = 'email' }) => {
    return (
        <Dialog open={true}>
            <DialogTitle>{`${type.charAt(0).toUpperCase()}${type.substring(1)}`} Locked</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 2 }}>
                    This {type} is currently locked for editing by
                </DialogContentText>
                <List>
                    {usersEditing.map(user => {
                        return (
                            <ListItem key={user.email}>
                                <ListItemText primary={user.name || user.email} />
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button component={Link} to="/editor">
                    Return to Create &amp; Send
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ContentInUseDialog;
