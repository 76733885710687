import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { format } from 'date-fns';
import CampaignPreview from '../../campaignPreview/CampaignPreview';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Topic from '../../topics/Topic';
import useAccount from '../../hooks/useAccount';
import useApi from '../../hooks/useApi';
import buildUrl from '../../buildUrl';
import ShoppingList from '../../editor/ShoppingList';
import PreviewContactsDialog from '../dialogs/PreviewContactsDialog';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ToggleConditions from '../../campaignPreview/ToggleConditions';
import SendingError from '../SendingError';

const tableHead = {
    width: '25%',
    minWidth: 150
};

const ToggleDevice = ({ device, onChangeDevice }) => {
    const handleChange = (event, value) => {
        value && onChangeDevice(value);
    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={device}
            exclusive
            onChange={handleChange}
        >
            <ToggleButton value="desktop">Desktop</ToggleButton>
            <ToggleButton value="mobile">Mobile</ToggleButton>
        </ToggleButtonGroup>
    );
};

const Audience = ({ campaign, audienceName, audience }) => {
    if (campaign.segmentId || campaign.groupIds?.length > 0) {
        return `${audienceName}: ${audience}`;
    }

    if (campaign.emailAddresses?.length > 0) {
        return campaign.emailAddresses.join(', ');
    }

    return audienceName;
};

const SendCompleteDialog = ({ isSent, campaign, onClose, sendingError }) => {
    if (isSent) {
        return (
            <Dialog
                open={true}
                onClose={onClose}
            >
                <DialogTitle>Congratulations - Campaign {campaign.sendDateTime ? 'Scheduled' : 'Sent'}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                        Your campaign has been {campaign.sendDateTime ? `scheduled to send at ${format((new Date(campaign.sendDateTime)), 'HH:mm \'on\' MMMM do yyyy')}.` : 'sent successfully and is now on its way.'}
                    </Typography>
                    <Typography variant="body1">
                        Remember to monitor your campaign. You can see the activity and engagement as it happens in Reports.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog
            open={true}
            onClose={onClose}
        >
            <DialogTitle>Campaign not sent</DialogTitle>
            <DialogContent>
                <Alert severity="error" sx={{ mb: 2 }}>
                    {sendingError || 'We have encountered a problem'}
                </Alert>
                <SendingError error={sendingError} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Send = ({ emailId, campaign, previewHtml, onBack, topics, drawerWidth, steps, step, isProofSent, audienceName, qrCodeContent, titleBarDepth, setStep, type }) => {
    const history = useHistory();
    const [numberOfSubscribers, setNumberOfSubscribers] = useState(campaign?.emailAddresses?.length || 0);
    const [isLoading, setIsLoading] = useState(true);
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [sendingError, setSendingError] = useState(null);
    const [audience, setAudience] = useState(null);
    const [showSendCompleteDialog, setShowSendCompleteDialog] = useState(false);
    const [showPreviewContactsDialog, setShowPreviewContactsDialog] = useState(false);
    const [device, setDevice] = useState('desktop');
    const { smtpUsername } = useAccount();
    const { handleGet, handlePost } = useApi();
    const [finalHtml, setFinalHtml] = useState(null);

    const [availableConditions, setAvailableConditions] = useState(null);
    const [conditions, setConditions] = useState([]);

    const handleSetAvailableConditions = conditions => {
        setAvailableConditions(conditions);
        setConditions(conditions);
    };

    const handleFetchNumberOfSubscribers = async () => {
        const url = buildUrl('campaigns/audience-count', {
            ...(campaign.groupIds && { groupIds: campaign.groupIds }),
            ...(campaign.segmentId && { segmentId: campaign.segmentId })
        });

        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        data && setNumberOfSubscribers(data);
    };

    const handlePostCampaign = async html => {
        const response = await handlePost('campaigns', {
            name: campaign.name,
            subject: campaign.subject,
            fromName: campaign.fromName,
            fromEmail: smtpUsername || campaign.fromEmail,
            googleAnalyticsCampaign: campaign.gaCampaign,
            sendDateTime: campaign.sendDateTime,
            sendToAll: campaign.sendToAll,
            groupIds: campaign.groupIds,
            segmentId: campaign.segmentId,
            emailAddresses: campaign.emailAddresses,
            html,
            emailId,
            qrCodeId: qrCodeContent?.qrCodeId,
            qrCodeStartDate: qrCodeContent?.startDate,
            qrCodeEndDate: qrCodeContent?.endDate,
            type
        });

        if (response?.ok) {
            setIsSent(true);
        }
        else {
            setIsSent(false);
            const error = await response.json();
            setSendingError(error.message);
        }

        setShowSendCompleteDialog(true);
    };

    const handleSend = () => {
        setIsSending(true);

        handlePostCampaign(previewHtml);
    };

    const handleCloseSentDialog = () => {
        if (isSent) {
            history.push('/editor');
        }
        else {
            setIsSending(false);
            setShowSendCompleteDialog(false);
        }
    };

    const handleGetSegments = async () => {
        const response = await handleGet('segments');

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const data = await response.json();

        const audience = data.find(e => e.id === campaign.segmentId).name;

        setAudience(audience);
    };

    const handleGetGroups = async () => {
        const response = await handleGet('groups');

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const data = await response.json();

        const audience = data.filter(e => campaign.groupIds.includes(e.id)).map(e => e.name).join(', ');

        setAudience(audience);
    };

    const handleGetAudience = async () => {
        if (!campaign.emailAddresses || campaign.emailAddresses.length === 0) {
            if (campaign.segmentId) {
                await handleGetSegments();
            }

            if (campaign.groupIds?.length > 0) {
                await handleGetGroups();
            }

            handleFetchNumberOfSubscribers();
        }
    };

    const handleGetFinalHTML = async () => {
        const response = await handlePost(`emails/${emailId}/preview`, {
            previewHtml
        });

        if (!response.ok) {
            console.error(response?.error);
            setIsLoading(false);
            return;
        }

        const data = await response.text();

        setFinalHtml(data);
    };

    useEffect(() => {
        const getData = async () => {
            await handleGetAudience();
            await handleGetFinalHTML();
            setIsLoading(false);
        };

        getData();
    }, []);

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64, zIndex: titleBarDepth }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">Final Checks</Typography>
                </Toolbar>
            </AppBar>
            <div style={{ marginRight: drawerWidth, flexGrow: 1 }}>
                {isLoading ? <LoadingOverlay /> : (
                    <Box p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} xl={5}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Alert severity="info">Please check all your details are correct before sending.</Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell variant="head" sx={tableHead}>
                                                            To
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body2">
                                                                <Audience
                                                                    campaign={campaign}
                                                                    audience={audience}
                                                                    audienceName={audienceName}
                                                                />
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head" sx={tableHead}>
                                                            From
                                                        </TableCell>
                                                        <TableCell>
                                                            {campaign.fromName} ({smtpUsername || campaign.fromEmail})
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head" sx={tableHead}>
                                                            Subject
                                                        </TableCell>
                                                        <TableCell>
                                                            {campaign.subject}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head" sx={tableHead}>
                                                            Google Analytics Campaign Name
                                                        </TableCell>
                                                        <TableCell>
                                                            {campaign.gaCampaign}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head" sx={tableHead}>
                                                            Topics
                                                        </TableCell>
                                                        <TableCell>
                                                            {topics && topics.map(e => <Topic key={e.id} name={e.name} colour={e.colour} />)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head" sx={tableHead}>
                                                            Contacts selected
                                                        </TableCell>
                                                        <TableCell>
                                                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                                {numberOfSubscribers}
                                                                <Button
                                                                    endIcon={<OpenInNewIcon />}
                                                                    onClick={() => setShowPreviewContactsDialog(true)}
                                                                    disabled={numberOfSubscribers === 0}
                                                                >
                                                                    Preview Audience
                                                                </Button>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head" sx={tableHead}>
                                                            QR Code
                                                        </TableCell>
                                                        <TableCell>
                                                            {qrCodeContent ? (
                                                                <>
                                                                    <Typography variant="body1">{qrCodeContent.name}</Typography>
                                                                    <Typography variant="body2">
                                                                        From {format((new Date(qrCodeContent.startDate)), 'HH:mm \'on\' MMMM do yyyy')}{qrCodeContent.endDate ? ` until ${format((new Date(qrCodeContent.endDate)), 'HH:mm \'on\' MMMM do yyyy')}` : ', indefinitely'}
                                                                    </Typography>
                                                                </>
                                                            ) : ''}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head" sx={tableHead}>
                                                            Time to Send
                                                        </TableCell>
                                                        <TableCell>
                                                            {campaign.sendDateTime ? format((new Date(campaign.sendDateTime)), 'HH:mm \'on\' MMMM do yyyy') : 'Immediately'}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} xl={7}>
                                <Card>
                                    <CardHeader
                                        action={(
                                            <Stack direction="row" spacing={2}>
                                                <ToggleConditions
                                                    availableConditions={availableConditions}
                                                    conditions={conditions}
                                                    onChangeConditions={setConditions}
                                                />
                                                <ToggleDevice
                                                    device={device}
                                                    onChangeDevice={e => setDevice(e)}
                                                />
                                            </Stack>
                                        )}
                                        title="Campaign Preview"
                                    />
                                    <CardContent>
                                        <CampaignPreview
                                            html={finalHtml}
                                            device={device}
                                            conditions={conditions}
                                            onChangeConditions={handleSetAvailableConditions}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>
            <ShoppingList
                drawerWidth={drawerWidth}
                stepNumber={step}
                steps={steps}
                onNext={handleSend}
                onBack={onBack}
                disabled={isSending}
                campaign={campaign}
                isProofSent={isProofSent}
                audienceName={audienceName}
                setStep={setStep}
            />
            {showSendCompleteDialog && (
                <SendCompleteDialog
                    isSent={isSent}
                    campaign={campaign}
                    onClose={handleCloseSentDialog}
                    sendingError={sendingError}
                />
            )}
            {showPreviewContactsDialog && (
                <PreviewContactsDialog
                    sendToAll={campaign.sendToAll}
                    emailAddresses={campaign.emailAddresses}
                    segmentId={campaign.segmentId}
                    groupIds={campaign.groupIds}
                    onClose={() => setShowPreviewContactsDialog(false)}
                    audienceCount={numberOfSubscribers}
                />
            )}
        </>
    );
};

export default Send;