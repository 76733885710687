import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircleIcon from '@mui/icons-material/Circle';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RenderOption = ({ topic, topics, onClickCheckbox }) => {
    const selected = Boolean(topics.find(e => e.id === topic.id));

    return (
        <MenuItem
            value={topic}
            onClick={() => onClickCheckbox({ topic, selected })}
            sx={{ padding: 0, paddingRight: 1 }}
        >
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                sx={{ marginRight: 1 }}
                checked={selected}
            />
            <CircleIcon fontSize="small" sx={{ color: topic.colour ?? 'rgba(0, 0, 0, 0.08)', marginRight: 1 }} />
            <Typography variant="inherit" noWrap>
                {topic.name}
            </Typography>
        </MenuItem>
    );
};

const TopicFilter = ({ availableTopics, selectedTopics, onRemoveTopic, onAddTopic, onClearTopics }) => {
    const [topics, setTopics] = useState(selectedTopics);

    const handleClearFilter = () => {
        onClearTopics();
        setTopics([]);
    };

    const handleClickCheckbox = ({ topic, selected }) => {
        if (selected) {
            onRemoveTopic(topic);
            setTopics(topics.filter(e => e.id !== topic.id));
        }
        else {
            onAddTopic(topic);

            const newTopics = [...topics, topic];

            const orderedTopics = newTopics.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }

                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });

            setTopics(orderedTopics);
        }
    };

    useEffect(() => {
        setTopics(selectedTopics);
    }, [selectedTopics]);

    const disabled = !availableTopics || availableTopics.length === 0;
    const label = disabled ? 'No Topics available' : 'Filter Topics';

    return (
        <FormControl
            sx={{
                margin: 0,
                marginRight: 1,
                width: 212 //annoying to have to give this a width, but without one it is collapsed
            }}
            size="small"
            disabled={disabled}
        >
            <InputLabel id="topicFilterLabel">{label}</InputLabel>
            <Select
                labelId="topicFilterLabel"
                id="topicFilter"
                multiple
                value={topics}
                input={<OutlinedInput label={label} />}
                renderValue={value => value.map(e => e.name).join(', ')}
                IconComponent={() => null}
                sx={{ paddingRight: 0 }}
                endAdornment={disabled ? undefined : (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleClearFilter}
                            disabled={topics.length === 0}
                        >
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                )}
            >
                {availableTopics?.map(topic => (
                    <RenderOption
                        key={topic.id}
                        topic={topic}
                        topics={topics}
                        onClickCheckbox={handleClickCheckbox}
                    />
                ))}
            </Select>
        </FormControl>
    );
};

export default TopicFilter;