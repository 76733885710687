import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import { Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useApi from '../../hooks/useApi';
import useAccount from '../../hooks/useAccount';
import useSnackbar from '../../hooks/useSnackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

const ToggleUserFeatureModal = ({ shown, onHide }) => {

    const { user: data, handleRefresh } = useAccount();
    const { handlePut } = useApi();
    const { showSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(() => {
        if (data.userFeature.length > 0 && data.feature.find(feature => feature.id === data.userFeature[0].featureId && feature.level === 0)) {
            return data.feature.find(feature => feature.id === data.userFeature[0].featureId && feature.level === 0);
        }
        else if (data.feature.find(feature => feature.level === 1)) {
            return data.feature.find(feature => feature.level === 1);
        }
        else {
            return {};
        }
    });

    const handleDisableFeature = async (data) => {
        const temp = {
            ...data,
            enabled: false
        };
        setIsLoading(true);

        const response = await handlePut(`user/features/${data.featureId}`, temp);

        if (response.ok) {
            showSnackbar('Feature disabled', 'success');
        }
        else {
            showSnackbar('Error while disabling feature', 'error');
        }

        await handleRefresh();
        setIsLoading(false);
    };

    const handleEnableFeature = async (data) => {
        const temp = {
            featureId: data.id,
            enabled: true
        };

        setIsLoading(true);

        const response = await handlePut(`user/features/${data.id}`, temp);

        if (response.ok) {
            showSnackbar('Feature enabled', 'success');
        }
        else {
            showSnackbar('Error while enabling feature', 'error');
        }

        await handleRefresh();
        setIsLoading(false);
    };

    const handleFeatureToggle = () => {
        if (data.userFeature.some(feature => feature.featureId === selectedOption.id && feature.enabled)) {
            handleDisableFeature(data.userFeature.find(feature => feature.featureId === selectedOption.id));
        }
        else {
            handleEnableFeature(selectedOption);
        }
    };

    return (
        <Dialog open={shown} onClose={onHide} maxWidth="md" fullWidth={true}>
            <Paper square variant="outlined">
                <DialogTitle>Feature Preview</DialogTitle>
            </Paper>
            {(data.userFeature.length > 0 && data.feature.some(feature => feature.level === 0 && data.userFeature.some(userFeature => userFeature.featureId === feature.id))) || (data.feature.length > 0 && data.feature.some(feature => feature.level === 1)) ? (
                <Box sx={{ display: 'flex', flexDirection: 'row', height: '400px' }}>
                    <Paper square sx={{ flex: 1, overflow: 'auto' }}>
                        <List>
                            {data.feature
                                .filter(item => item.level === 0 && data.userFeature.some(feature => feature.featureId === item.id))
                                .map(item => (
                                    <ListItem key={item.id} button onClick={() => setSelectedOption(item)} divider>
                                        <ListItemText primary={<Typography variant="subtitle2">{item.name}</Typography>} />
                                        {data.userFeature.some(feature => feature.featureId === item.id && feature.enabled) ? (
                                            <VisibilityIcon color="primary" sx={{ fontSize: 18 }} />
                                        ) : (
                                            <VisibilityOffIcon color="disabled" sx={{ fontSize: 18 }} />
                                        )}
                                    </ListItem>
                                ))}

                            {data.feature
                                .filter(item => item.level === 1)
                                .map(item => (
                                    <ListItem key={item.id} button onClick={() => setSelectedOption(item)} divider>
                                        <ListItemText primary={<Typography variant="subtitle2">{item.name}</Typography>} />
                                        {data.userFeature.some(feature => feature.featureId === item.id && feature.enabled) ? (
                                            <VisibilityIcon color="primary" sx={{ fontSize: 18 }} />
                                        ) : (
                                            <VisibilityOffIcon color="disabled" sx={{ fontSize: 18 }} />
                                        )}
                                    </ListItem>
                                ))}
                        </List>
                    </Paper>

                    <Paper square sx={{ flex: 2, overflow: 'auto' }}>
                        <AppBar position="sticky" color="inherit" sx={{ p: 1, display: 'flex', flexDirection: 'row' }}>
                            <Grid item sx={{ flex: 1, minWidth: 0 }}>
                                <Typography variant="h6" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {selectedOption.name}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleFeatureToggle}
                                    disabled={isLoading}
                                    sx={{ position: 'sticky', top: 0 }}
                                >
                                    {data.userFeature.some(feature => feature.featureId === selectedOption.id && feature.enabled) ? 'Disable' : 'Enable'}
                                </Button>

                            </Grid>
                        </AppBar>
                        <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)', padding: 2 }}>
                            {selectedOption.image && (
                                <img src={selectedOption.image} alt="Alt Text" style={{ maxWidth: '100%', height: 'auto', borderRadius: '15px' }} />
                            )}
                            <Box>
                                <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                                    {selectedOption.description}
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            ) : (
                <Box sx={{ alignItems: 'center', justifyContent: 'center', height: '100%', padding: 3 }}>
                    <Alert severity="info">No Features to enable.</Alert>
                </Box>
            )}
            <DialogActions>
                <Button onClick={onHide} variant="outlined" sx={{ marginRight: 2, marginTop: 1 }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ToggleUserFeatureModal;