import React, { useEffect } from 'react';
import useInput from '../../hooks/useInput';
import TextField from '@mui/material/TextField';

const CustomField = ({ id, name, type, value, onChange, isReverting }) => {
    const input = useInput(value);

    useEffect(() => {
        onChange({ id, value: input.value, hasChanged: input.hasChanged });
    }, [input.value]);

    useEffect(() => {
        if (isReverting) {
            input.onRevert();
        }

    }, [isReverting]);

    return (
        <TextField
            fullWidth
            size="small"
            margin="none"
            id={id.toString()}
            type={type === 'number' ? 'number' : 'text'}
            label={name}
            value={input.value}
            onChange={input.onChange}
        />
    );
};

export default CustomField;