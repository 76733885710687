import { useState } from 'react';

const useInputPhoneNumber = initialValue => {
    const reset = initialValue ?? '';
    const [value, setValue] = useState(reset);

    const handleChange = value => {
        const newValue = value.replace(/[^0-9()+\s]+/, '');

        setValue(newValue);
    };

    return {
        value,
        onChange: event => handleChange(event.target.value),
        onRevert: () => setValue(reset),
        hasChanged: value !== reset
    };
};

export default useInputPhoneNumber;