import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import useApi from '../../hooks/useApi';
import buildUrl from '../../buildUrl';
import NoRows from '../../dataTable/NoRows';
import { DataGridPro } from '@mui/x-data-grid-pro';

const PreviewContactsDialog = ({ onClose, emailAddresses, segmentId, groupIds, audienceCount }) => {
    const manualList = Boolean(emailAddresses?.length > 0);
    const rowsPerPageOptions = manualList ? [20] : [10, 20, 50];

    const { handleGet } = useApi();
    const [audience, setAudience] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [isLoading, setIsLoading] = useState(false);

    const handleFetchAudience = async () => {
        const url = buildUrl('campaigns/audience', {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            ...(groupIds && { groupIds }),
            ...(segmentId && { segmentId })
        });

        const response = await handleGet(url);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const data = await response.json();
        setAudience(data);
    };

    useEffect(() => {
        if (manualList) {
            setAudience(emailAddresses.map((emailAddress, i) => ({ id: i, emailAddress })));
        }
        else {
            setIsLoading(true);
        }
    }, []);

    useEffect(() => {
        audience && setIsLoading(false);
    }, [audience]);

    useEffect(() => {
        !manualList && setIsLoading(true);
    }, [rowsPerPage, page]);

    useEffect(() => {
        isLoading && handleFetchAudience();
    }, [isLoading]);

    return (
        <Dialog onClose={onClose} open={true} fullWidth maxWidth="md">
            <DialogTitle>Preview Audience</DialogTitle>
            <DialogContent>
                {segmentId && <Alert sx={{ mb: 2 }} severity="info">Contacts shown here are those who meet the selected Segment's criteria right now. Depending on your filters this may change at point of send.</Alert>}
                {audience && (
                    <DataGridPro
                        autoHeight
                        disableColumnMenu
                        disableColumnFilter
                        disableMultipleColumnsSorting
                        hideFooterSelectedRowCount
                        pagination
                        paginationMode="server"
                        sortingMode="server"
                        rows={audience}
                        rowCount={audienceCount}
                        rowHeight={32}
                        columns={[{ field: 'emailAddress', headerName: 'Email Address', orderDesc: true, type: 'string', sortable: false, flex: 1 }]}
                        loading={isLoading}
                        pageSize={rowsPerPage}
                        onPageSizeChange={rowsPerPage => setRowsPerPage(rowsPerPage)}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onPageChange={page => setPage(page)}
                        components={{
                            NoRowsOverlay: NoRows
                        }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PreviewContactsDialog;