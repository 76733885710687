import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const trackingId = 'G-XRH8LRFD8P';

const isNumber = n => !isNaN(n);

const getPage = pathname => {
    const parts = pathname.split('/').filter(p => p);

    if (parts.length > 0) {
        const [root] = parts;

        if (root === 'editor' && isNumber(parts[1])) {
            parts[1] = 'email';
        }

        if (root === 'subscribers' && isNumber(parts[1])) {
            parts[1] = 'group';
        }

        if (root === 'reports' && isNumber(parts[1])) {
            parts[1] = 'campaign';
        }

        if (root === 'automations' && parts[1] === 'reports' && isNumber(parts[2])) {
            parts[2] = 'campaign';
        }

        if (root === 'superadmin' && parts[1] === 'templates' && isNumber(parts[2])) {
            parts[2] = 'template';
        }
    }

    return `/${parts.join('/')}`;
};

const useGoogleAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize(trackingId);
    }, []);

    useEffect(() => {
        const page = getPage(location.pathname);
        ReactGA.send({ hitType: 'pageview', page });
    }, [location]);
};

export default useGoogleAnalytics;