import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';

const ConfirmRemoveContactDialog = ({ onClose, emailAddress, onConfirm }) => {

    const handleConfirm = e => {
        e.preventDefault();
        onConfirm();
        onClose();
    };

    //add loading state and await confirm?

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Delete {emailAddress}</DialogTitle>
            <DialogContent>
                <Alert severity="warning" sx={{ mb: 2 }}>
                    Are you sure you want to delete this contact from your Account?
                </Alert>
                <DialogContentText>
                    Restoring Contacts deleted in error will be subject to an administration fee. What's the difference between&nbsp;
                    <Link target="_blank" href="https://newzapp.com/docs/NewZapp-Userguide-How-To-Remove-or-Unsubscribe.pdf">
                        Delete and Unsubscribe?
                    </Link>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>Confirm Delete</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmRemoveContactDialog;