import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useSnackbar from '../../hooks/useSnackbar';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FormControl from '@mui/material/FormControl';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';

const SurveyDialog = ({ open, onHide, onSend, data, isLoading }) => {
    const { showSnackbar } = useSnackbar();
    const baseUrl = window.location.origin;
    const link = `${baseUrl}/#/surveys/${data.id}/${data.verifier}`;

    const copyLink = () => {
        navigator.clipboard.writeText(link);
        showSnackbar('Link copied to clipboard', 'success');
    };

    return (
        <Dialog
            open={open}
            onClose={onHide}
            fullWidth={true}
            maxWidth="sm">
            <DialogTitle sx={{ m: 0, p: 2, display: 'flex' }}>
                Distribute Survey
            </DialogTitle>
            <DialogContent dividers>
                <Typography>
                    You can copy the link below to share your Survey or click the Distribute button to send by email
                </Typography>
                <Box sx={{ position: 'relative' }}>
                    {isLoading && (
                        <LoadingOverlay />
                    )}
                </Box>
                <FormControl sx={{ width: '100%' }} variant="filled">
                    <FilledInput
                        type="text"
                        disabled
                        size="small"
                        defaultValue={link}
                        inputProps={{
                            style: {
                                paddingTop: '10px',
                                paddingBottom: '10px'
                            }
                        }}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={copyLink}
                                    edge="end"
                                >
                                    <ContentCopyIcon />
                                </IconButton>
                            </InputAdornment>
                        )}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onHide}>
                    Cancel
                </Button>
                <Button onClick={onSend}>
                    Distribute
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SurveyDialog;
