import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import { format } from 'date-fns';

const CompletedMessageNode = ({ step, campaign, isProofSent, audienceName }) => {
    switch (step) {
        case 0:
            return (
                <Typography variant="body2">
                    Content created
                </Typography>
            );
        case 1:
            return (
                <Typography variant="body2">
                    Setup Complete
                </Typography>
            );
        case 2:
            return (
                <Typography variant="body2">
                    {isProofSent ? 'Proof Sent' : 'Proof Skipped'}
                </Typography>
            );
        case 3:
            return (
                <Typography variant="body2">
                    {audienceName}
                </Typography>
            );
        case 4:
            return (
                <Typography variant="body2">
                    {campaign.sendDateTime ? `Send at ${format((new Date(campaign.sendDateTime)), 'HH:mm \'on\' MMMM do yyyy')}` : 'Send Immediately'}
                </Typography>
            );
        default:
            return (
                <Typography variant="body2">
                    Step Complete
                </Typography>
            );
    }
};

const ShoppingList = ({ drawerWidth, stepNumber, steps, onNext, onBack, campaign, isProofSent, isQRCodeSelected = null, audienceName, disabled = false, publishText = null, setStep, finalStepButtonText = null }) => {
    const isStepCompleted = index => {
        return index < stepNumber;
    };

    const handleClickStep = index => {
        setStep(index);
    };

    return (
        <Drawer
            variant="permanent"
            anchor="right"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' }
            }}
        >
            <Toolbar />
            <Toolbar />
            <Box sx={{ p: 2 }}>
                <Stepper activeStep={stepNumber} orientation="vertical">
                    {steps.map((step, index) => {
                        const labelProps = {};

                        if (isStepCompleted(index)) {
                            labelProps.optional = CompletedMessageNode({ step: index, campaign, isProofSent, audienceName });
                        }

                        const nextText = (index === steps.length - 1 ? finalStepButtonText ?? (campaign?.sendDateTime ? 'Schedule Send' : 'Send') : 'Next');

                        return (
                            <Step
                                key={step.name}
                            >
                                <StepButton color="inherit" onClick={() => handleClickStep(index)}>
                                    <StepLabel {...labelProps}>{step.name}</StepLabel>
                                </StepButton>
                                <StepContent>
                                    <Typography variant="body2">
                                        {step.description}
                                    </Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <div>
                                            {index === 2 ? (
                                                <Button
                                                    onClick={onNext}
                                                    disabled={disabled}
                                                    sx={{ mt: 1, mr: 1 }}
                                                    variant={publishText || isProofSent || isQRCodeSelected ? 'contained' : 'outlined'}
                                                >
                                                    {publishText ? publishText : isProofSent || isQRCodeSelected ? 'Next' : isQRCodeSelected !== null ? 'Skip QR Code' : 'Skip Proof'}
                                                </Button>
                                            ) : index === 5 ? (
                                                <Button
                                                    onClick={onNext}
                                                    disabled={disabled}
                                                    sx={{ mt: 1, mr: 1 }}
                                                    variant={publishText || isQRCodeSelected ? 'contained' : 'outlined'}
                                                >
                                                    {publishText ? publishText : isQRCodeSelected ? 'Next' : 'Skip QR Code'}
                                                </Button>
                                            ) : (
                                                <Button
                                                    onClick={onNext}
                                                    disabled={disabled}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {nextText}
                                                </Button>
                                            )}
                                            <Button
                                                variant="text"
                                                disabled={index === 0}
                                                onClick={onBack}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                    </Box>
                                </StepContent>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
        </Drawer>
    );
};

export default ShoppingList;