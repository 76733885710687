import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const SelectTriggers = ({ triggerIds, onChangeTriggerIds, availableTriggers, selectProps, formControlProps, label }) => {
    return (
        <FormControl size="small" {...formControlProps}>
            <InputLabel id="select-triggers-label">{label}</InputLabel>
            <Select
                {...selectProps}
                value={triggerIds}
                onChange={e => onChangeTriggerIds(e.target.value)}
                labelId="select-triggers-label"
                label={label}
                multiple
                input={<OutlinedInput label={label} />}
                renderValue={selected => availableTriggers.filter(e => selected.includes(e.id)).map(e => e.description).join(', ')}
            >
                {availableTriggers.map(e => (
                    <MenuItem key={e.id} value={e.id}>
                        <Checkbox checked={triggerIds.includes(e.id)} />
                        <ListItemText primary={e.description} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectTriggers;