import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';

const QRCodeFormDialog = ({ onClose, onSubmit, qrCodes, item = null }) => {
    const [name, setName] = useState(item?.name ?? '');
    const [location, setLocation] = useState(item?.location);
    const [error, setError] = useState(false);

    // eslint-disable-next-line no-undef
    const existingLocations = [...new Set(qrCodes.filter(e => e.location).map(e => e.location))];

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit({ name, location });
        onClose();
    };

    useEffect(() => {
        setError(qrCodes.filter(e => e.id !== item?.id).find(e => e.name.toLowerCase() === name.toLowerCase()));
    }, [name]);

    return (
        <Dialog onClose={onClose} open={true}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{item?.name ? 'Edit' : 'Create a new'} QR Code</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        onFocus={event => event.target.select()}
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={name}
                        required
                        onChange={e => setName(e.target.value)}
                        margin="dense"
                        error={error}
                        helperText={error ? 'Name must be unique' : undefined}
                    />
                    <Autocomplete
                        id="location"
                        options={existingLocations}
                        renderInput={(params) => <TextField {...params} label="Location" />}
                        value={location}
                        onChange={(e, value) => setLocation(value)}
                        freeSolo
                        autoSelect
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">{item?.name ? 'Save' : 'Create'}</Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default QRCodeFormDialog;
