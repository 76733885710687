import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import RenameDialog from './dialogs/RenameDialog';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import UploadFiles from '../editor/UploadFiles';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog';
import useApi from '../hooks/useApi';

const UploadImages = () => {
    const history = useHistory();
    const { id, type } = useParams();
    const [showRenameDialog, setShowRenameDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [uploadResult, setUploadResult] = useState(null);
    const [item, setItem] = useState(null);
    const [isInitialising, setIsInitialising] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { handleFetch, handleGet, handlePut, handleDelete } = useApi();

    const handleFetchItem = async () => {
        const response = await handleGet(`${type}s/${id}`);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setItem(data);
    };

    const handleUpdateItem = async name => {
        const response = await handlePut(`${type}s/${id}`, {
            name,
            autoSave: false
        });

        if (!response.ok) {
            console.error('Could not rename');
        }

        setShowRenameDialog(false);

        history.push(`/editor/${type}/${id}`);
    };

    const supportedTypes = [
        { extension: 'jpg', contentType: 'image/jpeg' },
        { extension: 'jpeg', contentType: 'image/jpeg' },
        { extension: 'png', contentType: 'image/png' },
        { extension: 'gif', contentType: 'image/gif' }
    ];

    const maxSize = 1048576; // 1MB to match editor

    const handleUpload = async acceptedFiles => {
        setIsLoading(true);

        let results = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
            const data = new FormData();

            data.append('file', acceptedFiles[i]);

            const response = await handleFetch(`${type}s/${id}/images`, {
                method: 'post',
                body: data
            });

            if (!response.ok) {
                console.error(response);
                return;
            }

            const result = await response.json();

            results.push(result);
        }

        setUploadResult(results);
        setIsLoading(false);
    };

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: supportedTypes.map(({ extension }) => `.${extension}`).join(', '),
        disabled: isLoading,
        maxSize,
        onDropAccepted: acceptedFiles => handleUpload(acceptedFiles)
    });

    const handleDeleteItem = async () => {
        const response = await handleDelete(`${type}s/${id}`);

        if (!response.ok) {
            console.error(response);
            return;
        }

        history.push({
            pathname: '/editor',
            state: { type }
        });
    };

    const itemType = type === 'email' ? 'Email' : 'Landing Page';

    useEffect(() => {
        handleFetchItem();
    }, []);

    useEffect(() => {
        item && setIsInitialising(false);
    }, [item]);

    if (isInitialising) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AppBar
                position="sticky"
                color="inherit"
                sx={{ top: 64 }}
            >
                <Toolbar>
                    <div style={{ flexGrow: 1 }}>
                        <Typography component="h1" variant="h6">Upload {itemType}: Images</Typography>
                    </div>
                    <Button variant="outlined" onClick={() => setShowDeleteDialog(true)} startIcon={<NavigateBeforeIcon />}>Back</Button>
                    <Button variant="outlined" onClick={() => setShowRenameDialog(true)} endIcon={<NavigateNextIcon />} sx={{ marginLeft: 1 }}>Next</Button>
                </Toolbar>
            </AppBar>
            <Box sx={{ p: 2 }}>
                <Paper>
                    <UploadFiles
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        fileRejections={fileRejections}
                        uploadResult={uploadResult}
                        maxSize={maxSize}
                        guidance={(
                            <>
                                <Typography
                                    component="p"
                                    variant="body1"
                                >
                                    Upload the images associated with your HTML file. Files must be <strong>less than 1 megabyte</strong> in size. Supported file types:
                                </Typography>
                                <List dense disablePadding>
                                    <ListItem>JPEG (.jpg and .jpeg)</ListItem>
                                    <ListItem>PNG (.png)</ListItem>
                                    <ListItem>GIF (.gif)</ListItem>
                                </List>
                            </>
                        )}
                        highlightedGuidance={`Skip this step if your ${itemType} has no images`}
                        isLoading={isLoading}
                    />
                </Paper>
            </Box>
            {showDeleteDialog && (
                <ConfirmDeleteDialog
                    onClose={() => setShowDeleteDialog(false)}
                    onDelete={handleDeleteItem}
                    item={item}
                    type={type}
                />
            )}
            {showRenameDialog && (
                <RenameDialog
                    onClose={() => setShowRenameDialog(false)}
                    onSubmit={handleUpdateItem}
                    itemName={item.name}
                    type={type}
                />
            )}
        </>
    );
};

export default UploadImages;