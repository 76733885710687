import React, { useEffect, useState } from 'react';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useParams } from 'react-router-dom';
import useApi from '../hooks/useApi';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import Box from '@mui/material/Box';

const SurveyPreview = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [survey, setSurvey] = useState(null);
    const { handleGet } = useApi();
    const [surveyTheme, setSurveyTheme] = useState(null);

    useEffect(() => {
        const handleLoadSurvey = async () => {
            const response = await handleGet(`surveys/${id}`);

            if (!response.ok) {
                console.error(response?.error);
                return;
            }

            const data = await response.json();
            setSurvey(data.json);
            setSurveyTheme(data.theme);
            setIsLoading(false);
        };

        handleLoadSurvey();
    }, [id, handleGet]);

    if (isLoading) {
        return <LoadingOverlay />;
    }

    const surveyForm = new Model(survey);

    if (surveyTheme) {
        surveyForm.applyTheme(JSON.parse(surveyTheme));
    }

    return (
        <Box sx={{ height: 'calc(100vh - 64px)' }}>
            <Survey model={surveyForm} showCompleteButton={false} />
        </Box>
    );
};

export default SurveyPreview;
