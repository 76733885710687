import React, { useState, useEffect } from 'react';
import useApi from '../hooks/useApi';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import useAccount from '../hooks/useAccount';
import buildUrl from '../buildUrl';
import UserSimpleEncodeChart from './users/charts/UserSimpleEncodeChart';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import IconTooltip from '../reports/IconTooltip';
import Box from '@mui/material/Box';
import { Card, TableFooter } from '@mui/material';

const Feedback = () => {
    const { customerId } = useAccount();
    const { handleGet } = useApi();
    const [campaigns, setCampaigns] = useState(null);
    const [feedbackCounts, setFeedbackCounts] = useState({});
    const [submissions, setSubmissions] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const handleLoadItems = async () => {
        const url = buildUrl('navigation/items');
        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        setCampaigns(data);
        setIsLoading(false);
    };

    const countFeedbackOccurrences = (campaigns) => {
        const feedbackCounts = {};

        campaigns?.forEach(campaign => {
            campaign.feedback?.forEach(feedback => {
                const feedbackText = feedback.feedbackText;

                if (feedbackCounts[feedbackText]) {
                    feedbackCounts[feedbackText] += feedback.total;
                }
                else {
                    feedbackCounts[feedbackText] = feedback.total;
                }

                setFeedbackCounts(feedbackCounts);
            });
        });

        setSubmissions(Object.values(feedbackCounts).reduce((sum, value) => sum + value, 0));

        return feedbackCounts;
    };

    useEffect(() => {
        handleLoadItems();
    }, [customerId]);

    useEffect(() => {
        countFeedbackOccurrences(campaigns);
    }, [campaigns, setCampaigns]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <Stack>
            <Paper sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography component="h2" variant="h6" gutterBottom sx={{ flexGrow: 1 }}>
                            Unsubscribe Feedback
                    </Typography>
                    <IconTooltip sx={{ marginLeft: 0.5 }}
                        title="This screen anonymously summarises stats from Unsubscribers who have chosen to leave feedback"
                    />
                </Box>

                <Card sx={{ cursor: 'default', pointerEvents: 'none' }}>
                    <CardContent>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th">Feedback</TableCell>
                                        <TableCell component="th">Submissions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {feedbackCounts && Object.entries(feedbackCounts).map(([feedback, total]) => (
                                        <TableRow key={feedback}>
                                            <TableCell>{feedback}</TableCell>
                                            <TableCell>{total}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell component="th" sx={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>Total Submissions</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', color: 'black', fontSize: 14 }}>{submissions}</TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
                {(submissions !== 0) && <UserSimpleEncodeChart feedbackCounts={feedbackCounts} />}
            </Paper>
        </Stack>

    );
};

export default Feedback;