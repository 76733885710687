import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import buildUrl from '../../buildUrl';
import useApi from '../../hooks/useApi';
import useSnackbar from '../../hooks/useSnackbar';
import NoRows from '../../dataTable/NoRows';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import ExportCompleteDialog from '../../account/auditing/ExportCompleteDialog';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import usePrevious from '../../hooks/usePrevious';

const rowsPerPageOptions = [10, 20, 50];

const typeValue = item => item.row.groupId ? `${item.value}. Group ID: ${item.row.groupId}` : item.value;

const columns = [
    { field: 'dateTime', headerName: 'Date/Time', orderDesc: true, type: 'dateTime', width: 200, valueGetter: ({ value }) => value && new Date(value) },
    { field: 'type', headerName: 'Action', orderDesc: false, type: 'string', width: 250, valueGetter: item => typeValue(item) },
    { field: 'ipAddress', headerName: 'IP Address', orderDesc: false, type: 'string' },
    { field: 'device', headerName: 'Device', orderDesc: false, type: 'string' },
    { field: 'emailAddress', headerName: 'Email Address', orderDesc: false, type: 'string', width: 200 }
];

const GdprActions = ({ id }) => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState('dateTime');
    const [orderDesc, setOrderDesc] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const prevRowsPerPage = usePrevious(rowsPerPage);
    const prevOrderBy = usePrevious(orderBy);
    const prevOrderDesc = usePrevious(orderDesc);
    const prevPage = usePrevious(page);
    const { handleGet } = useApi();
    const [isProcessing, setIsProcessing] = useState(false);
    const [dialog, setDialog] = useState(null);
    const { showSnackbar } = useSnackbar();

    const handleFetchContactGdprActions = async () => {
        const params = {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            field: orderBy,
            isDescending: orderDesc
        };

        const url = buildUrl(`contacts/${id}/gdpr-actions`, params);

        const response = await handleGet(url);

        if (!response.ok) {
            console.error(response?.error);
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setData(data);
        setIsLoading(false);
    };

    const handleFetchContactGdprActionsCount = async () => {
        const response = await handleGet(`contacts/${id}/gdpr-actions/count`);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const count = await response.json();

        setCount(count);
    };

    const handleSort = props => {
        const { field, sort } = props[0];

        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(sort === 'desc');
        }
    };

    const handleExport = async () => {
        setIsProcessing(true);

        const params = {
            field: orderBy,
            isDescending: orderDesc
        };

        const url = buildUrl(`contacts/${id}/gdpr-actions/export`, params);

        try {
            const response = await handleGet(url);
            const data = await response.json();

            setDialog(
                <ExportCompleteDialog
                    downloadPath={data.downloadPath}
                    onClose={() => setDialog(null)}
                />
            );
        }
        catch (e) {
            console.error(e);
            showSnackbar('Export failed', 'error');
        }
        finally {
            setIsProcessing(false);
        }
    };

    useEffect(() => {
        if (orderBy !== prevOrderBy ||
            orderDesc !== prevOrderDesc ||
            rowsPerPage !== prevRowsPerPage) {
            setPage(0);
            setIsLoading(true);
        }
        else if (page !== prevPage) {
            setIsLoading(true);
        }
    }, [orderBy, orderDesc, rowsPerPage, page]);

    useEffect(() => {
        if (isLoading) {
            handleFetchContactGdprActions();
            handleFetchContactGdprActionsCount();
        }
    }, [isLoading]);

    const sort = orderDesc ? 'desc' : 'asc';

    return (
        <>
            <DataGridPro
                autoHeight
                disableSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableMultipleColumnsSorting
                hideFooterSelectedRowCount
                pagination
                paginationMode="server"
                sortingMode="server"
                rows={data}
                page={page}
                rowCount={count}
                rowHeight={32}
                columns={columns}
                loading={isLoading}
                pageSize={rowsPerPage}
                onPageSizeChange={rowsPerPage => setRowsPerPage(rowsPerPage)}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageChange={page => setPage(page)}
                onSortModelChange={props => handleSort(props)}
                sortingOrder={['desc', 'asc']}
                initialState={{
                    sorting: {
                        sortModel: [{ field: orderBy, sort }]
                    }
                }}
                components={{
                    NoRowsOverlay: NoRows,
                    Toolbar: () => (
                        <>
                            <Toolbar>
                                <Button
                                    onClick={handleExport}
                                >
                                    Export
                                </Button>
                            </Toolbar>
                            <Divider />
                        </>
                    )
                }}
            />
            {isProcessing && (
                <LoadingOverlay />
            )}
            {dialog}
        </>
    );
};

export default GdprActions;