import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import EmailIcon from '@mui/icons-material/Email';
import WebIcon from '@mui/icons-material/Web';
import PollIcon from '@mui/icons-material/Poll';
import DateTimeSelector from '../selects/DateTimeSelector';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useApi from '../../hooks/useApi';
import buildUrl from '../../buildUrl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { format, differenceInMinutes } from 'date-fns';
import { Collapse } from '@mui/material';
import isValidDate from '../../isValidDate';
import SurveySelect from '../../survey/SurveySelect';
import CampaignSelect from '../../campaign/CampaignSelect';
import PageSelect from '../../page/PageSelect';

const contentType = str => str ? `${str[0].toUpperCase()}${str.slice(1)}` : '';

const QRCodeAssignContentDialog = ({ onClose, onSubmit, qrCode }) => {
    const now = new Date();
    const { handleGet } = useApi();
    const [isInitialising, setIsInitialising] = useState(true);
    const [startDate, setStartDate] = useState(now);
    const [endDate, setEndDate] = useState(null);
    const [content, setContent] = useState(null);
    const [type, setType] = useState(content?.type ?? 'email');
    const [assignedContent, setAssignedContent] = useState([]);
    const [conflictingAssignment, setConflictingAssignment] = useState(null);
    const [step, setStep] = useState(0);
    const [scheduleStart, setScheduleStart] = useState(false);

    const handleFetchQrCodeContents = async () => {
        const url = buildUrl(`qrcodes/${qrCode.id}/contents`);
        const response = await handleGet(url);

        setAssignedContent(await response.json());
        setIsInitialising(false);
    };

    const handleSubmit = async e => {
        e.preventDefault();

        onSubmit(qrCode.id, content.id, startDate, endDate, content.type);
        onClose();
    };

    const handleSelectContent = (item, type) => {
        setContent({
            id: item.id,
            name: item.name,
            type
        });
    };

    useEffect(() => {
        if (!assignedContent || !content) {
            return;
        }

        //if starts and ends are within an hour (or both ends are null) return the first match.
        //There may be multiple matches, this only reports the first one

        const closeMatch = assignedContent.find(e => {
            const startA = new Date(e.startDate);
            const startB = new Date(startDate);

            let startDiff = differenceInMinutes(startA, startB);

            if (startDiff < 0) {
                startDiff = Math.abs(startDiff);
            }

            const endA = new Date(e.endDate);
            const endB = new Date(endDate);

            let endDiff = differenceInMinutes(endA, endB);

            if (endDiff < 0) {
                endDiff = Math.abs(endDiff);
            }

            return (startDiff < 60) && (endDiff < 60 || (!e.endDate && !endDate));
        });

        setConflictingAssignment(closeMatch || null);
    }, [startDate, endDate]);

    useEffect(() => {
        setStartDate(new Date());
    }, [scheduleStart]);

    useEffect(() => {
        handleFetchQrCodeContents();
    }, []);

    useEffect(() => {
        setContent(null);
    }, [type]);

    if (isInitialising) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <Dialog onClose={onClose} open={step === 0} maxWidth="lg">
                <DialogTitle>Assign Content to QR Code: {qrCode.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Only sent campaigns, published landing pages and active surveys can be assigned.</DialogContentText>
                    <Tabs value={type} onChange={(e, value) => setType(value)}>
                        <Tab icon={<EmailIcon />} iconPosition="start" label="Emails" value="email" />
                        <Tab icon={<WebIcon />} iconPosition="start" label="Landing Pages" value="page" />
                        <Tab icon={<PollIcon />} iconPosition="start" label="Surveys" value="survey" />
                    </Tabs>
                    {type === 'survey' && (
                        <SurveySelect
                            onSelect={item => handleSelectContent(item, type)}
                            currentlySelected={content}
                            showTools={false}
                        />
                    )}
                    {type === 'email' && (
                        <CampaignSelect
                            onSelect={item => handleSelectContent(item, type)}
                            currentlySelected={content}
                        />
                    )}
                    {type === 'page' && (
                        <PageSelect
                            onSelect={item => handleSelectContent(item, type)}
                            currentlySelected={content}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button disabled={!content?.id} onClick={() => setStep(1)}>Choose Active Period</Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={onClose} open={step === 1} maxWidth="md">
                <DialogTitle>Assign Content to QR Code: {qrCode.name}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Alert severity="info">
                            <AlertTitle>Selected {contentType(content?.type)}: {content?.name}</AlertTitle>
                            Active period: {(startDate && isValidDate(new Date(startDate))) ? `${format((new Date(startDate)), 'HH:mm \'on\' MMMM do yyyy')}${endDate ? ` until ${isValidDate(new Date(endDate)) ? format((new Date(endDate)), 'HH:mm \'on\' MMMM do yyyy') : ''}` : ', indefinitely.'}` : 'Not selected'}
                        </Alert>
                        <FormGroup>
                            <FormControlLabel
                                label="Schedule"
                                control={(
                                    <Switch
                                        checked={scheduleStart}
                                        onChange={e => setScheduleStart(e.target.checked)}
                                        inputProps={{ 'aria-label': 'Schedule' }}
                                    />
                                )}
                            />
                        </FormGroup>
                        <Collapse in={scheduleStart}>
                            <Stack spacing={2}>
                                <DialogContentText>Choose a date and time for the selected content to be active:</DialogContentText>
                                <Stack direction="row" spacing={2}>
                                    <DateTimeSelector label="Start Date" value={startDate} setValue={setStartDate} helperText="Required" minDateTime={now.setSeconds(now.getSeconds() - 10)} disabled={!scheduleStart} />
                                    <DateTimeSelector label="End Date" value={endDate} setValue={setEndDate} helperText="Optional" minDateTime={startDate} />
                                </Stack>
                            </Stack>
                        </Collapse>
                        {Boolean(conflictingAssignment) && (
                            <Alert severity="warning">
                                <AlertTitle>The selected time period very closely matches that of an existing assigned item</AlertTitle>
                                '{conflictingAssignment.name}' is assigned to this QR Code from {format((new Date(conflictingAssignment.startDate)), 'HH:mm \'on\' MMMM do yyyy')}{conflictingAssignment.endDate ? ` until ${format((new Date(conflictingAssignment.endDate)), 'HH:mm \'on\' MMMM do yyyy')}` : ', indefinitely.'}
                            </Alert>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button disabled={!(content?.id && startDate)} onClick={handleSubmit}>Save</Button>
                    <Button variant="outlined" onClick={() => setStep(0)}>
                        Back
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default QRCodeAssignContentDialog;