import React, { useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Statistic from '../components/Statistic';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import DraftsIcon from '@mui/icons-material/Drafts';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import EmailIcon from '@mui/icons-material/Email';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
//import Sentiment from '../components/Sentiment';

const tableCellStyle = {
    borderBottom: { xs: undefined, sm: 0 }
};

const EmailMenu = ({ automationIsActive, onEdit, onDelete, onDuplicate }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(null);
    };

    const handleAction = (e, action) => {
        e.stopPropagation();

        action();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                size="small"
                aria-label="Email Actions"
                disabled={automationIsActive}
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={e => handleAction(e, onEdit)}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleAction(e, onDuplicate)}>
                    <ListItemIcon>
                        <ContentCopyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Duplicate</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleAction(e, onDelete)}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

const AutomationEmail = ({ email, onDeleteEmail, onDuplicateEmail, onEditEmail, automationIsActive, selectedEmailId, resetSelected }) => {
    const [statistics, setStatistics] = useState(null);
    const selectedEmailRef = useRef(null);
    const theme = useTheme();

    const convertDelay = delay => {
        const days = Math.floor(delay / 24);
        const hours = Math.floor(delay - (days * 24));

        if (days > 0) {
            return `${days} days, ${hours} hours`;
        }
        else {
            return `${delay} hours`;
        }
    };

    const handleCalculateStatistics = () => {
        const averageOpenRate = email.sent > 0 ? (email.opens / email.sent) * 100 : null;
        const averageClickRate = email.sent > 0 ? email.opens > 0 ? (email.clicks / email.opens) * 100 : 0 : null;

        setStatistics({ averageOpenRate, averageClickRate });
    };

    const handleScrollToEmail = () => {
        selectedEmailRef.current.scrollIntoView({ behavior: 'smooth' });
        resetSelected();
    };

    const delay = convertDelay(email.delay);

    const selected = email.id === parseInt(selectedEmailId);

    useEffect(() => {
        selectedEmailRef?.current && handleScrollToEmail();
    }, [selectedEmailRef]);

    useEffect(() => {
        email && handleCalculateStatistics();
    }, [email]);

    return (
        <Card
            ref={selected ? selectedEmailRef : null}
            sx={{ borderLeft: 16, borderColor: theme.palette.primary.light }}
            variant="outlined"
        >
            <CardHeader
                disableTypography
                title={(
                    <React.Fragment>
                        <Typography variant="overline" component="div">
                            Email Name: {email.name}
                        </Typography>
                        <Typography variant="h6">
                            Subject: {email.subject}
                        </Typography>
                    </React.Fragment>
                )}
                subheader={<Typography variant="body2" color={theme.palette.text.secondary}>Sent {delay} after the trigger event</Typography>}
                sx={{ alignItems: 'start' }}
                avatar={(
                    <Avatar aria-label="Email" variant="rounded" sx={{ bgcolor: theme.palette.secondary.main }}>
                        <EmailIcon />
                    </Avatar>
                )}
                action={(
                    <EmailMenu
                        automationIsActive={automationIsActive}
                        onDelete={onDeleteEmail}
                        onEdit={onEditEmail}
                        onDuplicate={onDuplicateEmail}
                    />
                )}
            />
            <CardContent>
                <Stack spacing={2}>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        {/*
                        <Grid xs={12} sm={4}>
                            <Sentiment sentiment={email.sent > 0 ? email.sentiment : null} size="small" />
                        </Grid>
                        */}
                        <Grid xs={12} sm={4}>
                            <Statistic
                                title="Reach"
                                subheader="Average open rate"
                                size="small"
                                statistic={statistics?.averageOpenRate ?? null}
                                icon={<DraftsIcon />}
                            />
                        </Grid>
                        <Grid xs={12} sm={4}>
                            <Statistic
                                title="Engagement"
                                subheader="Average click rate"
                                size="small"
                                statistic={statistics?.averageClickRate ?? null}
                                icon={<AdsClickIcon />}
                            />
                        </Grid>
                    </Grid>
                    <Paper variant="outlined">
                        <Grid container rowSpacing={0} columnSpacing={1}>
                            <Grid xs={12} sm={6}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell variant="head">
                                                Delay
                                            </TableCell>
                                            <TableCell align="right">
                                                {`${email.delay} hour${email.delay !== 1 ? 's' : ''}`}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head" sx={tableCellStyle}>
                                                Sent
                                            </TableCell>
                                            <TableCell align="right" sx={tableCellStyle}>
                                                {email.sent}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell variant="head">
                                                Opens
                                            </TableCell>
                                            <TableCell align="right">
                                                {email.opens}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head" sx={tableCellStyle}>
                                                Clicks
                                            </TableCell>
                                            <TableCell align="right" sx={tableCellStyle}>
                                                {email.clicks}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Paper>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default AutomationEmail;