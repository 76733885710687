import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

const ChipTriggers = ({ triggers }) => triggers.map((e, i) => <Chip key={i} label={e.description} size="small" sx={{ marginRight: 1 }} />);

const TogglePublishDialog = ({ onClose, onSubmit, automation, availableTriggers }) => {
    const handlePublish = async e => {
        e.preventDefault();
        onSubmit({
            ...automation,
            groupId: automation.group?.id,
            active: true
        });
        onClose();
    };

    const handleMakeDraft = async e => {
        e.preventDefault();
        onSubmit({
            ...automation,
            groupId: automation.group?.id,
            active: false
        });
        onClose();
    };

    const triggers = availableTriggers.filter(e => automation.triggerIds.includes(e.id));

    //todo add delay and email name info in a list?

    return (
        <>
            <Dialog onClose={onClose} open={automation && !automation.active}>
                <DialogTitle>Publish Automation: {automation.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Name: {automation.name}
                    </DialogContentText>
                    <DialogContentText>
                        Group: {automation.group ? automation.group.name : 'All Contacts'}
                    </DialogContentText>
                    <DialogContentText>
                        Trigger{automation.triggerIds.length > 1 ? 's' : ''}: <ChipTriggers triggers={triggers} />
                    </DialogContentText>
                    <DialogContentText sx={{ marginTop: 2 }}>
                        {automation.name} sends {automation.emailsCount > 1 ? `a series of ${automation.emailsCount} emails` : '1 email'} to contacts added into {automation.group ? `'${automation.group.name}'` : 'your account'} by any selected trigger.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePublish}>Publish</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={onClose} open={automation && automation.active}>
                <DialogTitle>Pause Automation: {automation.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {automation.name} will no longer send emails.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleMakeDraft}>Confirm</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TogglePublishDialog;