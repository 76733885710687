import React, { useState, useEffect } from 'react';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import CenteredProgress from '../../loadingOverlay/CenteredProgress';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import SubscribersTable from '../SubscribersTable';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { Toolbar } from '@mui/material';

const emailColumns = [
    { name: 'i', label: '#', orderDesc: false, isSortable: true },
    { name: 'url', label: 'URL', orderDesc: false, minWidth: false, isSortable: false },
    { name: 'uniqueClicks', label: 'Unique clicks', align: 'right', orderDesc: true, minWidth: true, isSortable: true },
    { name: 'totalClicks', label: 'Total clicks', align: 'right', orderDesc: true, minWidth: true, isSortable: true }
];

const pageColumns = [
    { name: 'i', label: '#', orderDesc: false, isSortable: true },
    { name: 'url', label: 'URL', orderDesc: false, minWidth: false, isSortable: false },
    { name: 'totalClicks', label: 'Total clicks', align: 'right', orderDesc: true, minWidth: true, isSortable: true },
    { name: 'active', label: 'Active', align: 'right', orderDesc: false, minWidth: false, isSortable: true }
];

const Links = ({ onViewWhoClicked, linkId, allLinks, type = 'email', id = null, parentIsLoading = false, isPrint = false }) => {
    const [links, setLinks] = useState(null);
    const [orderBy, setOrderBy] = useState('i');
    const [orderDesc, setOrderDesc] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [includeInactive, setIncludeInactive] = useState(type === 'email' ? true : false);

    const handleSortLinks = (a, b) => orderDesc ? b[orderBy] - a[orderBy] : a[orderBy] - b[orderBy];

    const handleSort = (field, defaultOrder) => {
        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(defaultOrder);
        }
    };

    const style = {
        minWidth: '150px'
    };

    const handleRowClick = ({ id, hasClicks }) => {
        (hasClicks && onViewWhoClicked) && onViewWhoClicked(id);
    };

    const handleSetLinks = () => {
        if (allLinks?.length > 0) {
            setLinks(includeInactive
                ? allLinks.map((link, i) => ({ ...link, i })).sort(handleSortLinks)
                : allLinks.filter(e => e.active).map((link, i) => ({ ...link, i })).sort(handleSortLinks));
        }
        else {
            setLinks([]);
        }
    };

    useEffect(() => {
        handleSetLinks();
    }, [includeInactive, allLinks]);

    useEffect(() => {
        links && setIsLoading(false);
    }, [links]);

    useEffect(() => {
        if (!links) {
            return;
        }

        setLinks([...links.sort(handleSortLinks)]);
    }, [orderBy, orderDesc]);

    useEffect(() => {
        handleSetLinks();
    }, []);

    if (parentIsLoading) {
        return (
            <CenteredProgress />
        );
    }

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    const columns = type === 'email' ? emailColumns : pageColumns;

    return (
        <>
            <Paper sx={{ margin: 3 }}>
                {(type === 'page' && isPrint === false) && (
                    <>
                        <Toolbar>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button
                                onClick={() => setIncludeInactive(!includeInactive)}
                                disabled={!allLinks?.some(e => !e.active)}
                            >
                                {includeInactive ? 'Hide' : 'Show'} Inactive Links
                            </Button>
                        </Toolbar>
                        <Divider />
                    </>
                )}
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {columns.map(col => (
                                    <TableCell
                                        key={col.name}
                                        component="th"
                                        scope="row"
                                        align={col.align}
                                        sx={col.minWidth ? style : null}
                                    >
                                        {col.isSortable ? (
                                            <TableSortLabel
                                                active={orderBy === col.name}
                                                direction={orderDesc ? 'desc' : 'asc'}
                                                onClick={() => handleSort(col.name, col.orderDesc)}
                                            >
                                                {col.label}
                                            </TableSortLabel>
                                        ) : (
                                            col.label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {links.map(({ i, id, url, totalClicks, uniqueClicks, active }) => {
                                const hasClicks = totalClicks > 0;

                                return (
                                    <TableRow
                                        key={id}
                                        hover={onViewWhoClicked && hasClicks}
                                        onClick={() => handleRowClick({ id, hasClicks })}
                                        selected={parseInt(linkId) === parseInt(id)}
                                    >
                                        <TableCell>{(type === 'email' || active) ? (i + 1) : '-'}</TableCell>
                                        <TableCell sx={{ wordBreak: 'break-all' }}>{url}</TableCell>
                                        {type === 'email' && <TableCell align="right" sx={style}>{uniqueClicks}</TableCell>}
                                        <TableCell align="right" sx={style}>{totalClicks}</TableCell>
                                        {type === 'page' && <TableCell align="right" sx={style}>{active ? <CheckIcon color="success" /> : <ClearIcon color="disabled" />}</TableCell>}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {(type === 'email' && id && linkId) && (
                <SubscribersTable
                    campaignId={id}
                    linkId={linkId}
                    enableJumping={true}
                />
            )}
        </>
    );
};

export default Links;