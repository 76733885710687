import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const DeleteEmailDialog = ({ email, emailText, onComplete, onClose }) => {
    const handleDelete = e => {
        e.preventDefault();
        onComplete(email.id);
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <form onSubmit={handleDelete}>
                <DialogTitle>
                    Delete {emailText}: {email.name}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please confirm this action. This action cannot be reversed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Confirm Delete</Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default DeleteEmailDialog;
