import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

const DeleteNotificationsDialog = ({ notifications, onHide, onConfirm }) => {
    return (
        <Dialog
            open={true}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Delete Notifications</DialogTitle>
            <DialogContent dividers>
                <Typography>You are about to delete {notifications.length} notification{notifications.length > 1 && 's'}. Are you sure you want to proceed?</Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onHide} variant="outlined">
                    Cancel
                </Button>
                <Button onClick={onConfirm}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteNotificationsDialog;