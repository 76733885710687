import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useSnackbar from './useSnackbar';
import { useDialog } from '../dialogContext/DialogContext';
import config from '../config';
import SessionEndedDialog from '../dialogs/SessionEndedDialog';
import ErrorDialog from '../dialogs/ErrorDialog';

const { AUTH0_AUDIENCE: audience, AUTH0_SCOPE: scope } = config;

// todo support optional global loading overlay

const useApi = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { showSnackbar } = useSnackbar();
    const { setDialog } = useDialog();

    const handleFetch = async (url, request) => {
        const accessToken = await getAccessTokenSilently({
            audience,
            scope
        });

        const response = await fetch(url, {
            ...request,
            headers: {
                ...request?.headers,
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            console.error(`Error ${response.status}.`);
        }

        if (response.status === 500) {
            // todo use a modal instead?
            showSnackbar('An error occurred. If the problem persists please contact support.', 'error');
        }

        if (response.status === 403) {
            try {
                const { reason, message } = await response.json();

                if (reason === 'session') {
                    setDialog(<SessionEndedDialog />);
                }
                else {
                    setDialog(<ErrorDialog message={message} />);
                }
            }
            catch {
                console.error('Error not in expected format.');
                // todo generic forbidden dialog
            }
        }

        return response;
    };

    const handleGet = async url => {
        return handleFetch(url, { method: 'get' });
    };

    const handlePut = async (url, data) => {
        return handleFetch(url, {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    };

    const handlePost = async (url, data) => {
        return handleFetch(url, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
    };

    const handleDelete = async url => {
        return handleFetch(url, { method: 'delete' });
    };

    return {
        handleFetch,
        handleGet,
        handlePut,
        handlePost,
        handleDelete
    };
};

export default useApi;
