import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import AddUserModal from '../../accountDetails/modals/AddUserModal';

const LicenceUserPanel = ({ formData, onFormChange, products, handleValid }) => {
    const [licenceData, setLicenceData] = useState({
        licence: formData.licence,
        users: formData.users
    });

    const isFirstUser = licenceData.users.length === 0;
    const [user, setUser] = useState('');
    const [newUserName, setNewUserName] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [showAddUserModal, setShowAddUserModal] = useState(false);

    const userLimit = products.find(product => product.productID === licenceData.licence)?.userLimit ?? 0;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLicenceData((prevData) => ({
            ...prevData,
            [name]: value,
            users: name === 'licence' ? [] : prevData.users
        }));

        onFormChange(name, value);
    };

    const handleHideAddUserDialog = () => {
        setShowAddUserModal(false);
        setUser('');
        setNewUserName('');
    };

    const handleDelete = (index) => {
        const filteredUsers = licenceData.users.filter((_, i) => i !== index);

        setLicenceData({
            ...licenceData,
            users: filteredUsers
        });

        onFormChange('users', filteredUsers);
    };

    const handleAddUser = (userData, action) => {
        const newUser = {
            email: user,
            name: newUserName,
            permissions: userData.map(item => item.id)
        };

        setLicenceData({
            ...licenceData,
            users: [...licenceData.users, newUser]
        });

        setUser('');
        setNewUserName('');
        onFormChange('users', [...licenceData.users, newUser]);

        if (action === 'close') {
            setShowAddUserModal(false);
        }
    };

    useEffect(() => {
        if (formData.type === 'external') {
            setFilteredProducts(products.filter(p =>
                (!p.isInternalComms || p.productID === 14) &&
                p.productID !== 422 &&
                p.productName.includes('Trusted Delivery') === formData.isTrustedDelivery)
            );
        }

        if (formData.type === 'internal') {
            setFilteredProducts(products.filter(p =>
                (p.isInternalComms || p.productID === 14))
            );
        }
    }, [formData.type]);

    useEffect(() => {
        handleValid(licenceData.licence && licenceData.users.length > 0);
    }, [licenceData]);

    return (
        <>
            <Stack>
                <FormControl fullWidth>
                    <InputLabel>Licence Type</InputLabel>
                    <Select
                        label="Licence Type"
                        name="licence"
                        value={licenceData.licence}
                        onChange={handleChange}
                        required
                    >
                        {filteredProducts.map((product, index) => (
                            <MenuItem key={index} value={product.productID}>{product.productName}</MenuItem>
                        ))}
                    </Select>
                    <Button sx={{ mt: 2, width: 'fit-content' }} variant="contained" onClick={() => setShowAddUserModal(true)} disabled={licenceData.users.length === userLimit}>
                        Add User
                    </Button>
                </FormControl>
                {licenceData.users.length > 0 && (
                    <TableContainer sx={{ mt: 2, width: '100%' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email Address</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {licenceData.users.map((e, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>{e.name}</TableCell>
                                        <TableCell>{e.email}</TableCell>
                                        <TableCell align="right">
                                            {index !== 0 && (
                                                <IconButton onClick={() => handleDelete(index)} fontSize="small">
                                                    <DeleteOutlinedIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Stack>
            {showAddUserModal && (
                <AddUserModal
                    shown={showAddUserModal}
                    email={user}
                    name={newUserName}
                    handleChange={(e) => setUser(e.target.value)}
                    handleNameChange={(e) => setNewUserName(e.target.value)}
                    handleHide={handleHideAddUserDialog}
                    onSubmit={handleAddUser}
                    isFirstUser={isFirstUser}
                />
            )}
        </>
    );
};

export default LicenceUserPanel;