import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const AddContacts = ({ onManualEntry, onImport }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAction = action => {
        action();
        handleClose();
    };

    return (
        <>
            <Button
                id="add-contacts-button"
                aria-controls={open ? 'add-contacts-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
            >
                Add Contacts
            </Button>
            <Menu
                id="add-contacts-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'add-contacts-button'
                }}
            >
                <MenuItem onClick={() => handleAction(onManualEntry)}>Manual Entry</MenuItem>
                <MenuItem onClick={() => handleAction(onImport)}>Upload a File</MenuItem>
            </Menu>
        </>
    );
};

export default AddContacts;