import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import useTheme from '@mui/material/styles/useTheme';

const LoadingOverlay = () => {
    const theme = useTheme();

    return (
        <Backdrop sx={{ zIndex: theme.zIndex.drawer + 2, color: '#fff' }} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default LoadingOverlay;