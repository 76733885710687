import React, { useState, useEffect, useRef } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Chart from 'chart.js/auto';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import SubscribersTable from '../SubscribersTable';
import Skeleton from '@mui/material/Skeleton';
import SquareIcon from '@mui/icons-material/Square';
import renderPercentage from '../renderPercentage';
import generateChartColours from '../../generateChartColours';
import useApi from '../../hooks/useApi';

const SummaryPane = ({ title, data, selectedItem, onSelectItem, isReport }) => {
    const canvas = useRef();
    const totalSubscribers = data.reduce((acc, { total }) => (acc + total), 0);

    const colours = generateChartColours(data);

    const handleBuildChart = () => {
        const chartData = {
            labels: data.map(({ label }) => label),
            datasets: [{
                data: data.map(({ total }) => total),
                backgroundColor: data.map((e, i) => colours[i])
            }]
        };

        const options = {
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        new Chart(canvas.current, {
            type: 'pie',
            data: chartData,
            options
        });
    };

    useEffect(() => {
        handleBuildChart();
    }, [data]);

    return (
        <Paper sx={{ margin: 3 }}>
            <Toolbar variant="dense">{title}</Toolbar>
            <Divider />
            <Box sx={{ padding: 3, display: 'flex' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                {data.map(({ label, total }, i) => (
                                    <TableRow
                                        key={label}
                                        hover
                                        onClick={!isReport ? () => onSelectItem(label) : null}
                                        selected={selectedItem === label}
                                    >
                                        <TableCell width="52">
                                            <SquareIcon fontSize="small" sx={{ color: colours[i], display: 'block' }} />
                                        </TableCell>
                                        <TableCell component="th">{label}</TableCell>
                                        <TableCell width="100" align="right">{total}</TableCell>
                                        <TableCell width="100" align="right">{renderPercentage(total, totalSubscribers)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{ marginLeft: 6 }}>
                    <canvas ref={canvas} style={{ width: 150, height: 150 }} />
                </Box>
            </Box>
        </Paper>
    );
};

const Device = ({ campaignId, isReport = false, parentIsLoading = null, setParentIsLoading = null }) => {
    const [devices, setDevices] = useState(null);
    const [clients, setClients] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { handleGet } = useApi();

    const handleFetchDevices = async () => {
        const response = await handleGet(`reports/${campaignId}/devices`);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setDevices(data);
    };

    const handleFetchClients = async () => {
        const response = await handleGet(`reports/${campaignId}/clients`);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setClients(data);
    };

    const handleSelectDevice = device => {
        setSelectedDevice(device);
        setSelectedClient(null);
    };

    const handleSelectClient = client => {
        setSelectedClient(client);
        setSelectedDevice(null);
    };

    useEffect(() => {
        handleFetchDevices();
        handleFetchClients();
    }, []);

    useEffect(() => {
        if (clients && devices) {
            if (setParentIsLoading) {
                setParentIsLoading(false);
                return;
            }

            setIsLoading(false);
        }
    }, [clients, devices]);

    if (parentIsLoading === null && isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    if (parentIsLoading) {
        return <Skeleton variant="rectangular" height={100} />;
    }

    return (
        <>
            <SummaryPane
                title="Devices"
                data={devices}
                selectedItem={selectedDevice}
                onSelectItem={handleSelectDevice}
                isReport={isReport}
            />
            <SummaryPane
                title="Mail Apps"
                data={clients}
                selectedItem={selectedClient}
                onSelectItem={handleSelectClient}
                isReport={isReport}
            />
            {(selectedDevice || selectedClient) && (
                <SubscribersTable
                    campaignId={campaignId}
                    device={selectedDevice}
                    client={selectedClient}
                    enableJumping={true}
                />
            )}
        </>
    );
};

export default Device;