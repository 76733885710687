import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ConfirmDeleteQRCodeCampaignDialog = ({ onClose, onDelete, qrCodeContent, qrCodeName }) => {
    const handleDelete = e => {
        e.preventDefault();
        onDelete(qrCodeContent);
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Delete Content Association: {qrCodeContent.name}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please confirm you wish to remove the association between QR Code '{qrCodeName}' and the {qrCodeContent.type === 'page' ? 'landing Page' : 'Email'} '{qrCodeContent.name}'.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDelete}>Confirm</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteQRCodeCampaignDialog;
