import React, { useState } from 'react';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

const QRCodeActions = ({ hasActiveContent, onAssignContent, onView, onGetQRCode, onEdit, onDelete }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(null);
    };

    const handleAction = (e, action) => {
        e.stopPropagation();

        action();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick} size="small">
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={e => handleAction(e, onAssignContent)}>
                    <ListItemIcon>
                        <NoteAddIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Assign Content</ListItemText>
                </MenuItem>
                {hasActiveContent && (
                    <MenuItem onClick={e => handleAction(e, onView)}>
                        <ListItemIcon>
                            <VisibilityIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>View Active Content</ListItemText>
                    </MenuItem>
                )}
                <MenuItem onClick={e => handleAction(e, onGetQRCode)}>
                    <ListItemIcon>
                        <QrCodeScannerIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Download QR Code</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleAction(e, onEdit)}>
                    <ListItemIcon>
                        <DriveFileRenameOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit QR Code Details</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleAction(e, onDelete)}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export default QRCodeActions;