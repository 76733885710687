import React, { useState, useEffect } from 'react';
// Material UI
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// Components
import AccountDetailsField from '../fields/AccountDetailsField';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

const AccountDetailsForm = ({ account, onChangeAccount }) => {
    const [mta, setMta] = useState(account.mta || '');
    const [company, setCompany] = useState(account.company || '');
    const [customerName, setCustomerName] = useState(account.customerName || '');
    const [emailAddress, setEmailAddress] = useState(account.emailAddress || '');

    const handleSubmit = e => {
        e.preventDefault();
        onChangeAccount({ company, customerName, emailAddress, mta });
    };

    useEffect(() => {
        setMta(account.mta || '');
        setCompany(account.company || '');
        setCustomerName(account.customerName || '');
        setEmailAddress(account.emailAddress || '');
    }, [account]);

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{ px: 2 }}
            >
                <div style={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">
                        Account Details
                    </Typography>
                </div>
                <Button
                    size="small"
                    type="submit"
                    startIcon={<SaveIcon />}
                >
                    Save
                </Button>
            </Toolbar>
            <Divider />
            {account.resellerId !== null && account.resellerId !== 0 && (
                <Box sx={{ px: 2, mt: 1 }}>
                    <Alert severity="info">This is a sub account of <Link href={`/#/superadmin/accounts/${account.resellerId}`}>{account.resellerId} - {account.parentAccountName}</Link></Alert>
                </Box>
            )}
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item>
                    <AccountDetailsField
                        type="number"
                        label="CID"
                        disabled
                        value={account.id}
                    />
                </Grid>
                <Grid item>
                    <AccountDetailsField
                        type="text"
                        label="Customer Name (our Ref)"
                        value={customerName}
                        onChange={e => setCustomerName(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <AccountDetailsField
                        type="text"
                        label="Company (customer Ref)"
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <AccountDetailsField
                        type="text"
                        label="MTA"
                        placeholder="Not set"
                        value={mta}
                        onChange={e => setMta(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <AccountDetailsField
                        type="email"
                        label="Email (notifications)"
                        value={emailAddress}
                        onChange={e => setEmailAddress(e.target.value)}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default AccountDetailsForm;
