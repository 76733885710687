import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link, useLocation } from 'react-router-dom';
import NavigationContainer from '../navigation/NavigationContainer';
import useAccount from '../hooks/useAccount';

const SuperAdminNavigationListItem = ({ text, url, icon }) => {
    const location = useLocation();

    return (
        <ListItem button component={Link} to={url} selected={location.pathname === url}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={text} />
        </ListItem>
    );
};

const SuperAdminNavigation = ({ width = null, isSmallScreen, open, onClose }) => {
    const { userPermissions } = useAccount();
    const canManageAccounts = userPermissions.includes('manage:accounts');
    const canManageCampaigns = userPermissions.includes('manage:campaigns');
    const canManageTemplates = userPermissions.includes('manage:templates');
    const canManageNotifications = userPermissions.includes('manage:notifications');
    const canManageEmails = userPermissions.includes('manage:emails');
    const canReadAuditActions = userPermissions.includes('read:audit-actions');
    const canReadImports = userPermissions.includes('read:imports');
    const canManageFeatures = userPermissions.includes('manage:features');

    return (
        <>
            <NavigationContainer width={width} isSmallScreen={isSmallScreen} open={open} onClose={onClose}>
                <List>
                    {canManageAccounts && <SuperAdminNavigationListItem text="Accounts" url="/superadmin/accounts" />}
                    {canManageCampaigns && <SuperAdminNavigationListItem text="Campaigns" url="/superadmin/campaigns" />}
                    {canManageTemplates && <SuperAdminNavigationListItem text="Templates" url="/superadmin/templates" />}
                    {canManageEmails && <SuperAdminNavigationListItem text="Content" url="/superadmin/content" />}
                    {canManageNotifications && <SuperAdminNavigationListItem text="Announcements" url="/superadmin/notifications" />}
                    {canReadAuditActions && <SuperAdminNavigationListItem text="Auditing" url="/superadmin/auditing" />}
                    {canReadImports && <SuperAdminNavigationListItem text="Imports" url="/superadmin/imports" />}
                    {canManageFeatures && <SuperAdminNavigationListItem text="Features" url="/superadmin/features" />}
                </List>
            </NavigationContainer>
        </>
    );
};

export default SuperAdminNavigation;