import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import AddFieldDialog from './AddFieldDialog';
import EditFieldDialog from './EditFieldDialog';
import DeleteFieldDialog from './DeleteFieldDialog';
import useAccount from '../../hooks/useAccount';
import useSnackbar from '../../hooks/useSnackbar';

// todo type display name could be moved into types

const getType = type => {
    switch (type) {
        case 'string': return 'Text';
        case 'number': return 'Number';
        case 'bool': return 'Yes/No';
        case 'date': return 'Date/Time';
        case 'phone': return 'Telephone Number';
        case 'email': return 'Email Address';
    }

    return type;
};

const dialogs = {
    ADD_FIELD: 1,
    EDIT_FIELD: 2,
    DELETE_FIELD: 3
};

const CustomFields = () => {
    const { customFields, handleUpdateField, handleDeleteField } = useAccount();
    const [dialog, setDialog] = useState(null);
    const [selectedField, setSelectedField] = useState(null);
    const { showSnackbar } = useSnackbar();

    const handleCloseDialog = () => setDialog(null);

    const handleSubmitAddField = async () => {
        handleCloseDialog();
    };

    const handleOpenEditDialog = field => {
        setSelectedField(field);
        setDialog(dialogs.EDIT_FIELD);
    };

    const handleCloseEditDialog = () => {
        handleCloseDialog();
        setSelectedField(null);
    };

    const handleSubmitUpdateField = async (name, display) => {
        handleCloseEditDialog();

        const params = {
            name: name,
            display: display
        };

        const response = await handleUpdateField(selectedField.id, params);

        if (!response.ok) {
            showSnackbar('Error updating Custom Field', 'error');
        }
    };

    const handleOpenDeleteDialog = field => {
        setSelectedField(field);
        setDialog(dialogs.DELETE_FIELD);
    };

    const handleCloseDeleteDialog = () => {
        handleCloseDialog();
        setSelectedField(null);
    };

    const handleSubmitDeleteField = async () => {
        handleCloseDeleteDialog();

        const response = await handleDeleteField(selectedField.id);

        if (!response.ok) {
            showSnackbar('Error deleting Custom Field', 'error');
        }
    };

    return (
        <>
            <Box sx={{ padding: 2 }}>
                {customFields.length === 0 && <Typography variant="body1" gutterBottom>You have no custom fields in your account.</Typography>}

                <Toolbar disableGutters={true}>
                    <Button onClick={() => setDialog(dialogs.ADD_FIELD)}>
                        Add Custom Field
                    </Button>
                </Toolbar>

                {customFields.length > 0 && (
                    <TableContainer component={Box}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" scope="row">Name</TableCell>
                                    <TableCell component="th" scope="row">Type</TableCell>
                                    <TableCell component="th" scope="row">Enabled</TableCell>
                                    <TableCell component="th" scope="row">Date Added</TableCell>
                                    <TableCell component="th" scope="row" align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {customFields.map(field => (
                                    <TableRow key={field.id}>
                                        <TableCell>{field.name}</TableCell>
                                        <TableCell>{getType(field.type)}</TableCell>
                                        <TableCell>{field.display ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>{new Date(field.dateAdded).toLocaleString('en-GB')}</TableCell>
                                        <TableCell align="right">
                                            <Tooltip title="Edit">
                                                <IconButton
                                                    onClick={() => handleOpenEditDialog(field)}
                                                    size="small"
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton
                                                    onClick={() => handleOpenDeleteDialog(field)}
                                                    size="small"
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {dialog === dialogs.ADD_FIELD && (
                    <AddFieldDialog
                        onSubmit={handleSubmitAddField}
                        onClose={handleCloseDialog}
                    />
                )}

                {dialog === dialogs.EDIT_FIELD && (
                    <EditFieldDialog
                        field={selectedField}
                        onSubmit={handleSubmitUpdateField}
                        onClose={handleCloseEditDialog}
                    />
                )}

                {dialog === dialogs.DELETE_FIELD && (
                    <DeleteFieldDialog
                        field={selectedField}
                        onSubmit={handleSubmitDeleteField}
                        onClose={handleCloseDeleteDialog}
                    />
                )}
            </Box>
        </>
    );
};

export default CustomFields;