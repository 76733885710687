import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';

const QRCodeDownloadDialog = ({ onClose, qrCode }) => {
    const downloadQRCode = filetype => {
        let dataUrl;

        if (filetype === 'svg') {
            const svg = document.getElementById('qr-code-svg');
            const serializer = new XMLSerializer();
            const svgString = serializer.serializeToString(svg);
            const blob = new Blob([svgString], { type: 'image/svg+xml' });
            dataUrl = URL.createObjectURL(blob);
        }
        else {
            const canvas = document.getElementById('qr-code');
            dataUrl = canvas.toDataURL(`image/${filetype}`);
        }

        const downloadLink = document.createElement('a');
        downloadLink.href = dataUrl;
        downloadLink.download = `${qrCode.name}.${filetype}`;
        downloadLink.click();

        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle sx={{ pb: 0 }}>Download QR Code: {qrCode.name}</DialogTitle>
            <DialogContent>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <QRCodeCanvas
                        id="qr-code"
                        value={qrCode.url}
                        size={320}
                        level="H"
                        includeMargin={true}
                    />
                    <QRCodeSVG
                        id="qr-code-svg"
                        display="none"
                        value={qrCode.url}
                        size={320}
                        level="H"
                        includeMargin={true}
                    />
                </Box>
                <Alert severity="info">
                    It's best to use the SVG vector format for print. Either filetype can be used for web and screen.
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => downloadQRCode('png')}>Download PNG</Button>
                <Button onClick={() => downloadQRCode('svg')}>Download SVG</Button>
                <Button variant="outlined" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default QRCodeDownloadDialog;
