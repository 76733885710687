import React from 'react';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LoginIcon from '@mui/icons-material/Login';

const AccountPicker = ({ accounts, handleChangeAccount }) => {
    return (
        <List>
            {accounts.map(account => (
                <MenuItem
                    key={account.id}
                    onClick={() => handleChangeAccount(account.id)}
                >
                    <ListItemIcon>
                        <LoginIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primary={account.name}
                        secondary={`Customer ID: ${account.id}`}
                    />
                </MenuItem>
            ))}
        </List>
    );
};

export default AccountPicker;