import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

const DownloadExportedFileDialog = ({ downloadUrl, onClose }) => {
    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Export Contacts</DialogTitle>
            <DialogContent>
                Your export is complete. This link will be valid for the next 24hrs.
            </DialogContent>
            <DialogActions>
                <Button component="a" href={downloadUrl} target="_blank" sx={{ marginRight: 1 }}>Download</Button>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DownloadExportedFileDialog;