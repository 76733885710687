import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const SpamCheckDialog = ({ spamCheckResult, onClose }) => {
    const [height, setHeight] = useState(0);

    return (
        <Dialog
            onClose={onClose}
            open={spamCheckResult}
            fullScreen
            TransitionComponent={Transition}
        >
            <AppBar position="fixed" color="inherit">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Spam Check: Result
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <iframe
                src={spamCheckResult}
                frameBorder="0"
                onLoad={() => setHeight(document.body.scrollHeight)}
                style={{
                    overflow: 'hidden',
                    width: '100%',
                    height
                }}
            />
        </Dialog>
    );
};

export default SpamCheckDialog;