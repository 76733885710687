import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FullScreenSettingsDialog from '../../shared/FullScreenSettingsDialog';
import Tags from '../../account/tags/Tags';
import SignUpForm from '../../account/signUpForm/SignUpForm';
import CustomFields from '../../account/customFields/CustomFields';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';

const Settings = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const open = Boolean(anchorEl);

    const menuItems = {
        manageTags: { label: 'Manage Tags', icon: <LocalOfferIcon fontSize="small" />, component: <Tags /> },
        singUpForm: { label: 'Sign Up Form', icon: <DynamicFormIcon fontSize="small" />, component: <SignUpForm /> },
        customFields: { label: 'Custom Fields', icon: <EditAttributesIcon fontSize="small" />, component: <CustomFields /> }
    };

    const handleDialogOpen = (menuItem) => {
        setIsDialogOpen(true);
        setSelectedMenuItem(menuItem);
        setAnchorEl(null);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setAnchorEl(null);
        setSelectedMenuItem(null);
    };

    return (
        <>
            <IconButton
                onClick={(event) => setAnchorEl(event.currentTarget)}
                variant="text"
            >
                <SettingsIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                {Object.keys(menuItems).map((menuItem) => (
                    <MenuItem key={menuItem}>
                        <ListItemIcon>
                            {menuItems[menuItem].icon}
                        </ListItemIcon>
                        <ListItemText onClick={() => handleDialogOpen(menuItem)}>{menuItems[menuItem].label}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
            {isDialogOpen && (
                <FullScreenSettingsDialog handleDialogClose={handleDialogClose} title={menuItems[selectedMenuItem]?.label}>
                    {menuItems[selectedMenuItem]?.component}
                </FullScreenSettingsDialog>
            )}
        </>
    );
};

export default Settings;