import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';

const AuthenticationPanel = ({ formData, onFormChange }) => {
    const [authenticationDetail, setAuthenticationDetail] = useState({
        domainName: formData.domainName,
        subDomainName: formData.subDomainName
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setAuthenticationDetail((prevData) => ({
            ...prevData,
            [name]: value,
            subDomainName: name === 'domainName' ? (value ? `bounced.${value}` : '') : prevData.subDomainName
        }));

        onFormChange(name, value);
    };

    return (
        <Stack>
            <TextField
                label="Domain Name"
                name="domainName"
                value={authenticationDetail.domainName}
                onChange={handleChange}
            />
            <TextField
                label="Sub Domain"
                name="subDomainName"
                value={authenticationDetail.subDomainName}
                disabled
                InputLabelProps={{ shrink: Boolean(authenticationDetail.subDomainName) }}
            />
        </Stack>
    );
};

export default AuthenticationPanel;