import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

const UnsubscribeSubscribersDailog = ({ open, onClose, onSubmit, count }) => {
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Unsubscribe Contact{count > 1 && 's'}</DialogTitle>
            <DialogContent>
                This will unsubscribe {count > 1 ? 'all' : 'the'} {count} contact{count > 1 && 's'} selected.
            </DialogContent>
            <DialogActions>
                <Button onClick={onSubmit} sx={{ marginRight: 1 }}>Unsubscribe</Button>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnsubscribeSubscribersDailog;