import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import SelectTriggers from '../components/SelectTriggers';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';

const defaultSort = 'modified-desc';

const ToolbarItem = ({ children }) => {
    return (
        <Box sx={{ display: 'inline-flex', margin: 1, marginRight: 0 }}>
            {children}
        </Box>
    );
};

const RenderTriggers = (triggers, availableTriggers) => availableTriggers.filter(e => triggers.includes(e.id)).map(e => <Chip key={e.id} label={e.description} size="small" sx={{ margin: 0.5 }} />);

const StyledDataGrid = styled(DataGridPro)(() => ({
    '& .MuiDataGrid-row:hover': {
        cursor: 'pointer'
    },
    '& .wrap': {
        flexWrap: 'wrap'
    }
}));

const Dashboard = ({ allAutomations, availableTriggers, groups, isInitialising, isLoading, onCreateAutomation, onSetAutomationToEdit, onSetAutomationToPublish, onSetAutomationToDelete }) => {
    const history = useHistory();

    const [automations, setAutomations] = useState([]);
    const [sortBy, setSortBy] = useState(defaultSort);
    const [filterGroups, setFilterGroups] = useState([]);
    const [filterTriggers, setFilterTriggers] = useState([]);
    const [groupId, setGroupId] = useState('any');
    const [triggerIds, setTriggerIds] = useState([]);

    const columns = [
        { field: 'active', headerName: 'Status', orderDesc: false, type: 'boolean', renderCell: ({ value }) => <Chip label={value ? 'Published' : 'Draft'} color={value ? 'success' : undefined} size="small" /> },
        { field: 'name', headerName: 'Name', orderDesc: false, type: 'string', flex: 1 },
        { field: 'group', headerName: 'Group', orderDesc: false, type: 'string', flex: 1, valueGetter: ({ value }) => value ? value.name : 'All Contacts' },
        { field: 'triggerIds', headerName: 'Triggers', orderDesc: false, type: 'string', flex: 2, renderCell: ({ value }) => RenderTriggers(value, availableTriggers) },
        { field: 'emailsCount', headerName: 'Emails', orderDesc: false, type: 'number' },
        { field: 'created', headerName: 'Created', orderDesc: true, type: 'dateTime', flex: 1, valueGetter: ({ value }) => value && new Date(value) },
        { field: 'modified', headerName: 'Modified', orderDesc: true, type: 'dateTime', flex: 1, valueGetter: ({ value }) => value && new Date(value) },
        {
            field: 'actions', type: 'actions', getActions: ({ row }) => [
                <GridActionsCellItem
                    key={0}
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => onSetAutomationToEdit(row)}
                    showInMenu
                    disabled={row.active}
                />,
                <GridActionsCellItem
                    key={1}
                    icon={row.active ? <PauseIcon /> : <PlayArrowIcon />}
                    label={row.active ? 'Revert to Draft' : 'Publish'}
                    onClick={() => onSetAutomationToPublish(row)}
                    showInMenu
                    disabled={row.emailsCount === 0}
                />,
                <GridActionsCellItem
                    key={2}
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => onSetAutomationToDelete(row)}
                    showInMenu
                />
            ]
        }
    ];

    const handleSort = sortValues => {
        setSortBy(`${sortValues.field}-${sortValues.sort}`);
    };

    const handleOpenAutomation = params => {
        params?.id && history.push(`automations/${params.id}`);
    };

    useEffect(() => {
        const automationGroups = allAutomations.filter(a => Boolean(a.group)).map(e => e.group.id);
        const filterGroups = groups.filter(g => automationGroups.includes(g.id));

        setFilterGroups(filterGroups);

        let automationTriggers = [];

        // should be a better way of doing this
        allAutomations.map(a => a.triggerIds.map(t => automationTriggers.push(t)));

        // and this
        const allTriggersInUse = [...new Set(automationTriggers)];

        const filterTriggers = availableTriggers.filter(e => allTriggersInUse.includes(e.id));

        setFilterTriggers(filterTriggers);
    }, [allAutomations, groups, availableTriggers]);

    // filter/sort

    useEffect(() => {
        let automations = [...allAutomations];

        // filter by group

        if (groupId === 'any') {
            // don't filter by group
        }
        else if (groupId === 'allContacts') {
            automations = automations.filter(a => a.group === null);
        }
        else if (groupId) {
            automations = automations.filter(a => a.group?.id === groupId);
        }

        // filter by trigger

        if (triggerIds.length > 0) {
            automations = automations.filter(a => a.triggerIds.some(id => triggerIds.includes(id)));
        }

        // sort

        if (sortBy === 'name-desc') {
            automations = automations.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                if (nameA > nameB) {
                    return -1;
                }

                if (nameA < nameB) {
                    return 1;
                }

                return 0;
            });
        }

        if (sortBy === 'name-asc') {
            automations = automations.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }

                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });
        }

        if (sortBy === 'modified-desc') {
            automations = automations.sort((a, b) => new Date(b.modified) - new Date(a.modified));
        }

        if (sortBy === 'modified-asc') {
            automations = automations.sort((a, b) => new Date(a.modified) - new Date(b.modified));
        }

        if (sortBy === 'created-desc') {
            automations = automations.sort((a, b) => new Date(b.created) - new Date(a.created));
        }

        if (sortBy === 'created-asc') {
            automations = automations.sort((a, b) => new Date(a.created) - new Date(b.created));
        }

        setAutomations(automations);
    }, [allAutomations, sortBy, groupId, triggerIds]);

    if (isInitialising) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <Container maxWidth={false} sx={{ marginTop: 3, marginBottom: 2 }}>
                <Paper>
                    <StyledDataGrid
                        autoHeight
                        disableSelectionOnClick
                        disableColumnMenu
                        disableColumnFilter
                        disableMultipleColumnsSorting
                        hideFooter
                        rows={automations}
                        rowCount={automations.length}
                        columns={columns}
                        loading={isLoading}
                        onSortModelChange={props => handleSort(props)}
                        sortingOrder={['desc', 'asc']}
                        getRowHeight={() => 'auto'}
                        sx={{
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' }
                        }}
                        getCellClassName={(params) => {
                            if (params.field === 'triggerIds') {
                                return 'wrap';
                            }

                            return params.value >= 15 ? 'hot' : 'cold';
                        }}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'modified', sort: 'desc' }]
                            }
                        }}
                        onRowClick={(params) => handleOpenAutomation(params)}
                        components={{
                            NoRowsOverlay: () => (
                                allAutomations.length === 0 ? (
                                    <Box sx={{ padding: 3, textAlign: 'center' }}>
                                        <Typography>You haven't created any automations yet.</Typography>
                                        <Typography>Click New Automation to create your first one!</Typography>
                                    </Box>
                                ) : 'No rows'
                            ),
                            Toolbar: () => (
                                <>
                                    <Toolbar
                                        disableGutters
                                        sx={{ mr: 1, paddingTop: 0.5, paddingBottom: 0.5 }}
                                    >
                                        <Box sx={{ display: 'flex', flexGrow: 1 }}>
                                            <ToolbarItem>
                                                <FormControl margin="none" size="small" disabled={filterGroups === 0}>
                                                    <InputLabel id="filter-group-label">Filter by Group</InputLabel>
                                                    <Select
                                                        value={groupId}
                                                        onChange={e => setGroupId(e.target.value)}
                                                        sx={{ minWidth: 160 }}
                                                        labelId="filter-group-label"
                                                        label="Filter by Group"
                                                    >
                                                        <MenuItem value="any">Any</MenuItem>
                                                        {allAutomations.find(e => e.group === null) && <MenuItem value="allContacts">All Contacts</MenuItem>}
                                                        {filterGroups.map(e => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </ToolbarItem>
                                            <ToolbarItem>
                                                <SelectTriggers
                                                    triggerIds={triggerIds}
                                                    onChangeTriggerIds={setTriggerIds}
                                                    availableTriggers={filterTriggers}
                                                    label="Filter by Triggers"
                                                    selectProps={{
                                                        sx: { width: 200 },
                                                        disabled: filterTriggers.length === 0
                                                    }}
                                                    formControlProps={{
                                                        margin: 'none'
                                                    }}
                                                />
                                            </ToolbarItem>
                                            <ToolbarItem>
                                                <Button
                                                    onClick={() => {
                                                        setSortBy(defaultSort);
                                                        setGroupId('any');
                                                        setTriggerIds([]);
                                                    }}
                                                    startIcon={<FilterListOffIcon />}
                                                    variant="outlined"
                                                    disabled={!(groupId !== 'any' || triggerIds.length > 0)}
                                                >
                                                    Reset Filters
                                                </Button>
                                            </ToolbarItem>
                                        </Box>
                                        <ToolbarItem>
                                            <Button onClick={onCreateAutomation}>
                                                New Automation
                                            </Button>
                                        </ToolbarItem>
                                    </Toolbar>
                                    <Divider />
                                </>
                            )
                        }}
                    />
                </Paper>
            </Container>
        </>
    );
};

export default Dashboard;