import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import useNotifications from '../../hooks/useNotifications';

const BulkOperationInProgressDialog = ({ onClose, jobId }) => {
    const { notifications } = useNotifications();
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        const notification = notifications
            .filter(({ isRead, type }) => !isRead && type === 'job')
            .map(({ body }) => JSON.parse(body))
            .find(({ id }) => id === jobId);

        if (notification) {
            setIsComplete(true);
        }
    }, [notifications]);

    const title = isComplete ? 'Operation complete' : 'Operation in progress';

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {isComplete ? (
                    <Typography>The operation is complete.</Typography>
                ) : (
                    <>
                        <Typography paragraph>You will be notified when the operation is complete.<br />You can safely close this dialog.</Typography>
                        <LinearProgress />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default BulkOperationInProgressDialog;