import React from 'react';
import { format } from 'date-fns';
import Button from '@mui/material/Button';
import AlertBar from '../../../alertBar/AlertBar';

const AccountInfo = ({ account, onCancelAccount, onDeleteAccount }) => {
    if (account.status === 'Cancelled') {
        return (
            <AlertBar severity="warning" positionTop={128}>
                This account was cancelled on {account.cancelledDate ? format((new Date(account.cancelledDate)), 'dd/MM/yyyy') : '-'}
            </AlertBar>
        );
    }

    if (account.status === 'Expired') {
        return (
            <AlertBar
                positionTop={128}
                severity="error"
                action={(
                    <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={onCancelAccount}
                    >
                        Close Account
                    </Button>
                )}
            >
                This account&apos;s licence has expired
            </AlertBar>
        );
    }

    if (account.status === 'Closed') {
        return (
            <AlertBar
                positionTop={128}
                severity="error"
                action={(
                    <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={onDeleteAccount}
                    >
                        Delete Account
                    </Button>
                )}
            >
                This account is closed
            </AlertBar>
        );
    }

    return (
        <AlertBar
            positionTop={128}
            severity="success"
            action={(
                <Button
                    variant="outlined"
                    color="success"
                    size="small"
                    onClick={onCancelAccount}
                >
                    Cancel Account
                </Button>
            )}
        >
            This account is active
        </AlertBar>
    );
};

export default AccountInfo;
