import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReactECharts from 'echarts-for-react';
import { chartColours } from '../../../theme/constants';

const UserSimpleEncodeChart = ({ feedbackCounts }) => {

    const options = {
        dataset: {
            source: [
                ['total', 'feedback'],
                ...Object.entries(feedbackCounts).map(([feedback, total]) => [
                    total,
                    feedback
                ])
            ]
        },
        grid: { containLabel: true },
        xAxis: { name: 'Submissions', type: 'value' },
        yAxis: { name: 'Feedback', type: 'category' },
        tooltip: { show: false },
        series: [
            {
                type: 'bar',
                encode: {
                    x: 'total',
                    y: 'feedback'
                },
                itemStyle: {
                    color: (params) => {
                        const index = params.dataIndex;
                        const colors = [chartColours[17].value, chartColours[18].value, chartColours[19].value, chartColours[20].value, chartColours[21].value];
                        return colors[index % colors.length];
                    }
                },
                emphasis: {
                    disabled: true
                },
                cursor: 'default'
            }
        ]
    };

    return (
        <>
            <Box sx={{ pt: 3 }}>
                <Typography component="h2" variant="h6" gutterBottom>Feedback Metrics</Typography>
                <ReactECharts
                    option={options}
                    style={{ height: 350 }}
                    opts={{ renderer: 'svg' }}
                />
            </Box>
        </>
    );
};

export default UserSimpleEncodeChart;
