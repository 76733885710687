import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import useApi from '../../hooks/useApi';
import useSnackbar from '../../hooks/useSnackbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import useTheme from '@mui/material/styles/useTheme';

import AddCustomColourModal from './modal/AddCustomColourModal';
import { Stack } from '@mui/system';

const calculateLuminance = ({ r, g, b }) => (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
};

const getTextColour = (backgroundColour, lightColour, darkColour) => {
    const rgb = hexToRgb(backgroundColour);

    const luminance = calculateLuminance(rgb);

    return luminance < 0.3 ? darkColour : lightColour;
};

const Item = ({ onMouseEnter, onMouseLeave, backgroundColour, children }) => {
    const theme = useTheme();

    return (
        <Paper
            sx={{
                overflow: 'hidden',
                backgroundColor: backgroundColour,
                padding: theme.spacing(2),
                textAlign: 'center',
                position: 'relative',
                transition: '0.3s ease-in-out',
                color: getTextColour(backgroundColour, theme.palette.getContrastText(backgroundColour), theme.palette.getContrastText(backgroundColour)),
                '&:hover': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    transform: 'scale(1.05)'
                }
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
        </Paper>
    );
};

const CustomColour = ({ account, onRefresh }) => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { handlePost, handleDelete } = useApi();
    const [customColourDialog, setCustomColourDialog] = useState(false);
    const { showSnackbar } = useSnackbar();
    const [hoveredItem, setHoveredItem] = useState(null);

    const handleOnSubmit = async (colour, label) => {
        setIsLoading(true);

        const uri = `/superadmin/accounts/${id}/colour`;
        const response = await handlePost(uri, { value: colour, label: label });

        setIsLoading(false);

        if (!response.ok) {
            showSnackbar('An error occurred.', 'error');
        }
        else {
            showSnackbar('Colour added successfully.', 'success');
            setCustomColourDialog(false);
        }

        onRefresh();
    };

    const handleDeleteColour = async (row) => {
        setIsLoading(true);

        const uri = `/superadmin/accounts/${id}/colour/${row.id}`;
        const response = await handleDelete(uri);

        setIsLoading(false);

        if (!response.ok) {
            showSnackbar('An error occurred.', 'error');
        }
        else {
            showSnackbar('Colour deleted successfully.', 'success');
            setCustomColourDialog(false);
        }

        onRefresh();
    };

    return (
        <>
            <Box>
                <Button variant="contained" onClick={() => setCustomColourDialog(true)}>Add Brand Colour</Button>
                {account.customColours.length > 0 ? (
                    <Box sx={{ flexGrow: 1, mt: 2 }}>
                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {account.customColours.map((colour, index) => (
                                <Grid item xs={4} sm={4} md={2} key={index}>
                                    <Item backgroundColour={colour.value} onMouseEnter={() => setHoveredItem(index)} onMouseLeave={() => setHoveredItem(null)}>
                                        <Stack>
                                            <Typography variant="h6" gutterBottom>
                                                {colour.label ?? ''}
                                            </Typography>
                                            <Typography variant="button text">
                                                {colour.value}
                                            </Typography>
                                            <Typography variant="subtitle2" gutterBottom>
                                                RGB ({hexToRgb(colour.value).r},{hexToRgb(colour.value).g},{hexToRgb(colour.value).b})
                                            </Typography>
                                        </Stack>
                                        {hoveredItem === index && (
                                            <DeleteIcon fontSize="small" onClick={() => handleDeleteColour(colour)} sx={{ cursor: 'pointer' }} />
                                        )}
                                    </Item>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ) : (
                    <Alert sx={{ mt: 2 }} severity="info">No Brand Colours available.</Alert>

                )}
            </Box>
            {customColourDialog && (
                <AddCustomColourModal
                    shown={customColourDialog}
                    onHide={() => setCustomColourDialog(false)}
                    onSubmit={handleOnSubmit}
                    onLoading={isLoading}
                />
            )}
        </>
    );
};

export default CustomColour;