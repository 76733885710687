import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CreateEmailDialog = ({ email, emailText, onComplete, onClose, useSameCID = false }) => {
    const [name, setName] = useState(email.name);
    const [customerId, setCustomerId] = useState(useSameCID ? email.customerId : '');
    const [isValid, setIsValid] = useState(false);
    const [form, setForm] = useState(null);
    const [type, setType] = useState(email?.type ?? 'email');

    const onRefChange = useCallback(node => setForm(node), []);

    const handleSubmit = e => {
        e.preventDefault();
        onComplete({
            name,
            customerId: parseInt(customerId),
            type
        });
    };

    useEffect(() => {
        setIsValid(form && form.checkValidity());
    }, [form, name]);

    return (
        <Dialog onClose={onClose} open={true}>
            <form ref={onRefChange} onSubmit={handleSubmit}>
                <DialogTitle>Create {emailText}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Create a new {emailText} in the specified account.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        id="name"
                        label={`${emailText} Name`}
                        type="text"
                        fullWidth
                        value={name}
                        required
                        onChange={e => setName(e.target.value)}
                        margin="dense"
                    />
                    {emailText === 'Template' && (
                        <FormControl sx={{ minWidth: 160 }}>
                            <InputLabel id="select-type-label">Type</InputLabel>
                            <Select
                                labelId="select-type-label"
                                id="select-type"
                                value={type}
                                label="Type"
                                onChange={event => setType(event.target.value)}
                            >
                                <MenuItem value="email">Email</MenuItem>
                                <MenuItem value="page">Landing Page</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    <TextField
                        id="customerId"
                        label="Customer ID"
                        type="number"
                        fullWidth
                        value={customerId}
                        disabled={useSameCID}
                        onChange={e => setCustomerId(e.target.value)}
                        margin="dense"
                        helperText={emailText.toLowerCase() === 'template' ? 'Leave blank to create a global template' : undefined}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={!isValid}>Create {emailText}</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreateEmailDialog;
