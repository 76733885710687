import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import useApi from '../../hooks/useApi';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useSnackbar from '../../hooks/useSnackbar';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import Stepper from './components/Stepper';
import Domain from './components/Domain';
import Instruction from './components/Instruction';

const EmailAuthentication = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [verifyLoading, setVerifyLoading] = useState(false);
    const { handleGet } = useApi();
    const [account, setAccount] = useState(null);
    const [boolStepper, setBoolStepper] = useState([]);
    const { showSnackbar } = useSnackbar();

    const handleFetchAccount = async () => {
        const response = await handleGet('account');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        const steps = ['mxCheck', 'spf', 'dkim', 'dmarc'];
        const dateStatusArray = steps.map(field => !!data[field]);

        setBoolStepper(dateStatusArray);

        setAccount(data);
    };

    const handleSubmit = async () => {
        setVerifyLoading(true);

        const response = await handleGet(`account/${account.id}/email-authentication`);

        const data = await response.json();

        let count = 0;

        for (let key in data) {
            if (data[key] === true) {
                count += 1;
            }
        }

        if (!response.ok) {
            showSnackbar('Checks not completed. Please contact support', 'error');
            setIsLoading(false);
            setVerifyLoading(false);
            return;
        }

        if (count === 4) {
            showSnackbar('All checks completed', 'success');
            setVerifyLoading(false);
        }
        else {
            showSnackbar(`${count} out of 4 checks completed`, 'warning');
            setVerifyLoading(false);
        }

        await handleFetchAccount();
    };

    useEffect(() => {
        handleFetchAccount();
    }, []);

    useEffect(() => {
        account && setIsLoading(false);
    }, [account]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <Stack spacing={2}>
            <Paper>
                <Stepper data={boolStepper} />
            </Paper>
            <Paper>
                <Domain account={account} onRefresh={handleFetchAccount} />
            </Paper>
            <Paper>
                <Instruction account={account} handleSubmit={handleSubmit} />
                <Divider />
            </Paper>
            {verifyLoading && <LoadingOverlay />}
        </Stack>
    );
};

export default EmailAuthentication;