import React, { useState } from 'react';
import useAccount from '../../hooks/useAccount';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import AllowedRecipientsList from '../AllowedRecipientsList';

const AddContactDialog = ({ onClose, onSubmit, groupName }) => {
    const { isInternalComms, allowedRecipients } = useAccount();

    const [error, setError] = useState(false);
    const [email, setEmail] = useState('');
    const [addToCurrentGroup, setAddToCurrentGroup] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const checkAllowedRecipients = isInternalComms && allowedRecipients.length > 0;

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);

        if (checkAllowedRecipients) {
            const mailbox = email.trim().toLowerCase();
            const domain = mailbox.split('@')[1];

            const matches = allowedRecipients.filter(e => e.type === 'mailbox' ? mailbox === e.pattern ? true : false : domain === e.pattern ? true : false);

            if (matches.length > 0) {
                setError(false);
            }
            else {
                setError(true);
                setIsLoading(false);
                return;
            }
        }

        await onSubmit(email, addToCurrentGroup);
        setIsLoading(false);
        onClose();
    };

    const handleChange = event => {
        setAddToCurrentGroup(event.target.checked);
    };

    //todo await create in dialog so user can't navigate away before drawer opens?

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Add Contact</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Stack spacing={2}>
                        <Box>
                            <TextField
                                autoFocus
                                id="email"
                                label="Email Address"
                                type="email"
                                fullWidth
                                value={email}
                                required
                                onChange={e => setEmail(e.target.value)}
                                margin="none"
                                error={error}
                                helperText={error ? 'You can only add email addresses that match allowed patterns in your account' : undefined}
                            />
                            {groupName && (
                                <FormControlLabel
                                    control={<Checkbox checked={addToCurrentGroup} onChange={handleChange} />}
                                    label={`Add to Current Group '${groupName}'`}
                                />
                            )}
                        </Box>
                        {checkAllowedRecipients && (
                            <AllowedRecipientsList allowedRecipients={allowedRecipients} />
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        disabled={isLoading}
                        startIcon={isLoading ? <CircularProgress size="1rem" color="inherit" /> : undefined}
                    >
                        {isLoading ? 'Adding Contact' : 'Add Contact'}
                    </Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AddContactDialog;