import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CampaignPreview from '../../campaignPreview/CampaignPreview';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import useAccount from '../../hooks/useAccount';
import ShoppingList from '../../editor/ShoppingList';

const tableHead = {
    width: '25%',
    minWidth: 150
};

const ToggleDevice = ({ device, onChangeDevice }) => {
    const handleChange = (event, value) => {
        value && onChangeDevice(value);
    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={device}
            exclusive
            onChange={handleChange}
        >
            <ToggleButton value="desktop">Desktop</ToggleButton>
            <ToggleButton value="mobile">Mobile</ToggleButton>
        </ToggleButtonGroup>
    );
};

const FinalChecks = ({ email, onSaveEmail, previewHtml, onBack, drawerWidth, steps, step, isProofSent, titleBarDepth, isLoading }) => {
    const [device, setDevice] = useState('desktop');
    const { smtpUsername } = useAccount();

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64, zIndex: titleBarDepth }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">Final Checks</Typography>
                </Toolbar>
            </AppBar>
            <div style={{ marginRight: drawerWidth, flexGrow: 1 }}>
                {isLoading ? <LoadingOverlay /> : (
                    <Box p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} xl={5}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Alert severity="info">Please check all your details are correct.</Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell variant="head" sx={tableHead}>
                                                            From
                                                        </TableCell>
                                                        <TableCell>
                                                            {email.fromName} ({smtpUsername || email.fromEmail})
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head" sx={tableHead}>
                                                            Subject
                                                        </TableCell>
                                                        <TableCell>
                                                            {email.subject}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell variant="head" sx={tableHead}>
                                                            Delay
                                                        </TableCell>
                                                        <TableCell>
                                                            {email.delay}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} xl={7}>
                                <Card>
                                    <CardHeader
                                        action={(
                                            <ToggleDevice
                                                device={device}
                                                onChangeDevice={e => setDevice(e)}
                                            />
                                        )}
                                        title="Email Preview"
                                    />
                                    <CardContent>
                                        <CampaignPreview html={previewHtml} device={device} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>
            <ShoppingList
                drawerWidth={drawerWidth}
                stepNumber={step}
                steps={steps}
                onNext={onSaveEmail}
                onBack={onBack}
                disabled={isLoading}
                campaign={null}
                isProofSent={isProofSent}
                audienceName=""
                finalStepButtonText="Save Email"
            />
        </>
    );
};

export default FinalChecks;