import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { Alert } from '@mui/material';

const DeleteSafeSenderDialog = ({ emailAddress, customerId, onSubmit, onCancel }) => {
    return (
        <Dialog
            open={true}
            onClose={onCancel}
            maxWidth="sm"
        >
            <DialogTitle>Delete Safe Sender</DialogTitle>
            <DialogContent dividers>
                <Alert severity="error" sx={{ marginBottom: 2 }}>
                    You are about to permanently delete the safe sender <strong>{emailAddress}</strong> from account <strong>{customerId}</strong>.
                </Alert>
                <DialogContentText>
                    Are you sure you want to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="outlined">Back to Safety</Button>
                <Button onClick={onSubmit} color="error">Delete Safe Sender</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteSafeSenderDialog;