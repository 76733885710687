import React, { useEffect, useState, useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import InboxPreviewDialog from '../../editor/dialogs/InboxPreviewDialog';
import FormHelperText from '@mui/material/FormHelperText';
import useAccount from '../../hooks/useAccount';
import ShoppingList from '../../editor/ShoppingList';

const Setup = ({ email, onUpdateEmail, onNext, onBack, drawerWidth, steps, step, isProofSent, titleBarDepth }) => {
    const [showInboxPreview, setShowInboxPreview] = useState(false);
    const [subject, setSubject] = useState(email.subject);
    const [fromName, setFromName] = useState(email.fromName);
    const [fromEmail, setFromEmail] = useState(email.fromEmail);
    const [isValid, setIsValid] = useState(false);
    const [form, setForm] = useState(null);
    const [fromEmailField, setFromEmailField] = useState(null);
    const [isFromEmailValid, setIsFromEmailValid] = useState();
    const { smtpUsername } = useAccount();

    const onRefChange = useCallback(node => setForm(node), []);

    const onFromEmailFieldChange = useCallback(node => setFromEmailField(node), []);

    const handleNext = () => {
        onUpdateEmail({
            subject,
            fromName,
            fromEmail: smtpUsername || fromEmail
        });

        onNext();
    };

    const handleCheckIsValid = () => {
        return (form && form.checkValidity());
    };

    useEffect(() => {
        setIsValid(handleCheckIsValid());
    }, [form, subject, fromName, fromEmail]);

    useEffect(() => {
        fromEmailField && setIsFromEmailValid(() => fromEmailField.checkValidity());
    }, [fromEmail]);

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64, zIndex: titleBarDepth }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">Campaign Setup</Typography>
                </Toolbar>
            </AppBar>
            <div style={{ marginRight: drawerWidth, flexGrow: 1 }}>
                <Container disableGutters>
                    <Card sx={{ m: 2, p: 3 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <form ref={onRefChange}>
                                    <FormControl fullWidth>
                                        <TextField required value={subject} onChange={e => setSubject(e.target.value)} label="Subject line" margin="none" />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <TextField required value={fromName} onChange={e => setFromName(e.target.value)} label="Email from name" margin="none" />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        {smtpUsername ? (
                                            <>
                                                <TextField disabled value={smtpUsername} label="From email address" margin="none" />
                                                <FormHelperText margin="dense">You cannot change your from email address when using a custom SMTP configuration.</FormHelperText>
                                            </>
                                        ) : (
                                            <TextField
                                                type="email"
                                                error={isFromEmailValid === false}
                                                inputRef={onFromEmailFieldChange}
                                                required value={fromEmail}
                                                onChange={e => setFromEmail(e.target.value)}
                                                label="From email address"
                                                margin="none"
                                            />
                                        )}
                                    </FormControl>
                                </form>
                                <FormControl>
                                    <Button onClick={() => setShowInboxPreview(true)}>
                                        Inbox Preview
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
            </div>
            <ShoppingList
                drawerWidth={drawerWidth}
                stepNumber={step}
                steps={steps}
                disabled={!isValid}
                onNext={handleNext}
                onBack={onBack}
                campaign={null}
                email={email}
                isProofSent={isProofSent}
                audienceName=""
            />
            {showInboxPreview && (
                <InboxPreviewDialog
                    open={showInboxPreview}
                    onClose={() => setShowInboxPreview(false)}
                    fromName={fromName}
                    fromEmail={smtpUsername || fromEmail}
                    subject={subject}
                    html={email.html}
                />
            )}
        </>
    );
};

export default Setup;