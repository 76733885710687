import { chartColours } from './theme/constants';

const generateChartColours = data => {
    let colours = [];
    let i = 0;

    data.forEach(() => {
        // add colour to array
        colours.push(chartColours.find(e => e.key === i).value);

        // increase colour counter or reset if at the end of the colours array
        i > chartColours.length - 2 ? i = 0 : i++;
    });

    return colours;
};

export default generateChartColours;