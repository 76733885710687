import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';

const AddAllowedRecipientDialog = ({ onSubmit, onCancel }) => {
    const [pattern, setPattern] = useState('');
    const [type, setType] = useState('domain');

    return (
        <Dialog
            open={true}
            onClose={onCancel}
            maxWidth="sm"
        >
            <DialogTitle>Add Allowed Recipient</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Please enter a domain or email address to allow.
                </DialogContentText>
                <Stack direction="row" spacing={1}>
                    <FormControl sx={{ minWidth: 125 }}>
                        <InputLabel id="select-label">Type</InputLabel>
                        <Select
                            labelId="select-label"
                            label="Type"
                            value={type}
                            onChange={e => setType(e.target.value)}
                            size="small"
                        >
                            <MenuItem value="domain">Domain</MenuItem>
                            <MenuItem value="mailbox">Mailbox</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            fullwidth
                            variant="outlined"
                            size="small"
                            label="Pattern"
                            autoComplete="off"
                            value={pattern}
                            onChange={e => setPattern(e.target.value)}
                            placeholder={type === 'domain' ? 'example.com' : 'name@example.com'}
                        />
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="outlined">
                    Close
                </Button>
                <Button onClick={() => onSubmit(pattern, type)} disabled={!pattern.trim()}>
                    Add Allowed Recipient
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddAllowedRecipientDialog;