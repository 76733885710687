import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FilteredGroupsSelector from '../../contacts/FilteredGroupsSelector';

const MoveContactsDialog = ({ sourceGroupName, onClose, onConfirm, onCreateGroup }) => {
    const [selectedGroups, setSelectedGroups] = useState([]);

    const handleConfirm = e => {
        e.preventDefault();
        onConfirm(selectedGroups);
        onClose();
    };

    return (
        <>
            <Dialog onClose={onClose} open={true}>
                <DialogTitle>Move Contacts to Group</DialogTitle>
                <Alert severity="warning">
                    This action will move the selected contacts from {sourceGroupName} to the destination group(s). These contacts will be removed from {sourceGroupName}.
                </Alert>
                <FilteredGroupsSelector
                    selectedGroups={selectedGroups}
                    setSelectedGroups={setSelectedGroups}
                    spacing={3}
                    popperGutters={3}
                    multipleSelection={false}
                    onCreateGroup={onCreateGroup}
                />
                <DialogActions>
                    <Button onClick={handleConfirm} disabled={selectedGroups.length === 0}>Confirm</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MoveContactsDialog;