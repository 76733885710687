import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const RenameDialog = ({ onClose, onSubmit, itemName, type, isCopy = false, isCopying = false }) => {
    const [name, setName] = useState(itemName);

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(name);
    };

    const item = type === 'email' ? 'Email' : type === 'survey' ? 'Survey' : 'Landing Page';

    return (
        <Dialog onClose={onClose} open={true}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Name {item}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ marginBottom: 2 }}>
                        Name {isCopy && 'the new copy of'} your {item}. This is for your reference only and does not form part of the {type === 'email' ? 'campaign' : 'page'}.
                    </DialogContentText>
                    {(type === 'email' && isCopy) && (
                        <Alert severity="info" sx={{ mb: 2 }}>
                            Please note that if the original email has been sent, scheduled to send, or an audience has been selected, these settings are not copied over.
                        </Alert>
                    )}
                    <TextField
                        autoFocus
                        onFocus={event => {
                            event.target.select();
                        }}
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={name}
                        required
                        onChange={e => setName(e.target.value)}
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        startIcon={isCopying ? <CircularProgress color="inherit" size={20} /> : null}
                    >
                        {isCopying ? 'Saving' : 'Save'}
                    </Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default RenameDialog;