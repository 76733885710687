import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import useApi from '../hooks/useApi';
import buildUrl from '../buildUrl';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import CreateFromTemplateDialog from './dialogs/CreateFromTemplateDialog';

const TemplateItem = ({ name, thumbnail, onSelectTemplate }) => {
    const screen = {
        height: 200,
        overflow: 'hidden',
        position: 'relative',

        '& img': {
            top: 0,
            width: '100%',
            height: 'auto',
            position: 'absolute',
            zIndex: 0,
            WebkitTransition: 'top 2s',
            MozTransition: 'top 2s',
            MsTransition: 'top 2s',
            OTransition: 'top 2s',
            transition: 'top 2s'
        },

        '&:hover img': {
            top: '-100%',
            WebkitTransition: 'all 4s',
            MozTransition: 'all 4s',
            MsTransition: 'all 4s',
            OTransition: 'all 4s',
            transition: 'all 4s'
        }
    };

    return (
        <Grid item xs={6} sm={4} xl={3}>
            <Card>
                <CardActionArea onClick={onSelectTemplate}>
                    <CardHeader
                        title={<Typography component="p" variant="body1">{name}</Typography>}
                        action={(
                            <Button onClick={onSelectTemplate} component="div">
                                Select
                            </Button>
                        )}
                    />
                    <Divider />
                    <Box sx={screen}>
                        <img src={thumbnail} alt={name} />
                    </Box>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

const ListName = ({ name }) => {
    return (
        <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
                {name}
            </Typography>
        </Grid>
    );
};

const TemplatesList = ({ templates, selectedTemplate, onSelectTemplate }) => {
    if (templates.length === 0) {
        return 'No templates available';
    }

    const bespokeTemplates = templates.filter(e => e.bespoke);
    const systemTemplates = templates.filter(e => !e.bespoke);

    return (
        <>
            {bespokeTemplates.length > 0 && (
                <Grid container spacing={2}>
                    <ListName name="Bespoke" />
                    {bespokeTemplates.map(template => (
                        <TemplateItem
                            key={template.id}
                            name={template.name}
                            id={template.id}
                            thumbnail={template.thumbnailUrl}
                            selectedTemplate={selectedTemplate}
                            onSelectTemplate={() => onSelectTemplate(template)}
                        />
                    ))}
                </Grid>
            )}
            {(bespokeTemplates.length > 0 && systemTemplates.length > 0) && <Divider sx={{ marginY: 4 }} />}
            {systemTemplates.length > 0 && (
                <Grid container spacing={2}>
                    <ListName name="Free" />
                    {systemTemplates.map(template => (
                        <TemplateItem
                            key={template.id}
                            name={template.name}
                            id={template.id}
                            thumbnail={template.thumbnailUrl}
                            selectedTemplate={selectedTemplate}
                            onSelectTemplate={() => onSelectTemplate(template)}
                        />
                    ))}
                </Grid>
            )}
        </>
    );
};

const NewSurveyFromTemplate = () => {
    const history = useHistory();
    const [surveyTemplates, setSurveyTemplates] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [showCreateFromTemplateDialog, setShowCreateFromTemplateDialog] = useState(false);
    const { handleGet, handlePost } = useApi();

    const loadSurveyTemplates = async () => {
        const url = buildUrl('surveys/templates');

        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setSurveyTemplates(data);
    };

    const handleShowCreateFromTemplateDialog = template => {
        setSelectedTemplate(template);
        setShowCreateFromTemplateDialog(true);
    };

    const handleHideCreateFromTemplateDialog = () => {
        setShowCreateFromTemplateDialog(false);
        setSelectedTemplate(null);
    };

    const handleCreateFromTemplate = async name => {
        const response = await handlePost('surveys', {
            templateId: selectedTemplate.id,
            name
        });

        if (!response.ok) {
            return;
        }

        const data = await response.json();

        handleHideCreateFromTemplateDialog();

        history.push({
            pathname: `/editor/surveys/${data.id}`
        });
    };

    const handleGoBack = () => {
        history.push({
            pathname: '/editor',
            state: { type: 'survey' }
        });
    };

    useEffect(() => {
        loadSurveyTemplates();
    }, []);

    if (!surveyTemplates) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AppBar
                position="sticky"
                color="inherit"
                sx={{ top: 64 }}
            >
                <Toolbar>
                    <div style={{ flexGrow: 1 }}>
                        <Typography component="h1" variant="h6">Choose Template</Typography>
                    </div>
                    <Button variant="outlined" onClick={handleGoBack} startIcon={<NavigateBeforeIcon />}>Back</Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth={false} sx={{ paddingTop: 3, paddingBottom: 3 }}>
                <TemplatesList
                    templates={surveyTemplates}
                    selectedTemplate={selectedTemplate}
                    onSelectTemplate={handleShowCreateFromTemplateDialog}
                />
            </Container>
            {showCreateFromTemplateDialog && (
                <CreateFromTemplateDialog
                    template={selectedTemplate}
                    onClose={handleHideCreateFromTemplateDialog}
                    onSubmit={handleCreateFromTemplate}
                    type="survey"
                />
            )}
        </>
    );
};

export default NewSurveyFromTemplate;