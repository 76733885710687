import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Emails from './tabs/Emails';
import Pages from './tabs/Pages';
import QRCodes from './tabs/QRCodes';
import Surveys from './tabs/Surveys';
import ContentNavigation from './ContentNavigation';

const Dashboard = () => {
    const location = useLocation();
    const [type, setType] = useState(location?.state?.type ?? 'email');

    return (
        <>
            <ContentNavigation
                type={type}
                onSetType={setType}
            />
            <Paper sx={{ px: 1.5 }} square>
                {type === 'email' && <Emails />}
                {type === 'page' && <Pages />}
                {type === 'qrcode' && <QRCodes />}
                {type === 'survey' && <Surveys />}
            </Paper>
        </>
    );
};

export default Dashboard;
