import React from 'react';
import { createContext, useContext, useState, useCallback } from 'react';

const DialogContext = createContext();

// const DialogRoot = ({ children }) => {
//     return createPortal(children, document.getElementById('dialog-root'));
// };

const DialogProvider = ({ children }) => {
    const [dialog, setDialog] = useState();

    const unsetDialog = useCallback(() => setDialog(), [setDialog]);

    return (
        <DialogContext.Provider value={{ setDialog, unsetDialog }}>
            {children}
            {Boolean(dialog) && dialog}
        </DialogContext.Provider>
    );
};

const useDialog = () => useContext(DialogContext);

export { DialogProvider, useDialog };