const colours = {
    primary: '#1973CE',
    secondary: '#1E50B3',
    primarySelected: '#EDF4FB',
    queued: '#FCE0AF'
};

const chartColours = [
    { key: 0, value: '#24A19C' },
    { key: 1, value: '#FDCEB9' },
    { key: 2, value: '#D67D3E' },
    { key: 3, value: '#9C0F48' },
    { key: 4, value: '#FFE162' },
    { key: 5, value: '#FF6464' },
    { key: 6, value: '#91C483' },
    { key: 7, value: '#1A1A40' },
    { key: 8, value: '#7A0BC0' },
    { key: 9, value: '#FA58B6' },
    { key: 10, value: '#1572A1' },
    { key: 11, value: '#9AD0EC' },
    { key: 12, value: '#EFDAD7' },
    { key: 13, value: '#F3C5C5' },
    { key: 14, value: '#C1A3A3' },
    { key: 15, value: '#886F6F' },
    { key: 16, value: '#694E4E' },
    { key: 17, value: '#BBDEFB' },
    { key: 18, value: '#2196F3' },
    { key: 19, value: '#1976D2' },
    { key: 20, value: '#0D47A1' },
    { key: 21, value: '#1A237E' }
];

const drawerWidth = 320;

const printStyleSection = {
    breakAfter: 'page', //Safari
    pageBreakAfter: 'page', //Chrome
    marginBottom: 4,
    position: 'relative',
    breakInside: 'avoid',
    pageBreakInside: 'avoid'
};

const printStyleCover = {
    paddingTop: 4,
    paddingBottom: 4,
    textAlign: 'center'
};

const printStyleBreakAfter = {
    display: 'block',
    pageBreakAfter: 'always',
    position: 'relative'
};

const printStyleOverrides = {
    '@media print': {
        position: 'relative',
        display: 'block'
    }
};

const gaugeColour = {
    dark: '#FD666D',
    medium: '#37A2DA',
    light: '#67E0E3'
};

const printStyleHide = {
    '@media print': {
        visibility: 'hidden'
    }
};

const userChartColour = {
    allocated: '#5470C6',
    unallocated: '#D1D1D1',
    azureAD: '#80C4E9',
    userName: '#F3CA52'
};

export {
    colours,
    drawerWidth,
    chartColours,
    printStyleSection,
    printStyleCover,
    printStyleBreakAfter,
    printStyleOverrides,
    printStyleHide,
    gaugeColour,
    userChartColour
};