import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const ConfirmDeleteDialog = ({ onClose, onDelete, item, type }) => {
    const handleDelete = e => {
        e.preventDefault();
        onDelete(item.id);
        onClose();
    };

    const dialogTitle = type === 'email'
        ? item.status === 'Scheduled'
            ? 'Cancel & Delete Campaign'
            : 'Delete Email'
        : type === 'survey'
            ? 'Delete Survey'
            : 'Delete Page';

    const dialogText = type === 'email'
        ? item.status === 'Scheduled'
            ? 'Please confirm you wish to cancel the scheduled send for this campaign and also delete the campaign itself.'
            : 'Please confirm you wish to delete this email.'
        : type === 'survey'
            ? 'Please confirm you wish to delete this survey'
            : 'Please confirm you wish to delete this page.' ;

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>{dialogTitle}: {item.name}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {dialogText}
                </DialogContentText>
                <Alert severity="warning" sx={{ mt: 2 }}>
                    This action cannot be undone.
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDelete}>Confirm</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteDialog;
