import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Alert from '@mui/material/Alert';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const ConfirmDeleteDialog = ({ onClose, onConfirm, item, type }) => {
    const handleConfirm = e => {
        e.preventDefault();
        onConfirm(item.id);
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Delete {type}: {item.name}</DialogTitle>
            <DialogContent>
                <Alert severity="warning">
                    Are you sure you want to delete this {type}?
                </Alert>

                {(type === 'Automation' && item.emailsCount > 0) && (

                    <DialogContentText sx={{ pt: 2 }}>
                        {`${item.name} and ${item.emailsCount > 1 ? `${item.emailsCount} emails` : '1 email'} will be deleted.`}
                    </DialogContentText>

                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>Delete {type}</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteDialog;