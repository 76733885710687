import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const PublishEmailDialog = ({ email, emailText, onComplete, onClose }) => {
    const handlePublish = e => {
        e.preventDefault();
        onComplete();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <form onSubmit={handlePublish}>
                <DialogTitle>
                    Publish {emailText}: {email.name} to account: {email.customerId}?
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>Please confirm this action.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Confirm Publish</Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default PublishEmailDialog;
