import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AuditingTable from './AuditingTable';
import TitleBar from '../../titleBar/TitleBar';
import AlertBar from '../../alertBar/AlertBar';

const Auditing = ({ showHamburger, onClickHamburger }) => {
    const [showAlert, setShowAlert] = useState(false);

    return (
        <>
            <TitleBar showHamburger={showHamburger} onClickHamburger={onClickHamburger}>
                <Breadcrumbs>
                    <Link href="/#/superadmin">Super Admin</Link>
                    <Typography>
                        <strong>Auditing</strong>
                    </Typography>
                </Breadcrumbs>
            </TitleBar>
            {showAlert && (
                <AlertBar severity="warning">
                    One or more filters have been applied. Please check they are correct before exporting.
                </AlertBar>
            )}

            <Paper sx={{ m: 2 }}>
                <AuditingTable handleAlert={setShowAlert} />
            </Paper>
        </>
    );
};

export default Auditing;