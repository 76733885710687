import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import useInterval from '../../hooks/useInterval';
import useSearch from '../../hooks/useSearch';
import useSnackbar from '../../hooks/useSnackbar';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import ConfirmDeleteQRCodeDialog from '../dialogs/ConfirmDeleteQRCodeDialog';
import QRCodeDownloadDialog from '../dialogs/QRCodeDownloadDialog';
import QRCodeFormDialog from '../dialogs/QRCodeFormDialog';
import QRCodeAssignContentDialog from '../dialogs/QRCodeAssignContentDialog';
import QRCodeActions from '../../qrcode/QRCodeActions';
import QRCodeImage from '../../qrcode/QRCodeImage';
import TimerIcon from '@mui/icons-material/Timer';
import PlaceIcon from '@mui/icons-material/Place';
import WebIcon from '@mui/icons-material/Web';
import EmailIcon from '@mui/icons-material/Email';
import useTheme from '@mui/material/styles/useTheme';
import CancelIcon from '@mui/icons-material/Cancel';
import PollIcon from '@mui/icons-material/Poll';

const EmptyTable = ({ isInitialising, filtersApplied }) => {
    if (isInitialising) {
        return null;
    }

    return (
        <Box sx={{ padding: 6, textAlign: 'center' }}>
            {filtersApplied ? (
                <Typography>No data</Typography>
            ) : (
                <>
                    <Typography>You haven't created any QR Codes yet.</Typography>
                    <Typography>Click 'New QR Code' to create your first one!</Typography>
                </>
            )}
        </Box>
    );
};

const Content = ({ activeContent, onAssignContent, scheduledContent, showAssignButton }) => {
    const hasScheduledContent = scheduledContent?.filter(id => id !== activeContent?.campaignId).length > 0;

    if (activeContent) {
        return (
            <Box display="inlineFlex" alignItems="center">
                {
                    activeContent.type === 'page'
                        ? <WebIcon color="success" />
                        : activeContent.type === 'survey'
                            ? <PollIcon color="success" />
                            : <EmailIcon color="success" />
                }
                <Stack>
                    <Typography variant="body2" ml={1}>Name: {activeContent.name}</Typography>
                    {activeContent.type !== 'survey' && <Typography variant="body2" ml={1}>{activeContent.type === 'page' ? 'Title' : 'Subject'}: {activeContent.title}</Typography>}
                    <Typography variant="body2" ml={1}>Type: {activeContent.type}</Typography>
                </Stack>
            </Box>
        );
    }

    if (hasScheduledContent) {
        return (
            <Box display="inlineFlex" alignItems="center">
                <TimerIcon color="info" />
                <Typography variant="body2" ml={1}>Scheduled</Typography>
            </Box>
        );
    }

    if (showAssignButton) {
        return (
            <Button size="small" onClick={onAssignContent} sx={{ whiteSpace: 'pre' }}>Assign Content</Button>
        );
    }

    return (
        <Box display="inlineFlex" alignItems="center">
            <CancelIcon color="disabled" />
            <Typography variant="body2" ml={1}>None</Typography>
        </Box>
    );
};

const Location = ({ location }) => {
    const theme = useTheme();

    if (!location) {
        return (
            <Box display="inlineFlex" alignItems="center">
                <PlaceIcon fontSize="small" color="disabled" />
                <Typography variant="body2" color={theme.palette.grey[500]} ml={0.5}>No location set</Typography>
            </Box>
        );
    }

    return (
        <Box display="inlineFlex" alignItems="center">
            <PlaceIcon fontSize="small" color="info" />
            <Typography variant="body2" ml={0.5}>{location}</Typography>
        </Box>
    );
};

const Row = ({ qrCode, onSetItemToAssignTo, onEditQRCode, onSetItemToDelete, onDisplayCode, onSelect, selectedQRCode, assignedQrCodes, history }) => {
    const handleAssignContent = (e, qrCode) => {
        e.stopPropagation();
        onSetItemToAssignTo(qrCode);
    };

    const handleClick = () => {
        onSelect ? onSelect() : history.push(`editor/qr/${qrCode.id}`);
    };

    const isAssigned = Boolean(assignedQrCodes?.find(e => e.qrCodeId === qrCode.id));

    const handleOpenLink = url => {
        const urlObject = new URL(url, window.location.origin);
        urlObject.searchParams.set('preview', 'true');
        window.open(urlObject.toString(), '_blank');
    };

    return (
        <TableRow onClick={handleClick} hover selected={selectedQRCode?.id === qrCode.id || isAssigned}>
            <TableCell>
                <QRCodeImage qrCode={qrCode} size={35} />
            </TableCell>
            <TableCell>{qrCode.name}</TableCell>
            <TableCell><Location location={qrCode.location} /></TableCell>
            <TableCell>
                <Content
                    activeContent={qrCode.activeContent}
                    scheduledContent={qrCode.scheduledCampaignIds}
                    onAssignContent={e => handleAssignContent(e, qrCode)}
                    showAssignButton={!onSelect}
                />
            </TableCell>
            {!onSelect && (
                <TableCell align="right">{qrCode.activeContent?.opens ?? '-'}</TableCell>
            )}
            {!onSelect && (
                <TableCell align="right">{qrCode.activeContent?.clicks ?? '-'}</TableCell>
            )}
            {!onSelect && (
                <TableCell>
                    {qrCode.activeContent?.startDate
                        ? format(new Date(qrCode.activeContent.startDate), 'dd/MM/yyyy HH:mm')
                        : '-'}
                </TableCell>
            )}
            {!onSelect && (
                <TableCell>
                    {qrCode.activeContent?.endDate
                        ? format(new Date(qrCode.activeContent.endDate), 'dd/MM/yyyy HH:mm')
                        : '-'}
                </TableCell>
            )}
            {!onSelect && (
                <TableCell align="right">
                    <QRCodeActions
                        hasActiveContent={Boolean(qrCode.activeContent?.name)}
                        onAssignContent={() => onSetItemToAssignTo(qrCode)}
                        onView={() => handleOpenLink(qrCode.url)}
                        onGetQRCode={onDisplayCode}
                        onEdit={onEditQRCode}
                        onDelete={onSetItemToDelete}
                    />
                </TableCell>
            )}
        </TableRow>
    );
};

const QRCodes = ({ onSelect = null, selectedQRCode = null, assignedQrCodes = null }) => {
    const history = useHistory();
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();
    const [searchValue, setSearchValue] = useState('');
    const [tempSearchValue, setTempSearchValue] = useSearch(setSearchValue);
    const { showSnackbar } = useSnackbar();
    const [isInitialising, setIsInitialising] = useState(true);
    const [unfilteredQRCodes, setUnfilteredQRCodes] = useState([]);
    const [qrCodes, setQRCodes] = useState([]);
    const [location, setLocation] = useState(null);
    const [itemToAssignTo, setItemToAssignTo] = useState(null);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const [codeToDisplay, setCodeToDisplay] = useState(null);
    const [sort, setSort] = useState({ by: 'name', desc: false });

    const handleFetchQRCodes = async () => {
        const response = await handleGet('qrcodes');

        if (!response.ok) {
            showSnackbar('Error fetching QR Codes', 'error');
            return;
        }

        const data = await response.json();

        setUnfilteredQRCodes(data);
    };

    const handleEditItem = async ({ item, name = null, location = null }) => {
        const params = {
            ...item,
            name,
            location
        };

        const response = await handlePut(`qrcodes/${item.id}`, params);

        if (!response.ok) {
            showSnackbar('QR Code not found', 'error');
            return;
        }

        handleFetchQRCodes();
    };

    const handleCreateItem = async ({ name, location }) => {
        const params = {
            name,
            location
        };

        const response = await handlePost('qrcodes/', params);

        if (response.ok) {
            showSnackbar('QR Code created successfully', 'success');

            handleFetchQRCodes();

            const newQRCode = await response.json();

            if (onSelect) {
                onSelect(newQRCode);
            }
            else {
                history.push(`editor/qr/${newQRCode.id}`);
            }
        }
        else {
            showSnackbar('QR Code could not be created', 'error');
        }
    };

    const handleDeleteItem = async item => {
        const response = await handleDelete(`qrcodes/${item.id}`);

        if (!response.ok) {
            showSnackbar('QR Code not found', 'error');
        }

        setItemToDelete(null);
        handleFetchQRCodes();
    };

    const handleAssignContent = async (qrCodeId, contentId, startDate, endDate, type) => {
        const params = {
            qrCodeId,
            ...(type === 'email' && { campaignId: contentId }),
            ...(type === 'page' && { landingPageId: contentId }),
            ...(type === 'survey' && { surveyId: contentId }),
            type,
            startDate,
            endDate
        };

        const response = await handlePost(`qrcodes/${qrCodeId}/contents`, params);

        if (!response.ok) {
            showSnackbar('Content could not be associated', 'error');
            return;
        }

        await handleFetchQRCodes();

        showSnackbar('Content associated successfully', 'success');
    };

    const handleSort = field => {
        if (field === sort.by) {
            setSort({ ...sort, desc: !sort.desc });
        }
        else {
            setSort({ by: field, desc: !sort.desc });
        }
    };

    const handleCloseAssignCampaignDialog = (event, reason) => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        setItemToAssignTo(null);
    };

    const handleInit = async () => {
        await handleFetchQRCodes();

        setIsInitialising(false);
    };

    const intervalRef = useInterval(() => {
        if (!isInitialising) {
            handleFetchQRCodes();
        }

        return () => {
            clearInterval(intervalRef.current);
        };
    }, 30000);

    useEffect(() => {
        let filteredQRCodes = unfilteredQRCodes;

        if (searchValue) {
            filteredQRCodes = filteredQRCodes.filter(e => e.name.toLowerCase().includes(searchValue.toLowerCase()));
        }

        if (location) {
            filteredQRCodes = filteredQRCodes.filter(e => e.location.toLowerCase().includes(location.toLowerCase()));
        }

        if (sort.by === 'name') {
            if (sort.desc) {
                filteredQRCodes.sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    if (nameA > nameB) {
                        return -1;
                    }

                    if (nameA < nameB) {
                        return 1;
                    }

                    return 0;
                });
            }
            else {
                filteredQRCodes.sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    if (nameA < nameB) {
                        return -1;
                    }

                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
            }
        }

        if (sort.by === 'location') {
            if (sort.desc) {
                filteredQRCodes.sort((a, b) => {
                    const locationA = a.location?.toLowerCase() ?? '';
                    const locationB = b.location?.toLowerCase() ?? '';

                    if (locationA > locationB) {
                        return -1;
                    }

                    if (locationA < locationB) {
                        return 1;
                    }

                    return 0;
                });
            }
            else {
                filteredQRCodes.sort((a, b) => {
                    const locationA = a.location?.toLowerCase() ?? '';
                    const locationB = b.location?.toLowerCase() ?? '';

                    if (locationA < locationB) {
                        return -1;
                    }

                    if (locationA > locationB) {
                        return 1;
                    }

                    return 0;
                });
            }
        }

        if (sort.by === 'startDate') {
            if (sort.desc) {
                filteredQRCodes.sort((a, b) => (b.activeContent?.startDate ? new Date(b.activeContent?.startDate) : 0) - (a.activeContent?.startDate ? new Date(a.activeContent?.startDate) : 0));
            }
            else {
                filteredQRCodes.sort((a, b) => (a.activeContent?.startDate ? new Date(a.activeContent?.startDate) : 0) - (b.activeContent?.startDate ? new Date(b.activeContent?.startDate) : 0));
            }
        }

        if (sort.by === 'endDate') {
            if (sort.desc) {
                filteredQRCodes.sort((a, b) => (b.activeContent?.endDate ? new Date(b.activeContent?.endDate) : 0) - (a.activeContent?.endDate ? new Date(a.activeContent?.endDate) : 0));
            }
            else {
                filteredQRCodes.sort((a, b) => (a.activeContent?.endDate ? new Date(a.activeContent?.endDate) : 0) - (b.activeContent?.endDate ? new Date(b.activeContent?.endDate) : 0));
            }
        }

        setQRCodes([...filteredQRCodes]);
    }, [searchValue, sort, location, unfilteredQRCodes]);

    useEffect(() => {
        handleInit();
    }, []);

    if (isInitialising) {
        return <LoadingOverlay />;
    }

    // eslint-disable-next-line no-undef
    const existingLocations = [...new Set(unfilteredQRCodes.filter(e => e.location).map(e => e.location))];

    return (
        <>
            <Toolbar disableGutters sx={{ mx: 1.5, paddingTop: 0.5, paddingBottom: 0.5 }}>
                <Box sx={{ flexGrow: 1, mr: 1 }}>
                    <Stack spacing={1.5} direction={{ sm: 'column', md: 'row' }}>
                        <OutlinedInput
                            value={tempSearchValue}
                            onChange={e => setTempSearchValue(e.target.value)}
                            placeholder="Search..."
                            size="small"
                            endAdornment={(
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setTempSearchValue('')}
                                        edge="end"
                                        disabled={!Boolean(tempSearchValue)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )}
                        />
                        <Autocomplete
                            disablePortal
                            id="location"
                            options={existingLocations}
                            renderInput={params => <TextField {...params} label="Location" margin="none" />}
                            value={location}
                            onChange={(e, value) => setLocation(value)}
                            fullWidth
                            sx={{ maxWidth: 500 }}
                            size="small"
                        />
                    </Stack>
                </Box>
                <Button
                    onClick={() => setShowCreateDialog(true)}
                    startIcon={<AddIcon />}
                    sx={{ whiteSpace: 'pre' }}
                >
                    New QR Code
                </Button>
            </Toolbar>
            <Divider />
            {qrCodes.length > 0 ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '2%' }}></TableCell>
                                <TableCell component="th" scope="row" align="left" sx={{ width: '20%' }}>
                                    <TableSortLabel
                                        active={sort.by === 'name'}
                                        direction={sort.desc ? 'desc' : 'asc'}
                                        onClick={() => handleSort('name')}
                                        hideSortIcon
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell component="th" scope="row" align="left" sx={{ width: '30%' }}>
                                    <TableSortLabel
                                        active={sort.by === 'location'}
                                        direction={sort.desc ? 'desc' : 'asc'}
                                        onClick={() => handleSort('location')}
                                        hideSortIcon
                                    >
                                        Location
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell component="th" scope="row" align="left" sx={{ width: '20%' }}>
                                    Active Content
                                </TableCell>
                                {!onSelect && (
                                    <TableCell component="th" scope="row" align="right">
                                        Opens
                                    </TableCell>
                                )}
                                {!onSelect && (
                                    <TableCell component="th" scope="row" align="right">
                                        Clicks
                                    </TableCell>
                                )}
                                {!onSelect && (
                                    <TableCell component="th" scope="row" align="left">
                                        <TableSortLabel
                                            active={sort.by === 'startDate'}
                                            direction={sort.desc ? 'desc' : 'asc'}
                                            onClick={() => handleSort('startDate')}
                                            hideSortIcon
                                        >
                                            Start Date
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                {!onSelect && (
                                    <TableCell component="th" scope="row" align="left">
                                        <TableSortLabel
                                            active={sort.by === 'endDate'}
                                            direction={sort.desc ? 'desc' : 'asc'}
                                            onClick={() => handleSort('endDate')}
                                            hideSortIcon
                                        >
                                            End Date
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                {!onSelect && <TableCell />}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {qrCodes.map(qr => (
                                <Row
                                    key={qr.id}
                                    qrCode={qr}
                                    onSetItemToAssignTo={setItemToAssignTo}
                                    onEditQRCode={() => setItemToEdit(qr)}
                                    onSetItemToDelete={() => setItemToDelete(qr)}
                                    onDisplayCode={() => setCodeToDisplay(qr)}
                                    onSelect={onSelect ? () => onSelect(qr) : null}
                                    selectedQRCode={selectedQRCode}
                                    assignedQrCodes={assignedQrCodes}
                                    history={history}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <EmptyTable isInitialising={isInitialising} filtersApplied={unfilteredQRCodes.length !== qrCodes.length} />
            )}
            {showCreateDialog && (
                <QRCodeFormDialog
                    onClose={() => setShowCreateDialog(false)}
                    onSubmit={handleCreateItem}
                    qrCodes={qrCodes}
                />
            )}
            {itemToEdit && (
                <QRCodeFormDialog
                    onClose={() => setItemToEdit(null)}
                    onSubmit={qrCode => handleEditItem({ item: itemToEdit, name: qrCode.name, location: qrCode.location })}
                    item={itemToEdit}
                    qrCodes={qrCodes}
                />
            )}
            {itemToDelete && (
                <ConfirmDeleteQRCodeDialog
                    onClose={() => setItemToDelete(null)}
                    onDelete={handleDeleteItem}
                    qrCode={itemToDelete}
                />
            )}
            {itemToAssignTo && (
                <QRCodeAssignContentDialog
                    onClose={handleCloseAssignCampaignDialog}
                    onSubmit={handleAssignContent}
                    qrCode={itemToAssignTo}
                />
            )}
            {codeToDisplay && (
                <QRCodeDownloadDialog
                    onClose={() => setCodeToDisplay(null)}
                    qrCode={codeToDisplay}
                />
            )}
            <Snackbar
                open={Boolean(snackbarMessage)}
                onClose={() => setSnackbarMessage(null)}
                autoHideDuration={3000}
                message={snackbarMessage}
            />
        </>
    );
};

export default QRCodes;