import { VisualizationManager, HistogramPlotly, PlotlySetup } from 'survey-analytics';

VisualizationManager.unregisterVisualizer('rating', HistogramPlotly);

function generateValues (maxValue, stepsCount) {
    const values = [];

    for (let i = 0; i < stepsCount; i++) {
        values.push(maxValue / stepsCount);
    }

    values.push(maxValue);

    return values;
}

const interpolateColor = (value) => {
    const numericValue = parseInt(value);
    const r = (numericValue < 0) ? 255 : Math.floor(255 - (numericValue * 255 / 100));
    const g = (numericValue > 0) ? 200 : Math.floor(200 + (numericValue * 200 / 100));
    const b = 0;

    return `rgb(${r}, ${g}, ${b})`;
};

function getData (visualizer, level, score, arrowColor) {
    const { question, name } = visualizer;
    const { rateMax } = question;
    const stepsCount = 11;
    const values = generateValues(rateMax, stepsCount);
    const text = [100, 80, 60, 40, 20, 0, -20, -40, -60, -80, -100];
    const colors = text.map(value => interpolateColor(value));

    colors[colors.length] = 'rgba(255, 255, 255, 0)';

    return [
        {
            type: 'scatter',
            name: name,
            text: score,
            x: [0],
            y: [0],
            marker: {
                size: 20,
                color: arrowColor
            },
            showlegend: false,
            hoverinfo: 'text+name'
        },
        {
            type: 'pie',
            position: 'bottom center',
            values: values,
            rotation: 90,
            text: text,
            textinfo: 'text',
            textposition: 'outside',
            marker: {
                colors: colors
            },
            hole: 0.8,
            showlegend: false,
            hoverinfo: 'skip'
        }
    ];
}

function getLayout (visualizer, level, score, arrowColor) {
    const maxValue = visualizer.question.rateMax;
    const degrees = maxValue - level;
    const radius = 0.85;
    const radians = (degrees * Math.PI) / maxValue;
    const x = radius * Math.cos(radians);
    const y = radius * Math.sin(radians);

    // Build SVG markup for the gauge arrow
    const mainPath = 'M -.0 -0.025 L .0 0.025 L ';
    const pathX = String(x);
    const space = ' ';
    const pathY = String(y);
    const pathEnd = ' Z';
    const path = mainPath.concat(pathX, space, pathY, pathEnd);

    const layout = {
        title: {
            text: `NPS Score: ${score}`,
            font: {
                size: 20
            }
        },
        height: 600,
        width: 600,
        shapes: [{
            type: 'path',
            path: path,
            fillcolor: arrowColor,
            line: {
                color: arrowColor
            }
        }],
        // eslint-disable-next-line camelcase
        plot_bgcolor: visualizer.backgroundColor,
        // eslint-disable-next-line camelcase
        paper_bgcolor: visualizer.backgroundColor,
        xaxis: {
            zeroline: false,
            showticklabels: false,
            showgrid: false,
            range: [-1, 1]
        },
        yaxis: {
            zeroline: false,
            showticklabels: false,
            showgrid: false,
            range: [-1, 1]
        }
    };

    return layout;
}

const calculateNPS = (response) => {
    const totalResponse = response.length;
    const promoters = response.filter(score => score >= 7).length;
    const detractors = response.filter(score => score <= 5).length;
    const npsScore = ((promoters - detractors) / totalResponse) * 100;
    return Math.round(npsScore);
};

PlotlySetup.onPlotCreating.add((visualizer, options) => {
    const { name } = visualizer;

    if (visualizer.chartType === 'gauge' && name === 'nps_score') {
        const arrowColor = '#4e6198';
        const response = visualizer.data.map(item => item.nps_score);
        const npsScore = calculateNPS(response);

        let normalizedScore;
        let score = npsScore;

        if (npsScore && !isNaN(npsScore)) {
            normalizedScore = (npsScore + 100) / 20;
        }
        else {
            normalizedScore = (0 + 100) / 20;
            score = 0;
        }

        options.data = getData(visualizer, normalizedScore, score, arrowColor);
        options.layout = getLayout(visualizer, normalizedScore, score, arrowColor);
    }
});