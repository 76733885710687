import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';

const CreateFolderDialog = ({ open, onSubmit, onClose }) => {
    const [name, setName] = useState('');

    useEffect(() => {
        !open && setName('');
    }, [open]);

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Add New Folder</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Create a new folder.
                </DialogContentText>
                <TextField
                    autoFocus
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={name}
                    required
                    onChange={e => setName(e.target.value)}
                    margin="dense"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onSubmit(name)}>Add Folder</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateFolderDialog;