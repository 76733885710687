import React, { useEffect, useState, useRef } from 'react';
import 'survey-analytics/survey.analytics.min.css';
import { setLicenseKey } from 'survey-core';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import { Model } from 'survey-core';
import { VisualizationPanel } from 'survey-analytics';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import './gaugeCustomization.js';
import config from '../../config';

const { SURVEY_API_KEY } = config;

setLicenseKey(SURVEY_API_KEY);

const vizPanelOptions = {
    allowHideQuestions: false
};

const SurveyGraph = ({ survey, surveyResults }) => {
    const [surveyModel, setSurveyModel] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [vizPanel, setVizPanel] = useState(null);
    const vizPanelRef = useRef(null);

    useEffect(() => {
        if (survey !== null) {
            const newSurvey = new Model(survey);
            setSurveyModel(newSurvey);
        }
    }, [survey]);

    useEffect(() => {
        if (!!surveyModel && surveyResults !== null) {
            setIsLoading(true);

            const newVizPanel = new VisualizationPanel(
                surveyModel.getAllQuestions(),
                surveyResults,
                vizPanelOptions,
            );

            newVizPanel.showToolbar = false;
            setVizPanel(newVizPanel);

            setIsLoading(false);
        }
    }, [surveyModel, surveyResults]);

    useEffect(() => {
        if (vizPanel !== null && vizPanelRef.current) {
            vizPanel.render(vizPanelRef.current);

            return () => {
                if (vizPanelRef.current) {
                    vizPanelRef.current.innerHTML = '';
                }
            };
        }
    }, [vizPanel]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <Paper>
            <Box id="surveyVizPanel" ref={vizPanelRef} />
        </Paper>
    );
};

export default SurveyGraph;