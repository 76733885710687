import React from 'react';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const TitleBar = ({ title, children = null, actions = null, showHamburger = false, onClickHamburger, boxShadow = null, borderBottom = null }) => {
    const theme = useTheme();

    return (
        <AppBar
            position="sticky"
            color="inherit"
            sx={{ top: 64, zIndex: theme.zIndex.appBar + 1, boxShadow: boxShadow, borderBottom: borderBottom }}
        >
            <Toolbar>
                {showHamburger && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        sx={{ mr: 2 }}
                        onClick={() => onClickHamburger(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                {Boolean(children) ? children : (
                    <Typography component="div" variant="h6" sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                )}
                {actions}
            </Toolbar>
        </AppBar>
    );
};

export default TitleBar;