import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const UnsavedChangesDialog = ({ onClose, onDiscard }) => {

    const handleDiscard = e => {
        e.preventDefault();
        onDiscard();
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
                <Alert severity="warning">
                    Any unsaved changed will be lost
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDiscard}>Discard Changes</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnsavedChangesDialog;