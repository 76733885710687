import React, { useState, useEffect, forwardRef } from 'react';
import { format } from 'date-fns';
import { Table, TableBody, TableCell, IconButton, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel, Toolbar, AppBar, Paper, Typography, Dialog, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import buildUrl from '../../buildUrl';
import useApi from '../../hooks/useApi';
import ImportReportDialog from '../../notifications/ImportReportDialog';
import formatFileSize from '../../formatFileSize';
import useTheme from '@mui/material/styles/useTheme';
import { lighten } from '@mui/system';
import useInterval from '../../hooks/useInterval';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const fields = [
    { property: 'status', type: 'status', label: 'Status' },
    { property: 'createdDateTime', type: 'date', label: 'Created Date' },
    { property: 'completedDateTime', type: 'date', label: 'Completed Date' },
    { property: 'fileName', type: 'text', label: 'File Name' },
    { property: 'fileType', type: 'text', label: 'File Type' },
    { property: 'fileSize', type: 'fileSize', label: 'File Size', align: 'right' },
    { property: 'totalContacts', type: 'number', label: 'Total Contacts', align: 'right' },
    { property: 'totalAdded', type: 'number', label: 'Added', align: 'right' },
    { property: 'totalUpdated', type: 'number', label: 'Updated', align: 'right' },
    { property: 'totalFailed', type: 'number', label: 'Failed', align: 'right' }
];

const rowsPerPageOptions = [10, 20, 50];
const cellStyles = { whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

const ImportHistoryDialog = ({ onClose }) => {
    const { handleGet } = useApi();
    const [count, setCount] = useState(0);
    const [imports, setImports] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const theme = useTheme();

    const [orderBy, setOrderBy] = useState('createdDateTime');
    const [isDescending, setIsDescending] = useState(true);

    const [selectedImportId, setSelectedImportId] = useState(null);

    const handleFetchCount = async () => {
        const response = await handleGet('imports/count');

        if (response) {
            const data = await response.json();
            setCount(data);
        }
    };

    const handleFetchImports = async () => {
        const url = buildUrl('imports', {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending
        });

        const response = await handleGet(url);

        if (response) {
            const data = await response.json();
            setImports(data);
        }
    };

    const handleSort = field => {
        if (orderBy === field) {
            setIsDescending(!isDescending);
        }
        else {
            setIsDescending(field === 'createdDateTime');
        }

        setOrderBy(field);
    };

    const getStatusStyle = status => {
        let style = { backgroundColor: 'inherit' };

        switch (status) {
            case 'Completed':
                style = { backgroundColor: lighten(theme.palette.success.light, 0.7) };
                break;
            case 'Queued':
                style = { backgroundColor: lighten(theme.palette.warning.light, 0.7) };
                break;
            case 'In Progress':
                style = { backgroundColor: lighten(theme.palette.success.light, 0.9) };
                break;
        }

        return style;
    };

    const intervalRef = useInterval(() => {
        handleFetchImports();
        handleFetchCount();

        return () => {
            clearInterval(intervalRef.current);
        };
    }, 10000);

    useEffect(() => {
        handleFetchCount();
    }, []);

    useEffect(() => {
        setPage(0);
    }, [orderBy, isDescending, rowsPerPage]);

    useEffect(() => {
        handleFetchImports();
    }, [orderBy, isDescending, rowsPerPage, page]);

    return (
        <Dialog
            onClose={onClose}
            open={true}
            fullScreen
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Import History
                    </Typography>
                </Toolbar>
            </AppBar>
            <Paper sx={{ m: 2 }}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {fields.map(({ property, label, align }) => (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        component="th"
                                        sx={cellStyles}
                                    >
                                        <TableSortLabel
                                            active={orderBy === property}
                                            direction={isDescending ? 'desc' : 'asc'}
                                            onClick={() => handleSort(property)}
                                        >
                                            {label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {imports.map(e => {
                                const cells = fields.map(({ property, type, align }) => type === 'date' ? (
                                    <TableCell
                                        key={`${e.id}-${property}`}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        {e[property] ? format((new Date(e[property])), 'dd/MM/yyyy HH:mm:ss') : '-'}
                                    </TableCell>
                                ) : type === 'number' ? (
                                    <TableCell
                                        key={`${e.id}-${property}`}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        {e[property]}
                                    </TableCell>
                                ) : type === 'fileSize' ? (
                                    <TableCell
                                        key={`${e.id}-${property}`}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        {formatFileSize(e[property])}
                                    </TableCell>
                                ) : (type === 'status' ? (
                                    <TableCell
                                        key={`${e.id}-${property}`}
                                        align={align}
                                        sx={{ ...cellStyles, ...getStatusStyle(e[property]) }}
                                    >
                                        {e[property]}{e[property] === 'In Progress' && <CircularProgress color="success" size={14} sx={{ marginLeft: 1, verticalAlign: 'middle' }} />}
                                    </TableCell>
                                ) : (
                                    <TableCell
                                        key={`${e.id}-${property}`}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        {e[property] || '-'}
                                    </TableCell>
                                )));

                                return (
                                    <>
                                        <TableRow
                                            key={e.id}
                                            hover
                                            onClick={() => setSelectedImportId(e.id)}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            {cells}
                                        </TableRow>
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                />
            </Paper>
            {Boolean(selectedImportId) && (
                <ImportReportDialog
                    id={selectedImportId}
                    onClose={() => setSelectedImportId(null)}
                />
            )}
        </Dialog>
    );
};

export default ImportHistoryDialog;