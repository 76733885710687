import React from 'react';
import Typography from '@mui/material/Typography';

const SendingError = ({ error }) => {
    if (error?.toLowerCase().includes('smtp')) {
        return (
            <>
                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                    Your SMTP password may need updating or there may be a temporary service outage.
                </Typography>
                <Typography variant="body1">
                    <a href="https://support.newzappcommunications.co.uk/portal/en/kb/articles/applications-account#Troubleshooting" target="_blank" rel="noreferrer">Please follow this link for more details</a>
                </Typography>
            </>
        );
    }

    return (
        <Typography variant="body1">
            Please contact <a href="mailto:support@newzapp.com">support@newzapp.com</a>
        </Typography>
    );
};

export default SendingError;