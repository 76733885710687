const standardFields = [
    { name: 'title', displayName: 'Title', type: 'string' },
    { name: 'emailaddress', displayName: 'Email Address', type: 'string' },
    { name: 'firstname', displayName: 'First Name', type: 'string' },
    { name: 'lastname', displayName: 'Last Name', type: 'string' },
    { name: 'companyname', displayName: 'Company Name', type: 'string' },
    { name: 'mobilephone', displayName: 'Mobile Phone', type: 'string' },
    { name: 'jobtitle', displayName: 'Job Title', type: 'string' },
    { name: 'address1', displayName: 'Address 1', type: 'string' },
    { name: 'address2', displayName: 'Address 2', type: 'string' },
    { name: 'address3', displayName: 'Address 3', type: 'string' },
    { name: 'city', displayName: 'City', type: 'string' },
    { name: 'county', displayName: 'County', type: 'string' },
    { name: 'postcode', displayName: 'Postcode', type: 'string' },
    { name: 'country', displayName: 'Country', type: 'string' },
    { name: 'telno', displayName: 'Telephone', type: 'string' },
    { name: 'dateofbirth', displayName: 'Date of Birth', type: 'dateofbirth' }
];

export default standardFields;