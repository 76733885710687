import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const ConfigurationPanel = ({ formData, onFormChange, handleValid }) => {
    const [configurationDetails, setConfigurationDetails] = useState({
        trustedDeliveryType: formData.trustedDeliveryType,
        hostname: formData.hostname,
        port: formData.port,
        username: formData.username
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setConfigurationDetails({ ...configurationDetails, [name]: value });
        onFormChange(name, value);
    };

    useEffect(() => {
        setConfigurationDetails({
            ...configurationDetails,
            hostname: formData.hostname,
            username: formData.username,
            port: formData.port
        });
    }, [formData.port]);

    useEffect(() => {
        handleValid(configurationDetails.hostname !== '' && configurationDetails.port !== '');
    }, [configurationDetails]);

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <RadioGroup
                    row
                    name="trustedDeliveryType"
                    required
                    value={configurationDetails.trustedDeliveryType}
                    onChange={handleChange}
                    sx={{ display: 'flex' }}
                >
                    <FormControlLabel value="nhs.net" control={<Radio />} label="nhs.net" />
                    <FormControlLabel value="nhs.uk" control={<Radio />} label="nhs.uk" />
                </RadioGroup>
            </Box>
            {configurationDetails.trustedDeliveryType === 'nhs.net' ? (
                <Stack>
                    <TextField
                        label="Hostname"
                        name="hostname"
                        value={configurationDetails.hostname}
                        disabled
                    />
                    <TextField
                        label="Port"
                        name="port"
                        value={configurationDetails.port}
                        disabled
                    />
                    <TextField
                        label="Username"
                        name="username"
                        value={configurationDetails.username}
                        onChange={handleChange}
                    />
                </Stack>
            ) : (
                <Stack>
                    <TextField
                        label="Exchange MX Endpoint"
                        name="hostname"
                        value={configurationDetails.hostname}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Port"
                        name="port"
                        value={configurationDetails.port}
                        disabled
                    />
                    <TextField
                        label="Email From"
                        name="username"
                        value={configurationDetails.username}
                        onChange={handleChange}
                    />
                </Stack>
            )}
        </>
    );
};

export default ConfigurationPanel;