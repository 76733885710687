import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FilteredGroupsSelector from '../../contacts/FilteredGroupsSelector';

const nameRegex = /[a-zA-Z0-9\s-]/g;

const CreateSegmentDialog = ({ onClose, onSubmit, allContactsCount }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [groupId, setGroupId] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [step, setStep] = useState(0);
    const [isWorking, setIsWorking] = useState(false);

    const handleSubmit = async () => {
        setIsWorking(true);

        await onSubmit({
            name,
            groupId,
            ...(description && { description })
        });

        setIsWorking(false);
        onClose();
    };

    useEffect(() => {
        setIsValid(name.trim() !== '' && (name.match(nameRegex)?.length === name.length));
    }, [name]);

    return (
        <>
            <Dialog onClose={onClose} open={step === 0}>
                <DialogTitle>Create Segment</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ pt: 1 }}>
                        <TextField
                            autoFocus
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                            value={name}
                            required
                            onChange={e => setName(e.target.value)}
                            margin="none"
                            error={!isValid}
                            helperText="Name must be alphanumeric. Hyphens and spaces are allowed."
                        />
                        <TextField
                            id="description"
                            label="Description"
                            type="text"
                            fullWidth
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            margin="none"
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStep(1)} disabled={!isValid}>Choose Group</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog onClose={onClose} open={step === 1}>
                <DialogTitle>Create Segment: Choose Group</DialogTitle>
                <FilteredGroupsSelector
                    selectedGroups={groupId !== null ? [groupId] : null}
                    setSelectedGroups={selectedGroups => {
                        const groupId = (selectedGroups !== null && (selectedGroups.length)) > 0 ? selectedGroups[0] : null;
                        setGroupId(groupId);
                    }}
                    spacing={3}
                    popperGutters={3}
                    multipleSelection={false}
                    includeAllContacts={true}
                    allContactsCount={allContactsCount}
                />
                <DialogActions>
                    <Box sx={{ flexGrow: 1 }}>
                        <Button onClick={() => setStep(0)}>Back</Button>
                    </Box>
                    <Button
                        onClick={handleSubmit}
                        disabled={!isValid || isWorking}
                        startIcon={isWorking ? <CircularProgress size="1rem" color="inherit" /> : null}
                    >
                        Create Segment
                    </Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateSegmentDialog;