import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const TextItem = ({ primary, secondary }) => {
    return (
        <ListItem disablePadding>
            <ListItemText primary={primary} secondary={secondary} />
        </ListItem>
    );
};

export default TextItem;
