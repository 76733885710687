import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { useDropzone } from 'react-dropzone';
import useSnackbar from '../../../hooks/useSnackbar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const ScreenshotModal = ({ shown, onHide, data, onLoading, onSubmit }) => {
    const [files, setFiles] = useState(data.image ? [data.image] : []);
    const { showSnackbar } = useSnackbar();

    const handleChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const updatedFile = Object.assign(file, {
                preview: URL.createObjectURL(file)
            });
            setFiles([updatedFile]);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await onSubmit(files);
    };

    const onDrop = (acceptedFiles) => {
        const updatedFiles = acceptedFiles.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        );

        const maxSize = 1 * 1024 * 1024;

        const invalidFiles = updatedFiles.filter(
            (file) => file.size > maxSize || !file.type.startsWith('image/')
        );

        if (invalidFiles.length > 0) {
            showSnackbar('File size is too large. Max size is 2MB', 'error');
        }
        else {
            setFiles(updatedFiles);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/*',
        onDrop
    });

    const dropzoneStyle = {
        border: '2px dashed #cccccc',
        borderRadius: '8px',
        padding: '20px',
        transition: 'border 0.3s ease'
    };

    const activeDropzoneStyle = {
        border: '2px dashed #57a5f9'
    };

    const images = files.map((file, index) => (
        <Box key={index} sx={{ marginTop: 1, maxWidth: '100%' }}>
            <Paper variant="outlined" sx={{ overflow: 'hidden' }}>
                <img
                    src={file !== data.image ? file.preview : data.image}
                    alt={file !== data.image ? file.name : 'Feature Image'}
                    style={{ width: '100%', height: 'auto', display: 'block' }}
                />
            </Paper>
        </Box>
    ));

    return (
        <Dialog
            open={shown}
            onClose={onHide}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Add/Edit Screenshot</DialogTitle>
            <DialogContent dividers>
                <FormGroup>
                    <Box className="App">
                        <Box style={{ ...dropzoneStyle, ...(isDragActive ? activeDropzoneStyle : {}) }} {...getRootProps({ className: 'dropzone' })}>
                            <input className="input-zone" {...getInputProps()} onChange={handleChange} />
                            <Box style={{ textAlign: 'center' }}>
                                <p className="dropzone-content">
                                    {isDragActive
                                        ? 'Drop files here'
                                        : 'Drag\'n\'drop screenshot here, or click to select a image'}
                                </p>
                                <Button variant="outlined">Click to select image</Button>
                            </Box>
                        </Box>
                        <Box>{images}</Box>
                    </Box>
                </FormGroup>

            </DialogContent>

            <DialogActions>
                <Button onClick={onHide} variant="outlined">
                    Close
                </Button>
                {files.some(file => file !== data.image) && (
                    <Button
                        onClick={handleSubmit}
                        endIcon={onLoading ? <CircularProgress size={16} color="inherit" /> : null}
                    >
                        {onLoading ? 'Working' : 'Save Changes'}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ScreenshotModal;