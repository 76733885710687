import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
// Material UI
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

// Components
import buildUrl from '../../buildUrl';
import useApi from '../../hooks/useApi';
import useSnackbar from '../../hooks/useSnackbar';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import LoadingBox from '../accountDetails/components/LoadingBox';
import DownloadExportedFileDialog from './DownloadExportedFileDialog';
import ExportCampaignsDialog from './ExportCampaignsDialog';
import { colours } from '../../theme/constants';

const fields = [
    { property: 'id', type: 'number', label: 'ID', sortable: true },
    { property: 'customerId', type: 'number', label: 'CID', sortable: true },
    { property: 'scheduledDateTime', type: 'date', label: 'Send Time' },
    { property: 'subject', type: 'text', label: 'Subject' },
    { property: 'numberOfContacts', type: 'number', label: 'Contacts' },
    { property: 'fromEmail', type: 'text', label: 'From Email' },
    { property: 'emailServer', type: 'text', label: 'SMTP Server' }
];

const rowsPerPageOptions = [10, 20, 50];
const cellStyles = { whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2
    }
}));

const CampaignsTable = ({ cid = null, rowsOption = null, useLoadingBox = false, onChange }) => {
    const { handleGet, handlePut } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [campaigns, setCampaigns] = useState([]);
    const [showQueued, setShowQueued] = useState(false);
    const [customerId, setCustomerId] = useState(cid ?? '');
    const [search, setSearch] = useState(cid ?? '');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[rowsOption ?? 1]);
    const [orderBy, setOrderBy] = useState('scheduledDateTime');
    const [isDescending, setIsDescending] = useState(true);
    const [dialog, setDialog] = useState(null);
    const { showSnackbar } = useSnackbar();
    const [limit, setLimit] = useState(cid ? 30 : 7);
    const [openRows, setOpenRows] = useState([]);

    const handleChange = (event) => {
        const value = event.target.value;
        setLimit(value);

        if (onChange) {
            onChange(value); // Pass the selected value to the parent component
        }
    };

    const handleFetchCount = async () => {
        const url = buildUrl('superadmin/campaigns/count', { customerId, showQueued, limit });
        const response = await handleGet(url);
        const data = await response.json();
        setCount(data);
    };

    const handleFetchCampaigns = async () => {
        const url = buildUrl('superadmin/campaigns', {
            customerId,
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending,
            showQueued,
            limit
        });

        const response = await handleGet(url);
        const data = await response.json();

        setCampaigns(data);
        setIsLoading(false);
    };

    const handleSort = field => {
        if (orderBy === field.property) {
            setIsDescending(!isDescending);
        }
        else {
            // todo introduce field types
            setIsDescending(field.type === 'number' || field.type === 'date');
        }

        setOrderBy(field.property);
    };

    const handleExportCampaigns = async (fromDate, toDate) => {
        setDialog(null);
        setIsLoading(true);

        const url = buildUrl('superadmin/campaigns/export', {
            customerId,
            from: format(fromDate, 'yyyy-MM-dd'),
            to: format(toDate, 'yyyy-MM-dd')
        });

        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            showSnackbar('Campaign Export cannot be used at this time', 'error');
            return;
        }

        const data = await response.json();

        setIsLoading(false);

        setDialog(
            <DownloadExportedFileDialog
                downloadUrl={data.downloadPath}
                onClose={() => setDialog(null)}
            />
        );
    };

    const handleOpenExportDialog = () => {
        setDialog(
            <ExportCampaignsDialog
                customerId={customerId}
                onClose={() => setDialog(null)}
                onComplete={handleExportCampaigns}
            />
        );
    };

    useEffect(() => {
        handleFetchCount();
    }, [customerId, showQueued, limit]);

    useEffect(() => {
        setPage(0);
    }, [customerId, orderBy, isDescending, rowsPerPage, showQueued, limit]);

    useEffect(() => {
        setIsLoading(true);

        const fetchData = async () => {
            await handleFetchCampaigns();
            setIsLoading(false);
        };

        fetchData();
    }, [customerId, orderBy, isDescending, rowsPerPage, page, showQueued, limit]);

    useEffect(() => {
        let interval;

        if (!isLoading) {
            interval = setTimeout(() => {
                handleFetchCount();
                handleFetchCampaigns();
            }, 5000);
        }

        return () => interval && clearInterval(interval);
    }, [isLoading, showQueued, handleFetchCampaigns, handleFetchCount]);

    const handleToggleShowQueued = () => {
        setShowQueued(!showQueued);
    };

    const handleShowOrHideReport = async (campaignId, hidden) => {
        await handlePut(`superadmin/campaigns/${campaignId}`, { hidden });
        const newCampaigns = [...campaigns];
        const i = newCampaigns.findIndex(campaign => campaign.id === campaignId);
        newCampaigns[i].hidden = hidden;
        setCampaigns(newCampaigns);
    };

    const handleShowReport = campaignId => handleShowOrHideReport(campaignId, false);

    const handleHideReport = campaignId => handleShowOrHideReport(campaignId, true);

    const Row = ({ campaign }) => {

        const open = openRows.includes(campaign.id);

        const getRowBackgroundColor = (queued) => queued ? colours.queued : '';

        const handleRowToggle = () => {
            if (open) {
                setOpenRows(openRows.filter(id => id !== campaign.id));
            }
            else {
                setOpenRows([...openRows, campaign.id]);
            }
        };

        return (
            <>
                <TableRow sx={{ '& td': { borderBottom: 0, backgroundColor: getRowBackgroundColor(campaign.queued) } }}>
                    <TableCell>
                        {campaign.emails ? (
                            <IconButton aria-label="expand row" size="small" onClick={handleRowToggle}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        )
                            : <Box width={34} height={34}></Box>
                        }
                    </TableCell>
                    {fields.map(({ property, type, align }) => {
                        const value =
                            type === 'date'
                                ? campaign[property]
                                    ? format(new Date(campaign[property]), 'dd/MM/yyyy HH:mm')
                                    : '-'
                                : campaign[property] || '-';

                        return (
                            <TableCell key={property} align={align} {...(type !== 'text' && { sx: cellStyles })}>
                                {value}
                            </TableCell>
                        );
                    })}
                    <TableCell sx={cellStyles}>
                        {campaign.hidden ? (
                            <Link style={{ cursor: 'pointer' }} onClick={() => handleShowReport(campaign.id)}>
                                Show
                            </Link>
                        ) : (
                            <Link style={{ cursor: 'pointer' }} onClick={() => handleHideReport(campaign.id)}>
                                Hide
                            </Link>
                        )}
                    </TableCell>
                    <TableCell sx={cellStyles}>
                        <Link href={campaign.shareLink} target="_blank">
                            View
                        </Link>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="subtitle2" gutterBottom component="div">
                                    Proof Emails
                                </Typography>
                                <Table size="small" aria-label="proof">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>CID</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Phone</TableCell>
                                            <TableCell>DateTime Sent</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Result</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {campaign.emails &&
                                            campaign.emails.map(email => (
                                                <TableRow key={email.id}>
                                                    <TableCell>{email.id}</TableCell>
                                                    <TableCell>{email.customerId}</TableCell>
                                                    <TableCell>{email.firstName} {email.lastName}</TableCell>
                                                    <TableCell>{email.emailAddress}</TableCell>
                                                    <TableCell>{email.mobilePhone}</TableCell>
                                                    <TableCell>{format(new Date(email.dateTimeSent), 'dd/MM/yyyy HH:mm')}</TableCell>
                                                    <TableCell>{email.successfullySent ? 'Success' : 'Fail'}</TableCell>
                                                    <TableCell>{email.result}</TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    };

    if (isLoading) {
        return (
            useLoadingBox ? <LoadingBox /> : <LoadingOverlay />
        );
    }

    return (
        <>
            <Toolbar
                disableGutters
                sx={{ px: 2 }}
            >
                <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                    <form onSubmit={e => {
                        e.preventDefault();
                        setCustomerId(search);
                    }}>
                        <Stack alignItems="center" spacing={1} direction="row">
                            <TextField
                                size="small"
                                variant="outlined"
                                margin="none"
                                placeholder="CID"
                                autoComplete="off"
                                value={cid ?? search}
                                disabled={cid !== null}
                                onChange={e => setSearch(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                disableRipple
                                                edge="end"
                                                disabled={search === customerId}
                                                type="submit"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Select
                                value={limit}
                                onChange={handleChange}
                                variant="outlined"
                                size="small"
                            >
                                <MenuItem value="7">Last 7 Days</MenuItem>
                                <MenuItem value="14">Last 14 Days</MenuItem>
                                <MenuItem value="30">Last 30 Days</MenuItem>
                                <MenuItem value="60">Last 60 Days</MenuItem>
                                <MenuItem value="90">Last 90 Days</MenuItem>
                                <MenuItem value="0">All Time</MenuItem>
                            </Select>
                            <FormControlLabel
                                control={(
                                    <Android12Switch
                                        checked={showQueued}
                                        onChange={handleToggleShowQueued}
                                    />
                                )}
                                label="Queued Campaigns"
                            />
                        </Stack>
                    </form>
                </Box>
                <Button onClick={handleOpenExportDialog} disabled={!Boolean(customerId)}>Export</Button>
            </Toolbar>
            <Divider />
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                component="th"
                                sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                            >
                            </TableCell>
                            {fields.map(field => (
                                <TableCell
                                    key={field.property}
                                    align={field.align}
                                    component="th"
                                    sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                                >
                                    {field.sortable ? (
                                        <TableSortLabel
                                            active={orderBy === field.property}
                                            direction={isDescending ? 'desc' : 'asc'}
                                            onClick={() => handleSort(field)}
                                        >
                                            {field.label}
                                        </TableSortLabel>
                                    ) : field.label}
                                </TableCell>
                            ))}
                            <TableCell component="th" sx={cellStyles}>Reports</TableCell>
                            <TableCell component="th" sx={cellStyles}>View</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {campaigns.map((campaign) => (
                            <Row key={campaign.id} campaign={campaign} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
            />
            {dialog}
        </>
    );
};

export default CampaignsTable;