import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import Button from '@mui/material/Button';
import useApi from '../../hooks/useApi';
import AlertBar from '../../alertBar/AlertBar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import useSnackbar from '../../hooks/useSnackbar';
import Paper from '@mui/material/Paper';

const NotificationSettings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [account, setAccount] = useState();
    const [emailAddress, setEmailAddress] = useState('');
    const [lowQuotaNotification, setLowQuotaNotification] = useState(false);
    const [lowQuotaLevel, setLowQuotaLevel] = useState(0);
    const [doubleOptIn, setDoubleOptIn] = useState(false);
    const { handleGet, handlePut } = useApi();
    const { showSnackbar } = useSnackbar();

    const handleFetchAccount = async () => {
        const response = await handleGet('account');

        if (!response.ok) {
            setIsLoading(false);
            setIsSubmitting(false);
            return;
        }

        const data = await response.json();

        setAccount(data);

        setEmailAddress(data.emailAddress);
        setLowQuotaNotification(data.lowQuotaNotification);
        setLowQuotaLevel(data.lowQuotaLevel);
        setDoubleOptIn(data.doubleOptIn);

        setIsLoading(false);
        setIsSubmitting(false);
    };

    const onSubmit = async e => {
        e.preventDefault();

        setIsSubmitting(true);

        const response = await handlePut('account', {
            emailAddress,
            lowQuotaNotification,
            lowQuotaLevel,
            doubleOptIn
        });

        if (response.ok) {
            await handleFetchAccount();
        }
        else {
            showSnackbar('Error updating settings', 'error');
        }
    };

    useEffect(() => {
        handleFetchAccount();
    }, []);

    useEffect(() => {
        setHasUnsavedChanges(emailAddress !== account?.emailAddress
            || lowQuotaNotification !== account?.lowQuotaNotification
            || lowQuotaLevel !== account?.lowQuotaLevel
            || doubleOptIn !== account?.doubleOptIn);
    }, [account, emailAddress, lowQuotaNotification, lowQuotaLevel, doubleOptIn]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AlertBar
                shown={isLoading || hasUnsavedChanges}
                action={(
                    <Button
                        variant="outlined"
                        size="small"
                        disabled={isLoading}
                        onClick={onSubmit}
                    >
                        Save Changes
                    </Button>
                )}
                positionTop={128}
                mb={2}
            >
                {isLoading ? 'Updating...' : 'You have unsaved changes.'}
            </AlertBar>
            <Stack spacing={2}>
                <Paper sx={{ p: 3 }}>
                    <Typography component="h2" variant="h6" gutterBottom>Notifications</Typography>
                    <TextField
                        value={emailAddress}
                        onChange={e => setEmailAddress(e.target.value)}
                        type="email"
                        label="Send system notifications to/from"
                        fullWidth={true}
                    />
                    <Typography component="h2" variant="h6" gutterBottom>Send</Typography>
                    <FormControlLabel
                        control={(
                            <Switch
                                checked={lowQuotaNotification}
                                onChange={e => setLowQuotaNotification(e.target.checked)}
                                name="lowquota"
                            />
                        )}
                        label="Low quota notification"
                    />
                    <TextField value={lowQuotaLevel} onChange={e => setLowQuotaLevel(e.target.value)} disabled={!lowQuotaNotification} type="number" label="Low quota level" fullWidth={true} />
                </Paper>
            </Stack>

            {(isSubmitting) && (
                <LoadingOverlay />
            )}
        </>
    );
};

export default NotificationSettings;