import React from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import NavigationItem from '../contacts/NavigationItem';
import PseudoGroup from '../contacts/PseudoGroup';
import GroupsIcon from '@mui/icons-material/Groups';

const GroupSelector = ({
    groups,
    selectedGroups,
    setSelectedGroups,
    onFilterByTag = null,
    multipleSelection = true,
    includeAllContacts = false,
    isFiltered = false,
    allContactsCount = 0
}) => {

    const handleFilterByTag = tagId => {
        onFilterByTag && onFilterByTag(tagId);
    };

    const handleToggleGroup = id => {
        const newGroups = selectedGroups.includes(id) ? selectedGroups.filter(groupId => groupId !== id) : selectedGroups.concat(id);
        setSelectedGroups(newGroups);
    };

    const handleSelectItem = id => {
        if (multipleSelection) {
            handleToggleGroup(id);
        }
        else {
            setSelectedGroups(id !== null ? [id] : null);
        }
    };

    return (
        <List component="nav" aria-label="Select Groups" disablePadding>
            <Collapse in={!multipleSelection && includeAllContacts && !isFiltered}>
                <PseudoGroup
                    text="All Contacts"
                    count={allContactsCount}
                    icon={<GroupsIcon fontSize="small" aria-label="All Contacts" />}
                    setSelectedItem={() => handleSelectItem(null)}
                    selectedItem={selectedGroups === null}
                    lastItem={true}
                />
            </Collapse>
            {(groups && groups.length > 0) ? groups.map(group => {
                const selected = selectedGroups === group.id || (Array.isArray(selectedGroups) && selectedGroups.includes(group.id)); //it should always be array?

                return (
                    <NavigationItem
                        key={group.id}
                        item={group}
                        type="group"
                        selected={selected}
                        onSetSelectedItem={() => handleSelectItem(group.id)}
                        {...(multipleSelection && { onSelect: () => handleToggleGroup(group.id) })}
                        onFilterByTag={handleFilterByTag}
                    />
                );
            }) : (
                <Typography
                    variant="body2"
                    sx={{ textAlign: 'center' }}
                >
                No groups found
                </Typography>
            )}
        </List>
    );
};

export default GroupSelector;