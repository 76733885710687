import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { Alert } from '@mui/material';

const CancelAccountModal = ({ account, shown, onHide, onConfirm }) => {
    return (
        <Dialog
            open={shown}
            onClose={onHide}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>{account.expired ? 'Close account' : 'Cancel account'}</DialogTitle>
            <DialogContent dividers>
                <Alert severity="error" style={{ marginBottom: '16px' }}>
                    You are about to {account.expired ? 'close' : 'cancel'} account <strong>{account.company}</strong> (<strong>CID {account.id}</strong>). {account.expired
                        ? 'If you continue this account will be closed permenantly.'
                        : 'If you continue, this account\'s licence will not be renewed and the account will be closed permenantly once the current licence has expired.'}
                </Alert>
                <DialogContentText>
                    Are you sure you want to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onHide} variant="outlined">Back to safety</Button>
                <Button onClick={onConfirm} color="error">{account.expired ? 'Close account' : 'Cancel account'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CancelAccountModal;