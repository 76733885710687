import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { format } from 'date-fns';
import useApi from '../hooks/useApi';
import useNotifications from '../hooks/useNotifications';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ImportReportDialog from './ImportReportDialog';
import useTheme from '@mui/material/styles/useTheme';
import { add } from 'date-fns';

const drawerWidth = 500;

const Announcements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { handleGet } = useApi();

    const handleFetchAnnouncements = async () => {
        const response = await handleGet('announcements');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        setAnnouncements(data);
        setIsLoading(false);
    };

    useEffect(() => {
        handleFetchAnnouncements();
    }, []);

    if (isLoading) {
        return (
            <Box sx={{ textAlign: 'center', p: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    return announcements.map(announcement => (
        <Card
            key={announcement.id}
            sx={{ m: 1 }}
        >
            <CardContent sx={{ pb: 0 }}>
                <Typography
                    //component="div"
                    variant="body2"
                    color="text.secondary"
                //sx={{ mb: 1 }}
                >
                    {format((new Date(announcement.dateTime)), 'd MMMM yyyy')}
                </Typography>

                <Typography
                    variant="h6"
                    component="div"
                    sx={{ mb: 1 }}
                >
                    {announcement.subject}
                </Typography>

                <Typography
                    component="div"
                    variant="body2"
                >
                    {announcement.body}
                </Typography>
            </CardContent>
            {announcement.linkUrl && (
                <CardActions>
                    <Button variant="text" href={announcement.linkUrl} target="_blank">
                        {announcement.linkText || announcement.linkUrl}
                    </Button>
                </CardActions>
            )}
        </Card>
    ));
};

const Notification = ({ isRead, dateTime, subject, message, actions, onRead }) => {
    return (
        <Card sx={{ m: 1 }}>
            <CardHeader
                avatar={isRead ? <NotificationsIcon color="disabled" /> : <NotificationsActiveIcon color="primary" />}
                action={!isRead && (
                    <IconButton onClick={onRead}>
                        <DoneIcon />
                    </IconButton>
                )}
                title={subject}
                titleTypographyProps={{
                    ...(!isRead && { sx: { fontWeight: 'bold' } })
                }}
                subheader={format((new Date(dateTime)), 'd MMMM yyyy HH:mm')}
            />
            {message && (
                <CardContent sx={{ pb: 0, pt: 0 }}>
                    <Typography
                        component="div"
                        variant="body2"
                    >
                        {message}
                    </Typography>
                </CardContent>
            )}
            {actions}
        </Card>
    );
};

const Notifications = ({ onViewImportReport }) => {
    const { notifications, handleMarkAsRead } = useNotifications();

    if (notifications.length === 0) {
        return (
            <Box sx={{ textAlign: 'center', p: 4 }}>
                No Notifications
            </Box>
        );
    }

    // unread/all button

    return notifications.map(notification => {
        const { id, type, isRead, dateTime, subject, body, linkUrl, linkText } = notification;
        let json, message, actions;

        try {
            json = JSON.parse(body);
            message = json?.message;
        }
        catch (e) {
            // body is probably text or null
            message = body;
        }

        if (type === 'import' && json) {
            const importData = json;

            actions = (
                <CardActions>
                    <Button variant="text" onClick={() => onViewImportReport(importData)}>
                        View Report
                    </Button>
                </CardActions>
            );
        }
        else if (type === 'export' && json) {
            const exportData = json;
            const today = new Date();
            const exportExpiryDateTime = add(new Date(dateTime), { hours: 167 });
            const isExpired = exportExpiryDateTime < today;

            if (!isExpired) {
                actions = (
                    <CardActions>
                        <Button variant="text" href={exportData.url}>
                            Download
                        </Button>
                    </CardActions>
                );
            }
        }
        else if (linkUrl) {
            actions = (
                <CardActions>
                    <Button variant="text" href={linkUrl}>
                        {linkText || linkUrl}
                    </Button>
                </CardActions>
            );
        }

        return (
            <Notification
                key={id}
                id={id}
                isRead={isRead}
                dateTime={dateTime}
                subject={subject}
                message={message}
                onRead={() => handleMarkAsRead(notification)}
                actions={actions}
            />
        );
    });
};

const NotificationsPane = ({ isOpen, onClose }) => {
    const [tab, setTab] = useState('announcements');
    const { notifications } = useNotifications();
    const [importData, setImportData] = useState(null);
    const [showImportReportDialog, setShowImportReportDialog] = useState(false);
    const theme = useTheme();

    const handleShowImportReport = importData => {
        setImportData(importData);
        setShowImportReportDialog(true);
    };

    const handleHideImportReport = () => {
        setShowImportReportDialog(false);
        setImportData(null);
    };

    useEffect(() => {
        if (isOpen) {
            setTab(notifications.filter(n => !n.isRead).length > 0 ? 'notifications' : 'announcements');
        }
    }, [isOpen]);

    return (
        <>
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={onClose}
                variant="temporary"
                BackdropProps={{ invisible: true }}
                ModalProps={{ keepMounted: true }}
                sx={{ zIndex: theme.zIndex.drawer + 1 }}
            >
                <Toolbar />
                <Box sx={{
                    overflow: 'auto',
                    backgroundColor: '#eee',
                    width: drawerWidth,
                    flex: 1
                }}>
                    <AppBar
                        position="sticky"
                        color="inherit"
                    >
                        <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
                            <Tab value="announcements" label="Announcements" />
                            <Tab value="notifications" label="Notifications" />
                        </Tabs>
                    </AppBar>
                    <Box sx={{ display: tab === 'announcements' ? 'block' : 'none' }}>
                        <Announcements />
                    </Box>
                    <Box sx={{ display: tab === 'notifications' ? 'block' : 'none' }}>
                        <Notifications
                            onViewImportReport={handleShowImportReport}
                        />
                    </Box>
                </Box>
            </Drawer>
            {showImportReportDialog && (
                <ImportReportDialog
                    id={importData.id}
                    onClose={handleHideImportReport}
                />
            )}
        </>
    );
};

export default NotificationsPane;