import React, { useEffect, useState } from 'react';
import { eachDayOfInterval, format, startOfMonth } from 'date-fns';
// Material UI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';

// Components
import buildUrl from '../../../buildUrl';
import useApi from '../../../hooks/useApi';
import BarChart from '../charts/BarChart';
import TextItem from './TextItem';
import DatePicker from '../../../DatePicker';

const LoadingPlaceholder = () => {
    return (
        <Paper
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: 300
            }}
        >
            <CircularProgress />
        </Paper>
    );
};

const calculateMTD = (selectedDate) => {
    const date = selectedDate ? new Date(selectedDate) : new Date();
    return eachDayOfInterval({
        start: new Date(startOfMonth(date)),
        end: date
    }).length;
};

const ComparisonForm = ({ account, urlString }) => {
    const { handleGet } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedOffset, setSelectedOffset] = useState(0);
    const [lastPeriodData, setLastPeriodData] = useState(0);
    const [thisPeriodData, setThisPeriodData] = useState(0);
    const [percentChange, setPercentChange] = useState(0);

    const offsets = [
        { id: 0, name: 'Month to date', value: calculateMTD(selectedDate) },
        { id: 1, name: 'Last 7 days', value: 7 },
        { id: 2, name: 'Last 14 days', value: 14 },
        { id: 3, name: 'Last 30 days', value: 30 },
        { id: 4, name: 'Last 60 days', value: 60 },
        { id: 5, name: 'Last 90 days', value: 90 }
    ];

    const getDataCount = async (startDate, endDate) => {
        const url = buildUrl(urlString, {
            customerId: account.id,
            ...({ startDate: format(startDate, 'yyyy-MM-dd') }),
            ...({ endDate: format(endDate, 'yyyy-MM-dd') })
        });

        const response = await handleGet(url);

        if (response) {
            const data = await response.json();
            return data;
        }
    };

    const setGraphsData = async () => {
        setIsLoading(true);
        const dateA = selectedDate ? new Date(selectedDate) : new Date();
        const dateB = selectedDate ? new Date(selectedDate) : new Date();
        const dateC = selectedDate ? new Date(selectedDate) : new Date();

        const offset = offsets.find(o => o.id === selectedOffset);

        dateB.setDate(dateB.getDate() - offset.value);
        dateC.setDate(dateC.getDate() - offset.value * 2);

        setLastPeriodData(await getDataCount(dateC, dateB));
        setThisPeriodData(await getDataCount(dateB, dateA));
        setIsLoading(false);
    };

    const calculatePercentChange = () => {
        const percentChange = (((thisPeriodData - lastPeriodData) / lastPeriodData) * 100);
        setPercentChange(percentChange ? isFinite(percentChange) ? percentChange.toFixed(1) : 100 : 0);
    };

    useEffect(() => {
        setGraphsData();
    }, [selectedDate, selectedOffset]);

    useEffect(() => {
        calculatePercentChange();
    }, [thisPeriodData, lastPeriodData]);

    return (
        <>
            <Toolbar
                disableGutters
                sx={{ px: 2 }}
            >
                <Box style={{ flexGrow: 1 }}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                            <Select
                                value={selectedOffset}
                                onChange={e => setSelectedOffset(e.target.value)}
                                size="small"
                                displayEmpty
                            >
                                {offsets.map(({ id, name }) => (
                                    <MenuItem key={id} value={id}>{name}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item>
                            <DatePicker
                                value={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                label="Selected Date" />
                        </Grid>
                    </Grid>
                </Box>
            </Toolbar>
            <Divider />
            <Grid container spacing={2} justifyContent="center" sx={{ p: 2 }}>
                <Grid item xs={3}>
                    <List>
                        <TextItem primary="This Period" secondary={thisPeriodData} />
                        <TextItem primary="Last Period" secondary={lastPeriodData} />
                        {percentChange !== 0 && <TextItem primary="% Change" secondary={`${percentChange}%`} />}
                    </List>
                </Grid>
                <Grid item xs={9} width="100%">
                    {isLoading ? (
                        <LoadingPlaceholder />
                    ) : (
                        <BarChart lastPeriod={lastPeriodData} thisPeriod={thisPeriodData} />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default ComparisonForm;
