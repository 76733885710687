import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const DeleteGroupDialog = ({ onClose, group, onSubmit }) => {

    const handleConfirm = e => {
        e.preventDefault();
        onSubmit(group);
        onClose();
    };

    //add loading state and await confirm?

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Delete Group '{group.name}'</DialogTitle>
            <DialogContent>
                <Alert severity="warning" sx={{ mb: 2 }}>
                    Are you sure you want to delete this group from your Account?
                </Alert>
                <DialogContentText>
                    Deleting a Group does not delete any Contacts. They will remain in your account.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>Confirm Delete</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteGroupDialog;