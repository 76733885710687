import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import EmailAuthentication from '../emailAuthentication/EmailAuthentication';
import useAccount from '../../hooks/useAccount';
import SmtpSettings from '../smtpSettings/SmtpSettings';
import AppBar from '@mui/material/AppBar';
import useTheme from '@mui/material/styles/useTheme';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import useApi from '../../hooks/useApi';
import ReactECharts from 'echarts-for-react';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';

const CreateKeyDialog = ({ open, onSubmit, onClose }) => {
    const [name, setName] = useState('');

    useEffect(() => {
        !open && setName('');
    }, [open]);

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Add API Key</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Create a new key to access NewZapp's API.
                </DialogContentText>
                <TextField
                    autoFocus
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={name}
                    required
                    onChange={e => setName(e.target.value)}
                    margin="dense"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onSubmit(name)}>Add Key</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const RevokeKeyDialog = ({ keyName, open, onConfirm, onClose }) => {
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Revoke API Key</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to revoke the key {keyName}?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm}>Revoke Key</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const KeysTab = ({ hasManageAPIPermissions }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [keys, setKeys] = useState([]);
    const [isCreateKeyDialogOpen, setIsCreateKeyDialogOpen] = useState(false);
    const [isRevokeKeyDialogOpen, setIsRevokeKeyDialogOpen] = useState(false);
    const [keyToRevoke, setKeyToRevoke] = useState(null);
    const { handleGet, handlePut, handlePost } = useApi();

    const handleLoadKeys = async () => {
        const response = await handleGet('account/api-keys');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        setKeys(data);
        setIsLoading(false);
    };

    const handleCreateKey = async name => {
        setIsCreateKeyDialogOpen(false);

        await handlePost('account/api-keys', { name });

        handleLoadKeys();
    };

    const handleShowRevokeKeyDialog = key => {
        setKeyToRevoke(key);
        setIsRevokeKeyDialogOpen(true);
    };

    const handleHideRevokeKeyDialog = () => {
        setIsRevokeKeyDialogOpen(false);
        setKeyToRevoke(null);
    };

    const handleRevokeKey = async () => {
        const id = keyToRevoke.id;

        handleHideRevokeKeyDialog();

        await handlePut(`account/api-keys/${id}`, { enabled: false });

        handleLoadKeys();
    };

    useEffect(() => {
        if (hasManageAPIPermissions) {
            handleLoadKeys();
        }
    }, []);

    if (!hasManageAPIPermissions) {
        return (
            <Paper sx={{ padding: 6, textAlign: 'center' }}>
                <Typography>You don't have permission to manage API keys.</Typography>
                <Typography>Please contact your account administrator to have this enabled.</Typography>
            </Paper>
        );
    }

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <Box>
            <Typography>The NewZapp API makes it easy for programmers to integrate many of NewZapp's features into other applications. API Keys provide full access to your NewZapp account, so keep them secure.</Typography>

            <Paper sx={{ mt: 2 }}>
                <Toolbar variant="dense" disableGutters sx={{ display: 'flex', px: 2 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle2">
                            API Keys
                        </Typography>
                    </Box>
                    <Button
                        size="small"
                        onClick={() => setIsCreateKeyDialogOpen(true)}
                    >
                        Create API Key
                    </Button>
                </Toolbar>
                <Divider />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row">Name</TableCell>
                                <TableCell component="th" scope="row">Created Date/Time</TableCell>
                                <TableCell component="th" scope="row">Key</TableCell>
                                <TableCell component="th" scope="row" align="right">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {keys.map(key => (
                                <TableRow key={key.id}>
                                    <TableCell>{key.name}</TableCell>
                                    <TableCell>{key.createdDateTime ? new Date(key.createdDateTime).toLocaleString('en-GB') : '-'}</TableCell>
                                    <TableCell>{key.key}</TableCell>
                                    <TableCell align="right">{key.enabled ? <Link onClick={() => handleShowRevokeKeyDialog(key)}>Revoke</Link> : 'Revoked'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <CreateKeyDialog open={isCreateKeyDialogOpen} onClose={() => setIsCreateKeyDialogOpen(false)} onSubmit={handleCreateKey} />
            {keyToRevoke && <RevokeKeyDialog keyName={keyToRevoke.name} open={isRevokeKeyDialogOpen} onClose={handleHideRevokeKeyDialog} onConfirm={handleRevokeKey} />}
        </Box>
    );
};

const UsageChart = () => {
    const [usage, setUsage] = useState([]);
    const { handleGet } = useApi();
    const [options, setOptions] = useState(null);

    const handleBuildChart = () => {
        const chartOptions = {
            tooltip: {
                trigger: 'item'
            },
            xAxis: {
                type: 'category',
                data: usage.map(u => u.label)
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: usage.map(u => u.total),
                    type: 'line'
                }
            ]
        };

        setOptions(chartOptions);
    };

    const handleLoadUsage = async () => {
        try {
            const response = await handleGet('account/api-requests/by-day');
            const data = await response.json();
            setUsage(data);
        }
        catch (error) {
            console.error('Failed to load usage data:', error);
        }
    };

    useEffect(() => {
        handleLoadUsage();
    }, []);

    useEffect(() => {
        if (usage.length > 0) {
            handleBuildChart();
        }
    }, [usage]);

    return (
        usage.length > 0 && options && (
            <Paper sx={{ padding: 1, marginBottom: 2 }}>
                <ReactECharts
                    option={options}
                    style={{ height: 350 }}
                    opts={{ renderer: 'svg' }}
                />
            </Paper>
        )
    );
};

const RequestsTable = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [requests, setRequests] = useState();
    const { handleGet } = useApi();

    const handleLoadRequests = async () => {
        const response = await handleGet('account/api-requests');
        const data = await response.json();
        setRequests(data);
        setIsLoading(false);
    };

    useEffect(() => {
        handleLoadRequests();
    }, []);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell component="th" scope="row">Start Date/Time</TableCell>
                        <TableCell component="th" scope="row">IP Address</TableCell>
                        <TableCell component="th" scope="row">Resource</TableCell>
                        <TableCell component="th" scope="row" align="right">Duration</TableCell>
                        <TableCell component="th" scope="row" align="right">Response</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {requests.map(request => (
                        <TableRow key={request.id}>
                            <TableCell>{new Date(request.dateTime).toLocaleString('en-GB')}</TableCell>
                            <TableCell>{request.ipAddress}</TableCell>
                            <TableCell>{request.resource}</TableCell>
                            <TableCell align="right">{request.duration}</TableCell>
                            <TableCell align="right">{request.responseCode}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const HistoryTab = ({ hasManageAPIPermissions }) => {

    if (!hasManageAPIPermissions) {
        return (
            <Paper sx={{ padding: 6, textAlign: 'center' }}>
                <Typography>You don't have permission to view the API Call History.</Typography>
                <Typography>Please contact your account administrator to have this enabled.</Typography>
            </Paper>
        );
    }

    return (
        <Box>
            <UsageChart />
            <RequestsTable />
        </Box>
    );
};

const CompanySettings = () => {
    const { hasCustomSmtpConfiguration } = useAccount();
    const [value, setValue] = useState('emailAuthentication');
    const theme = useTheme();
    const { user } = useAccount();

    const hasManageAPIPermissions = user.permissions.includes('Manage API Keys');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64, zIndex: theme.zIndex.appBar + 1 }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">
                        Configuration
                    </Typography>
                </Toolbar>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Email Authentication" value="emailAuthentication" />
                    {hasCustomSmtpConfiguration && <Tab label="Sending Options" value="smtpConfiguration" />}
                    <Tab label="API Keys" value="apiKeys" />
                    <Tab label="API Call History" value="apiCallHistory" />
                </Tabs>
            </AppBar>
            <Box>
                <TabContext value={value}>
                    <TabPanel value="emailAuthentication"><EmailAuthentication /></TabPanel>
                    {hasCustomSmtpConfiguration && <TabPanel value="smtpConfiguration"><SmtpSettings /></TabPanel>}
                    <TabPanel value="apiKeys"><KeysTab hasManageAPIPermissions={hasManageAPIPermissions} /></TabPanel>
                    <TabPanel value="apiCallHistory"><HistoryTab hasManageAPIPermissions={hasManageAPIPermissions} /></TabPanel>
                </TabContext>
            </Box>
        </>
    );
};

export default CompanySettings;