import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { Alert } from '@mui/material';

const DeleteFeatureModal = ({ data, shown, onHide, onSubmit }) => {
    return (
        <Dialog
            open={shown}
            onClose={onHide}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Delete Feature</DialogTitle>
            <DialogContent dividers>
                <Alert severity="error" style={{ marginBottom: '16px' }}>
                    You are about to permanently delete <strong>{data.name}</strong> feature.
                </Alert>
                <DialogContentText>
                    Are you sure you want to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onHide} variant="outlined">Back to safety</Button>
                <Button onClick={() => onSubmit(data)} color="error">Delete Feature</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteFeatureModal;