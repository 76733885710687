import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import StripoEditor from '../stripoEditor/StripoEditor';
import PublishEmailDialog from './shared/dialogs/PublishEmailDialog';
import useSnackbar from '../hooks/useSnackbar';
import { useHistory } from 'react-router-dom';
import Proof from '../editor/views/Proof';
import Setup from '../editor/views/Setup';
import useApi from '../hooks/useApi';
import useTheme from '@mui/material/styles/useTheme';

const drawerWidth = 300;

const emailSteps = [
    { name: 'Editor', description: 'Create your content' },
    { name: 'Setup', description: 'Set your sending details and choose topics' },
    { name: 'Proof', description: 'Send a copy to trusted recipients (optional)' }
];

const pageSteps = [
    { name: 'Editor', description: 'Create your content' },
    { name: 'Setup', description: 'Set page title' }
];

const EditTemplate = () => {
    const { templateId } = useParams();
    const [step, setStep] = useState(0);
    const [template, setTemplate] = useState();
    const [html, setHtml] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [showPublishTemplateDialog, setShowPublishTemplateDialog] = useState(false);
    const [proofRecipients, setProofRecipients] = useState([]);
    const [campaign, setCampaign] = useState(null);
    const { showSnackbar } = useSnackbar();
    const history = useHistory();
    const { handleGet, handlePut, handlePost } = useApi();

    const [isProofSent, setIsProofSent] = useState(false);
    const theme = useTheme();

    const titleBarDepth = theme.zIndex.drawer + 1;

    const contentType = template?.type;
    const type = contentType === 'page' ? 'pageTemplate' : 'template';
    const steps = contentType === 'page' ? pageSteps : emailSteps;

    // load template

    const handleLoadTemplate = async () => {
        const response = await handleGet(`super-admin/templates/${templateId}`);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const data = await response.json();
        setTemplate(data);
    };

    // update template

    const handleUpdateTemplate = async () => {
        const response = await handlePut(`super-admin/templates/${template.id}`, template);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        showSnackbar('Changes saved', 'success');
    };

    // publish template

    const handlePublishTemplate = async () => {
        setShowPublishTemplateDialog(false);
        setIsLoading(true);

        const response = await handlePut(`super-admin/templates/${template.id}`, { hidden: false });

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        handlePost(`super-admin/templates/${template.id}/thumbnail`, { html });

        history.push('/superadmin/templates');
    };

    // side effects

    useEffect(() => {
        (async () => {
            await handleLoadTemplate();
            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        handleUpdateTemplate();
    }, [template]);

    const handleCompleteEdit = html => {
        setHtml(html);
        contentType === 'page' ? setShowPublishTemplateDialog(true) : setStep(1);
    };

    const handleCompleteSetup = async newCampaign => {
        setCampaign({ ...campaign, ...newCampaign });
        setStep(2);
    };

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            {step === 0 && (
                <StripoEditor
                    email={template}
                    type={type}
                    onUpdateEmail={props => setTemplate({ ...template, ...props })}
                    onNext={handleCompleteEdit}
                    title={`${template.customerId}: ${template.name}`}
                    showAlert={true}
                />
            )}
            {step === 1 && (
                <Setup
                    email={template}
                    onUpdateEmail={props => setTemplate({ ...template, ...props })}
                    onNext={handleCompleteSetup}
                    onBack={() => setStep(0)}
                    onFetchTopics={() => null}
                    isTemplate={true}
                    drawerWidth={drawerWidth}
                    steps={steps}
                    step={step}
                    completed={campaign}
                    isProofSent={isProofSent}
                    audienceName={null}
                    titleBarDepth={titleBarDepth}
                    type={contentType}
                />
            )}
            {step === 2 && (
                <Proof
                    email={template}
                    html={html}
                    onNext={() => setShowPublishTemplateDialog(true)}
                    onBack={() => setStep(1)}
                    onNavigateToEditor={() => setStep(0)}
                    onExit={() => history.push('/superadmin/templates')}
                    emailAddresses={proofRecipients}
                    setEmailAddresses={setProofRecipients}
                    type={type}
                    drawerWidth={drawerWidth}
                    steps={steps}
                    step={step}
                    campaign={campaign}
                    isProofSent={isProofSent}
                    setIsProofSent={setIsProofSent}
                    audienceName={null}
                    titleBarDepth={titleBarDepth}
                />
            )}
            {showPublishTemplateDialog && (
                <PublishEmailDialog
                    email={template}
                    emailText="Template"
                    onClose={() => setShowPublishTemplateDialog(false)}
                    onComplete={handlePublishTemplate}
                />
            )}
        </>
    );
};

export default EditTemplate;