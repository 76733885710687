import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import Popover from '@mui/material/Popover';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IconButton, Stack } from '@mui/material';

const QRCodeImage = ({ qrCode, size = null }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const downloadQRCode = (e, filetype) => {
        e.stopPropagation();

        let dataUrl;

        if (filetype === 'svg') {
            const svg = document.getElementById(`qr-code-${qrCode.id}-svg`);
            const serializer = new XMLSerializer();
            const svgString = serializer.serializeToString(svg);
            const blob = new Blob([svgString], { type: 'image/svg+xml' });
            dataUrl = URL.createObjectURL(blob);
        }
        else {
            const canvas = document.getElementById(`qr-code-${qrCode.id}-png`);
            dataUrl = canvas.toDataURL(`image/${filetype}`);
        }

        const downloadLink = document.createElement('a');
        downloadLink.href = dataUrl;
        downloadLink.download = `${qrCode.name}.${filetype}`;
        downloadLink.click();
    };

    const handleOpen = e => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = e => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'QR Code' : undefined;

    return (
        <>
            <IconButton
                onClick={handleOpen}
                size="small"
                sx={{
                    borderRadius: 0,
                    p: size === null ? 0 : undefined
                }}
            >
                <Avatar
                    aria-label={qrCode.name}
                    variant="square"
                    sx={size ? { width: size, height: size, backgroundColor: 'transparent' } : undefined}
                >
                    <QRCodeSVG
                        id={`qr-code-${qrCode.id}-svg`}
                        value={qrCode.url}
                        size={320}
                        level="H"
                        includeMargin={true}
                    />
                    <Box display="none">
                        <QRCodeCanvas
                            id={`qr-code-${qrCode.id}-png`}
                            value={qrCode.url}
                            size={320}
                            level="H"
                            includeMargin={true}
                        />
                    </Box>
                </Avatar>
            </IconButton>
            <Backdrop
                open={open}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <QRCodeSVG
                        id={`qr-code-${qrCode.id}-svg-large`}
                        value={qrCode.url}
                        size={320}
                        level="H"
                        includeMargin={true}
                    />
                    <Stack justifyContent="center" direction="row" spacing={2} sx={{ pb: 2 }}>
                        <Button onClick={e => downloadQRCode(e, 'png')}>Download PNG</Button>
                        <Button onClick={e => downloadQRCode(e, 'svg')}>Download SVG</Button>
                    </Stack>
                </Popover>
            </Backdrop>
        </>
    );
};

export default QRCodeImage;