/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import useApi from '../hooks/useApi';
import useAccount from '../hooks/useAccount';
import SplashScreen from '../splashScreen/SplashScreen';

const AgreeToTermsDialog = () => {
    const [html, setHtml] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [hasRead, setHasRead] = useState(false);
    const { handlePut } = useApi();
    const { handleRefresh } = useAccount();

    const handleFetchTermsAndConditions = async () => {
        const response = await fetch('terms-and-conditions');

        if (!response.ok) {
            setIsLoading(false);
            console.error(response?.error);
            return;
        }

        const text = await response.text();

        const htmlDocument = `<!DOCTYPE html><html><head><link rel="stylesheet" href="/css/terms-and-conditions.css"></head><body>${text}</body></html>`;

        setHtml(htmlDocument);
        setIsLoading(false);
    };

    const handleScroll = e => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;

        if (Math.ceil(scrollTop) + clientHeight >= scrollHeight) {
            setHasRead(true);
        }
    };

    const handleAgreeToTerms = async () => {
        setIsLoading(true);

        const response = await handlePut('user', { hasAgreed: true });

        if (!response.ok) {
            // handle error
            return;
        }

        handleRefresh();
    };

    useEffect(() => {
        handleFetchTermsAndConditions();
    }, []);

    if (isLoading) {
        return <SplashScreen />;
    }

    return (
        <Dialog open={true} fullScreen>
            <DialogContent onScroll={handleScroll}>
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleAgreeToTerms} disabled={!hasRead}>Accept &amp; Continue</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AgreeToTermsDialog;