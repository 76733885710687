import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

const CreateAutomationEmailDialog = ({ onClose, onSubmit, isLoading }) => {
    const [isValid, setIsValid] = useState(false);
    const [unit, setUnit] = useState('hour');
    const [name, setName] = useState('');
    const [delay, setDelay] = useState(0);

    const handleConfirm = async e => {
        e.preventDefault();

        await onSubmit({
            name,
            delay: delay === 0 ? 0 : unit === 'hour' ? delay : delay * 24
        });

        onClose();
    };

    const plural = delay !== 1 ? 's' : '';

    useEffect(() => {
        setIsValid(name.trim() !== '');
    }, [name]);

    return (
        <Dialog onClose={onClose} open={true}>
            <form onSubmit={handleConfirm}>
                <DialogTitle>Create Email</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="name"
                        label="Name"
                        fullWidth
                        value={name}
                        required
                        onChange={e => setName(e.target.value)}
                        size="small"
                    />
                    <TextField
                        id="delay"
                        label="Delay"
                        value={delay}
                        type="number"
                        onChange={e => setDelay(e.target.value)}
                        size="small"
                    />
                    <FormControl size="small" sx={{ marginLeft: 1 }}>
                        <InputLabel id="unit-label">Unit</InputLabel>
                        <Select
                            value={unit}
                            onChange={e => setUnit(e.target.value)}
                            labelId="unit-label"
                            label="Unit"
                        >
                            <MenuItem value="hour">Hour{plural}</MenuItem>
                            <MenuItem value="day">Day{plural}</MenuItem>
                        </Select>
                    </FormControl>
                    <Typography sx={{ marginTop: 1 }}>
                        This email will be sent to contacts {delay} {unit}{plural} after any selected trigger.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={isLoading || !isValid}>Create Email</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreateAutomationEmailDialog;