import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const CreateFromTemplateDialog = ({ onClose, onSubmit, template, type }) => {
    const [name, setName] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(name);
    };

    const itemType = type === 'page' ? 'Landing Page' : (type === 'survey' ? 'Survey' : 'Email');

    return (
        <Dialog onClose={onClose} open={true}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Create {itemType}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ marginBottom: 2 }}>
                        Using template '{template.name}'
                    </DialogContentText>
                    <TextField
                        autoFocus
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={name}
                        required
                        onChange={e => setName(e.target.value)}
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Create {itemType}</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreateFromTemplateDialog;