import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import useSnackbar from '../../../hooks/useSnackbar';
import CircularProgress from '@mui/material/CircularProgress';

const EditCustomFontModal = ({ shown, onHide, onSubmit, onLoading, data }) => {
    const [formData, setFormData] = useState({
        name: data.name ?? '',
        fontFamily: data.fontFamily ?? '',
        url: data.url ?? '',
        label: data.label ?? ''
    });

    const { showSnackbar } = useSnackbar();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (formData.name !== '' && formData.fontFamily !== '') {
            await onSubmit(formData);
        }
        else {
            showSnackbar('Please fill in the required fields', 'error');
        }
    };

    return (
        <Dialog
            open={shown}
            onClose={onHide}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Edit Custom Font</DialogTitle>
            <DialogContent dividers>
                <FormGroup fullwidth>
                    <TextField
                        fullwidth
                        type="text"
                        variant="outlined"
                        size="small"
                        label="Font Label"
                        autoComplete="off"
                        value={formData.label}
                        onChange={handleInputChange}
                        placeholder="Font Label"
                        name="label"
                    />
                    <TextField
                        fullwidth
                        type="text"
                        variant="outlined"
                        size="small"
                        label="Font Name"
                        autoComplete="off"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Font Name"
                        name="name"
                        required
                    />
                    <TextField
                        fullwidth
                        type="text"
                        variant="outlined"
                        size="small"
                        label="Font Family"
                        value={formData.fontFamily}
                        onChange={handleInputChange}
                        autoComplete="off"
                        placeholder="Font Family"
                        name="fontFamily"
                        required
                    />
                    <TextField
                        fullwidth
                        type="text"
                        variant="outlined"
                        size="small"
                        label="CDN"
                        value={formData.url}
                        onChange={handleInputChange}
                        autoComplete="off"
                        placeholder="CDN"
                        name="url"
                    />
                </FormGroup>

            </DialogContent>
            <DialogActions>
                <Button onClick={onHide} variant="outlined">
                    Close
                </Button>
                <Button onClick={handleSubmit} endIcon={onLoading ? <CircularProgress size={16} color="inherit" /> : null}>{onLoading ? 'Working' : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditCustomFontModal;