import React, { useState, useEffect } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CampaignSelectDialog from './CampaignSelectDialog';
import Button from '@mui/material/Button';
import useApi from '../hooks/useApi';

const CampaignSelectButton = ({ onSetValue, value, fieldStyle }) => {
    const [showCampaignSelectDialog, setShowCampaignSelectDialog] = useState(false);
    const [campaignId, setCampaignId] = useState(null);
    const [campaign, setCampaign] = useState(null);
    const { handleGet } = useApi();

    const handleFetchCampaign = async () => {
        const response = await handleGet(`reports/${campaignId}`);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const campaign = await response.json();
        setCampaign(campaign);
    };

    useEffect(() => {
        setCampaignId(value);
    }, [value]);

    useEffect(() => {
        if (campaignId) {
            handleFetchCampaign();
        }
        else {
            setCampaign(null);
        }
    }, [campaignId]);

    return (
        <>
            {campaign && (
                <Button
                    onClick={() => setShowCampaignSelectDialog(true)}
                    variant="outlined"
                    endIcon={<OpenInNewIcon />}
                    sx={{
                        ...fieldStyle,
                        justifyContent: 'space-between'
                    }}
                    title={`${campaign.subject} ${new Date(campaign.sendDateTime).toLocaleString('en-GB')}`}
                >
                    Campaign
                </Button>
            )}
            {!campaign && (
                <Button
                    onClick={() => setShowCampaignSelectDialog(true)}
                    variant="outlined"
                    endIcon={<OpenInNewIcon />}
                    sx={{
                        ...fieldStyle,
                        justifyContent: 'space-between'
                    }}
                >
                    Select Campaign
                </Button>
            )}
            {showCampaignSelectDialog && (
                <CampaignSelectDialog
                    onClose={() => setShowCampaignSelectDialog(false)}
                    onSubmit={() => onSetValue(campaignId)}
                    currentlySelected={campaign}
                    setCampaignId={campaignId => setCampaignId(campaignId)}
                />
            )}
        </>
    );
};

export default CampaignSelectButton;