import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const DeleteTopicDialog = ({ onClose, onDelete, topic }) => {

    const handleDelete = e => {
        e.preventDefault();
        onDelete(topic.id);
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <form onSubmit={handleDelete}>
                <DialogTitle>Delete Topic: {topic.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please confirm this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Confirm delete</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default DeleteTopicDialog;