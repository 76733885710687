import React, { useState, useEffect } from 'react';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useApi from '../../hooks/useApi';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';

const Invoices = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [invoices, setInvoices] = useState();
    const { handleGet } = useApi();

    const getInvoices = async () => {
        setIsLoading(true);
        const response = await handleGet('account/invoices');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        setInvoices(data);
        setIsLoading(false);
    };

    useEffect(() => {
        getInvoices();
    }, []);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    if (invoices.length === 0) {
        return (
            <Box sx={{ padding: 2 }}>
                <Typography variant="body1">You have no invoices in your account.</Typography>
            </Box>
        );
    }

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64 }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">Invoices</Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{ padding: 2 }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row">Invoice No.</TableCell>
                                <TableCell component="th" scope="row" align="right">Invoice Date</TableCell>
                                <TableCell component="th" scope="row" align="right">Status</TableCell>
                                <TableCell component="th" scope="row" align="right" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoices.map(invoice => (
                                <TableRow key={invoice.id}>
                                    <TableCell>{invoice.invoiceNumber ? invoice.invoiceNumber : '-'}</TableCell>
                                    <TableCell align="right">{new Date(invoice.orderDate).toLocaleDateString('en-GB')}</TableCell>
                                    <TableCell align="right">{invoice.status ? `${invoice.status.charAt(0).toUpperCase()}${invoice.status.substring(1)}` : '-'}</TableCell>
                                    <TableCell align="right">{invoice.invoiceUrl ? <Link href={invoice.invoiceUrl} target="_blank">Download</Link> : '-'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
};

export default Invoices;