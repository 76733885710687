import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';

import AddCustomFontModal from '../../brandManagement/modal/AddCustomFontModal';
import EditCustomFontModal from '../../brandManagement/modal/EditCustomFontModal';

const CustomFontsPanel = ({ account, onFormChange }) => {
    const [customFontDialog, setCustomFontDialog] = useState(false);
    const [editFontDialog, setEditFontDialog] = useState(false);
    const [selectedFont, setSelectedFont] = useState(null);
    const [fontLoading, setIsFontLoading] = useState(false);
    const [customFonts, setCustomFonts] = useState(account.customFonts);
    const [index, setIndex] = useState(0);

    const handleEditDialog = (row, rowIndex) => {
        setEditFontDialog(true);
        setSelectedFont(row);
        setIndex(rowIndex);
    };

    const handleCloseEditDialog = () => {
        setEditFontDialog(false);
        setSelectedFont(null);
    };

    const handleOnSubmit = async (newFont) => {
        setCustomFonts([...customFonts, newFont]);
        onFormChange('customFonts', [...customFonts, newFont]);
        setEditFontDialog(false);
    };

    const handleOnEdit = async (fontIndex) => {
        const updatedFonts = [...customFonts];
        updatedFonts[index] = fontIndex;

        setCustomFonts(updatedFonts);
        onFormChange('customFonts', updatedFonts);
        setEditFontDialog(false);
    };

    const handleDeleteFont = async (fontIndex) => {
        const updatedFonts = customFonts.filter((_, key) => key !== fontIndex);

        setCustomFonts(updatedFonts);
        onFormChange('customFonts', updatedFonts);
    };

    useEffect(() => {
        if (customFonts && customFonts.length > 0) {
            setIsFontLoading(true);

            const fontLinks = customFonts
                .filter(element => element.url && element.url.length > 0)
                .map(element => {
                    if (element.url.length > 0) {
                        const link = document.createElement('link');
                        link.setAttribute('rel', 'stylesheet');
                        link.href = element.url;
                        return link;
                    }
                });

            if (fontLinks.length === 0) {
                setIsFontLoading(false);
                return;
            }

            let loadedFontsCount = 0;

            const handleFontLoad = () => {
                loadedFontsCount++;

                if (loadedFontsCount === fontLinks.length) {
                    setIsFontLoading(false);
                }
            };

            fontLinks.forEach(link => {
                link.onload = handleFontLoad;
                document.head.appendChild(link);
            });

            return () => {
                fontLinks.forEach(link => {
                    link.onload = null;
                });
            };
        }
        else {
            setIsFontLoading(false);
        }
    }, [customFonts]);

    if (fontLoading) {
        return <CircularProgress size={16} color="inherit" />;
    }

    return (
        <>
            <Button variant="contained" onClick={() => setCustomFontDialog(true)}>Add Brand Font</Button>
            <Box sx={{ flexGrow: 1, mt: 2 }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {account.customFonts.map((font, index) => (
                        <Grid item xs={4} sm={4} md={3} key={index}>
                            <Card sx={{ overflow: 'hidden' }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {font.label}
                                    </Typography>
                                    <Typography gutterBottom variant="body1" component="div" sx={{ fontFamily: font.fontFamily }}>
                                        {font.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ fontFamily: font.fontFamily }}>
                                        The quick brown fox jumps over the lazy dog
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'flex-end' }}>
                                    <IconButton size="small" color="primary" onClick={() => handleEditDialog(font)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton size="small" color="primary" onClick={() => handleDeleteFont(font)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {customFontDialog && (
                <AddCustomFontModal
                    shown={customFontDialog}
                    onHide={() => setCustomFontDialog(false)}
                    onSubmit={handleOnSubmit}
                />
            )}
            {editFontDialog && (
                <EditCustomFontModal
                    shown={editFontDialog}
                    onHide={handleCloseEditDialog}
                    onSubmit={handleOnEdit}
                    data={selectedFont}
                />
            )}
        </>
    );
};

export default CustomFontsPanel;