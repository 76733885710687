import React from 'react';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import TitleBar from '../../titleBar/TitleBar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import CampaignsTable from './CampaignsTable';

const Campaigns = ({ showHamburger, onClickHamburger }) => {
    return (
        <>
            <TitleBar showHamburger={showHamburger} onClickHamburger={onClickHamburger}>
                <Breadcrumbs>
                    <Link href="/#/superadmin">Super Admin</Link>
                    <Typography>
                        <strong>Campaigns</strong>
                    </Typography>
                </Breadcrumbs>
            </TitleBar>
            <Paper sx={{ m: 2 }}>
                <CampaignsTable />
            </Paper>
        </>
    );
};

export default Campaigns;