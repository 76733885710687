import React from 'react';
import MuiTab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

const Tab = styled(props => <MuiTab {...props} />)(({ theme }) => ({
    textTransform: 'none',
    backgroundColor: theme.palette.background.default,
    borderBottom: '1px solid #DDD',
    borderRight: '1px solid #DDD',
    '&:last-child': {
        borderRight: '0px'
    },
    '&:hover': {
        backgroundColor: theme.palette.grey[200]
    },
    '&.Mui-selected': {
        backgroundColor: theme.palette.background.paper,
        borderBottom: '0px'
    },
    '&.Mui-focusVisible': {
        backgroundColor: theme.palette.grey[200]
    }
}));

export default Tab;