import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import Tag from '../tags/Tag';
import Topic from '../topics/Topic';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ListItemText from '@mui/material/ListItemText';
import EditTopicDialog from '../account/topics/dialogs/EditTopicDialog';
import ListItemIcon from '@mui/material/ListItemIcon';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ItemsMenu = ({ items, availableItems, onSetItems, title, isBeingUsed = false, type, setOpenTopicsDialog }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(null);
    };

    const handleAddTopicClick = e => {
        e.preventDefault();
        e.stopPropagation();
        setOpenTopicsDialog(true);
    };

    return (
        <>
            <Tooltip title={isBeingUsed ? null : title}>
                <span>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        disabled={isBeingUsed}
                    >
                        <ArrowDropDownIcon fontSize="small" />
                    </IconButton>
                </span>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {type === 'Topics' && (
                    <MenuItem
                        onClick={handleAddTopicClick}
                        sx={{ paddingBottom: 1, paddingLeft: 1.1 }}
                        divider
                    >
                        <ListItemIcon>
                            <AddCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Add Topic</ListItemText>
                    </MenuItem>
                )}
                {availableItems.map(option => (
                    <RenderOption
                        key={option.id}
                        option={option}
                        items={items}
                        onSetItems={onSetItems}
                    />
                ))}
            </Menu>
        </>
    );
};

const RenderOption = ({ option, items, onSetItems }) => {
    const selected = Boolean(items.find(e => e.id === option.id));

    const handleClickCheckbox = (event, option) => {
        event.stopPropagation();

        if (selected) {
            onSetItems(items.filter(e => e.id !== option.id));
        }
        else {
            const newItems = [...items, option];

            const orderedItems = newItems.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }

                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });

            onSetItems(orderedItems);
        }
    };

    return (
        <MenuItem
            onClick={event => handleClickCheckbox(event, option)}
            sx={{ padding: 0, paddingRight: 1 }}
        >
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                sx={{ marginRight: 1 }}
                checked={selected}
            />
            <CircleIcon fontSize="small" sx={{ color: option.colour ?? 'rgba(0, 0, 0, 0.08)', marginRight: 1 }} />
            <Typography variant="inherit" noWrap>
                {option.name}
            </Typography>
        </MenuItem>
    );
};

const NoItemsAvailable = ({ type }) => {
    return (
        <Typography variant="body2" sx={{ color: 'text.disabled' }}>No {type} Available</Typography>
    );
};

const Picker = ({ availableItems, selectedItems, onChangeItems, onClickItem = null, singleInstance = false, isTags = false, border = true, isBeingUsed = false, onCreateTopic }) => {
    const [items, setItems] = useState(selectedItems);
    const [openTopicsDialog, setOpenTopicsDialog] = useState(false);

    const handleClick = (event, item) => {
        event.stopPropagation();
        onClickItem(item);
    };

    const handleChangeItems = items => {
        setItems(items);
        onChangeItems(items);
    };

    const handleAddTopic = ({ name, description, colour, id = null }) => {
        onCreateTopic({ name, description, colour, ...(id && { id }) });
        setOpenTopicsDialog(false);
    };

    const type = isTags ? 'Tags' : 'Topics';

    const borderStyle = border ? null : { border: 0 };

    useEffect(() => {
        selectedItems?.length > 0 && setItems(selectedItems);
    }, [selectedItems]);

    if (availableItems.length === 0) {
        if (singleInstance) {
            return (
                <TableCell sx={{ padding: 1, ...borderStyle }}>
                    <NoItemsAvailable type={type} />
                </TableCell>
            );
        }

        return (
            <>
                <TableCell sx={{ width: '7%', ...borderStyle }} />
                <TableCell sx={{ paddingY: 1, paddingX: 2, ...borderStyle }}>
                    <NoItemsAvailable type={type} />
                </TableCell>
            </>
        );
    }

    return (
        <>
            <TableCell align="right" sx={{ paddingX: 0, ...borderStyle }}>
                <ItemsMenu
                    items={items}
                    availableItems={availableItems}
                    onSetItems={handleChangeItems}
                    title={isTags ? 'Edit Tags' : 'Edit Email Topics'}
                    isBeingUsed={isBeingUsed}
                    type={type}
                    setOpenTopicsDialog={setOpenTopicsDialog}
                />
            </TableCell>
            <TableCell align="left" sx={singleInstance ? { width: '100%', ...borderStyle } : { ...borderStyle }}>
                {items.length > 0 ? items.map((item, index) => (
                    isTags ? (
                        <Tag
                            key={index}
                            name={availableItems.find(obj => obj.id === item.id)?.name}
                            colour={item.colour}
                            onClick={onClickItem ? event => handleClick(event, item) : undefined}
                        />
                    ) : (
                        <Topic
                            key={index}
                            name={availableItems.find(obj => obj.id === item.id)?.name}
                            colour={item.colour}
                            onClick={onClickItem ? event => handleClick(event, item) : undefined}
                        />
                    )
                )) : (
                    <Typography variant="body2" sx={{ color: 'text.disabled' }}>No {isTags ? 'Tags' : 'Topics'} Selected</Typography>
                )}
            </TableCell>
            {openTopicsDialog && type === 'Topics' && (
                <EditTopicDialog
                    onClose={() => setOpenTopicsDialog(null)}
                    onSubmit={handleAddTopic}
                />
            )}
        </>
    );
};

export default Picker;