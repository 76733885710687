import { isValid as isValidDate } from 'date-fns';

const parseFilters = filters => {
    if (!filters) {
        return null;
    }

    return filters
        .filter(f => f.field)
        .map(f => {
            if (Number.isInteger(f.value) || !isValidDate(f.value)) { //ID ints look like dates
                return f;
            }
            else {
                return {
                    ...f,
                    value: new Date(f.value).toJSON()
                };
            }
        });
};

export default parseFilters;