import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import FormHelperText from '@mui/material/FormHelperText';
import useAccount from '../../hooks/useAccount';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const EmailDetailsDialog = ({ email, onComplete, onClose }) => {
    const [subject, setSubject] = useState(email.subject);
    const [fromName, setFromName] = useState(email.fromName);
    const [fromEmail, setFromEmail] = useState(email.fromEmail);
    const [canSubmit, setCanSubmit] = useState(false);
    const [form, setForm] = useState(null);
    const { smtpUsername } = useAccount();

    const onRefChange = useCallback(node => setForm(node), []);

    useEffect(() => {
        setCanSubmit(form && form.checkValidity());
    }, [form, subject, fromName, fromEmail]);

    return (
        <Dialog
            onClose={onClose}
            open={true}
            TransitionComponent={Transition}
        >
            <DialogTitle>
                Spam Check: Required details
            </DialogTitle>
            <DialogContent>
                <form ref={onRefChange}>
                    <Stack>
                        <FormControl fullwidth>
                            <TextField fullwidth required value={subject} onChange={e => setSubject(e.target.value)} label="Subject line" margin="none" />
                        </FormControl>
                        <FormControl fullwidth>
                            <TextField fullwidth required value={fromName} onChange={e => setFromName(e.target.value)} label="From name" margin="none" />
                        </FormControl>
                        <FormControl fullwidth>
                            {smtpUsername ? (
                                <>
                                    <TextField fullWidth disabled value={smtpUsername} label="From email address" margin="none" />
                                    <FormHelperText margin="dense">You cannot change your from email address when using a custom SMTP configuration.</FormHelperText>
                                </>
                            ) : (
                                <TextField fullwidth required type="email" value={fromEmail} onChange={e => setFromEmail(e.target.value)} label="From email address" margin="none" />
                            )}
                        </FormControl>
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onComplete(subject, fromName, fromEmail)} disabled={!canSubmit}>
                    Submit
                </Button>
                <Button onClick={onClose} variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailDetailsDialog;