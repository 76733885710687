import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import Skeleton from '@mui/material/Skeleton';
import useApi from '../hooks/useApi';

const colour = '0, 110, 205';
const emptyColour = 'rgba(0, 0, 0, 0.07)';

const cellStyle = {
    width: '12.5%',
    fontSize: '0.7rem',
    padding: '2px 4px',
    border: '2px solid #FFF',
    lineHeight: '1',
    '@media print': {
        border: '2px solid #FFF'
    }
};

const getCellColours = (value, data) => {
    const maxValue = Math.max(...data.map((i) => i.totalOpens));

    const getAlpha = () => {
        if (value / maxValue < 0.2) {
            return '0.2';
        }

        if (value / maxValue < 0.4) {
            return '0.4';
        }

        if (value / maxValue < 0.6) {
            return '0.6';
        }

        if (value / maxValue < 0.8) {
            return '0.8';
        }

        return 1;
    };

    const alpha = getAlpha();

    return ({
        backgroundColor: value !== 0 ? `rgba(${colour}, ${maxValue > 0 ? alpha : 0})` : emptyColour,
        color: (value / maxValue < 0.4) ? '#000' : '#FFF'
    });
};

const CampaignHeatmap = ({ campaignId, parentIsLoading = null, setParentIsLoading = null }) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { handleGet } = useApi();

    const handleFetchData = async () => {
        const response = await handleGet(`reports/${campaignId}/heatmap`);

        if (!response.ok) {
            return;
        }

        setData(await response.json());
    };

    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

    const days = [
        { label: 'Monday', dayOfWeek: 2 },
        { label: 'Tuesday', dayOfWeek: 3 },
        { label: 'Wednesday', dayOfWeek: 4 },
        { label: 'Thursday', dayOfWeek: 5 },
        { label: 'Friday', dayOfWeek: 6 },
        { label: 'Saturday', dayOfWeek: 7 },
        { label: 'Sunday', dayOfWeek: 1 }
    ];

    const renderCell = (d, h) => {
        const item = data.find(({ dayOfWeek, hour }) => dayOfWeek === d && hour === h);

        const emptyColours = {
            backgroundColor: emptyColour,
            color: '#000'
        };

        const colours = item ? getCellColours(item.totalOpens, data) : emptyColours;

        return (
            <TableCell
                align="center"
                key={d}
                sx={{
                    ...colours,
                    ...cellStyle
                }}
            >
                {item?.totalOpens ?? ''}
            </TableCell>
        );
    };

    const renderTable = () => {
        return hours.map(h => {
            return (
                <TableRow key={h}>
                    {days.map(d => renderCell(d.dayOfWeek, h))}
                    <TableCell
                        variant="head"
                        sx={{
                            ...cellStyle,
                            paddingLeft: 1,
                            fontWeight: 'bold'
                        }}
                    >
                        {h < 10 ? `0${h}:00` : `${h}:00`}
                    </TableCell>
                </TableRow>
            );
        });
    };

    useEffect(() => {
        handleFetchData();
    }, []);

    useEffect(() => {
        if (data) {
            if (setParentIsLoading) {
                setParentIsLoading(false);
                return;
            }

            setIsLoading(false);
        }
    }, [data]);

    if (parentIsLoading === null && isLoading || parentIsLoading) {
        return (
            <Table size="small">
                <TableBody>
                    {new Array(25).fill('').map((e, i) => (
                        <TableRow key={i}>
                            {new Array(8).fill('').map((e, i) => (
                                <TableCell key={i} sx={cellStyle}>
                                    <Skeleton width="100%" variant="rectangular" />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }

    return (
        <Table size="small">
            <TableBody>
                {renderTable()}
            </TableBody>
            <TableFooter>
                <TableRow>
                    {days.map((day, i) => (
                        <TableCell
                            key={i}
                            variant="head"
                            align="center"
                            sx={{
                                textTransform: 'uppercase',
                                border: 0,
                                ...cellStyle,
                                paddingTop: 1,
                                fontWeight: 'bold'
                            }}
                        >
                            {day.label.substring(0, 3)}
                        </TableCell>
                    ))}
                    <TableCell sx={{ border: 0, ...cellStyle }} />
                </TableRow>
            </TableFooter>
        </Table>
    );
};

export default CampaignHeatmap;