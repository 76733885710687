import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

const AddSignUpFormDomainDialog = ({ domains, onSubmit, onCancel }) => {
    const [domain, setDomain] = useState('');
    const [protocol, setProtocol] = useState('https://');
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [isTooShort, setIsTooShort] = useState(false);
    const [parsedDomain, setParsedDomain] = useState(null);

    const empty = domain.trim() === '';

    useEffect(() => {
        if (empty) {
            return;
        }

        const urlString = `${protocol}${domain}`;

        const canParse = URL.canParse(urlString);

        if (canParse) {
            const url = new URL(urlString);

            setParsedDomain(url.origin);
        }
        else {
            setParsedDomain(null);
        }
    }, [domain, protocol]);

    useEffect(() => {
        if (empty || !parsedDomain) {
            return;
        }

        setIsTooShort(parsedDomain.split('.').length < 2);

        setIsDuplicate(domains?.map(e => e.domain).includes(parsedDomain.toLowerCase().trim()));
    }, [parsedDomain]);

    return (
        <Dialog
            open={true}
            onClose={onCancel}
            maxWidth="sm"
        >
            <form onSubmit={() => onSubmit(parsedDomain)} noValidate autoComplete="off">
                <DialogTitle>Add Allowed Domain</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <DialogContentText>
                            Choose your protocol and enter the sub-domain, second-level domain and top-level domain e.g. www.example.com or my.newzapp.co.uk
                        </DialogContentText>
                        <Stack direction="row" spacing={1}>
                            <FormControl size="small" sx={{ minWidth: 100 }}>
                                <InputLabel id="protocol-select-label">Protocol</InputLabel>
                                <Select
                                    labelId="protocol-select-label"
                                    id="protocol-select"
                                    value={protocol}
                                    label="Protocol"
                                    onChange={event => setProtocol(event.target.value)}
                                >
                                    <MenuItem value="https://">https</MenuItem>
                                    <MenuItem value="http://">http</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField
                                    autoFocus
                                    fullwidth
                                    variant="outlined"
                                    size="small"
                                    margin="none"
                                    label="Domain"
                                    autoComplete="off"
                                    value={domain}
                                    onChange={e => setDomain(e.target.value)}
                                    placeholder="www.example.com"
                                    error={!empty && (isDuplicate || isTooShort)}
                                    helperText={empty ? undefined : isDuplicate ? 'Domain already exists' : isTooShort ? 'Invalid format' : undefined}
                                />
                            </FormControl>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} variant="outlined">
                        Close
                    </Button>
                    <Button type="submit" disabled={empty || isDuplicate || isTooShort}>
                        Add Domain
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AddSignUpFormDomainDialog;