import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import { DataGridPro } from '@mui/x-data-grid-pro';
import buildUrl from '../buildUrl';
import useApi from '../hooks/useApi';
import NoRows from '../dataTable/NoRows';
import usePrevious from '../hooks/usePrevious';

const rowsPerPageOptions = [10, 20, 50];

const columns = [
    { field: 'subject', headerName: 'Subject Line', orderDesc: false, type: 'string', flex: 1 },
    { field: 'sendDateTime', headerName: 'Send Date', orderDesc: true, type: 'dateTime', flex: 0.25, valueGetter: ({ value }) => value && new Date(value) },
    { field: 'selected', headerName: 'Sent To', orderDesc: false, type: 'number' }
];

const CampaignSelect = ({ onSelect, currentlySelected, showSelected = false }) => {
    const { handleGet } = useApi();
    const [campaigns, setCampaigns] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [orderDesc, setOrderDesc] = useState(true);
    const [orderBy, setOrderBy] = useState('sendDateTime');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedIds, setSelectedIds] = useState([]);
    const prevRowsPerPage = usePrevious(rowsPerPage);
    const prevOrderBy = usePrevious(orderBy);
    const prevOrderDesc = usePrevious(orderDesc);
    const prevPage = usePrevious(page);

    const handleSelectCampaign = (e, campaign) => {
        e.preventDefault();
        onSelect(campaign);
    };

    const handleFetchCampaigns = async () => {
        const params = {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending: orderDesc
        };

        const url = buildUrl('reports', params);

        const response = await handleGet(url);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const data = await response.json();

        setCampaigns(data);
        setIsLoading(false);
    };

    const handleFetchCampaignsCount = async () => {
        const url = buildUrl('reports/count');

        const response = await handleGet(url);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const count = await response.json();

        setCount(count);
    };

    const handleSort = props => {
        const { field, sort } = props[0];

        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderDesc(sort === 'desc');
            setOrderBy(field);
        }
    };

    useEffect(() => {
        if (currentlySelected && campaigns.length > 0) {
            setSelectedIds([currentlySelected.id]);
        }
    }, [campaigns, currentlySelected]);

    useEffect(() => {
        handleFetchCampaignsCount();
    }, []);

    useEffect(() => {
        if (orderBy !== prevOrderBy ||
            orderDesc !== prevOrderDesc ||
            rowsPerPage !== prevRowsPerPage) {
            setPage(0);
            setIsLoading(true);
        }
        else if (page !== prevPage) {
            setIsLoading(true);
        }
    }, [orderBy, orderDesc, rowsPerPage, page]);

    useEffect(() => {
        if (isLoading) {
            handleFetchCampaigns();
            handleFetchCampaignsCount();
        }
    }, [isLoading]);

    const sort = orderDesc ? 'desc' : 'asc';

    return (
        <>
            <Collapse in={showSelected && Boolean(currentlySelected)}>
                <Alert severity="info" sx={{ marginBottom: 2 }}>
                    <AlertTitle>Selected Email</AlertTitle>
                    {currentlySelected ? `${currentlySelected.subject} ${new Date(currentlySelected.sendDateTime).toLocaleString('en-GB')}` : null}
                </Alert>
            </Collapse>
            <DataGridPro
                autoHeight
                disableColumnMenu
                disableColumnFilter
                disableMultipleColumnsSorting
                disableMultipleSelection
                hideFooterSelectedRowCount
                pagination
                paginationMode="server"
                page={page}
                sortingMode="server"
                rows={campaigns}
                rowCount={count}
                rowHeight={32}
                columns={columns}
                loading={isLoading}
                pageSize={rowsPerPage}
                onPageSizeChange={rowsPerPage => setRowsPerPage(rowsPerPage)}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageChange={page => setPage(page)}
                onSortModelChange={props => handleSort(props)}
                onRowClick={(value, e) => handleSelectCampaign(e, value.row)}
                selectionModel={selectedIds}
                sortingOrder={['desc', 'asc']}
                initialState={{
                    sorting: {
                        sortModel: [{ field: orderBy, sort }]
                    }
                }}
                components={{
                    NoRowsOverlay: NoRows
                }}
                sx={{
                    '& .MuiDataGrid-cell:focus': { outline: 'none' }
                }}
            />
        </>
    );
};

export default CampaignSelect;