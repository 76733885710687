import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { SketchPicker } from 'react-color';
import { colours } from '../../../theme/constants';

const AddCustomColourModal = ({ shown, onHide, onLoading, onSubmit }) => {
    const [colour, setColour] = useState(colours.primary);
    const [label, setLabel] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        await onSubmit(colour, label);
    };

    return (
        <Dialog
            open={shown}
            onClose={onHide}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Add Brand Colour</DialogTitle>
            <DialogContent dividers>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{
                        width: '80%',
                        background: colour,
                        position: 'relative',
                        marginRight: 2,
                        borderRadius: 2
                    }} />
                    <SketchPicker disableAlpha={true} width={350} color={colour} onChange={(colour) => setColour(colour.hex)} />
                </Box>
                <FormGroup fullwidth>
                    <TextField
                        fullwidth
                        type="text"
                        variant="outlined"
                        size="small"
                        label="Colour Label"
                        autoComplete="off"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                        placeholder="Colour Label"
                        name="colourLabel"
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onHide} variant="outlined">
                    Close
                </Button>
                <Button onClick={handleSubmit} endIcon={onLoading ? <CircularProgress size={16} color="inherit" /> : null}>{onLoading ? 'Working' : 'Add Colour'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCustomColourModal;