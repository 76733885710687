import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useHistory, useParams } from 'react-router-dom';
import useApi from '../hooks/useApi';
import useInterval from '../hooks/useInterval';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import PlaceIcon from '@mui/icons-material/Place';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ConfirmDeleteQRCodeDialog from './dialogs/ConfirmDeleteQRCodeDialog';
import ConfirmDeleteQRCodeCampaignDialog from './dialogs/ConfirmDeleteQRCodeCampaignDialog';
import QRCodeDownloadDialog from './dialogs/QRCodeDownloadDialog';
import QRCodeFormDialog from './dialogs/QRCodeFormDialog';
import QRCodeAssignContentDialog from './dialogs/QRCodeAssignContentDialog';
import useSnackbar from '../hooks/useSnackbar';
import Snackbar from '@mui/material/Snackbar';
import QRCodeAssignedContent from '../qrcode/QRCodeAssignedContent';
import QRCodeImage from '../qrcode/QRCodeImage';
import QRCodeActions from '../qrcode/QRCodeActions';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Topic from '../topics/Topic';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import WebIcon from '@mui/icons-material/Web';
import EmailIcon from '@mui/icons-material/Email';
import { styled } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { format } from 'date-fns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentNavigation from './ContentNavigation';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import PollIcon from '@mui/icons-material/Poll';

const dialogs = {
    ASSIGN: 1,
    DOWNLOAD: 2,
    EDIT: 3,
    DELETE_QR_CODE: 4
};

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    },
    '& .MuiAccordionDetails-root': {
        paddingLeft: 0,
        paddingRight: 0
    }
}));

const QRCode = () => {
    const history = useHistory();
    const theme = useTheme();
    const { id } = useParams();
    const { showSnackbar } = useSnackbar();
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();

    const [isInitialising, setIsInitialising] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [qrCodes, setQrCodes] = useState(null);
    const [qrCode, setQrCode] = useState(null);
    const [expanded, setExpanded] = useState(null);
    const [dialog, setDialog] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const [qrCodeContents, setQrCodeContents] = useState(null);
    const [contentToDelete, setContentToDelete] = useState(null);
    const [previouslyAssignedContent, setPreviouslyAssignedContent] = useState([]);
    const [scheduledContent, setScheduledContent] = useState([]);
    const [tab, setTab] = useState('qrcode');

    const handleChangePanel = (panel) => (e, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleNavigateToDashboard = () => {
        history.push({
            pathname: '/editor',
            state: { type: tab }
        });
    };

    const handleFetchQRCodes = async () => {
        const response = await handleGet('qrcodes');

        if (!response.ok) {
            console.error(response?.error);
            handleNavigateToDashboard();
            return;
        }

        setQrCodes(await response.json());
    };

    const handleFetchQrCodeContents = async () => {
        const response = await handleGet(`qrcodes/${id}/contents`);
        const data = await response.json();

        setQrCodeContents(data);
    };

    const handleAssignContent = async (qrCodeId, contentId, startDate, endDate, type) => {
        setIsLoading(true);

        const params = {
            qrCodeId,
            ...(type === 'email' && { campaignId: contentId }),
            ...(type === 'page' && { landingPageId: contentId }),
            ...(type === 'survey' && { surveyId: contentId }),
            type,
            startDate,
            endDate
        };

        const response = await handlePost(`qrcodes/${qrCodeId}/contents`, params);

        if (!response.ok) {
            showSnackbar('Content could not be associated', 'error');
            return;
        }

        await handleFetchQRCodes();
        await handleFetchQrCodeContents();

        setIsLoading(false);
        showSnackbar('Content associated successfully', 'success');
    };

    const handleEditItem = async ({ name = null, location = null }) => {
        setIsLoading(true);

        const params = {
            ...qrCode,
            name,
            location
        };

        const response = await handlePut(`qrcodes/${qrCode.id}`, params);

        if (!response.ok) {
            showSnackbar('QR Code not found', 'error');
        }

        await handleFetchQRCodes();

        setIsLoading(false);
    };

    const handleDeleteQRCode = async qrCode => {
        const response = await handleDelete(`qrcodes/${qrCode.id}`);

        if (!response.ok) {
            showSnackbar('QR Code not found', 'error');
        }

        setDialog(null);
        handleNavigateToDashboard();
    };

    const handleDeleteContent = async () => {
        setIsLoading(true);

        const response = await handleDelete(`qrcodes/${qrCode.id}/contents/${contentToDelete.id}`);

        if (!response.ok) {
            showSnackbar('Content association not found', 'error');
        }

        setContentToDelete(null);

        await handleFetchQRCodes();
        await handleFetchQrCodeContents();

        setIsLoading(false);
    };

    const handleDeactivateContent = async qrCodeContent => {
        setIsLoading(true);

        const response = await handlePut(`qrcodes/${qrCode.id}/contents/${qrCodeContent.id}`, { endDate: new Date() });

        if (!response.ok) {
            showSnackbar('Content association not found', 'error');
        }

        await handleFetchQRCodes();
        await handleFetchQrCodeContents();

        setIsLoading(false);
    };

    const handleOpenLink = url => {
        const urlObject = new URL(url, window.location.origin);
        urlObject.searchParams.set('preview', 'true');
        window.open(urlObject.toString(), '_blank');
    };

    const handleInit = async () => {
        await handleFetchQrCodeContents();

        handleFetchQRCodes();
    };

    const handleCloseAssignCampaignDialog = (event, reason) => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        setDialog(null);
    };

    const intervalRef = useInterval(() => {
        if (!isInitialising) {
            handleFetchQRCodes();
        }

        return () => {
            clearInterval(intervalRef.current);
        };
    }, 30000);

    useEffect(() => {
        if (!qrCode) {
            return;
        }

        const scheduledContent = qrCodeContents?.filter(e => e.id !== qrCode.activeContent?.id && (e.endDate === null || new Date(e.endDate) > new Date()) && !e.campaignDeleted);

        const previouslyAssignedContent = qrCodeContents?.filter(e => e.id !== qrCode.activeContent?.id && !(scheduledContent.map(s => s.id).includes(e.id)));

        if (qrCode && qrCodeContents) {
            setScheduledContent(scheduledContent);
            setPreviouslyAssignedContent(previouslyAssignedContent);
        }
    }, [qrCode, qrCodeContents]);

    useEffect(() => {
        qrCode && setIsInitialising(false);
    }, [qrCode]);

    useEffect(() => {
        if (qrCodes?.length > 0) {
            const qrCode = qrCodes.find(e => e.id === parseInt(id));

            if (!qrCode) {
                handleNavigateToDashboard();
                return;
            }

            setQrCode(qrCode);
        }
    }, [qrCodes]);

    useEffect(() => {
        // Accordion logic, mostly for polling
        // if previous open, leave it alone

        if (!expanded) {
            //if it's closed
            if (scheduledContent.length > 0) {
                //if scheduled has content, open scheduled
                setExpanded('scheduled');
            }
        }
        else if (expanded === 'scheduled') {
            //if scheduled open
            if (scheduledContent.length === 0) {
                // if scheduled has no content
                if (previouslyAssignedContent.length > 0) {
                    // if previous has content, open previous
                    setExpanded('previous');
                }
                else {
                    // else close it
                    setExpanded(null);
                }
            }
        }
    }, [scheduledContent]);

    useEffect(() => {
        tab !== 'qrcode' && handleNavigateToDashboard();
    }, [tab]);

    useEffect(() => {
        handleInit();
    }, []);

    return (
        <>
            <ContentNavigation
                type={tab}
                onSetType={setTab}
            />
            {isInitialising ? <LoadingOverlay /> : (
                <>
                    <Button
                        variant="text"
                        onClick={handleNavigateToDashboard}
                        startIcon={<ArrowBackIcon />}
                        sx={{ mx: 3, mt: 1, textTransform: 'uppercase' }}
                    >
                        Back
                    </Button>
                    <Container maxWidth={false} sx={{ my: 1 }}>
                        <Card>
                            <CardHeader
                                title={qrCode.name}
                                titleTypographyProps={{ variant: 'h5' }}
                                sx={{ alignItems: 'start', pb: 0 }}
                                subheader={(
                                    <React.Fragment>
                                        <Box display="inlineFlex" alignItems="center">
                                            <PlaceIcon fontSize="small" color="info" />
                                            <Typography variant="body2" ml={0.5}>{qrCode.location ?? 'No location set'}</Typography>
                                        </Box>
                                    </React.Fragment>
                                )}
                                avatar={
                                    <QRCodeImage qrCode={qrCode} size={56} />
                                }
                                action={(
                                    <Stack spacing={1}>
                                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                                            <Chip
                                                label={Boolean(qrCode.activeContent) ? 'Active Content' : 'No Active Content'}
                                                icon={Boolean(qrCode.activeContent) ? <CheckCircleIcon /> : <CancelIcon />}
                                                color={Boolean(qrCode.activeContent) ? 'success' : undefined}
                                            />
                                            <QRCodeActions
                                                hasActiveContent={Boolean(qrCode.activeContent?.name)}
                                                onAssignContent={() => setDialog(dialogs.ASSIGN)}
                                                onView={() => handleOpenLink(qrCode.url)}
                                                onGetQRCode={() => setDialog(dialogs.DOWNLOAD)}
                                                onEdit={() => setDialog(dialogs.EDIT)}
                                                onDelete={() => setDialog(dialogs.DELETE_QR_CODE)}
                                            />
                                        </Stack>
                                        <Box>
                                            <Button
                                                sx={{ mr: 1 }}
                                                startIcon={<QrCodeScannerIcon />}
                                                onClick={() => setDialog(dialogs.DOWNLOAD)}
                                            >
                                                Download QR Code
                                            </Button>
                                        </Box>
                                    </Stack>
                                )}
                            />
                            <CardContent>
                                {Boolean(qrCode.activeContent) ? (
                                    <Card variant="outlined" sx={{ mb: 2 }}>
                                        <CardHeader
                                            title={`Active Content: ${qrCode.activeContent.name}`}
                                            titleTypographyProps={{ variant: 'h6', lineHeight: 1.4 }}
                                            subheader={qrCode.activeContent.type !== 'survey' ? `${qrCode.activeContent.type === 'page' ? 'Title' : 'Subject'}: ${qrCode.activeContent.title}` : undefined}
                                            subheaderTypographyProps={{ variant: 'body2', lineHeight: 1.1 }}
                                            avatar={(
                                                <Avatar variant="square" sx={{ bgcolor: theme.palette.success.main }}>
                                                    <Tooltip title={qrCode.activeContent.type === 'page' ? 'Landing Page' : qrCode.activeContent.type === 'survey' ? 'Survey' : 'Email'}>
                                                        {qrCode.activeContent.type === 'page' ? <WebIcon /> : qrCode.activeContent.type === 'survey' ? <PollIcon /> : <EmailIcon />}
                                                    </Tooltip>
                                                </Avatar>
                                            )}
                                            action={(
                                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} sx={{ mr: 1 }}>
                                                    <Button startIcon={<OpenInNewIcon />} onClick={() => handleOpenLink(qrCode.url)}>
                                                        View {qrCode.activeContent.type === 'page' ? 'Landing Page' : qrCode.activeContent.type === 'survey' ? 'Survey' : 'Email'}
                                                    </Button>
                                                    <Button startIcon={<EventBusyIcon />} onClick={() => handleDeactivateContent(qrCode.activeContent)} color="error">
                                                        Deactivate
                                                    </Button>
                                                </Stack>
                                            )}
                                            sx={{ alignItems: 'center', '& .MuiCardHeader-action': { alignSelf: 'center' } }}
                                        />
                                        <Box sx={{ flexGrow: 1, p: 1 }}>
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    borderTop: '1px solid',
                                                    borderColor: 'divider'
                                                }}
                                            >
                                                <Grid xs={12} sm={3} display="flex" justifyContent="center" alignItems="flex-start" sx={{ minHeight: { xs: 0, sm: 80 } }}>
                                                    <Stack spacing={1} alignItems="center" mt={1}>
                                                        <Typography variant="overline" sx={{ lineHeight: 1 }}>
                                                            QR Code Opens
                                                        </Typography>
                                                        <Typography variant="h4">
                                                            {qrCode.activeContent.opens}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid xs={12} sm={3} display="flex" justifyContent="center" alignItems="flex-start" sx={{ minHeight: { xs: 0, sm: 80 }, borderLeft: { xs: 0, sm: '1px solid' }, borderRight: { xs: 0, sm: '1px solid' }, borderColor: { xs: 0, sm: 'divider' } }}>
                                                    <Stack spacing={1} alignItems="center" mt={1}>
                                                        <Typography variant="overline" sx={{ lineHeight: 1 }}>
                                                            Content Clicks
                                                        </Typography>
                                                        <Typography variant="h4">
                                                            {qrCode.activeContent.clicks}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid xs={12} sm={3} display="flex" justifyContent="center" alignItems="flex-start" sx={{ minHeight: { xs: 0, sm: 80 }, borderLeft: { xs: 0, sm: '1px solid' }, borderRight: { xs: 0, sm: '1px solid' }, borderColor: { xs: 0, sm: 'divider' } }}>
                                                    <Stack spacing={1} alignItems="center" mt={1}>
                                                        <Typography variant="overline" sx={{ lineHeight: 1 }}>
                                                            {qrCode.activeContent?.topics.length > 0 ? 'Topics' : 'No Topics'}
                                                        </Typography>
                                                        <Box>
                                                            {qrCode.activeContent?.topics.map(t => <Topic key={t.id} name={t.name} colour={t.colour} />)}
                                                        </Box>
                                                    </Stack>
                                                </Grid>
                                                <Grid xs={12} sm={3} display="flex" justifyContent="center" alignItems="flex-start" sx={{ minHeight: { xs: 0, sm: 80 } }}>
                                                    <Stack spacing={0} alignItems="center" mt={1}>
                                                        <Typography variant="overline" sx={{ lineHeight: 1 }}>
                                                            Start Date
                                                        </Typography>
                                                        <Typography variant="h6" sx={{ lineHeight: 1.4, mb: 1 }}>
                                                            {format(new Date(qrCode.activeContent.startDate), 'dd/MM/yyyy HH:mm')}
                                                        </Typography>
                                                        <Typography variant="overline" sx={{ lineHeight: 1 }}>
                                                            {qrCode.activeContent?.endDate ? 'End Date' : 'Indefinitely'}
                                                        </Typography>
                                                        {qrCode.activeContent?.endDate && (
                                                            <Typography variant="h6" sx={{ lineHeight: 1.4 }}>
                                                                {format(new Date(qrCode.activeContent.endDate), 'dd/MM/yyyy HH:mm')}
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Card>
                                ) : (
                                    <Alert
                                        severity="info"
                                        action={(
                                            <Button size="small" onClick={() => setDialog(dialogs.ASSIGN)} sx={{ mr: 1 }}>
                                                Assign Content
                                            </Button>
                                        )}
                                        sx={{ mb: 2 }}
                                    >
                                        <AlertTitle>No Active Content</AlertTitle>
                                    </Alert>
                                )}
                                <Accordion
                                    expanded={expanded === 'scheduled'}
                                    onChange={handleChangePanel('scheduled')}
                                    disableGutters
                                    disabled={scheduledContent.length === 0}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="scheduled-content"
                                        id="scheduled-header"
                                    >
                                        <Typography variant="subtitle2">
                                            Scheduled Content{scheduledContent.length === 0 && ' (None)'}
                                        </Typography>
                                    </AccordionSummary>
                                    <Divider />
                                    <AccordionDetails>
                                        <QRCodeAssignedContent
                                            contentItems={scheduledContent}
                                            onOpenContent={campaign => handleOpenLink(campaign.shareLink)}
                                            onSetContentToDelete={setContentToDelete}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    expanded={expanded === 'previous'}
                                    onChange={handleChangePanel('previous')}
                                    disableGutters
                                    disabled={previouslyAssignedContent.length === 0}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="previous-content"
                                        id="previous-header"
                                    >
                                        <Typography variant="subtitle2">
                                            Previously Assigned Content{previouslyAssignedContent.length === 0 && ' (None)'}
                                        </Typography>
                                    </AccordionSummary>
                                    <Divider />
                                    <AccordionDetails>
                                        <QRCodeAssignedContent
                                            contentItems={previouslyAssignedContent}
                                            onOpenContent={campaign => handleOpenLink(campaign.shareLink)}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </CardContent>
                        </Card>
                    </Container>
                </>
            )}
            {dialog === dialogs.DOWNLOAD && (
                <QRCodeDownloadDialog
                    onClose={() => setDialog(null)}
                    qrCode={qrCode}
                />
            )}
            {dialog === dialogs.EDIT && (
                <QRCodeFormDialog
                    onClose={() => setDialog(null)}
                    onSubmit={handleEditItem}
                    item={qrCode}
                    qrCodes={qrCodes}
                />
            )}
            {dialog === dialogs.DELETE_QR_CODE && (
                <ConfirmDeleteQRCodeDialog
                    onClose={() => setDialog(null)}
                    onDelete={handleDeleteQRCode}
                    qrCode={qrCode}
                />
            )}
            {Boolean(contentToDelete) && (
                <ConfirmDeleteQRCodeCampaignDialog
                    onClose={() => setContentToDelete(null)}
                    onDelete={handleDeleteContent}
                    qrCodeContent={contentToDelete}
                    qrCodeName={qrCode.name}
                />
            )}
            {dialog === dialogs.ASSIGN && (
                <QRCodeAssignContentDialog
                    onClose={handleCloseAssignCampaignDialog}
                    onSubmit={handleAssignContent}
                    qrCode={qrCode}
                />
            )}
            {isLoading && <LoadingOverlay />}
            <Snackbar
                open={Boolean(snackbarMessage)}
                onClose={() => setSnackbarMessage(null)}
                autoHideDuration={3000}
                message={snackbarMessage}
            />
        </>
    );
};

export default QRCode;