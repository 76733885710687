import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import standardFields from '../standardFields';
import useApi from '../hooks/useApi';
import { stringOperators, numberOperators, dateOperators } from '../conditionOperators';
import { parseISO, format } from 'date-fns';

const Toggle = ({ active, setActive }) => {
    const handleChange = event => {
        setActive(event.target.checked);
    };

    return (
        <Switch
            checked={active}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'toggle condition' }}
            color="success"
            edge="end"
        />
    );
};

const ToggleConditions = ({ availableConditions, conditions, onChangeConditions }) => {
    const { handleGet } = useApi();
    const [isInitialising, setIsInitialising] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [customFields, setCustomFields] = useState(null);
    const open = Boolean(anchorEl);
    const allActive = conditions?.length === availableConditions?.length;

    const handleGetCustomFields = async () => {
        const response = await handleGet('custom-fields');
        const data = await response.json();

        const customFields = data?.filter(e => e.display !== false).map(e => ({
            name: e.name,
            displayName: e.name,
            type: e.type
        })) ?? [];

        setCustomFields(customFields);
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggleAll = () => {
        if (allActive) {
            onChangeConditions([]);
        }
        else {
            onChangeConditions(availableConditions);
        }
    };

    const handleToggle = condition => {
        //if condition is in conditions, remove it. Else add it
        let newConditions = [];

        if (conditions.find(e => e.key === condition.key)) {
            newConditions = conditions.filter(e => e.key !== condition.key);
        }
        else {
            newConditions = [...conditions, condition];
        }

        onChangeConditions(newConditions);
    };

    const handleRenderOperator = operator => {
        const displayOperator = dateOperators.find(e => e.name === operator)?.displayName ? numberOperators.find(e => e.name === operator)?.displayName : stringOperators.find(e => e.name === operator)?.displayName ?? operator;

        return displayOperator;
    };

    useEffect(() => {
        handleGetCustomFields();
    }, []);

    useEffect(() => {
        customFields && setIsInitialising(false);
    }, [customFields]);

    if (isInitialising) {
        return (
            <Button
                endIcon={<ArrowDropDownIcon />}
                disabled
                variant="outlined"
            >
                Toggle Dynamic Content
            </Button>
        );
    }

    return (
        <>
            <Button
                id="toggle-conditions-button"
                aria-controls={open ? 'toggle-conditions-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                disabled={!availableConditions}
                variant="outlined"
            >
                Toggle Dynamic Content
            </Button>
            <Menu
                id="toggle-conditions-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'toggle-conditions'
                }}
            >
                <ListItemButton
                    onClick={handleToggleAll}
                    divider={true}
                >
                    <ListItemText primary="All Dynamic Content" secondary="Show/Hide All" />
                    <Toggle
                        active={allActive}
                        setActive={handleToggleAll}
                    />
                </ListItemButton>
                {availableConditions?.map((e, i, a) => {
                    const active = Boolean(conditions?.find(c => c.key === e.key));
                    const operator = handleRenderOperator(e.operator);

                    const standardField = standardFields.find(f => f.name === e.field);
                    const customField = customFields.find(f => f.name === e.field);

                    const field = standardField ?? customField;

                    const fieldName = field?.displayName ?? e.field;
                    const fieldType = field?.type ?? 'string';

                    //handle date/dob but only if that type (because partial phone numbers look like dates)
                    const value = fieldType === 'date' ? format(parseISO(e.value), 'dd/MM/yyyy HH:mm') : fieldType === 'dateofbirth' ? format(parseISO(e.value), 'dd/MM/yyyy') : e.value;

                    return (
                        <ListItemButton
                            key={e.key}
                            onClick={() => handleToggle(e)}
                            divider={i !== a.length - 1}
                        >
                            <ListItemText primary={`${fieldName} ${operator} ${value}`} />
                            <Toggle
                                active={active}
                                setActive={() => handleToggle(e)}
                            />
                        </ListItemButton>
                    );
                })}
            </Menu>
        </>
    );
};

export default ToggleConditions;