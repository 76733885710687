import React from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const TemplateItem = ({ onSelectTemplate, name, thumbnail }) => {
    const screen = {
        height: 200,
        overflow: 'hidden',
        position: 'relative',

        '& img': {
            top: 0,
            width: '100%',
            height: 'auto',
            position: 'absolute',
            zIndex: 0,
            WebkitTransition: 'top 2s',
            MozTransition: 'top 2s',
            MsTransition: 'top 2s',
            OTransition: 'top 2s',
            transition: 'top 2s'
        },

        '&:hover img': {
            top: '-100%',
            WebkitTransition: 'all 4s',
            MozTransition: 'all 4s',
            MsTransition: 'all 4s',
            OTransition: 'all 4s',
            transition: 'all 4s'
        }
    };

    return (
        <Grid item xs={6} sm={4} xl={3}>
            <Card>
                <CardActionArea onClick={onSelectTemplate}>
                    <CardHeader
                        title={<Typography component="p" variant="body1">{name}</Typography>}
                        action={(
                            <Button onClick={onSelectTemplate} component="div">
                                Select
                            </Button>
                        )}
                    />
                    <Divider />
                    <Box sx={screen}>
                        <img src={thumbnail} alt={name} />
                    </Box>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

const ListName = ({ name }) => {
    return (
        <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
                {name}
            </Typography>
        </Grid>
    );
};

const TemplatesList = ({ templates, selectedTemplate, onSelectTemplate }) => {
    if (templates.length === 0) {
        return 'No templates available';
    }

    const bespokeTemplates = templates.filter(e => e.bespoke);
    const systemTemplates = templates.filter(e => !e.bespoke);

    return (
        <>
            {bespokeTemplates.length > 0 && (
                <Grid container spacing={2}>
                    <ListName name="Bespoke" />
                    {bespokeTemplates.map(template => (
                        <TemplateItem
                            key={template.id}
                            name={template.name}
                            id={template.id}
                            thumbnail={template.thumbnailUrl}
                            selectedTemplate={selectedTemplate}
                            onSelectTemplate={() => onSelectTemplate(template)}
                        />
                    ))}
                </Grid>
            )}
            {(bespokeTemplates.length > 0 && systemTemplates.length > 0) && <Divider sx={{ marginY: 4 }} />}
            {systemTemplates.length > 0 && (
                <Grid container spacing={2}>
                    <ListName name="Free" />
                    {systemTemplates.map(template => (
                        <TemplateItem
                            key={template.id}
                            name={template.name}
                            id={template.id}
                            thumbnail={template.thumbnailUrl}
                            selectedTemplate={selectedTemplate}
                            onSelectTemplate={() => onSelectTemplate(template)}
                        />
                    ))}
                </Grid>
            )}
        </>
    );
};

export default TemplatesList;