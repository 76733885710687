import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DatePicker from '../../../DatePicker';

const EditNotificationDialog = ({ notification, onHide, onConfirm }) => {

    const [subject, setSubject] = useState(notification.subject);
    const [body, setBody] = useState(notification.body);
    const [linkUrl, setLinkUrl] = useState(notification.linkUrl);
    const [linkText, setLinkText] = useState(notification.linkText);
    const [date, setDate] = useState(notification.dateTime);
    const [form, setForm] = useState(null);
    const onRefChange = useCallback(node => setForm(node), []);

    const [isValid, setIsValid] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onConfirm({ id: notification.id, subject, body, linkText, linkUrl, date });
    };

    useEffect(() => {
        setIsValid(form && form.checkValidity());
    }, [form, date, subject, body, linkText, linkUrl]);

    return (
        <Dialog onClose={onHide} open={true}>
            <form ref={onRefChange} onSubmit={handleSubmit}>
                <DialogTitle>Edit Announcement</DialogTitle>
                <DialogContent dividers>
                    <Typography sx={{ pb: 3 }}>
                        Edit a NewZapp announcement.
                    </Typography>
                    <DatePicker
                        autoFocus
                        label="Date"
                        type="date"
                        size="small"
                        fullWidth
                        value={date}
                        required
                        onChange={setDate}
                    />
                    <TextField
                        autoFocus
                        id="subject"
                        label="Subject"
                        type="text"
                        size="small"
                        fullWidth
                        value={subject}
                        required
                        onChange={e => setSubject(e.target.value)}
                    />
                    <TextField
                        id="body"
                        label="Body"
                        type="text"
                        fullWidth
                        size="small"
                        value={body}
                        required
                        onChange={e => setBody(e.target.value)}
                    />
                    <TextField
                        id="linkUrl"
                        label="LinkText"
                        type="text"
                        fullWidth
                        size="small"
                        value={linkText}
                        onChange={e => setLinkText(e.target.value)}
                    />
                    <TextField
                        id="linkUrl"
                        label="LinkUrl"
                        type="text"
                        fullWidth
                        size="small"
                        value={linkUrl}
                        onChange={e => setLinkUrl(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={!isValid}>Save Changes</Button>
                    <Button variant="outlined" onClick={onHide}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EditNotificationDialog;