/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const desktopDevice = {
    borderRadius: '10px 10px 3px 3px',
    backgroundColor: '#fff',
    border: '2px solid #e8e8e8'
};

const desktopHeader = {
    p: 2,
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    borderBottom: '1px solid #e8e8e8'
};

const mobileScreenWidth = '320px';
const mobileScreenHeight = '568px';

const mobileDevice = {
    margin: '0 auto',
    position: 'relative',
    height: '698px',
    width: '344px',
    borderRadius: '40px',
    paddingTop: '50px',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '60px',
    backgroundColor: '#fff',
    border: '2px solid #e8e8e8',
    '&::before': {
        position: 'absolute',
        top: '22px',
        left: '50%',
        marginLeft: '-30px',
        width: '60px',
        backgroundColor: '#e8e8e8',
        height: '8px',
        borderRadius: '20px',
        content: '" "',
        display: 'block'
    },
    '&::after': {
        position: 'absolute',
        bottom: '13px',
        left: '50%',
        marginLeft: '-24px',
        width: '50px',
        height: '50px',
        borderRadius: '30px',
        border: '2px solid #e8e8e8',
        content: '" "',
        display: 'block'
    }
};

const mobileScreen = {
    boxShadow: '0 0 0 1px #e8e8e8',
    borderRadius: '2px',
    overflow: 'hidden',
    height: mobileScreenHeight
};

const handleEscapeSpecialCharacters = s => s.replace(/[.*+?^${}()|\\]/g, '\\$&');

const handleEscapeSpecialCharactersIncludingSquareBrackets = s => s.replace(/[.*+?^${}()|\[\]\\]/g, '\\$&');

const handleEscapeSquareBrackets = s => s.replace(/[\[\]]/g, '\\$&');

const handleUnescapeSpecialCharacters = s => s.replace(/((\\)([.*+?^"${}()|[\]\\]))/g, '$3');

const CampaignPreview = ({ html, device, conditions, onChangeConditions }) => {
    const [doc, setDoc] = useState();
    const [availableConditions, setAvailableConditions] = useState(null);
    const [processedHtml, setProcessedHtml] = useState(html);
    const [iframeHeight, setIframeHeight] = useState(500);

    const handleResize = () => {
        const iframe = document.getElementById('myIframe');

        if (iframe) {
            const contentHeight = iframe.contentWindow.document.body.scrollHeight;
            setIframeHeight(contentHeight);
        }
    };

    const handleClickAnchorLink = e => {
        e.preventDefault();

        const name = e.target.getAttribute('href').toString().replace('#', '');
        const anchorEl = doc.querySelector(`a[name="${name}"]`);
        anchorEl && anchorEl.scrollIntoView({ behavior: 'smooth' });
    };

    const handleClickNonAnchorLink = e => {
        e.preventDefault();

        const url = e.currentTarget.getAttribute('href')?.toString();
        url && window.open(url, '_blank').focus();
    };

    const handleFindAvailableConditions = () => {
        const dynamicContentBlocks = html.match(new RegExp('<!--nz-dynamic conditions=\"(.*?)\" id=\"[A-Za-z0-9]*\"-->', 'g'));

        if (!dynamicContentBlocks || dynamicContentBlocks?.length === 0) {
            return null;
        }

        const conditions = dynamicContentBlocks.map(e => {
            const conditions = e.match('conditions="(.*?)" ')[1];

            const unescapedConditions = handleUnescapeSpecialCharacters(conditions);

            const parsedConditions = JSON.parse(unescapedConditions);

            const field = parsedConditions.find(e => e.field).field;
            const operator = parsedConditions.find(e => e.operator).operator;
            const value = parsedConditions.find(e => e.value).value;

            const id = e.match('id="(.*?)"')[0]?.split('"')[1] ?? '';

            return ({
                field,
                operator,
                value,
                key: `${field}-${operator}-${value}`,
                id,
                conditions
            });
        });

        return conditions;
    };

    const handleHideDynamicContent = contentToHide => {
        let newHtml = html;
        const blockIdsToHide = [];

        contentToHide?.map(e => e.ids?.map(id => blockIdsToHide.push(id)));

        blockIdsToHide.forEach(id => {
            const conditions = contentToHide.find(e => e.ids?.includes(id))?.conditions;

            const escapedConditions = handleEscapeSpecialCharactersIncludingSquareBrackets(conditions);

            const startComment = newHtml.match(`<!--nz-dynamic conditions="${escapedConditions}" id="${id}"-->`);
            const endComment = newHtml.match(`<!--end nz-dynamic id=\"${id}\"-->`);

            if (!startComment || !endComment) {
                return;
            }

            //re-escape special chars then square brackets
            const start = handleEscapeSpecialCharacters(startComment[0]);
            const escapedStart = handleEscapeSquareBrackets(start);

            const blockPattern = new RegExp(`${escapedStart}(.*)${endComment[0]}`);

            const block = newHtml.match(blockPattern);

            //block[0] is everything INCLUDING comment
            //block[1] is INSIDE comment only

            if (!block) {
                return;
            }

            newHtml = newHtml.replace(block[0], '');
        });

        setProcessedHtml(newHtml);
    };

    useEffect(() => {
        availableConditions && onChangeConditions(availableConditions);
    }, [availableConditions]);

    useEffect(() => {
        if (processedHtml) {
            handleResize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [processedHtml]);

    useEffect(() => {
        if (!doc || !availableConditions) {
            return;
        }

        //when conditions changes, compare to availableConditions, then remove distinct from doc
        const conditionKeys = conditions?.map(c => c.key);

        const contentToHide = conditionKeys?.length > 0 ? availableConditions.filter(e => !conditionKeys.includes(e.key)) : availableConditions;

        handleHideDynamicContent(contentToHide);
    }, [availableConditions, conditions]);

    useEffect(() => {
        if (!html) {
            return;
        }

        //find all nz-dynamic comments
        const conditions = handleFindAvailableConditions();

        if (!conditions) {
            return;
        }

        //dedupe by key
        const availableConditions = [...conditions.reduce((map, { field, operator, value, key, conditions }) => map.set(key, ({ field, operator, value, key, conditions })), new Map()).values()];

        //add ids
        const availableConditionsWithIds = availableConditions.map(e => {
            const ids = conditions?.filter(c => c.key === e.key)?.map(c => c.id);

            return { ...e, ids };
        });

        conditions && setAvailableConditions(availableConditionsWithIds);
    }, [html]);

    useEffect(() => {
        if (!doc) {
            return;
        }

        //handle links and anchors
        const anchorLinks = doc.querySelectorAll('a[href^="#"]');
        anchorLinks.forEach(a => a.addEventListener('click', handleClickAnchorLink));

        const nonAnchorLinks = doc.querySelectorAll('a:not([href^="#"])');
        nonAnchorLinks.forEach(a => a.addEventListener('click', handleClickNonAnchorLink));

        return () => {
            anchorLinks.forEach(a => a.removeEventListener('click', handleClickAnchorLink));
            nonAnchorLinks.forEach(a => a.removeEventListener('click', handleClickNonAnchorLink));
        };
    }, [doc]);

    useEffect(() => {
        const iframe = document.getElementById('myIframe');

        if (iframe) {
            const contentHeight = iframe.contentWindow.document.body.scrollHeight;
            setIframeHeight(contentHeight);
        }
    }, [processedHtml]);

    if (device === 'desktop') {
        return (
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={11} md={10} xl={11}>
                    <Box sx={desktopDevice}>
                        <Box sx={desktopHeader}>
                            <Skeleton animation={false} variant="circular" width={25} height={25} />
                            <Skeleton animation={false} variant="text" height={10} width={180} />
                        </Box>
                        <Box sx={{ overflow: 'hidden', width: '100%' }}>
                            <iframe
                                id="myIframe"
                                srcDoc={processedHtml}
                                style={{
                                    width: '100%',
                                    height: `${iframeHeight}px`,
                                    border: 'none',
                                    overflow: 'hidden',
                                    display: 'block'
                                }}
                                onLoad={(e) => {
                                    const doc = e.target.contentWindow.document;
                                    setIframeHeight(doc.body.scrollHeight);
                                    doc.body.style.overflow = 'hidden';
                                    doc.documentElement.style.overflow = 'hidden';

                                    doc.body.style.maxWidth = '100%';
                                    doc.body.style.boxSizing = 'border-box';
                                    setDoc(doc);
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        );
    }

    return (
        <Box sx={mobileDevice}>
            <Box sx={mobileScreen}>
                <iframe
                    srcDoc={processedHtml}
                    width={mobileScreenWidth}
                    height={mobileScreenHeight}
                    onLoad={e => setDoc(e.target.contentWindow.document)}
                />
            </Box>
        </Box>
    );
};

export default CampaignPreview;