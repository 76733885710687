import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const UnsubscribeSuppressContactsDialog = ({ onClose, onConfirm, titleText, bodyText }) => {

    const handleConfirm = e => {
        e.preventDefault();
        onConfirm();
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>{titleText} Contacts</DialogTitle>
            <DialogContent>
                <Alert severity="warning">
                    {bodyText}
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>Confirm {titleText}</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnsubscribeSuppressContactsDialog;