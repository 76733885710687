import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const EditFeature = ({ shown, onHide, onSubmit, onLoading, data }) => {
    const [formData, setFormData] = useState({
        id: data.id,
        name: data.name,
        description: data.description,
        level: data.level,
        alias: data.alias
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await onSubmit(formData);
    };

    return (
        <>
            <Dialog
                open={shown}
                onClose={onHide}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>Edit Feature</DialogTitle>
                <DialogContent dividers>
                    <FormGroup>
                        <TextField
                            fullwidth
                            type="email"
                            variant="outlined"
                            size="small"
                            label="Feature Name"
                            autoComplete="off"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Feature name"
                            name="name"
                        />
                        <TextField
                            fullwidth
                            type="text"
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            value={data.alias}
                            name="Alias"
                            label="Feature Alias"
                            disabled
                        />
                        <TextField
                            fullwidth
                            multiline
                            rows={4}
                            type="email"
                            variant="outlined"
                            size="small"
                            label="Description"
                            autoComplete="off"
                            value={formData.description}
                            onChange={handleInputChange}
                            name="description"
                        />
                        <InputLabel>Select Level</InputLabel>
                        <Select
                            value={formData.level}
                            label="Select Level"
                            onChange={handleInputChange}
                            name="level"
                        >
                            <MenuItem value={0}>Internal Testing</MenuItem>
                            <MenuItem value={1}>Public Testing</MenuItem>
                            <MenuItem value={2}>Production</MenuItem>
                        </Select>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onHide} variant="outlined">
                        Close
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        endIcon={onLoading ? <CircularProgress size={16} color="inherit" /> : null}
                    >
                        {onLoading ? 'Working' : 'Save Changes'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditFeature;