import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import WebIcon from '@mui/icons-material/Web';
import EmailIcon from '@mui/icons-material/Email';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TopicIcon from '@mui/icons-material/Topic';
import { format } from 'date-fns';
import Topic from '../topics/Topic';
import Chip from '@mui/material/Chip';
import PollIcon from '@mui/icons-material/Poll';

const TopicsPopper = ({ topics }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'Topics' : undefined;

    return (
        <>
            <Button aria-describedby={id} onClick={handleClick} endIcon={<TopicIcon />} size="small" variant="outlined">
                {topics.length}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box p={1}>
                    {topics.map(t => <Topic key={t.id} name={t.name} colour={t.colour} />)}
                </Box>
            </Popover>
        </>
    );
};

const QRCodeAssignedContent = ({ contentItems, onOpenContent, onSetContentToDelete = null }) => {
    const handleClickDelete = (e, item) => {
        e.stopPropagation();
        onSetContentToDelete(item);
    };

    const handleOpenContent = (e, item) => {
        e.stopPropagation();
        !item.campaignDeleted && onOpenContent(item);
    };

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Subject/Title</TableCell>
                        <TableCell align="right">Topics</TableCell>
                        <TableCell align="right">Opens</TableCell>
                        <TableCell align="right">Clicks</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contentItems.map(c => {
                        const type = c.type === 'page' ? 'Landing Page' : c.type === 'survey' ? 'Survey' : 'Email';

                        return (
                            <TableRow
                                key={c.id}
                                sx={{ '&:last-child td': { border: 0 } }}
                                onClick={e => handleOpenContent(e, c)}
                                hover={!c.campaignDeleted}
                            >
                                <TableCell sx={{ width: '2%', pt: 1 }}>
                                    <Tooltip title={type}>
                                        {c.type === 'page' ? <WebIcon color="action" /> : c.type === 'survey' ? <PollIcon color="action" /> : <EmailIcon color="action" />}
                                    </Tooltip>
                                </TableCell>
                                <TableCell sx={{ width: '15%' }}>
                                    {c.startDate
                                        ? format(new Date(c.startDate), 'dd/MM/yyyy HH:mm')
                                        : '-'}
                                </TableCell>
                                <TableCell sx={{ width: '15%' }}>
                                    {c.endDate
                                        ? format(new Date(c.endDate), 'dd/MM/yyyy HH:mm')
                                        : '-'}
                                </TableCell>
                                <TableCell>
                                    {c.name ?? '-'}
                                </TableCell>
                                <TableCell>
                                    {c.title ?? '-'}
                                </TableCell>
                                <TableCell align="right" sx={{ width: '5%' }}>
                                    {c.topics?.length ? <TopicsPopper topics={c.topics} /> : 0}
                                </TableCell>
                                <TableCell align="right" sx={{ width: '5%' }}>{c.opens}</TableCell>
                                <TableCell align="right" sx={{ width: '5%' }}>{c.clicks}</TableCell>
                                <TableCell align="right" sx={{ width: '12%' }}>
                                    {c.campaignDeleted ? (
                                        <Chip label="Content Deleted" size="small" />
                                    ) : (
                                        <Tooltip title={`View ${type}`}>
                                            <IconButton
                                                onClick={e => handleOpenContent(e, c)}
                                                size="small"
                                                disabled={c.campaignDeleted}
                                            >
                                                <OpenInNewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {onSetContentToDelete && (
                                        <Tooltip title="Delete Association">
                                            <IconButton
                                                onClick={e => handleClickDelete(e, c)}
                                                size="small"
                                                sx={{ ml: 0.5 }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default QRCodeAssignedContent;