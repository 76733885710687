import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Sort = ({ sort, setSort, fullWidth = false, margin = true }) => {
    return (
        <FormControl size="small" sx={fullWidth ? null : { flexGrow: 1 }} fullWidth={fullWidth} margin={margin ? 'normal' : 'none'}>
            <InputLabel id="sort-label">Sort</InputLabel>
            <Select
                labelId="sort-label"
                id="sort"
                value={sort}
                label="Sort"
                onChange={event => setSort(event.target.value)}
            >
                <MenuItem value="alphaAsc">A to Z</MenuItem>
                <MenuItem value="alphaDesc">Z to A</MenuItem>
                <MenuItem value="dateDesc">Newest to oldest</MenuItem>
                <MenuItem value="dateAsc">Oldest to newest</MenuItem>
            </Select>
        </FormControl>
    );
};

export default Sort;