import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SuperAdminIcon = props => {
    return (
        <SvgIcon viewBox="0 0 68 68" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M20.6,8.4H9L0,20l7.3,8C5.8,22.2,11.3,8.4,20.6,8.4z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M33.5,45.2c5.5,1.8,15-3.2,15.1-6.1c0-7.8-28-0.1-37.3-7.2l6.6,8.8
		C20.3,36.9,33.3,38.8,33.5,45.2z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M43.6,49.2c-6.1,1.7-12.8,1.7-19.6-0.1l9.3,10.6L43.6,49.2z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M60.2,10.6L60,21L44.9,21c-0.3-4.8-4-8.9-13.5-8.6c-6.7,0.1-12.3,2-13.2,6.2
		c-2.4,11.1,34.3-1.5,39.3,12.7L68,20L60.2,10.6z" />
        </SvgIcon>
    );
};

export default SuperAdminIcon;