import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FilteredGroupsSelector from '../../contacts/FilteredGroupsSelector';

const CopyContactsDialog = ({ onClose, onConfirm, onCreateGroup }) => {
    const [selectedGroups, setSelectedGroups] = useState([]);

    const handleConfirm = e => {
        e.preventDefault();
        onConfirm(selectedGroups);
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Add Contacts to Group(s)</DialogTitle>
            <FilteredGroupsSelector
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                spacing={3}
                popperGutters={3}
                onCreateGroup={onCreateGroup}
            />
            <DialogActions>
                <Button onClick={handleConfirm} disabled={selectedGroups.length === 0}>Confirm</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CopyContactsDialog;