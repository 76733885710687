import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const SplashScreen = () => {
    return (
        <Backdrop sx={{ backgroundColor: '#fff' }} open={true}>
            <CircularProgress color="primary" />
        </Backdrop>
    );
};

export default SplashScreen;