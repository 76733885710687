import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import config from '../../config';

const { AUTH0_AUDIENCE: audience, AUTH0_SCOPE: scope } = config;

const getEditorConnection = getAccessTokenSilently => {
    const connection = new HubConnectionBuilder()
        .withUrl('/hubs/editor', {
            accessTokenFactory: () =>
                getAccessTokenSilently({
                    audience,
                    scope
                })
        })
        .withAutomaticReconnect()
        .build();

    return connection;
};

const stopEditorConnection = connection => {
    if (connection && connection.state === HubConnectionState.Connected) {
        try {
            connection.stop();
        }
        catch (err) {
            console.error(err);
        }
    }
};

const useEditorHubConnection = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [connection, setConnection] = useState(null);
    const [usersEditing, setUsersEditing] = useState([]);

    useEffect(() => {
        const connection = getEditorConnection(getAccessTokenSilently);
        setConnection(connection);

        return () => {
            stopEditorConnection(connection);
        };
    }, []);

    return { connection, usersEditing, setUsersEditing };
};

export default useEditorHubConnection;
