const buildUrl = (url, params) => {
    if (params) {
        const query = Object.entries(params)
            // eslint-disable-next-line no-unused-vars
            .filter(([key, value]) => value !== null)
            .map(([key, value]) => {
                if (Array.isArray(value)) {
                    return value.map((item, i) => {
                        if ((typeof item === 'object')) {
                            let props = [];

                            for (const prop in item) {
                                props.push(`${prop}=${item[prop]}`);
                            }

                            return props.map(prop => `${encodeURIComponent(key)}[${i}].${prop}`).join('&');
                        }
                        else {
                            return `${encodeURIComponent(key)}=${encodeURIComponent(item)}`;
                        }
                    }).join('&');
                }

                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            })
            .join('&');

        return [url, query].join('?');
    }

    return url;
};

export default buildUrl;