import React from 'react';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

const PseudoGroup = ({ text, count, icon, selectedItem, setSelectedItem, lastItem = false }) => {
    return (
        <ListItem
            disablePadding
            sx={lastItem ? null : { borderBottom: '1px solid #E0E0E0' }}
        >
            <ListItemButton
                dense
                disableGutters
                selected={selectedItem}
                onClick={setSelectedItem}
            >
                <ListItemAvatar
                    sx={{ minWidth: 'unset', marginX: 1.5 }}
                >
                    <Avatar>
                        {icon}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={text}
                    primaryTypographyProps={{ sx: { fontWeight: 500 } }}
                    secondary={(
                        <React.Fragment>
                            <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                <PersonIcon sx={{ fontSize: 'inherit', marginRight: 0.5 }} />
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {count.toLocaleString()}
                                </Typography>
                            </Box>
                        </React.Fragment>
                    )}
                />
            </ListItemButton>
        </ListItem>
    );
};

export default PseudoGroup;