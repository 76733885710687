import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import buildUrl from '../buildUrl';
import { format } from 'date-fns';
import Skeleton from '@mui/material/Skeleton';
import useApi from '../hooks/useApi';

const columns = [
    { name: 'emailAddress', label: 'Email Address', orderDesc: false, dataType: 'text' },
    { name: 'opens', label: 'Opens', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'firstOpen', label: 'First Opened', align: 'right', orderDesc: false, dataType: 'dateTime' },
    { name: 'clicks', label: 'Clicks', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'firstClick', label: 'First Clicked', align: 'right', orderDesc: false, dataType: 'dateTime' }
];

const renderValue = (dataType, value) => {
    if (dataType === 'boolean') return value ? 'Yes' : 'No';
    if (dataType === 'number') return value ?? 0;
    if (!value) return '-';
    if (dataType === 'dateTime') return format((new Date(value)), 'dd/MM/yyyy HH:mm');
    return value;
};

const DataRow = ({ value, dataType, align }) => {
    const renderedValue = renderValue(dataType, value);

    return (
        <TableCell align={align}>
            {renderedValue}
        </TableCell>
    );
};

const PrintReportTable = ({ campaignId, subscriberStatus, parentIsLoading, setParentIsLoading, numberOfContacts }) => {
    const [subscribers, setSubscribers] = useState(null);
    const orderBy = subscriberStatus === 'opened' ? 'opens' : 'clicks';
    const { handleGet } = useApi();

    const filterColumns = () => {
        if (subscriberStatus === 'opened') {
            return columns.filter(e => e.name === 'emailAddress' || e.name === 'opens' || e.name === 'firstOpen');
        }

        if (subscriberStatus === 'clicked') {
            return columns.filter(e => e.name === 'emailAddress' || e.name === 'clicks' || e.name === 'firstClick');
        }
    };

    const tableColumns = filterColumns();

    const handleFetchSubscribersPage = async () => {
        const params = {
            skip: 0,
            take: numberOfContacts,
            status: subscriberStatus,
            orderBy,
            isDescending: true
        };

        const url = buildUrl(`reports/${campaignId}/subscribers`, params);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setSubscribers(data);
    };

    useEffect(() => {
        handleFetchSubscribersPage();
    }, []);

    useEffect(() => {
        subscribers && setParentIsLoading(false);
    }, [subscribers]);

    if (parentIsLoading) {
        return <Skeleton variant="rectangular" height={100} />;
    }

    return (
        <>
            <Paper sx={{ margin: 3 }}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {tableColumns.map((col, i) => (
                                    <TableCell key={i} component="th" scope="row" align={col.align}>
                                        <TableSortLabel
                                            active={orderBy === col.name}
                                            direction={'desc'}
                                        >
                                            {col.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {subscribers.map(item => (
                                <TableRow
                                    key={item.id}
                                    hover={true}
                                >
                                    {tableColumns.map((col, i) => (
                                        <DataRow
                                            key={i}
                                            align={col.align}
                                            dataType={col.dataType}
                                            value={item[col.name]}
                                        />
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {parentIsLoading && <Skeleton variant="rectangular" height={100} />}
        </>
    );
};

export default PrintReportTable;