import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';

const IconTooltip = ({ title, placement = 'right' }) => {
    return (
        <Tooltip
            title={(
                <Typography
                    component="span"
                    variant="body2"
                >
                    {title}
                </Typography>
            )}
            arrow
            placement={placement}
        >
            <HelpIcon
                aria-label={title}
                fontSize="small"
                color="info"
                sx={{ display: 'block' }}
            />
        </Tooltip>
    );
};

export default IconTooltip;