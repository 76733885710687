const filters = [
    { name: 'emailAddress', displayName: 'Email Address', type: 'string', category: 'standard' },
    { name: 'dateSubscribed', displayName: 'Date Added', type: 'date', category: 'standard' },
    { name: 'title', displayName: 'Title', type: 'string', category: 'standard' },
    { name: 'firstName', displayName: 'First Name', type: 'string', category: 'standard' },
    { name: 'lastName', displayName: 'Last Name', type: 'string', category: 'standard' },
    { name: 'dateOfBirth', displayName: 'Date of Birth', type: 'date', category: 'standard' },
    { name: 'jobTitle', displayName: 'Job Title', type: 'string', category: 'standard' },
    { name: 'companyName', displayName: 'Company', type: 'string', category: 'standard' },
    { name: 'telNo', displayName: 'Telephone', type: 'string', category: 'standard' },
    { name: 'mobilePhone', displayName: 'Mobile', type: 'string', category: 'standard' },
    { name: 'address1', displayName: 'Address 1', type: 'string', category: 'standard' },
    { name: 'address2', displayName: 'Address 2', type: 'string', category: 'standard' },
    { name: 'address3', displayName: 'Address 3', type: 'string', category: 'standard' },
    { name: 'city', displayName: 'City', type: 'string', category: 'standard' },
    { name: 'county', displayName: 'County', type: 'string', category: 'standard' },
    { name: 'postcode', displayName: 'Postcode', type: 'string', category: 'standard' },
    { name: 'country', displayName: 'Country', type: 'string', category: 'standard' },
    { name: 'confirmed', displayName: 'Confirmed', type: 'bool', category: 'standard' },

    { name: 'sent', displayName: 'Sent To', type: 'range', category: 'behaviour' },
    { name: 'opened', displayName: 'Opened', type: 'range', category: 'behaviour' },
    { name: 'clicked', displayName: 'Clicked', type: 'range', category: 'behaviour' },
    { name: 'sentcampaign', displayName: 'Sent Campaign', type: 'campaign', category: 'behaviour' },
    { name: 'openedcampaign', displayName: 'Opened Campaign', type: 'campaign', category: 'behaviour' },
    { name: 'clickedcampaign', displayName: 'Clicked Campaign', type: 'campaign', category: 'behaviour' },
    {
        name: 'device',
        displayName: 'Device',
        type: 'select',
        category: 'behaviour',
        options: [
            { value: 'desktop', displayValue: 'Desktop' },
            { value: 'mobile', displayValue: 'Mobile' },
            { value: 'tablet', displayValue: 'Tablet' }
        ]
    },
    {
        name: 'shared',
        displayName: 'Shared via',
        type: 'select',
        category: 'behaviour',
        options: [
            { value: 'facebook', displayValue: 'Facebook' },
            { value: 'twitter', displayValue: 'Twitter' },
            { value: 'linkedin', displayValue: 'LinkedIn' }
        ]
    }
];

const types = [
    {
        name: 'string',
        defaultValue: '',
        operators: [
            { value: 'contains', name: 'Containing' },
            { value: 'startsWith', name: 'Starting' },
            { value: 'equals', name: 'Matching' },
            { value: 'notEquals', name: 'Is Not' },
            { value: 'endsWith', name: 'Ending' },
            { value: 'notContains', name: 'Not Containing' }
        ]
    },
    {
        name: 'number',
        defaultValue: 0,
        operators: [
            { value: 'equals', name: 'Equals' },
            { value: 'lessThan', name: 'Less than' },
            { value: 'lessThanOrEqual', name: 'Less than or Equal to' },
            { value: 'greaterThan', name: 'Greater than' },
            { value: 'greaterThanOrEqual', name: 'Greater than or Equal to' }
        ]
    },
    {
        name: 'bool',
        defaultValue: false,
        operators: [
            { value: 'equals', name: 'Is' }
        ]
    },
    {
        name: 'date',
        defaultValue: new Date(),
        operators: [
            { value: 'equals', name: 'On' },
            { value: 'greaterThan', name: 'After' },
            { value: 'greaterThanOrEqual', name: 'On or After' },
            { value: 'lessThan', name: 'Before' },
            { value: 'lessThanOrEqual', name: 'On or Before' }
        ]
    },
    {
        name: 'range',
        defaultValue: 0,
        operators: [
            { value: 'ever', name: 'Ever' },
            { value: 'equals', name: 'In last X Days' },
            { value: 'notEquals', name: 'Not in last X Days' },
            { value: 'never', name: 'Never' }
        ]
    },
    {
        name: 'select',
        defaultValue: null,
        operators: [
            { value: 'equals', name: 'Is' },
            { value: 'notEquals', name: 'Is Not' }
        ]
    },
    {
        name: 'campaign',
        defaultValue: null,
        operators: [
            { value: 'equals', name: 'True' },
            { value: 'notEquals', name: 'False' }
        ]
    }
];

export { filters, types };