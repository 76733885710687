import React from 'react';
import Chip from '@mui/material/Chip';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

const ThemedChip = ({ chipProps, colour }) => {
    const defaultTheme = createTheme();

    const theme = createTheme({
        palette: {
            neutral: defaultTheme.palette.augmentColor({
                color: {
                    main: colour || '#EEE'
                }
            })
        }
    });

    if (!colour) {
        return (
            <Chip {...chipProps} />
        );
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Chip
                    {...chipProps}
                    color="neutral"
                />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default ThemedChip;