import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { setLicenseKey } from 'survey-core';
import useApi from '../hooks/useApi';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import useSnackbar from '../hooks/useSnackbar';
import './surveyComponents/css/surveyTheme.css';
import themeJson from './surveyComponents/surveyTheme';
import { ReactElementFactory } from 'survey-react-ui';
import SurveySend from './surveyComponents/SurveySend';
import buildUrl from '../buildUrl';
import { SvgRegistry } from 'survey-core';
import config from '../config';

const { SURVEY_API_KEY } = config;

setLicenseKey(SURVEY_API_KEY);

const customIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" title="ScreenRotation" width="24" height="24"><path d="M16.48 2.52c3.27 1.55 5.61 4.72 5.97 8.48h1.5C23.44 4.84 18.29 0 12 0l-.66.03 3.81 3.81zm-6.25-.77c-.59-.59-1.54-.59-2.12 0L1.75 8.11c-.59.59-.59 1.54 0 2.12l12.02 12.02c.59.59 1.54.59 2.12 0l6.36-6.36c.59-.59.59-1.54 0-2.12zm4.6 19.44L2.81 9.17l6.36-6.36 12.02 12.02zm-7.31.29C4.25 19.94 1.91 16.76 1.55 13H.05C.56 19.16 5.71 24 12 24l.66-.03-3.81-3.81z" /></svg>';
SvgRegistry.registerIconFromSvg('icon-device-rotate', customIcon);

const creatorOptions = {
    showLogicTab: true,
    isAutoSave: true,
    showDesignerTab: true,
    showTestSurveyTab: true,
    showTranslationTab: false,
    showJSONEditorTab: false,
    showThemeTab: true,
    questionTypes: ['text', 'checkbox', 'radiogroup', 'dropdown', 'boolean', 'comment', 'tagbox', 'image', 'matrix', 'matrixdropdown', 'matrixdynamic', 'multipletext', 'panel', 'paneldynamic', 'rating', 'ranking']
};

const SurveyEditor = () => {
    const { id } = useParams();
    const history = useHistory();
    const [survey, setSurvey] = useState({});
    const { handleGet, handlePut, handlePost } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [showSendDialog, setShowSendDialog] = useState(false);
    const { showSnackbar } = useSnackbar();

    const handleLoadSurvey = async () => {
        const response = await handleGet(`surveys/${id}`);

        if (!response.ok) {
            console.error(response?.error);
            history.push({
                pathname: '/editor',
                state: { type: 'survey' }
            });
            return;
        }

        const data = await response.json();

        setSurvey(data);
        setIsLoading(false);
    };

    const handleLoad = async () => {
        await handleLoadSurvey();
        // setIsLoading(false);
    };

    const handleSendSurvey = async () => {
        setIsLoading(true);
        const templateUrl = buildUrl('templates', { type: 'survey' });

        try {
            const template = await handleGet(templateUrl);

            if (!template.ok) {
                showSnackbar('Error fetching template', 'error');
                setIsLoading(false);
                return;
            }

            const templateData = await template.json();

            const response = await handlePost('/emails', {
                surveyId: survey.id,
                name: survey.name,
                templateId: templateData[0].id,
                type: 'survey'
            });

            const data = await response.json();

            if (!response.ok) {
                showSnackbar('Error sending survey', 'error');
            }
            else {
                history.push(`/editor/email/${data.id}`);
            }
        }
        catch (error) {
            showSnackbar('An unexpected error occurred', 'error');
        }
        finally {
            setIsLoading(false);
        }
    };

    ReactElementFactory.Instance.registerElement(
        'svc-back-tab',
        () => {
            history.push({
                pathname: '/editor',
                state: { type: 'survey' }
            });
        }
    );

    ReactElementFactory.Instance.registerElement(
        'svc-copy-link-tab',
        () => {
            const baseUrl = window.location.origin;
            const link = `${baseUrl}/#/surveys/${survey.id}/${survey.verifier}`;

            navigator.clipboard.writeText(link);
            showSnackbar('Link copied to clipboard', 'success');
        }
    );

    ReactElementFactory.Instance.registerElement(
        'svc-distribute-tab',
        () => {
            setShowSendDialog(true);
        }
    );

    const backTabPlugin = {
        // eslint-disable-next-line no-empty-function
        activate: () => { },
        deactivate: () => { return true; }
    };

    const distributeTabPlugin = {
        // eslint-disable-next-line no-empty-function
        activate: () => { },
        deactivate: () => { return true; }
    };

    const copyLinkTabPlugin = {
        // eslint-disable-next-line no-empty-function
        activate: () => { },
        deactivate: () => { return true; }
    };

    const handleSaveSurveyJson = async (json, saveNumber) => {
        const response = await handlePut(`surveys/${id}`, {
            json: JSON.stringify(json)
        });

        if (!response.ok) {
            console.error(response.statusText, saveNumber);
            showSnackbar('Cannot save survey', 'error');
        }
    };

    const saveSurveyTheme = async (theme, saveNumber) => {
        const response = await handlePut(`surveys/${id}`, {
            theme: JSON.stringify(theme)
        });

        if (!response.ok) {
            console.error(response.statusText, saveNumber);
            showSnackbar('Cannot save survey', 'error');
        }
    };

    const creator = new SurveyCreator(creatorOptions);
    creator.theme = survey.theme ? JSON.parse(survey.theme) : themeJson;
    creator.text = survey.json;

    creator.addPluginTab('backTab', backTabPlugin, 'Dashboard', 'svc-back-tab', 0);
    creator.addPluginTab('copyTab', copyLinkTabPlugin, 'Copy Link', 'svc-copy-link-tab');
    creator.addPluginTab('sendTab', distributeTabPlugin, 'Distribute', 'svc-distribute-tab');

    creator.saveSurveyFunc = (saveNumber) => {
        handleSaveSurveyJson(
            creator.JSON,
            saveNumber,
        );
    };

    creator.saveThemeFunc = (saveNumber) => {
        saveSurveyTheme(
            creator.theme,
            saveNumber
        );
    };

    useEffect(() => {
        const loadData = async () => {
            await handleLoad();
            setIsLoading(false);
        };

        loadData();
    }, []);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <Box sx={{ height: 'calc(100vh - 64px)' }}>
                <SurveyCreatorComponent creator={creator} />
            </Box>
            {showSendDialog && (
                <SurveySend
                    open={showSendDialog}
                    onHide={() => setShowSendDialog(false)}
                    data={survey}
                    onSend={handleSendSurvey}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};

export default SurveyEditor;