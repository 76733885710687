import React, { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Filter from '../filters/Filter';
import IconButton from '@mui/material/IconButton';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Badge from '@mui/material/Badge';
import useTheme from '@mui/material/styles/useTheme';
import { styled } from '@mui/material/styles';
import AlertBar from '../alertBar/AlertBar';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material/';
import { lighten } from '@mui/system';

const StyledAlert = styled(Alert)(() => ({
    '.MuiAlert-message': {
        minWidth: '100%'
    }
}));

const Filters = ({
    open,
    isSegment,
    filters,
    setFilters: onChangeFilters,
    onClose,
    contactsCount,
    contactsTotalCount,
    suppressedCount,
    customFields,
    unsavedChangesToSegment,
    isLoading,
    onUpdateSegment: onSaveSegment,
    onRevertSegmentChanges,
    condition,
    onChangeCondition
}) => {
    const theme = useTheme();
    const [newFilter, setNewFilter] = useState(null);

    const handleApplyFilter = filter => {
        const newFilters = filters ? [...filters] : [];
        newFilters[filter.order] = { ...newFilters[filter.order], ...filter };
        onChangeFilters(newFilters);
    };

    const handleDeleteFilter = index => {
        const newFilters = filters.filter((f, i) => i !== index);

        if (newFilters.length === filters.length) {
            return;
        }

        onChangeFilters(newFilters);
    };

    const handleAddNewFilter = () => {
        setNewFilter({ field: 'emailAddress', operator: 'contains', value: '' });
    };

    useEffect(() => {
        setNewFilter(null);
    }, [filters]);

    const FilteredResultInfo = ({ contactsCount, contactsPercentage, contactsTotalCount, suppressedCount }) => {
        const percentageRounded = Number.isInteger(contactsPercentage) ? contactsPercentage : Math.round(contactsPercentage);

        if (isLoading) {
            return (
                <CircularProgress />
            );
        }

        return (
            <Stack direction="row" spacing={1} alignItems="center">
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress
                        variant="determinate"
                        value={100}
                        sx={{
                            color: theme.palette.primary.light,
                            opacity: 0.25,
                            position: 'absolute', left: 0
                        }}
                    />
                    <CircularProgress variant="determinate" value={percentageRounded} />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography variant="caption" component="div" color="text.secondary" sx={{ letterSpacing: 0 }}>
                            {`${percentageRounded}%`}
                        </Typography>
                    </Box>
                </Box>
                <Chip label={`${(contactsCount ?? 0).toLocaleString()} of ${(contactsTotalCount ?? 0).toLocaleString()} Contact${contactsTotalCount === 1 ? '' : 's'}`} />
                <Chip label={`including ${(suppressedCount ?? 0).toLocaleString()} Suppressed`} />
            </Stack>
        );
    };

    const filteredContactsPercentage = (contactsCount / contactsTotalCount * 100) || 0;

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            variant="temporary"
            ModalProps={{
                slotProps: {
                    backdrop: {
                        invisible: true
                    }
                }
            }}
        >
            <Toolbar />
            <Box sx={{
                overflow: 'auto',
                minWidth: 840
            }}>
                <AppBar
                    position="sticky"
                    color="inherit"
                    sx={{ zIndex: theme.zIndex.appBar + 1 }}
                >
                    <Toolbar>
                        <Box sx={{ flexGrow: 1 }}>
                            <Badge badgeContent={filters?.filter(f => f.field).length} color="primary">
                                <Typography variant="h6">
                                    Filters
                                </Typography>
                            </Badge>
                        </Box>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <AlertBar
                    shown={unsavedChangesToSegment}
                    action={(
                        <Button
                            onClick={onSaveSegment}
                            size="small"
                            disabled={isLoading}
                        >
                            Save changes
                        </Button>
                    )}
                    secondaryAction={(
                        <Button
                            onClick={onRevertSegmentChanges}
                            size="small"
                            disabled={isLoading}
                            variant="outlined"
                        >
                            Revert
                        </Button>
                    )}
                >
                    {isLoading ? 'Updating...' : 'You have unsaved changes.'}
                </AlertBar>
                <StyledAlert severity="info" icon={false}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Box sx={{ flexGrow: 1 }}>
                            <FilteredResultInfo
                                contactsCount={contactsCount}
                                contactsTotalCount={contactsTotalCount}
                                contactsPercentage={filteredContactsPercentage}
                                suppressedCount={suppressedCount}
                            />
                        </Box>
                        <Button
                            onClick={() => onChangeFilters([])}
                            variant="outlined"
                            startIcon={<FilterListOffIcon />}
                            disabled={!filters || filters.length === 0}
                        >
                            Clear Filters
                        </Button>
                    </Stack>
                </StyledAlert>
                <Box sx={{ padding: 2 }}>
                    <FormControl
                        size="small"
                        margin="none"
                    >
                        <FormLabel>Condition</FormLabel>
                        <RadioGroup
                            name="radio-buttons-group"
                            value={condition}
                            onChange={e => onChangeCondition(e.target.value)}
                        >
                            <FormControlLabel
                                value="and"
                                control={<Radio />}
                                label={(
                                    <Typography>
                                        <Chip label={<code>AND</code>} sx={{ backgroundColor: lighten(theme.palette.primary.light, 0.7) }} size="small" /> - A contact will be included if it matches <Chip label={<code>ALL</code>} sx={{ backgroundColor: lighten(theme.palette.success.light, 0.7) }} size="small" /> of the filters
                                    </Typography>
                                )}
                            />
                            <FormControlLabel
                                value="or"
                                control={<Radio />}
                                label={(
                                    <Typography>
                                        <Chip label={<code>OR</code>} sx={{ backgroundColor: lighten(theme.palette.primary.light, 0.7) }} size="small" /> - A contact will be included if it matches <Chip label={<code>ANY</code>} sx={{ backgroundColor: lighten(theme.palette.success.light, 0.7) }} size="small" /> of the filters
                                    </Typography>
                                )}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box sx={{ padding: 2 }}>
                    {filters && filters.map((filter, index) => (
                        <Filter
                            key={index}
                            isFirst={filters.length >= 1 && index === 0}
                            filter={filter}
                            onApplyFilter={newFilter => handleApplyFilter({ ...newFilter, order: index })}
                            onDeleteFilter={() => handleDeleteFilter(index)}
                            isSegment={isSegment}
                            numberOfFiltersApplied={filters.length}
                            customFields={customFields}
                        />
                    ))}
                    {newFilter && (
                        <Filter
                            isFirst={(filters?.length ?? 0) === 0}
                            filter={newFilter}
                            onApplyFilter={newFilter => handleApplyFilter({ ...newFilter, order: filters?.length ?? 0 })}
                            isSegment={isSegment}
                            numberOfFiltersApplied={filters?.length ?? 0}
                            customFields={customFields}
                        />
                    )}
                    <Button
                        startIcon={<AddIcon />}
                        onClick={handleAddNewFilter}
                        disabled={newFilter}
                    >
                        Add Filter
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
};

export default Filters;