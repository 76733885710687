import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import NavigationContainer from '../navigation/NavigationContainer';
import BarChartIcon from '@mui/icons-material/BarChart';
import WebIcon from '@mui/icons-material/Web';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { format } from 'date-fns';
import CreateFolderDialog from './dialogs/CreateFolderDialog';
import RenameItemDialog from './dialogs/RenameItemDialog';
import DeleteItemDialog from './dialogs/DeleteItemDialog';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import buildUrl from '../buildUrl';
import TreeView from '@mui/lab/TreeView';
import TreeItem from './TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import useApi from '../hooks/useApi';
import { printStyleHide } from '../theme/constants';

const FolderItem = ({ item, numberOfItems, children, onDrop, onOpenMenu }) => {
    const ref = useRef(null);

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: ['campaign', 'folder'],
        drop: (dropItem, monitor) => {
            const didDrop = monitor.didDrop();
            if (didDrop) return;
            return { navigationId: item.navigationId };
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
            canDrop: monitor.canDrop()
        })
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'folder',
        item: { navigationId: item.navigationId, parentId: item.parentId },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            dropResult && onDrop(item, dropResult.navigationId);
        }
    });

    drag(drop(ref));

    const renderLabel = () => {
        const label = numberOfItems === 1 ? `${item.name} (1 item)` : `${item.name} (${numberOfItems} items)`;

        return (
            <Box
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                <Typography
                    sx={{ display: 'flex', flex: 1 }}
                >
                    {label}
                </Typography>
                <IconButton
                    onClick={e => onOpenMenu(e, item)}
                    sx={{ display: 'flex', flex: 0 }}
                    size="small"
                >
                    <MoreVertIcon fontSize="small" />
                </IconButton>
            </Box>
        );
    };

    const opacity = isDragging ? 0.4 : 1;
    const backgroundColor = canDrop && isOver ? 'rgba(99, 141, 46, 0.2)' : 'inherit';

    return (
        <TreeItem
            nodeId={item.navigationId.toString()}
            label={renderLabel()}
            ref={ref}
            sx={{ opacity, backgroundColor }}
            //isOver
        >
            {children}
        </TreeItem>
    );
};

const CampaignItem = ({ item, onDrop, onOpenMenu }) => {
    const history = useHistory();

    const [{ isDragging }, drag] = useDrag({
        type: 'campaign',
        item: { navigationId: item.navigationId, parentId: item.parentId },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            dropResult && onDrop(item, dropResult.navigationId);
        }
    });

    const opacity = isDragging ? 0.5 : 1;

    const renderLabel = () => {
        const dateTimeAsString = item.dateTime ? format((new Date(item.dateTime)), 'dd/MM/yyyy HH:mm') : '-';

        return (
            <Box
                sx={{ display: 'flex', alignItems: 'center' }}
                onClick={() => history.push(`/reports/${item.type}/${item.id}`)}
            >
                <Box
                    sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}
                >
                    <Typography
                        variant="body1"
                    >
                        {item.name}
                    </Typography>
                    <Typography
                        variant="body2"
                    >
                        {item.type === 'page' ? 'Title' : 'Subject'}: {item.title}
                    </Typography>
                    <Typography
                        variant="caption"
                    >
                        {dateTimeAsString}
                    </Typography>
                </Box>
                {item.type === 'email' && (
                    <IconButton
                        onClick={e => onOpenMenu(e, item)}
                        sx={{ display: 'flex', flex: 0 }}
                        size="small"
                    >
                        <MoreVertIcon fontSize="small" />
                    </IconButton>
                )}
            </Box>
        );
    };

    const icon = item.type === 'email' ? item.status === 'Scheduled' ? <ScheduleIcon /> : <BarChartIcon /> : <WebIcon />;

    return (
        <TreeItem
            nodeId={item.navigationId.toString()}
            label={renderLabel()}
            endIcon={icon}
            ref={drag}
            sx={{ opacity }}
        />
    );
};

const ArchiveFolderItem = ({ numberOfItems, children }) => {
    const renderLabel = () => {
        const label = numberOfItems === 1 ? 'Archived (1 item)' : `Archived (${numberOfItems} items)`;

        return (
            <Typography>{label}</Typography>
        );
    };

    return (
        <TreeItem
            nodeId="archived"
            label={renderLabel()}
        >
            {children}
        </TreeItem>
    );
};

const ReportsNavigationList = ({ items, currentItems, onMoveItem, onOpenMenu }) => {
    return (
        <>
            {currentItems.map(item => {
                if (item.isFolder) {
                    const childItems = items.filter(({ parentId }) => parentId === item.navigationId);

                    return (
                        <FolderItem
                            key={item.navigationId}
                            item={item}
                            numberOfItems={childItems.length}
                            onDrop={onMoveItem}
                            onOpenMenu={onOpenMenu}
                        >
                            <ReportsNavigationList
                                items={items}
                                currentItems={childItems}
                                onMoveItem={onMoveItem}
                                onOpenMenu={onOpenMenu}
                            />
                        </FolderItem>
                    );
                }

                return (
                    <CampaignItem
                        key={item.navigationId}
                        item={item}
                        onDrop={onMoveItem}
                        onOpenMenu={onOpenMenu}
                        type={item.type}
                    />
                );
            })}
        </>
    );
};

const LoadingMessage = () => {
    return (
        <Box sx={{ textAlign: 'center' }}>
            <CircularProgress sx={{ margin: 4 }} />
        </Box>
    );
};

const ReportsNavigation = () => {
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [menu, setMenu] = useState(null);
    const [isCreateFolderDialogOpen, setIsCreateFolderDialogOpen] = useState(false);
    const [isRenameItemDialogOpen, setIsRenameItemDialogOpen] = useState(false);
    const [isDeleteItemDialogOpen, setIsDeleteItemDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState('');
    const history = useHistory();
    const routeParams = useParams();
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();

    // load items

    const handleLoadItems = async () => {
        const url = buildUrl('navigation/items');
        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        setItems(data);
        setIsLoading(false);
    };

    // move item

    const handleMoveItem = async (item, parentId) => {
        if (item.navigationId === parentId || item.parentId === parentId) {
            return;
        }

        await handlePut(`navigation/${item.navigationId}/move`, { parentId });

        handleLoadItems();
    };

    // menu

    const handleOpenMenu = (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        setMenu({
            item,
            anchorEl: e.currentTarget,
            anchorPosition: {
                top: e.clientY,
                left: e.clientX
            }
        });
    };

    const handleCloseMenu = () => {
        setMenu(null);
    };

    // create folder dialog

    const handleOpenCreateFolderDialog = item => {
        setSelectedItem(item);
        handleCloseMenu();
        setIsCreateFolderDialogOpen(true);
    };

    const handleCloseCreateFolderDialog = () => {
        setIsCreateFolderDialogOpen(false);
    };

    const handleCreateFolder = async name => {
        await handlePost('navigation/items', {
            name,
            ...(selectedItem && { parentId: selectedItem.navigationId })
        });

        handleCloseCreateFolderDialog();
        handleLoadItems();
    };

    // rename dialog

    const handleOpenRenameDialog = item => {
        setSelectedItem(item);
        handleCloseMenu();
        setIsRenameItemDialogOpen(true);
    };

    const handleCloseRenameDialog = () => {
        setIsRenameItemDialogOpen(false);
        setSelectedItem(null);
    };

    const handleRename = async name => {
        await handlePut(`navigation/${selectedItem.navigationId}/rename`, { name });

        handleCloseRenameDialog();
        handleLoadItems();
    };

    // delete dialog

    const handleOpenDeleteDialog = item => {
        setSelectedItem(item);
        handleCloseMenu();
        setIsDeleteItemDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteItemDialogOpen(false);
        setSelectedItem(null);
    };

    const handleDeleteNavigationItem = async () => {
        await handleDelete(`navigation/items/${selectedItem.navigationId}`);

        if (selectedItem.id?.toString() === routeParams.id?.toString()) {
            history.push('/reports');
        }

        handleCloseDeleteDialog();
        handleLoadItems();
    };

    // render

    useEffect(() => {
        handleLoadItems();
    }, []);

    const renderItems = () => {
        if (isLoading) {
            return (
                <LoadingMessage />
            );
        }

        const filteredItems = search
            ? items.filter(item => !item.archived && !item.isFolder && item.name && item.name.toLowerCase().includes(search.toLowerCase()))
            : items.filter(item => !item.archived).filter(item => !item.parentId || item.parentId === 0);

        const archivedItems = items.filter(item => item.archived);

        return (
            <DndProvider backend={HTML5Backend}>
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                >
                    <ReportsNavigationList
                        items={items}
                        currentItems={filteredItems}
                        onMoveItem={handleMoveItem}
                        onOpenMenu={handleOpenMenu}
                    />
                    {(!search && archivedItems.length > 0) && (
                        <ArchiveFolderItem numberOfItems={archivedItems.length}>
                            <ReportsNavigationList
                                items={items}
                                currentItems={archivedItems}
                                onMoveItem={handleMoveItem}
                                onOpenMenu={handleOpenMenu}
                            />
                        </ArchiveFolderItem>
                    )}
                </TreeView>
            </DndProvider>
        );
    };

    return (
        <Box sx={printStyleHide}>
            <NavigationContainer>
                <Toolbar>
                    <IconButton
                        onClick={handleOpenCreateFolderDialog}
                        edge="start"
                    >
                        <CreateNewFolderIcon />
                    </IconButton>
                    <OutlinedInput
                        fullWidth
                        margin="none"
                        size="small"
                        placeholder="Search campaigns..."
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        sx={{ ml: 1 }}
                        endAdornment={search && (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setSearch('')}
                                    edge="end"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )}
                    />
                </Toolbar>
                <Divider />
                {renderItems()}
            </NavigationContainer>

            {menu && (
                <Menu
                    //anchorEl={menu.anchorEl}
                    //keepMounted
                    //open={Boolean(anchorEl)}
                    anchorReference="anchorPosition"
                    anchorPosition={menu.anchorPosition}
                    open={true}
                    onClose={handleCloseMenu}
                >
                    {menu.item.isFolder && <MenuItem onClick={() => handleOpenCreateFolderDialog(menu.item)}>Add Folder</MenuItem>}
                    <MenuItem onClick={() => handleOpenRenameDialog(menu.item)}>Rename</MenuItem>
                    <MenuItem onClick={() => handleOpenDeleteDialog(menu.item)}>{menu.item.isFolder ? 'Delete' : menu.item.status === 'Scheduled' ? 'Cancel & Revert to Draft' : 'Delete & Revert to Draft'}</MenuItem>
                </Menu>
            )}

            <CreateFolderDialog
                open={isCreateFolderDialogOpen}
                onClose={handleCloseCreateFolderDialog}
                onSubmit={handleCreateFolder}
            />

            {selectedItem && (
                <RenameItemDialog
                    item={selectedItem}
                    open={isRenameItemDialogOpen}
                    onClose={handleCloseRenameDialog}
                    onSubmit={handleRename}
                />
            )}

            {selectedItem && (
                <DeleteItemDialog
                    item={selectedItem}
                    open={isDeleteItemDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    onSubmit={handleDeleteNavigationItem}
                />
            )}
        </Box>
    );
};

export default ReportsNavigation;