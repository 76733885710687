import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import DialogContentText from '@mui/material/DialogContentText';

const ChooseColumnsDialog = ({ columns, open, onClose, onSubmit }) => {
    const newColumns = columns;

    const Check = ({ title, field, visible }) => {
        const [checked, setChecked] = useState(visible);
        const label = title || field;
        const isEmailAddressColumn = label === 'Email Address';

        const toggleVisibility = fieldValue => {
            const i = newColumns.findIndex(col => col.field === fieldValue);
            newColumns[i].visible = !checked;
            setChecked(!checked);
        };

        return (
            <ListItem disablePadding dense>
                <ListItemButton
                    role={undefined}
                    onClick={() => toggleVisibility(field)}
                    dense
                    disabled={isEmailAddressColumn} // Disable the ListItemButton if it's the "Email Address" field
                >
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={checked}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-label': label }}
                            disabled={isEmailAddressColumn} // Disable the Checkbox if it's the "Email Address" field
                        />
                    </ListItemIcon>
                    <ListItemText primary={label} />
                </ListItemButton>
            </ListItem>
        );
    };

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(newColumns);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <form onSubmit={handleSubmit}>
                <DialogTitle>Choose Columns</DialogTitle>
                <DialogContent dividers={true} style={{ maxHeight: '400px' }}>
                    <DialogContentText>
                        <Grid container spacing={2}>
                            {newColumns.map(({ title, field, visible }) => {
                                return (
                                    <Grid key={field} item xs={4}>
                                        <Check title={title} field={field} visible={visible} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" type="button" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit">Apply</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ChooseColumnsDialog;
