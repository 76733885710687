import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const ArchiveGroupConfirmationDialog = ({ group, onClose, onSubmit }) => {
    const handleSubmit = async e => {
        e.preventDefault();
        await onSubmit({ ...group, type: 'archive' });
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Archive {group.name}</DialogTitle>
            <DialogContent>
                <Alert severity="warning">
                    Archived groups cannot be sent to, and are generally hidden from view.
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit}>Confirm Archive Group</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ArchiveGroupConfirmationDialog;