import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import buildUrl from '../buildUrl';
import useSearch from '../hooks/useSearch';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import CreateEmailDialog from './shared/dialogs/CreateEmailDialog';
import RenameEmailDialog from './shared/dialogs/RenameEmailDialog';
import DeleteEmailDialog from './shared/dialogs/DeleteEmailDialog';
import useApi from '../hooks/useApi';
import ContextMenu from './shared/components/ContextMenu';
import TitleBar from '../titleBar/TitleBar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const rowsPerPageOptions = [10, 20, 50];

const Templates = ({ showHamburger, onClickHamburger }) => {
    const history = useHistory();
    const [templates, setTemplates] = useState([]);
    const [templatesCount, setTemplatesCount] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [tempSearchValue, setTempSearchValue] = useSearch(setSearchValue);
    const [isLoading, setIsLoading] = useState(true);
    const [showCreateTemplateDialog, setShowCreateTemplateDialog] = useState(false);
    const [showRenameTemplateDialog, setShowRenameTemplateDialog] = useState(false);
    const [showDeleteTemplateDialog, setShowDeleteTemplateDialog] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();

    // loading

    const loadTemplates = async () => {
        const url = buildUrl('super-admin/templates', {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            searchValue
        });

        const response = await handleGet(url);
        const data = await response.json();
        setTemplates(data);
    };

    const loadTemplatesCount = async () => {
        const url = buildUrl('super-admin/templates/count', { searchValue });
        const response = await handleGet(url);
        const data = await response.json();
        setTemplatesCount(data);
    };

    // create template

    const handleOpenCreateTemplateDialog = template => {
        setSelectedTemplate(template);
        setShowCreateTemplateDialog(true);
    };

    const handleCloseCreateTemplateDialog = () => {
        setShowCreateTemplateDialog(false);
        setSelectedTemplate(null);
    };

    const handleCreateTemplate = async ({ name, customerId, type }) => {
        handleCloseCreateTemplateDialog();
        setIsLoading(true);

        const response = await handlePost('super-admin/templates', {
            name,
            customerId,
            type,
            templateId: selectedTemplate.id
        });

        const data = await response.json();

        history.push(`/superadmin/templates/${data.id}`);
    };

    // rename template

    const handleOpenRenameTemplateDialog = template => {
        setSelectedTemplate(template);
        setShowRenameTemplateDialog(true);
    };

    const handleCloseRenameTemplateDialog = () => {
        setShowRenameTemplateDialog(false);
        setSelectedTemplate(null);
    };

    const handleRenameTemplate = async ({ name }) => {
        handleCloseRenameTemplateDialog();
        setIsLoading(true);

        await handlePut(`super-admin/templates/${selectedTemplate.id}`, { name });

        await loadTemplates();
        setIsLoading(false);
    };

    // delete template

    const handleOpenDeleteTemplateDialog = template => {
        setSelectedTemplate(template);
        setShowDeleteTemplateDialog(true);
    };

    const handleCloseDeleteTemplateDialog = () => {
        setShowDeleteTemplateDialog(false);
        setSelectedTemplate(null);
    };

    const handleDeleteTemplate = async () => {
        handleCloseDeleteTemplateDialog();
        setIsLoading(true);

        await handleDelete(`super-admin/templates/${selectedTemplate.id}`);

        await loadTemplatesCount();
        await loadTemplates();
        setIsLoading(false);
    };

    // side effects

    useEffect(() => {
        (async () => {
            await loadTemplatesCount();
            await loadTemplates();
            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            //setIsLoading(true);
            await loadTemplatesCount();
            //await loadTemplates();
            //setIsLoading(false);
        })();
    }, [searchValue]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            //await loadTemplatesCount();
            await loadTemplates();
            setIsLoading(false);
        })();
    }, [searchValue, page, rowsPerPage]);

    // render

    return (
        <>
            <TitleBar showHamburger={showHamburger} onClickHamburger={onClickHamburger}>
                <Breadcrumbs>
                    <Link href="/#/superadmin">Super Admin</Link>
                    <Typography>
                        <strong>Manage Templates</strong>
                    </Typography>
                </Breadcrumbs>
            </TitleBar>
            <Paper sx={{ m: 2 }}>
                <Toolbar disableGutters sx={{ mx: 1 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <OutlinedInput
                            value={tempSearchValue}
                            onChange={e => setTempSearchValue(e.target.value)}
                            placeholder="Search Templates..."
                            size="small"
                            sx={{ mx: 1 }}
                            endAdornment={(
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setTempSearchValue('')}
                                        edge="end"
                                        disabled={!Boolean(tempSearchValue)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )}
                        />
                    </Box>
                </Toolbar>
                <Divider />

                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={{ width: '15%' }}>
                                    Company Name
                                </TableCell>
                                <TableCell component="th" scope="row" align="right" sx={{ width: '10%' }}>
                                    Customer ID
                                </TableCell>
                                <TableCell component="th" scope="row" align="left" sx={{ width: '15%' }}>
                                    Name
                                </TableCell>
                                <TableCell component="th" scope="row" align="left" sx={{ width: '10%' }}>
                                    Type
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{ width: '10%' }}>
                                    Status
                                </TableCell>
                                <TableCell sx={{ width: '5%' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templates.map(template => (
                                <TableRow key={template.id}>
                                    <TableCell align="left">{template.companyName || '-'}</TableCell>
                                    <TableCell align="right">{template.customerId || '-'}</TableCell>
                                    <TableCell align="left">{template.name}</TableCell>
                                    <TableCell align="left">{template.type}</TableCell>
                                    <TableCell align="left">{template.hidden ? 'Draft' : 'Published'}</TableCell>
                                    <TableCell align="right" sx={{ width: '10%' }}>
                                        <ContextMenu
                                            onCopy={() => handleOpenCreateTemplateDialog(template)}
                                            onEdit={() => history.push(`/superadmin/templates/${template.id}`)}
                                            onRename={() => handleOpenRenameTemplateDialog(template)}
                                            onDelete={() => handleOpenDeleteTemplateDialog(template)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={templatesCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                />
            </Paper>

            {isLoading && (
                <LoadingOverlay />
            )}

            {showCreateTemplateDialog && (
                <CreateEmailDialog
                    email={selectedTemplate}
                    emailText="Template"
                    onClose={handleCloseCreateTemplateDialog}
                    onComplete={handleCreateTemplate}
                />
            )}

            {showRenameTemplateDialog && (
                <RenameEmailDialog
                    email={selectedTemplate}
                    emailText="Template"
                    onClose={handleCloseRenameTemplateDialog}
                    onComplete={handleRenameTemplate}
                />
            )}

            {showDeleteTemplateDialog && (
                <DeleteEmailDialog
                    email={selectedTemplate}
                    emailText="Template"
                    onClose={handleCloseDeleteTemplateDialog}
                    onComplete={handleDeleteTemplate}
                />
            )}
        </>
    );
};

export default Templates;