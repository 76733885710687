import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';

import useApi from '../../../hooks/useApi';
import useSnackbar from '../../../hooks/useSnackbar';

const Domain = ({ account, onRefresh }) => {
    const subDomainArray = ['newzapp', 'bounced', 'newsletter', 'campaign'];
    const [domain, setDomain] = useState(account.sendingDomain ? account.sendingDomain.substring(account.sendingDomain.indexOf('.') + 1) : '');
    const [subDomain, setSubDomain] = useState(account.sendingDomain ? subDomainArray.indexOf(account.sendingDomain.split('.')[0]) : '');
    const { handlePut } = useApi();
    const { showSnackbar } = useSnackbar();
    const [checked, setChecked] = useState(account.sendingDomainEnabled ?? false);
    const [customSubDomain, setCustomSubDomain] = useState(account.sendingDomain ? account.sendingDomain.replace('.' + domain, '') : '');
    const [spfCheck, setSpfCheck] = useState(account.spfSubDomainCheck ?? false);

    const handleChange = async (event) => {
        event.preventDefault();
        setChecked(event.target.checked);

        const response = await handlePut(`superadmin/accounts/${account.id}/sending-domain/enabled`, {
            sendingDomainEnabled: event.target.checked
        });

        if (!response.ok) {
            showSnackbar('An error occurred.', 'error');
            return;
        }

        if (event.target.checked) {
            showSnackbar('Sending Domain Disabled', 'success');
        }
        else {
            showSnackbar('Sending Domain Enabled', 'success');
        }

        onRefresh();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let sendingSubDomain;

        if (subDomain === -1) {
            sendingSubDomain = `${customSubDomain}.${domain}`;
        }
        else {
            sendingSubDomain = `${subDomainArray[subDomain]}.${domain}`;
        }

        const response = await handlePut(`superadmin/accounts/${account.id}/sending-domain`, {
            domain: domain,
            sendingDomain: sendingSubDomain,
            spfSubDomainCheck: spfCheck
        });

        if (!response.ok) {
            showSnackbar('An error occurred.', 'error');
            return;
        }

        showSnackbar('Details saved', 'success');
        onRefresh();
    };

    useEffect(() => {
        onRefresh();
    }, [customSubDomain]);

    return (
        <>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{ px: 2 }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">
                        Sending Domain
                    </Typography>
                </Box>
                <Switch checked={checked} onChange={handleChange} />
            </Toolbar>
            <Divider />
            <Box sx={{ width: '100%', py: 1 }}>
                <Box sx={{ py: 0.25, px: 2, marginRight: 1, margin: 1, display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1, margin: 1 }}>
                        <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
                            Sending Domain
                        </Typography>
                        <TextField
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Sending Domain"
                            value={domain}
                            onChange={(e) => setDomain(e.target.value)}
                            sx={{ width: 300, marginLeft: 2 }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={handleSubmit} edge="end">
                                        <SearchIcon />
                                    </IconButton>
                                )
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
                        <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap', marginTop: 1 }}>Sending Sub-Domain</Typography>
                        {subDomain === -1 ? (
                            <>
                                <TextField
                                    label="Custom Sub-Domain"
                                    variant="outlined"
                                    size="small"
                                    value={customSubDomain}
                                    onChange={(e) => setCustomSubDomain(e.target.value.replace(/[^a-zA-Z]/g, ''))}
                                    sx={{ width: 250, marginLeft: 2 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setSubDomain('')}
                                                    edge="end"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Typography variant="subtitle1" color="textSecondary" sx={{ marginLeft: 2, marginTop: 1 }}>
                                    {`.${domain}`}
                                </Typography>
                            </>
                        ) : (
                            <FormControl fullWidth sx={{ marginLeft: 2, width: 250 }} size="small">
                                <InputLabel>Sub-Domain</InputLabel>
                                <Select
                                    value={subDomain}
                                    onChange={(e) => setSubDomain(e.target.value)}
                                    label="Sub-Domain"
                                >
                                    <MenuItem value={0}>newzapp.{domain}</MenuItem>
                                    <MenuItem value={1}>bounced.{domain}</MenuItem>
                                    <MenuItem value={2}>newsletter.{domain}</MenuItem>
                                    <MenuItem value={3}>campaign.{domain}</MenuItem>
                                    <MenuItem value={-1}>Custom Sub-Domain</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap', marginTop: 1 }}>SPF check on sub-domain</Typography>
                        <FormControl>
                            <Checkbox checked={spfCheck} onChange={e => setSpfCheck(e.target.checked)} />
                        </FormControl>
                    </Box>
                    <Button variant="contained" onClick={handleSubmit} sx={{ marginTop: 1 }}>
                        Submit
                    </Button>
                </Box>
            </Box>

        </>
    );
};

export default Domain;