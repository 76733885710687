import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router-dom';
import LoadingOverlay from '../../../loadingOverlay/LoadingOverlay';

import Domain from '../../emailAuthentication/components/Domain';
import Stepper from '../../emailAuthentication/components/Stepper';
import useApi from '../../../hooks/useApi';
import useSnackbar from '../../../hooks/useSnackbar';
import Instruction from '../../emailAuthentication/components/Instruction';

const EmailAuthentication = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [verifyLoading, setVerifyLoading] = useState(false);
    const { handleGet } = useApi();
    const [account, setAccount] = useState();
    const [boolStepper, setBoolStepper] = useState([]);
    const { showSnackbar } = useSnackbar();

    const handleFetchAccount = async () => {
        const response = await handleGet(`superadmin/accounts/${id}`);

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        const steps = ['mxcheck', 'spf', 'dkim', 'dmarc'];
        const dateStatusArray = steps.map(field => !!data[field]);

        setBoolStepper(dateStatusArray);

        setAccount(data);
    };

    const handleSubmit = async () => {
        setVerifyLoading(true);

        const response = await handleGet(`superadmin/accounts/${id}/email-authentication`);

        const data = await response.json();

        let count = 0;

        for (let key in data) {
            if (data[key] === true) {
                count += 1;
            }
        }

        if (!response.ok) {
            showSnackbar('Checks not completed. Please contact support', 'error');
            setIsLoading(false);
            setVerifyLoading(false);
            return;
        }

        if (count === 4) {
            showSnackbar('All checks completed', 'success');
            setVerifyLoading(false);
        }
        else {
            showSnackbar(`${count} out of 4 checks completed`, 'warning');
            setVerifyLoading(false);
        }

        await handleFetchAccount();
    };

    useEffect(() => {
        handleFetchAccount();
    }, []);

    useEffect(() => {
        account && setIsLoading(false);
    }, [account]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <Box sx={{ px: 2 }}>
                <Paper>
                    <Stepper data={boolStepper} />
                </Paper>
                <Paper sx={{ marginTop: 2 }}>
                    <Domain account={account} onRefresh={handleFetchAccount} />
                </Paper>
                <Paper sx={{ marginTop: 2 }}>
                    <Instruction account={account} />
                    <Divider />
                </Paper>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button onClick={handleSubmit}>Verify</Button>
                </Box>
            </Box>
            {verifyLoading && <LoadingOverlay />}
        </>
    );
};

export default EmailAuthentication;