import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

const AllowedRecipients = ({ allowedRecipients, onAdd, onDelete, cellStyles }) => {

    const newIndexNewzapp = allowedRecipients.findIndex(obj => obj.pattern === 'newzapp.com');
    const newIndexOutlook = allowedRecipients.findIndex(obj => obj.pattern === 'newzapp@outlook.com');

    const sortedPatterns = [
        ...(newIndexNewzapp !== -1 ? [allowedRecipients[newIndexNewzapp]] : []),
        ...(newIndexOutlook !== -1 ? [allowedRecipients[newIndexOutlook]] : []),
        ...allowedRecipients.filter(obj => obj.pattern !== 'newzapp.com' && obj.pattern !== 'newzapp@outlook.com')
    ];

    return (
        <>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{ px: 2 }}
            >
                <div style={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">
                        Allowed Recipients
                    </Typography>
                </div>
                <Button
                    size="small"
                    onClick={onAdd}
                    startIcon={<AddIcon />}
                >
                    Add Allowed Recipient
                </Button>
            </Toolbar>
            <Divider />
            {allowedRecipients.length > 0 ? (
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Pattern</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedPatterns.map(e => (
                                <TableRow key={e.id} sx={{ backgroundColor: (e.pattern === 'newzapp.com' || e.pattern === 'newzapp@outlook.com') ? '#F5F5F5' : 'inherit' }}>
                                    <TableCell sx={cellStyles}>{e.pattern}</TableCell>
                                    <TableCell sx={cellStyles}>{e.type}</TableCell>
                                    <TableCell sx={cellStyles} align="right">
                                        <Link
                                            onClick={() => onDelete(e)}
                                            title="Delete Pattern"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Delete
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box sx={{ p: 2 }}>
                    <Typography variant="body2">This account has no allowed recipients set up.</Typography>
                </Box>
            )}
        </>
    );
};

export default AllowedRecipients;
