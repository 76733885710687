import React from 'react';
import BlockIcon from '@mui/icons-material/Block';

const SuppressedEmailCell = ({ value }) => (
    <>
        <BlockIcon sx={{ marginRight: 0.5, fontSize: 'inherit', color: 'inherit' }} />
        <em>{value}</em>
    </>
);

// data-grid defaults: width 100 type string

const baseColumns = [
    { field: 'emailAddress', headerName: 'Email Address', orderDesc: false, type: 'string', width: 300, renderCell: params => params.row.isSuppressed ? <SuppressedEmailCell value={params.value} /> : params.value },
    { field: 'dateSubscribed', headerName: 'Date Added', orderDesc: true, type: 'dateTime', width: 200, valueGetter: ({ value }) => value && new Date(value) },
    { field: 'title', headerName: 'Title', orderDesc: false, type: 'string' },
    { field: 'firstName', headerName: 'First Name', orderDesc: false, type: 'string' },
    { field: 'lastName', headerName: 'Last Name', orderDesc: false, type: 'string' },
    { field: 'dateOfBirth', headerName: 'Date of Birth', orderDesc: true, type: 'date', width: 200, valueGetter: ({ value }) => value && new Date(value) },
    { field: 'jobTitle', headerName: 'Job Title', orderDesc: false, type: 'string', width: 200 },
    { field: 'companyName', headerName: 'Company', orderDesc: false, type: 'string' },
    { field: 'telNo', headerName: 'Telephone', orderDesc: false, type: 'string', width: 150 },
    { field: 'mobilePhone', headerName: 'Mobile', orderDesc: false, type: 'string', width: 150 },
    { field: 'address1', headerName: 'Address 1', orderDesc: false, type: 'string', width: 150 },
    { field: 'address2', headerName: 'Address 2', orderDesc: false, type: 'string', width: 150 },
    { field: 'address3', headerName: 'Address 3', orderDesc: false, type: 'string', width: 150 },
    { field: 'city', headerName: 'City', orderDesc: false, type: 'string' },
    { field: 'county', headerName: 'County', orderDesc: false, type: 'string' },
    { field: 'postcode', headerName: 'Postcode', orderDesc: false, type: 'string' },
    { field: 'country', headerName: 'Country', orderDesc: false, type: 'string' },
    { field: 'isConfirmed', headerName: 'Confirmed', orderDesc: false, type: 'boolean' }
];

export default baseColumns;