import React, { useEffect } from 'react';
import useInputDate from '../../hooks/useInputDate';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import enLocale from 'date-fns/locale/en-GB';

const CustomFieldDate = ({ id, name, value, onChange, isReverting }) => {
    const input = useInputDate(value);

    useEffect(() => {
        onChange({ id, value: input.value, hasChanged: input.hasChanged });
    }, [input.value]);

    useEffect(() => {
        if (isReverting) {
            input.onRevert();
        }

    }, [isReverting]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
            <DateTimePicker
                mask="__/__/____ __:__"
                inputFormat="dd/MM/yyyy HH:mm"
                renderInput={(props) => <TextField size="small" margin="none" fullWidth {...props} />}
                label={name}
                value={input.value}
                onChange={input.onChange}
                ampm
            />
        </LocalizationProvider>
    );
};

export default CustomFieldDate;