import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ClearIcon from '@mui/icons-material/Clear';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditTopicDialog from './dialogs/EditTopicDialog';
import DeleteTopicDialog from './dialogs/DeleteTopicDialog';
import Topic from '../../topics/Topic';
import Toolbar from '@mui/material/Toolbar';
import useAccount from '../../hooks/useAccount';

const actionsCell = { width: '7%' };

const TopicMenu = ({ onDelete, onUpdate }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(null);
    };

    const handleAction = (e, action) => {
        e.stopPropagation();

        action();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                size="small"
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={e => handleAction(e, onUpdate)}>Edit</MenuItem>
                <MenuItem onClick={e => handleAction(e, onDelete)}>Delete</MenuItem>
            </Menu>
        </>
    );
};

const Topics = () => {
    const [dialog, setDialog] = useState(null);
    const { topics, handleCreateTopic, handleUpdateTopic, handleDeleteTopic } = useAccount();
    const [searchValue, setSearchValue] = useState('');
    const [filteredTopics, setFilteredTopics] = useState(topics);
    const [sort, setSort] = useState({ by: 'name', desc: false });

    const clearFilterTopics = () => {
        setSearchValue('');

        setFilteredTopics(topics);
    };

    const handleSubmitCreateTopic = async ({ name, description, colour }) => {
        await handleCreateTopic({
            name,
            description,
            colour
        });

        setDialog(null);
    };

    const handleSubmitUpdateTopic = async topic => {
        await handleUpdateTopic(topic.id, {
            name: topic.name,
            description: topic.description,
            colour: topic.colour
        });

        setDialog(null);
    };

    const handleSubmitDeleteTopic = async id => {
        await handleDeleteTopic(id);
        setDialog(null);
    };

    const handleSort = field => {
        if (field === sort.by) {
            setSort({ ...sort, desc: !sort.desc });
        }
        else {
            setSort({ by: field, desc: !sort.desc });
        }
    };

    useEffect(() => {
        let sortedTopics = filteredTopics;

        if (sort.by === 'name') {
            if (sort.desc) {
                sortedTopics.sort((a, b) => {
                    const aValue = a.name.toLowerCase();
                    const bValue = b.name.toLowerCase();

                    if (aValue < bValue) {
                        return 1;
                    }

                    if (aValue > bValue) {
                        return -1;
                    }

                    return 0;
                });
            }
            else {
                sortedTopics.sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    if (nameA < nameB) {
                        return -1;
                    }

                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
            }
        }

        if (sort.by === 'description') {
            if (sort.desc) {
                sortedTopics.sort((a, b) => {
                    const aValue = (a.description || '').toLowerCase();
                    const bValue = (b.description || '').toLowerCase();

                    if (aValue < bValue) {
                        return 1;
                    }

                    if (aValue > bValue) {
                        return -1;
                    }

                    return 0;
                });
            }
            else {
                sortedTopics.sort((a, b) => {
                    const nameA = (a.description || '').toLowerCase();
                    const nameB = (b.description || '').toLowerCase();

                    if (nameA < nameB) {
                        return -1;
                    }

                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
            }
        }

        setFilteredTopics([...sortedTopics]);
    }, [searchValue, sort, topics]);

    useEffect(() => {
        if (searchValue) {
            setFilteredTopics(topics.filter(e => e.name.toLowerCase().includes(searchValue.toLowerCase())));
        }
        else {
            setFilteredTopics(topics);
        }
    }, [topics, searchValue]);

    return (
        <>
            <Box sx={{ px: 2, my: 2 }}>
                <Toolbar disableGutters sx={{ mx: 1 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <OutlinedInput
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            placeholder="Search Topics..."
                            size="small"
                            sx={{ mx: 1 }}
                            endAdornment={(
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={clearFilterTopics}
                                        edge="end"
                                        disabled={!Boolean(searchValue)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )}
                        />
                    </Box>
                    <Button
                        onClick={() => setDialog(
                            <EditTopicDialog
                                onClose={() => setDialog(null)}
                                onSubmit={handleSubmitCreateTopic}
                            />
                        )}
                    >
                        Add Topic
                    </Button>
                </Toolbar>
                <Divider />
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row" align="left" sx={{ width: '40%' }}>
                                    <TableSortLabel
                                        active={sort.by === 'name'}
                                        direction={sort.desc ? 'desc' : 'asc'}
                                        onClick={() => handleSort('name')}
                                    >
                                        Topic
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell component="th" scope="row" align="left" sx={{ width: '40%' }}>
                                    <TableSortLabel
                                        active={sort.by === 'description'}
                                        direction={sort.desc ? 'desc' : 'asc'}
                                        onClick={() => handleSort('description')}
                                    >
                                        Description
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={actionsCell} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredTopics.map(topic => (
                                <TableRow key={topic.id}>
                                    <TableCell align="left">
                                        <Topic
                                            name={topic.name}
                                            colour={topic.colour}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        {topic.description}
                                    </TableCell>
                                    <TableCell align="right" sx={actionsCell}>
                                        <TopicMenu
                                            onUpdate={() => {
                                                setDialog(
                                                    <EditTopicDialog
                                                        onClose={() => setDialog(null)}
                                                        onSubmit={handleSubmitUpdateTopic}
                                                        topic={topic}
                                                    />
                                                );
                                            }}
                                            onDelete={() => {
                                                setDialog(
                                                    <DeleteTopicDialog
                                                        onClose={() => setDialog(null)}
                                                        onDelete={handleSubmitDeleteTopic}
                                                        topic={topic}
                                                    />
                                                );
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {dialog}
        </>
    );
};

export default Topics;