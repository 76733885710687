import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';
import useApi from '../../hooks/useApi';
import useSnackbar from '../../hooks/useSnackbar';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AlertBar from '../../alertBar/AlertBar';
import useAccount from '../../hooks/useAccount';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import AddSignUpFormDomainDialog from './AddSignUpFormDomainDialog';
import DeleteSignUpFormDomainDialog from './DeleteSignUpFormDomainDialog';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const SignUpForm = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { showSnackbar } = useSnackbar();
    const [form, setForm] = useState();
    const { handleGet, handlePost, handleDelete } = useApi();
    const [doubleOptIn, setDoubleOptIn] = useState(false);
    const { account, doubleOptIn: doubleOptedIn, handleDoubleOptInSubmit } = useAccount();
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [domains, setDomains] = useState([]);
    const [dialog, setDialog] = useState(null);

    const generateForm = async () => {
        const response = await handleGet('account/signup-form');

        if (!response.ok) {
            showSnackbar('Failed to generate form', 'error');
            return;
        }

        const data = await response.json();

        setForm(data.html);
    };

    const handleFetchDomains = async () => {
        const response = await handleGet('form-domains');

        if (!response.ok) {
            showSnackbar('Failed to fetch domains', 'error');
            return;
        }

        const data = await response.json();

        setDomains(data);
    };

    const onSubmit = async e => {
        e.preventDefault();
        await handleDoubleOptInSubmit(doubleOptIn);
        setHasUnsavedChanges(false);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(form);
        showSnackbar('Copied to clipboard', 'success');
    };

    const handleDeleteDomain = async id => {
        setDialog(null);

        const response = await handleDelete(`form-domains/${id}`);

        if (!response.ok) {
            showSnackbar('Domain not found', 'error');
            return;
        }

        handleFetchDomains();
        showSnackbar('Domain deleted', 'success');
    };

    const handleAddDomain = async domain => {
        setDialog(null);

        const response = await handlePost('form-domains', domain);

        if (!response.ok) {
            showSnackbar('Domain not added, please try again', 'error');
            return;
        }

        handleFetchDomains();
        showSnackbar('Domain added', 'success');
    };

    const handleInit = async () => {
        await handleFetchDomains();
        generateForm();
    };

    useEffect(() => {
        handleInit();
        setDoubleOptIn(doubleOptedIn);
    }, []);

    useEffect(() => {
        form && setIsLoading(false);
    }, [form]);

    useEffect(() => {
        setHasUnsavedChanges(doubleOptIn !== doubleOptedIn);
    }, [account, doubleOptIn]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AlertBar
                shown={isLoading || hasUnsavedChanges}
                action={(
                    <Button
                        variant="outlined"
                        size="small"
                        disabled={isLoading}
                        onClick={onSubmit}
                    >
                        Save Changes
                    </Button>
                )}
                positionTop={0}
            >
                {isLoading ? 'Updating...' : 'You have unsaved changes.'}
            </AlertBar>
            <Container sx={{ padding: 3 }} maxWidth="false">
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Typography>
                            Use a Sign Up form on your website to allow users to sign up to receive emails from you.
                        </Typography>
                    </Grid>
                    <Grid xs={12} xl={8}>
                        <Card>
                            <CardHeader
                                title="Allowed Domains"
                                titleTypographyProps={{ variant: 'h6' }}
                                subheader="For security reasons, only forms hosted on domains listed here will be allowed to submit data to your account"
                                action={(
                                    <Button
                                        size="small"
                                        onClick={() => setDialog(<AddSignUpFormDomainDialog domains={domains} onCancel={() => setDialog(null)} onSubmit={handleAddDomain} />)}
                                        startIcon={<AddIcon />}
                                    >
                                        Add Domain
                                    </Button>
                                )}
                            />
                            {domains.length > 0 ? (
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="70%">Domain</TableCell>
                                                <TableCell>Added</TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {domains.map(e => (
                                                <TableRow key={e.id}>
                                                    <TableCell>{e.domain}</TableCell>
                                                    <TableCell>Added {new Date(e.createdDateTime).toLocaleString('en-GB')}</TableCell>
                                                    <TableCell align="right">
                                                        <Link
                                                            onClick={() => setDialog(<DeleteSignUpFormDomainDialog domain={e.domain} onCancel={() => setDialog(null)} onSubmit={() => handleDeleteDomain(e.id)} />)}
                                                            title="Delete Domain"
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            Delete
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <CardContent>
                                    <Alert severity="error">This account has no domains allowed for forms</Alert>
                                </CardContent>
                            )}
                        </Card>
                    </Grid>
                    <Grid xs={12} xl={4}>
                        <Card>
                            <CardHeader
                                title="Double Opt-in"
                                titleTypographyProps={{ variant: 'h6' }}
                                action={(
                                    <FormGroup row sx={{ px: 1 }}>
                                        <FormControlLabel
                                            control={(
                                                <Switch
                                                    checked={doubleOptIn}
                                                    onChange={e => setDoubleOptIn(e.target.checked)}
                                                    name="doubleOptIn"
                                                />
                                            )}
                                            label={doubleOptIn ? 'Active' : 'Disabled'}
                                            labelPlacement="start"
                                        />
                                    </FormGroup>
                                )}
                            />
                            <CardContent sx={{ paddingTop: 0 }}>
                                <Typography variant="body1">
                                    Double Opt-in requires new sign-ups to confirm their subscription via an email sent to them after they submit the form.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12}>
                        <Card>
                            <CardHeader
                                title="Form Code"
                                titleTypographyProps={{ variant: 'h6' }}
                                action={(
                                    <Button
                                        onClick={copyToClipboard}
                                        startIcon={<ContentCopyIcon />}
                                    >
                                        Copy to clipboard
                                    </Button>
                                )}
                            />
                            <CardContent sx={{ paddingTop: 0 }}>
                                <SyntaxHighlighter
                                    language="markup"
                                    style={prism}
                                    wrapLongLines={true}
                                >
                                    {form}
                                </SyntaxHighlighter>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            {dialog}
        </>
    );
};

export default SignUpForm;