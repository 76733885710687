import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useApi from '../../../hooks/useApi';
import Grid from '@mui/material/Grid';

const PermissionsPicker = ({ selectedPermissions, onChange, isFirstUser = null, isEdit = false }) => {
    const [permissions, setPermissions] = useState([]);
    const { handleGet } = useApi();

    const handleGetPermissions = async () => {
        const response = await handleGet('permissions');
        const data = await response.json();
        setPermissions(data);

        if (!isEdit) {
            if (selectedPermissions.length === 0 && isFirstUser) {
                const filteredPermissions = data.filter(({ name }) =>
                    name !== 'Manage API Keys'
                );
                onChange(filteredPermissions);
            }
            else {
                const filteredPermissions = data.filter(({ name }) =>
                    name !== 'Manage Users' &&
                    name !== 'Manage Brand' &&
                    name !== 'Manage API Keys'
                );
                onChange(filteredPermissions);
            }
        }
    };

    const handleTogglePermission = p => {
        if (selectedPermissions.find(({ id }) => id === p.id)) {
            onChange([...selectedPermissions.filter(({ id }) => id !== p.id)]);
        }
        else {
            onChange([...selectedPermissions, p]);
        }
    };

    useEffect(() => {
        handleGetPermissions();
    }, []);

    useEffect(() => {
        handleGetPermissions();
    }, [isFirstUser]);

    if (permissions.length === 0) {
        return null;
    }

    return (
        <Grid sx={{ p: 2 }} container>
            {permissions.map(({ id, label, name, description }) => (
                <Grid item xs={6} key={id}>
                    <ListItem
                        key={id}
                        dense
                        disableGutters
                        disablePadding
                    >
                        <ListItemButton
                            onClick={() => {
                                if (!(name === 'Manage Users' && isFirstUser)) {
                                    handleTogglePermission({ id });
                                }
                            }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    disableRipple
                                    checked={Boolean(selectedPermissions.find(p => p.id === id))}
                                    disabled={name === 'Manage Users' && isFirstUser}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={label}
                                secondary={description}
                            />
                        </ListItemButton>
                    </ListItem>
                </Grid>
            ))}
        </Grid>
    );
};

export default PermissionsPicker;
