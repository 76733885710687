import React, { useEffect } from 'react';
import useInput from '../../hooks/useInput';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CustomFieldBool = ({ id, name, value, onChange, isReverting }) => {
    const input = useInput(value);

    useEffect(() => {
        onChange({ id, value: input.value, hasChanged: input.hasChanged });
    }, [input.value]);

    useEffect(() => {
        if (isReverting) {
            input.onRevert();
        }

    }, [isReverting]);

    return (
        <FormGroup>
            <FormControl size="small" margin="none" fullWidth>
                <InputLabel id="bool-select-label">{name}</InputLabel>
                <Select
                    labelId="bool-select-label"
                    id="bool-select"
                    value={input.value}
                    label={name}
                    onChange={input.onChange}
                    displayEmpty
                >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                </Select>
            </FormControl>
        </FormGroup>
    );
};

export default CustomFieldBool;