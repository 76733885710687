import React, { useReducer, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

const reducer = (prevState, newState) => {
    let {
        name,
        delay,
        unit,
        isValid
    } = { ...prevState, ...newState };

    isValid = name.trim() !== '';

    return {
        name,
        delay,
        unit,
        isValid
    };
};

const initialState = {
    name: '',
    delay: 0,
    unit: 'hour',
    isValid: false
};

const EditAutomationEmailDialog = ({ isOpen, onClose, onSubmit, isLoading, onNavigateToEditor, email }) => {
    const [state, setState] = useReducer(reducer, initialState);

    const handleConfirm = async e => {
        e.preventDefault();

        await onSubmit({
            ...email,
            name: state.name,
            delay: state.delay === 0 ? 0 : state.unit === 'hour' ? state.delay : state.delay * 24
        });

        onClose();
    };

    const handleConfirmAndGo = async e => {
        e.preventDefault();

        await onSubmit({
            ...email,
            name: state.name,
            delay: state.delay === 0 ? 0 : state.unit === 'hour' ? state.delay : state.delay * 24
        });

        onNavigateToEditor();
        onClose();
    };

    useEffect(() => {
        if (email) {
            setState({
                name: email.name,
                delay: email.delay
            });
        }
    }, [email]);

    const plural = state.delay !== 1 && 's';

    return (
        <Dialog onClose={onClose} open={isOpen}>
            <DialogTitle>Edit Email: Name & Delay</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    id="name"
                    label="Name"
                    fullWidth
                    value={state.name}
                    required
                    onChange={e => setState({ name: e.target.value })}
                    size="small"
                />
                <TextField
                    id="delay"
                    label="Delay"
                    value={state.delay}
                    type="number"
                    onChange={e => setState({ delay: e.target.value })}
                    size="small"
                />
                <FormControl size="small" sx={{ marginLeft: 1 }}>
                    <InputLabel id="unit-label">Unit</InputLabel>
                    <Select
                        value={state.unit}
                        onChange={e => setState({ unit: e.target.value })}
                        labelId="unit-label"
                        label="Unit"
                    >
                        <MenuItem value="hour">Hour{plural}</MenuItem>
                        <MenuItem value="day">Day{plural}</MenuItem>
                    </Select>
                </FormControl>
                <Typography sx={{ marginTop: 1 }}>
                    This email will be sent {state.delay} {state.unit}{plural} after the trigger event.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} disabled={isLoading || !state.isValid}>Save & Close</Button>
                <Button onClick={handleConfirmAndGo} disabled={isLoading || !state.isValid}>Save & Edit Content</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditAutomationEmailDialog;