import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import StripoEditor from '../../stripoEditor/StripoEditor';
import Proof from '../../editor/views/Proof';
import Setup from './Setup';
import FinalChecks from './FinalChecks';
import useSnackbar from '../../hooks/useSnackbar';
import useApi from '../../hooks/useApi';
import useTheme from '@mui/material/styles/useTheme';

const drawerWidth = 300;

const steps = [
    { name: 'Editor', description: 'Create your content' },
    { name: 'Setup', description: 'Set your sending details' },
    { name: 'Proof', description: 'Send a copy to trusted recipients (optional)' },
    { name: 'Final Checks', description: 'Last chance to right any wrongs...' }
];

const AutomationEmailEditor = () => {
    const { automationId, emailId } = useParams();
    const history = useHistory();
    const [step, setStep] = useState(0);
    const [previewHtml, setPreviewHtml] = useState('');
    const { showSnackbar } = useSnackbar();
    const [proofRecipients, setProofRecipients] = useState([]);
    const [isProofSent, setIsProofSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialising, setIsInitialising] = useState(true);
    const [automation, setAutomation] = useState(null);
    const [email, setEmail] = useState(null);
    const { handlePut, handleGet } = useApi();
    const theme = useTheme();

    const handleFetchAutomation = async () => {
        const response = await handleGet(`automations/${automationId}`);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const automation = await response.json();

        setAutomation(automation);
    };

    const handleFetchEmail = async () => {
        const response = await handleGet(`automations/${automationId}/emails/${emailId}`);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const email = await response.json();

        setEmail(email);
    };

    const titleBarDepth = theme.zIndex.drawer + 1;

    const handleUpdateEmail = async (html = null) => {
        const response = await handlePut(`automations/${automation.id}/emails/${email.id}`, {
            ...email,
            compiledHTML: html ?? previewHtml
        });

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        step === 0 && showSnackbar('Changes saved', 'success');
    };

    const handleCompleteEdit = previewHtml => {
        handleUpdateEmail(previewHtml);
        setPreviewHtml(previewHtml);
        setStep(1);
    };

    const handleInit = async () => {
        await handleFetchEmail();
        await handleFetchAutomation();
        setIsInitialising(false);
    };

    const handleSave = async () => {
        setIsLoading(true);
        await handleUpdateEmail();
        setIsLoading(false);

        history.push({
            pathname: `/automations/${automationId}`,
            state: { emailId }
        });
    };

    useEffect(() => {
        if (isInitialising) {
            return;
        }

        email?.id && handleUpdateEmail();
    }, [email]);

    useEffect(() => {
        (automationId && emailId) && handleInit();
    }, []);

    if (isInitialising || isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            {step === 0 && (
                <StripoEditor
                    email={email}
                    onUpdateEmail={props => setEmail({ ...email, ...props })}
                    onNext={handleCompleteEdit}
                    type="automationEmail"
                />
            )}
            {step === 1 && (
                <Setup
                    email={email}
                    onUpdateEmail={props => setEmail({ ...email, ...props })}
                    onNext={() => setStep(2)}
                    onBack={() => setStep(0)}
                    drawerWidth={drawerWidth}
                    steps={steps}
                    step={step}
                    isProofSent={isProofSent}
                    titleBarDepth={titleBarDepth}
                />
            )}
            {step === 2 && (
                <Proof
                    email={email}
                    html={previewHtml}
                    onNext={() => setStep(3)}
                    onBack={() => setStep(1)}
                    emailAddresses={proofRecipients}
                    setEmailAddresses={setProofRecipients}
                    drawerWidth={drawerWidth}
                    steps={steps}
                    step={step}
                    isProofSent={isProofSent}
                    setIsProofSent={setIsProofSent}
                    titleBarDepth={titleBarDepth}
                    onNavigateToEditor={() => setStep(0)}
                    campaign={null}
                    audienceName=""
                    postProofNextStepButtonText="Final Checks"
                />
            )}
            {step === 3 && (
                <FinalChecks
                    email={email}
                    previewHtml={previewHtml}
                    onBack={() => setStep(2)}
                    drawerWidth={drawerWidth}
                    steps={steps}
                    step={step}
                    isProofSent={isProofSent}
                    titleBarDepth={titleBarDepth}
                    onSaveEmail={handleSave}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};

export default AutomationEmailEditor;