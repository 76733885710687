import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';

import AddAllowedRecipientDialog from '../../accountDetails/modals/AddAllowedRecipientDialog';
import { Stack } from '@mui/material';

const AllowedRecipientsPanel = ({ formData, onFormChange }) => {
    const [allowedRecipientDialog, setAllowedRecipientDialog] = useState(false);
    const [allowedRecipientData, setAllowedRecipientData] = useState(formData.allowedRecipients);

    const newIndexNewzapp = allowedRecipientData.findIndex(obj => obj.pattern === 'newzapp.com');
    const newIndexOutlook = allowedRecipientData.findIndex(obj => obj.pattern === 'newzapp@outlook.com');

    const sortedPatterns = [
        ...(newIndexNewzapp !== -1 ? [allowedRecipientData[newIndexNewzapp]] : []),
        ...(newIndexOutlook !== -1 ? [allowedRecipientData[newIndexOutlook]] : []),
        ...allowedRecipientData.filter(obj => obj.pattern !== 'newzapp.com' && obj.pattern !== 'newzapp@outlook.com')
    ];

    const handleSubmit = (pattern, type) => {
        const newData = {
            type,
            pattern
        };

        setAllowedRecipientData([
            ...allowedRecipientData,
            newData
        ]);

        onFormChange('allowedRecipients', [...allowedRecipientData, newData]);
        setAllowedRecipientDialog(false);
    };

    const handleDelete = (index) => {
        const filteredData = allowedRecipientData.filter((_, i) => i !== index);

        setAllowedRecipientData(filteredData);
        onFormChange('allowedRecipients', filteredData);
    };

    return (
        <>
            <Stack>
                <Button sx={{ mt: 2, width: 'fit-content' }} variant="contained" onClick={() => setAllowedRecipientDialog(true)}>
                    Add Allowed Recipient
                </Button>
                {allowedRecipientData.length > 0 && (
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Pattern</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedPatterns.map((e, index) => (
                                    <TableRow key={e.pattern} sx={{ backgroundColor: (e.pattern === 'newzapp.com' || e.pattern === 'newzapp@outlook.com') ? '#F5F5F5' : 'inherit', '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>{e.pattern}</TableCell>
                                        <TableCell>{e.type}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleDelete(index)} fontSize="small">
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Stack>
            {allowedRecipientDialog && (
                <AddAllowedRecipientDialog onSubmit={handleSubmit} onCancel={() => setAllowedRecipientDialog(false)} />
            )}
        </>
    );
};

export default AllowedRecipientsPanel;