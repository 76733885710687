import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import useTheme from '@mui/material/styles/useTheme';

const Statistic = ({ title, subheader, icon, statistic, size = 'normal' }) => {
    const theme = useTheme();

    return (
        <Card
            sx={{ height: '100%' }}
            variant="outlined"
        >
            <CardHeader
                avatar={(
                    <Avatar aria-label="Reach" sx={statistic ? { bgcolor: theme.palette.info.light } : undefined}>
                        {icon}
                    </Avatar>
                )}
                title={statistic !== null ? `${Number.isInteger(statistic) ? statistic : statistic.toFixed(2)}% ${title}` : title}
                titleTypographyProps={size === 'normal' ? { variant: 'h6' } : { fontWeight: 'bold' }}
                subheader={statistic !== null ? subheader : 'No data'}
            />
        </Card>
    );
};

export default Statistic;