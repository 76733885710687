import React, { useEffect, useReducer } from 'react';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import ClearIcon from '@mui/icons-material/Clear';
import ListItem from '@mui/material/ListItem';
import { useDropzone } from 'react-dropzone';
import ConfirmDeleteDocumentDialog from './ConfirmDeleteDocumentDialog';
import UploadFiles from '../editor/UploadFiles';
import CenteredProgress from '../loadingOverlay/CenteredProgress';
import DataColumn from '../dataTable/DataColumn';
import DatePicker from '../DatePicker';
import useApi from '../hooks/useApi';

const actionsCell = { width: '7%' };

const rowsPerPageOptions = [10, 25, 50];

const reducer = (prevState, newState) => {

    let {
        isInitialising,
        isLoading,
        documents,
        filteredDocuments,
        unfilteredDocuments,
        documentsCount,
        searchValue,
        orderBy,
        orderDesc,
        uploadResult,
        documentToDelete,
        page,
        rowsPerPage,
        isUploadingFile,
        toDate,
        fromDate
    } = { ...prevState, ...newState };

    if (searchValue) {
        filteredDocuments = unfilteredDocuments.filter(e => e.name.toLowerCase().includes(searchValue.toLowerCase()));
    }
    else {
        filteredDocuments = unfilteredDocuments;
    }

    const from = fromDate ? new Date(fromDate).setHours(0, 0, 0, 0) : new Date('1970/01/01');
    const to = toDate ? new Date(toDate).setHours(23, 59, 59, 999) : new Date();

    if (toDate || fromDate) {
        filteredDocuments = filteredDocuments.filter(e => (new Date(e.lastModified) >= from && new Date(e.lastModified) <= to));
    }

    if (orderBy === 'name') {
        if (orderDesc) {
            filteredDocuments = filteredDocuments.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                if (nameA > nameB) {
                    return -1;
                }

                if (nameA < nameB) {
                    return 1;
                }

                return 0;
            });
        }
        else filteredDocuments = filteredDocuments.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();

            if (nameA < nameB) {
                return -1;
            }

            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
    }

    if (orderBy === 'size') {
        if (orderDesc) {
            filteredDocuments = filteredDocuments.sort((a, b) => b.size - a.size);
        }
        else filteredDocuments = filteredDocuments.sort((a, b) => a.size - b.size);
    }

    if (orderBy === 'lastModified') {
        if (orderDesc) {
            filteredDocuments = filteredDocuments.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
        }
        else filteredDocuments = filteredDocuments.sort((a, b) => new Date(a.lastModified) - new Date(b.lastModified));
    }

    documentsCount = filteredDocuments.length;

    if (orderBy !== prevState.orderBy || orderDesc !== prevState.orderDesc || rowsPerPage !== prevState.rowsPerPage || fromDate !== prevState.fromDate || toDate !== prevState.toDate || searchValue !== prevState.searchValue) {
        page = 0;
    }

    documents = filteredDocuments.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

    if (documents) {
        isInitialising = false;
    }

    return {
        orderBy,
        orderDesc,
        page,
        isInitialising,
        isLoading,
        searchValue,
        rowsPerPage,
        documentsCount,
        documents,
        unfilteredDocuments,
        isUploadingFile,
        uploadResult,
        documentToDelete,
        filteredDocuments,
        toDate,
        fromDate
    };
};

const initialState = {
    orderBy: 'lastModified',
    orderDesc: true,
    page: 0,
    isInitialising: true,
    isLoading: false,
    searchValue: '',
    rowsPerPage: rowsPerPageOptions[0],
    documentsCount: 0,
    unfilteredDocuments: null,
    documents: null,
    isUploadingFile: false,
    uploadResult: null,
    documentToDelete: null,
    filteredDocuments: null,
    toDate: null,
    fromDate: null
};

const columns = [
    { name: 'name', label: 'Name', orderDesc: false, dataType: 'text' },
    { name: 'lastModified', label: 'Created', align: 'right', orderDesc: false, dataType: 'dateTime', width: '15%' },
    { name: 'size', label: 'Size', align: 'right', orderDesc: false, dataType: 'filesize', width: '10%' }
];

const supportedTypes = [
    { extension: 'pdf', contentType: 'application/pdf' },

    { extension: 'doc', contentType: 'application/msword' },
    { extension: 'docx', contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },

    { extension: 'xls', contentType: 'application/vnd.ms-excel' },
    { extension: 'xlsx', contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },

    { extension: 'ppt', contentType: 'application/vnd.ms-powerpoint' },
    { extension: 'pptx', contentType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },

    { extension: 'txt', contentType: 'text/plain' },
    { extension: 'csv', contentType: 'text/csv' },
    { extension: 'ics', contentType: 'text/calendar' }
];

const Documents = ({ onSelectDocument = null }) => {
    const [state, setState] = useReducer(reducer, initialState);
    const { handleFetch, handleGet, handleDelete } = useApi();

    const handleGetDocuments = async () => {
        const response = await handleGet('documents');
        const unfilteredDocuments = await response.json();

        if (!response.ok) {
            console.error(response.error);
            return;
        }

        setState({ unfilteredDocuments });
    };

    const maxSize = 5242880;

    const handleUpload = async acceptedFiles => {
        setState({ isLoading: true });

        let results = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
            const data = new FormData();

            data.append('file', acceptedFiles[i]);

            const response = await handleFetch('documents', {
                method: 'post',
                body: data
            });

            if (!response.ok) {
                console.error(response);
                return;
            }

            const result = await response.json();

            results.push(result);
        }

        setState({ uploadResult: results });

        //if any uploads were successful, refresh the table
        results.find(e => e.succeeded) && handleGetDocuments();

        setState({ isLoading: false });
    };

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: supportedTypes.map(({ extension }) => `.${extension}`).join(', '),
        minSize: 0,
        maxSize,
        onDropAccepted: acceptedFiles => handleUpload(acceptedFiles)
    });

    const handleConfirmDeleteDocument = (e, document) => {
        e.stopPropagation();
        setState({ documentToDelete: document });
    };

    const handleDeleteDocument = async name => {
        const response = await handleDelete(`documents/${name}`);

        if (!response.ok) {
            console.error(response.error);
            return;
        }

        handleGetDocuments();
    };

    const handleSort = async (orderBy, orderDesc) => {
        if (orderBy === state.orderBy) {
            setState({ orderDesc: !state.orderDesc });
        }
        else {
            setState({ orderBy, orderDesc });
        }
    };

    const handleSelectDocument = link => {
        if (!onSelectDocument) {
            return;
        }

        onSelectDocument(link);
    };

    const spacing = 2;

    useEffect(() => {
        handleGetDocuments();
    }, []);

    return (
        <Box sx={{ p: spacing }}>
            <Grid container spacing={spacing}>
                <Grid item xs={12}>
                    <Paper>
                        <UploadFiles
                            getInputProps={getInputProps}
                            getRootProps={getRootProps}
                            fileRejections={fileRejections}
                            uploadResult={state.uploadResult}
                            maxSize={maxSize}
                            spacing={spacing}
                            guidance={(
                                <>
                                    <Typography
                                        component="p"
                                        variant="body1"
                                    >
                                        Upload files to link to from campaigns.
                                    </Typography>
                                    <Typography
                                        component="p"
                                        variant="body1"
                                    >
                                        Supported file types:
                                    </Typography>
                                    <List dense disablePadding>
                                        <ListItem>PDF Files (.pdf)</ListItem>
                                        <ListItem>Microsoft Word document (.doc, .docx)</ListItem>
                                        <ListItem>Microsoft Excel spreadsheet (.xls, .xlsx)</ListItem>
                                        <ListItem>Microsoft Powerpoint presentation (.ppt, .pptx)</ListItem>
                                        <ListItem>Text (.txt, .csv)</ListItem>
                                        <ListItem>Calendar (.ics)</ListItem>
                                    </List>
                                    <Typography
                                        component="p"
                                        variant="body1"
                                    >
                                        File size must be less than 5MB.
                                    </Typography>
                                </>
                            )}
                            isLoading={state.isLoading}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {state.isInitialising ? <CenteredProgress /> : (
                        <Paper>
                            <Toolbar disableGutters sx={{ mx: 1 }}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <OutlinedInput
                                        value={state.searchValue}
                                        onChange={e => setState({ searchValue: e.target.value })}
                                        placeholder="Search..."
                                        size="small"
                                        sx={{ mx: 1 }}
                                        endAdornment={(
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setState({ searchValue: '' })}
                                                    edge="end"
                                                    disabled={!Boolean(state.searchValue)}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )}
                                    />
                                    <DatePicker value={state.fromDate} onChange={fromDate => setState({ fromDate })} label="Created from date" />
                                    <DatePicker value={state.toDate} onChange={toDate => setState({ toDate })} label="Created to date" />
                                </Box>
                            </Toolbar>
                            <Divider />
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map(col => (
                                                <TableCell component="th" scope="row" align={col.align} width={col.width} key={col.name}>
                                                    <TableSortLabel
                                                        active={state.orderBy === col.name}
                                                        direction={state.orderDesc ? 'desc' : 'asc'}
                                                        onClick={() => handleSort(col.name, col.orderDesc)}
                                                    >
                                                        {col.label}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                            <TableCell sx={actionsCell} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.documents.map(document => (
                                            <TableRow
                                                key={document.name}
                                                hover={onSelectDocument ? true : false}
                                                onClick={() => handleSelectDocument(document.link)}
                                            >
                                                {columns.map((col, i) => (
                                                    <DataColumn
                                                        key={i}
                                                        align={col.align}
                                                        dataType={col.dataType}
                                                        value={document[col.name]}
                                                    />
                                                ))}
                                                <TableCell align="right" sx={actionsCell}>
                                                    <IconButton
                                                        onClick={e => handleConfirmDeleteDocument(e, document)}
                                                        size="small"
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                component="div"
                                count={state.documentsCount}
                                rowsPerPage={state.rowsPerPage}
                                page={state.page}
                                onPageChange={(e, page) => setState({ page })}
                                onRowsPerPageChange={e => setState({ rowsPerPage: e.target.value })}
                            />
                        </Paper>
                    )}
                </Grid>
                {state.documentToDelete && (
                    <ConfirmDeleteDocumentDialog
                        onClose={() => setState({ documentToDelete: null })}
                        onDelete={handleDeleteDocument}
                        documentName={state.documentToDelete.name}
                    />
                )}
            </Grid>
        </Box>
    );
};

export default Documents;