import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CreateAutomationEmailDialog from '../dialogs/CreateAutomationEmailDialog';
import Container from '@mui/material/Container';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useApi from '../../hooks/useApi';
import TemplatesList from '../../editor/TemplatesList';
import buildUrl from '../../buildUrl';

const NewAutomationEmail = () => {
    const history = useHistory();
    const { automationId } = useParams();
    const [showCreateEmailDialog, setShowCreateEmailDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templates, setTemplates] = useState(null);
    const { handleGet, handlePost } = useApi();

    const loadTemplates = async () => {
        const url = buildUrl('templates', { type: 'email' });
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setTemplates(data);
    };

    const handleShowCreateEmailDialog = template => {
        setSelectedTemplate(template);
        setShowCreateEmailDialog(true);
    };

    const handleHideCreateEmailDialog = () => {
        setShowCreateEmailDialog(false);
        setSelectedTemplate(null);
    };

    const handleCreateEmail = async ({ name, delay }) => {
        setIsLoading(true);

        const response = await handlePost(`automations/${automationId}/emails`, {
            templateId: selectedTemplate.id,
            name,
            delay,
            active: false
        });

        if (!response.ok) {
            //handle error with a snackbar
            console.error(response?.error);
            setIsLoading(false);
            return;
        }

        const email = await response.json();

        handleHideCreateEmailDialog();
        setIsLoading(false);
        history.push(`/automations/${automationId}/emails/${email.id}`);
    };

    const handleGoBack = () => {
        history.push(`/automations/${automationId}`);
    };

    const handleInit = async () => {
        loadTemplates();
    };

    useEffect(() => {
        handleInit();
    }, []);

    if (!templates) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AppBar
                position="sticky"
                color="inherit"
                sx={{ top: 64 }}
            >
                <Toolbar>
                    <div style={{ flexGrow: 1 }}>
                        <Typography component="h1" variant="h6">Choose Template</Typography>
                    </div>
                    <Button variant="outlined" onClick={handleGoBack} startIcon={<NavigateBeforeIcon />}>Back</Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth={false} sx={{ paddingTop: 3, paddingBottom: 3 }}>
                <TemplatesList
                    templates={templates}
                    onSelectTemplate={handleShowCreateEmailDialog}
                    selectedTemplate={selectedTemplate}
                />
            </Container>
            {showCreateEmailDialog && (
                <CreateAutomationEmailDialog
                    onClose={handleHideCreateEmailDialog}
                    onSubmit={handleCreateEmail}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};

export default NewAutomationEmail;