import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import LanguageIcon from '@mui/icons-material/Language';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import KeyIcon from '@mui/icons-material/Key';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

const ColorlibConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        backgroundColor: '#1973CE',
        borderRadius: 1
    }
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: ownerState
        ? ownerState.success ? theme.palette.success.main : theme.palette.warning.main
        : theme.palette.mode === 'dark'
            ? theme.palette.grey[700]
            : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
}));

function ColorlibStepIcon(props) {
    const { active, completed, className, color, success, failed } = props;

    const icons = {
        1: <LanguageIcon />,
        2: <VpnLockIcon />,
        3: <KeyIcon />,
        4: <ForwardToInboxIcon />
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active, color, success, failed }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
    color: PropTypes.string,
    success: PropTypes.bool,
    failed: PropTypes.bool
};

const steps = ['BOUNCE HANDLER', 'SPF', 'DKIM', 'DMARC'];

const CustomizedSteppers = ({ data }) => {
    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper sx={{ padding: 2 }} alternativeLabel activeStep={4} connector={<ColorlibConnector />}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={(props) => (
                                <ColorlibStepIcon {...props} success={data[index]} />
                            )}
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
};

export default CustomizedSteppers;