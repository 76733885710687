import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FilteredGroupsSelector from '../../contacts/FilteredGroupsSelector';

const CreateOrEditAutomationDialog = ({ onClose, onSubmit, availableTriggers, allContactsCount, automation = null }) => {
    const [step, setStep] = useState(0);
    const [triggerIds, setTriggerIds] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [id, setId] = useState(null);
    const [groupId, setGroupId] = useState(null);
    const [name, setName] = useState('');

    const handleSubmit = e => {
        e.preventDefault();

        onSubmit({
            name,
            triggerIds,
            groupId,
            ...(id && { id }),
            ...(automation && { active: automation.active })
        });

        onClose();
    };

    const handleChangeTriggerIds = (id, selected) => {
        if (selected) {
            setTriggerIds(triggerIds.filter(e => e !== id));
        }
        else {
            setTriggerIds([...triggerIds, id]);
        }
    };

    useEffect(() => {
        setIsValid(name.trim() !== '' && name.length <= 255);
    }, [name]);

    useEffect(() => {
        if (automation) {
            setId(automation.id);
            setName(automation.name);
            setGroupId(automation.group?.id);
            setTriggerIds(automation.triggerIds);
        }
    }, [automation]);

    const dialogTitle = automation ? 'Edit Automation' : 'Create New Automation';

    return (
        <>
            <Dialog onClose={onClose} open={step === 0}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="name"
                        label="Name"
                        fullWidth
                        value={name}
                        required
                        onChange={e => setName(e.target.value)}
                        size="small"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStep(1)} disabled={!isValid}>Next</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={onClose} open={step === 1}>
                <DialogTitle>Select Group</DialogTitle>
                <FilteredGroupsSelector
                    selectedGroups={groupId ? [groupId] : []}
                    setSelectedGroups={selectedGroups => {
                        const groupId = selectedGroups && selectedGroups.length > 0 ? selectedGroups[0] : null;
                        setGroupId(groupId);
                    }}
                    spacing={3}
                    popperGutters={3}
                    multipleSelection={false}
                    includeAllContacts={true}
                    allContactsCount={allContactsCount}
                />
                <DialogActions>
                    <Box sx={{ flexGrow: 1 }}>
                        <Button variant="outlined" onClick={() => setStep(0)}>Back</Button>
                    </Box>
                    <Button onClick={() => setStep(2)} disabled={groupId?.length === 0}>Next</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={onClose} open={step === 2}>
                <DialogTitle>Choose Triggers</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <DialogContentText>
                            Choose the events that will trigger your Automation.
                        </DialogContentText>
                        <FormGroup>
                            {availableTriggers.map(e => {
                                const selected = triggerIds.includes(e.id);

                                return (
                                    <FormControlLabel
                                        key={e.id}
                                        control={(
                                            <Checkbox
                                                checked={selected}
                                                onChange={() => handleChangeTriggerIds(e.id, selected)}
                                                inputProps={{ 'aria-label': e.description }}
                                            />
                                        )}
                                        label={e.description}
                                    />
                                );
                            })}
                        </FormGroup>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ flexGrow: 1 }}>
                        <Button variant="outlined" onClick={() => setStep(1)}>Back</Button>
                    </Box>
                    <Button onClick={handleSubmit} disabled={triggerIds.length === 0}>{automation ? 'Save Changes' : 'Create New Automation'}</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateOrEditAutomationDialog;