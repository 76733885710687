import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DashboardInUseDialog = ({ onClose, type = 'email' }) => {
    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>{`${type.charAt(0).toUpperCase()}${type.substring(1)}`} Locked</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 2 }}>
                    This {type} is currently locked for editing by another user.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DashboardInUseDialog;
