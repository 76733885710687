import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import CampaignPreview from '../../campaignPreview/CampaignPreview';
import ToggleConditions from '../../campaignPreview/ToggleConditions';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const ToggleDevice = ({ device, onChangeDevice }) => {
    const handleChange = (event, value) => {
        value && onChangeDevice(value);
    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={device}
            exclusive
            onChange={handleChange}
        >
            <ToggleButton value="desktop">Desktop</ToggleButton>
            <ToggleButton value="mobile">Mobile</ToggleButton>
        </ToggleButtonGroup>
    );
};

const CampaignPreviewDialog = ({ open, onClose, html }) => {
    const [device, setDevice] = useState('desktop');
    const [availableConditions, setAvailableConditions] = useState(null);
    const [conditions, setConditions] = useState([]);

    const handleSetAvailableConditions = conditions => {
        setAvailableConditions(conditions);
        setConditions(conditions);
    };

    return (
        <Dialog
            onClose={onClose}
            open={open}
            fullScreen
            TransitionComponent={Transition}
        >
            <AppBar position="sticky" color="inherit">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Preview
                    </Typography>
                    <Stack direction="row" spacing={2}>
                        <ToggleConditions
                            availableConditions={availableConditions}
                            conditions={conditions}
                            onChangeConditions={setConditions}
                        />
                        <ToggleDevice
                            device={device}
                            onChangeDevice={e => setDevice(e)}
                        />
                    </Stack>
                </Toolbar>
            </AppBar>
            <Box sx={{ my: 3 }}>
                <CampaignPreview
                    html={html}
                    device={device}
                    conditions={conditions}
                    onChangeConditions={handleSetAvailableConditions}
                />
            </Box>
        </Dialog>
    );
};

export default CampaignPreviewDialog;