import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import { useAuth0 } from '@auth0/auth0-react';

const SessionEndedDialog = () => {
    const { loginWithRedirect } = useAuth0();
    const handleSignOut = () => loginWithRedirect({ prompt: 'login' });

    return (
        <Dialog open={true}>
            <DialogTitle>Session Logged Out</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Another login has been detected using your credentials. If this was not you then you should change your password.
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" onClick={handleSignOut}>Log In</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SessionEndedDialog;