import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import useApi from '../hooks/useApi';
import { format, add } from 'date-fns';
import Chart from 'chart.js/auto';
import useTheme from '@mui/material/styles/useTheme';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';

const ImportChart = ({ importData }) => {
    const theme = useTheme();

    const handleBuildChart = () => {
        if (!importData) {
            return;
        }

        const data = {
            labels: [`${importData.totalAdded} contacts added to account`, `${importData.totalUpdated} contacts already in account`, `${importData.totalFailed} contacts not added`],
            datasets: [{
                data: [importData.totalAdded, importData.totalUpdated, importData.totalFailed],
                backgroundColor: [theme.palette.success.light, theme.palette.grey[300], theme.palette.warning.main] //theme.palette.error.main
            }]
        };

        const options = {
            plugins: {
                legend: {
                    position: 'right'
                }
            },
            aspectRatio: 3
        };

        const dataChart = document.getElementById('dataCanvas');
        let chartStatus = Chart.getChart('dataCanvas');
        chartStatus !== undefined && chartStatus.destroy();

        new Chart(dataChart, {
            type: 'pie',
            data,
            options
        });
    };

    useEffect(() => {
        handleBuildChart();
    }, []);

    return (
        <Box sx={{ py: 3, width: '100%' }}>
            <canvas id="dataCanvas" />
        </Box>
    );
};

const ImportReportDialog = ({ id, onClose }) => {
    const [importData, setImportData] = useState(null);
    const [expiryDateTime, setExpiryDateTime] = useState(null);
    const { handleGet } = useApi();

    const handleFetchImport = async () => {
        const response = await handleGet(`imports/${id}`);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setImportData(data);
    };

    useEffect(() => {
        handleFetchImport();
    }, [id]);

    useEffect(() => {
        if (importData) {
            const dateTime = add(new Date(importData.completedDateTime), { hours: 167 });
            setExpiryDateTime(dateTime);
        }
    }, [importData]);

    if (importData) {
        if (importData.totalAdded === 0 && importData.totalUpdated === 0 && importData.totalFailed === 0) {
            return (
                <Dialog onClose={onClose} open={true}>
                    <DialogTitle>Import Report</DialogTitle>
                    <DialogContent>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell variant="head">File Name</TableCell>
                                    <TableCell>{importData.fileName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Import Failed</TableCell>
                                    <TableCell>{importData.completedDateTime ? format((new Date(importData.completedDateTime)), 'dd/MM/yyyy HH:mm') : '-'}</TableCell>
                                </TableRow>
                                {(importData.groups.length > 0) && (
                                    <TableRow>
                                        <TableCell variant="head">Groups</TableCell>

                                        <TableCell>{importData.groups.map(({ name }) => name).join(', ')}</TableCell>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <TableCell variant="head">Possible reason</TableCell>
                                    <TableCell>Attempt to import invalid data</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={onClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            );
        }
        else {
            const hasExpired = Date.now() > expiryDateTime;

            return (
                <Dialog onClose={onClose} open={true}>
                    <DialogTitle>Import Report</DialogTitle>
                    <DialogContent>
                        <ImportChart importData={importData} />

                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell variant="head">File Name</TableCell>
                                    <TableCell>{importData.fileName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Import Completed</TableCell>
                                    <TableCell>{importData.completedDateTime ? format((new Date(importData.completedDateTime)), 'dd/MM/yyyy HH:mm') : '-'}</TableCell>
                                </TableRow>
                                {(importData.groups.length > 0) && (
                                    <TableRow>
                                        <TableCell variant="head">Groups</TableCell>

                                        <TableCell>{importData.groups.map(({ name }) => name).join(', ')}</TableCell>
                                    </TableRow>
                                )}

                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        {importData.completedDateTime && (
                            <Button onClick={() => window.open(importData.reportFilePath)} disabled={hasExpired}>
                                {hasExpired ? 'Report Expired' : 'Download Report'}
                            </Button>
                        )}
                        <Button variant="outlined" onClick={onClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            );
        }
    }

    return (
        <LoadingOverlay />
    );
};

export default ImportReportDialog;