import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { Alert } from '@mui/material';

const DeleteUserModal = ({ user, shown, onHide, onConfirm, accountId }) => {
    return (
        <Dialog
            open={shown}
            onClose={onHide}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Delete user</DialogTitle>
            <DialogContent dividers>
                <Alert severity="error" style={{ marginBottom: '16px' }}>
                    You are about to permanently delete user <strong>{user.username}</strong> from account <strong>{accountId}</strong>.
                </Alert>
                <DialogContentText>
                    Are you sure you want to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onHide} variant="outlined">Back to safety</Button>
                <Button onClick={onConfirm} color="error">Delete user</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteUserModal;