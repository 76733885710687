import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ConfirmUnsuppressContactDialog = ({ onClose, emailAddress, onConfirm }) => {

    const handleConfirm = e => {
        e.preventDefault();
        onConfirm();
        onClose();
    };

    //add loading state and await confirm?

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Unsuppress {emailAddress}</DialogTitle>
            <DialogContent>
                <Typography paragraph>
                    Unsuppress this contact?
                </Typography>
                <Typography>
                    This action will enable you to send campaigns to this subscriber. The contact can be suppressed again at any time.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>Confirm Unsuppress</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmUnsuppressContactDialog;