import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import CircleIcon from '@mui/icons-material/Circle';
import red from '@mui/material/colors/red';
import pink from '@mui/material/colors/pink';
import purple from '@mui/material/colors/purple';
import deepPurple from '@mui/material/colors/deepPurple';
import indigo from '@mui/material/colors/indigo';
import blue from '@mui/material/colors/blue';
import lightBlue from '@mui/material/colors/lightBlue';
import cyan from '@mui/material/colors/cyan';
import teal from '@mui/material/colors/teal';
import green from '@mui/material/colors/green';
import lightGreen from '@mui/material/colors/lightGreen';
import lime from '@mui/material/colors/lime';
import yellow from '@mui/material/colors/yellow';
import amber from '@mui/material/colors/amber';
import orange from '@mui/material/colors/orange';
import deepOrange from '@mui/material/colors/deepOrange';

const colours = [
    red[500],
    pink[500],
    purple[500],
    deepPurple[500],
    indigo[500],
    blue[500],
    lightBlue[500],
    cyan[500],
    teal[500],
    green[500],
    lightGreen[500],
    lime[500],
    yellow[500],
    amber[500],
    orange[500],
    deepOrange[500]
];

const CreateEditTagDialog = ({ onClose, onSubmit, tag = null }) => {
    const [name, setName] = useState(tag ? tag.name : '');
    const [description, setDescription] = useState(tag ? tag.description : '');
    const [colour, setColour] = useState(tag ? tag.colour : '');

    const validName = name.length <= 50;

    const validColour = !colour || (colour.length === 7 && colour.startsWith('#'));

    const handleSubmit = e => {
        e.preventDefault();

        onSubmit({
            name,
            description,
            colour,
            ...(tag && { id: tag.id })
        });
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{tag ? 'Edit' : 'Create'} Tag</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={name}
                        required
                        onChange={e => setName(e.target.value)}
                        margin="dense"
                        error={!validName}
                        helperText="Name must be 50 characters or less"
                    />
                    <TextField
                        id="description"
                        label="Description"
                        type="text"
                        fullWidth
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        margin="dense"
                    />
                    <Autocomplete
                        id="colour"
                        freeSolo
                        options={colours}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Colour"
                                margin="dense"
                                helperText="Hexadecimal value e.g. #FF6600"
                                error={!validColour}
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props}>
                                <CircleIcon sx={{ color: option, marginRight: 1 }} />
                                {option}
                            </li>
                        )}
                        value={colour}
                        onChange={(event, value) => setColour(value)}
                        onInputChange={(event, value) => setColour(value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={!validName || !validColour}>Save</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreateEditTagDialog;