import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ lastPeriod, thisPeriod }) => {
    const figureChange = thisPeriod - lastPeriod;
    const figureChangeColour = figureChange > 0 ? '#91C483' : '#FF6464';

    const data = {
        labels: ['Last Period', 'Difference', 'This Period'],
        datasets: [
            {
                data: [lastPeriod, figureChange, thisPeriod],
                backgroundColor: ['#1572A1', figureChangeColour, '#9AD0EC']
            }
        ]
    };

    const options = {
        plugins: {
            title: {
                display: true
            },
            legend: {
                display: false
            }
        }
    };

    return <Bar data={data} options={options} />;
};

export default BarChart;
