import React, { useState, useEffect } from 'react';
import useApi from '../../hooks/useApi';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Picker from '../../picker/Picker';
import CreateEditTagDialog from '../../account/tags/dialogs/CreateEditTagDialog';
import Tooltip from '@mui/material/Tooltip';
import ArchiveIcon from '@mui/icons-material/Archive';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import buildUrl from '../../buildUrl';
import useAccount from '../../hooks/useAccount';

const nameRegex = /[a-zA-Z0-9\s-]/g;

const toggleIcon = {
    marginRight: 1
};

const CreateGroupDialog = ({ onClose, onSubmit }) => {
    const { handleGet, handlePost } = useApi();
    const [type, setType] = useState('private');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [groups, setGroups] = useState(null);
    const [isInitialising, setIsInitialising] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { tags, handleCreateTag } = useAccount();
    const [tagToSelect, setTagToSelect] = useState(null);

    const handleFetchGroups = async () => {
        const url = buildUrl('groups');
        const response = await handleGet(url);

        if (!response.ok) {
            setIsInitialising(false);
            console.error(response?.error);
            return;
        }

        const groups = await response.json();

        setGroups(groups);
    };

    const handleAddTag = async ({ name, description, colour }) => {
        const tag = {
            name,
            description,
            colour
        };

        await handleCreateTag(tag);

        setTagToSelect(tag);
        setShowDialog(false);
    };

    const handleSubmit = async e => {
        e.preventDefault();

        setIsLoading(true);

        const response = await handlePost('groups', {
            type,
            name,
            ...(description && { description }),
            ...(selectedTags && { tags: selectedTags })
        });

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const newGroup = await response.json();

        await handleFetchGroups();

        onSubmit(newGroup);
        setIsLoading(false);
    };

    useEffect(() => {
        handleFetchGroups();
    }, []);

    useEffect(() => {
        if (groups) {
            setIsInitialising(false);
        }

        if (name.trim() !== '' && (name.match(nameRegex)?.length === name.length)) {
            const existingGroup = groups?.find(e => e.name.trim().toLowerCase() === name.trim().toLowerCase());
            setIsValid(!existingGroup);
        }
        else {
            setIsValid(false);
        }
    }, [groups, name]);

    useEffect(() => {
        if (!tagToSelect) {
            return;
        }

        const tag = tags.find(t => t.name === tagToSelect.name);

        if (!tag) {
            return;
        }

        setSelectedTags([...selectedTags, tag]);
        setTagToSelect(null);
    }, [tags, tagToSelect]);

    if (isInitialising) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <Dialog onClose={onClose} open={true}>
                <DialogTitle>Create Group</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Stack spacing={2}>
                            <TextField
                                autoFocus
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                value={name}
                                required
                                onChange={e => setName(e.target.value)}
                                margin="none"
                                error={!isValid}
                                helperText="Group name must be unique. Alphanumeric characters, hyphens and spaces are allowed."
                            />
                            <TextField
                                id="description"
                                label="Description"
                                type="text"
                                fullWidth
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                margin="none"
                            />
                            <Alert severity="info">
                                Group Name and Description are shown to your contacts in the list of Public Groups in Manage Preferences, accessible via a link in your campaigns.
                            </Alert>
                            <Typography>
                                Type
                            </Typography>
                            <ToggleButtonGroup
                                value={type}
                                exclusive
                                onChange={(e, type) => setType(type)}
                                aria-label="type"
                                color="primary"
                            >
                                <ToggleButton value="private" aria-label="private">
                                    <VisibilityOffIcon sx={toggleIcon} />
                                    Private
                                </ToggleButton>
                                <ToggleButton value="public" aria-label="public">
                                    <VisibilityIcon sx={toggleIcon} />
                                    Public
                                </ToggleButton>
                                <ToggleButton value="archive" aria-label="archive">
                                    <ArchiveIcon sx={toggleIcon} />
                                    Archive
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Alert severity="info">
                                Private Groups are only seen by Account Administrators. Public Groups are visible to your Contacts as opt-in/opt-out options if they choose to Manage Preferences.
                            </Alert>
                            <div>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography>
                                        Tag this group
                                    </Typography>
                                    <Tooltip title="Add New Tag">
                                        <IconButton
                                            onClick={() => setShowDialog(true)}
                                            color="primary"
                                            size="small"
                                        >
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <Picker
                                                availableItems={tags}
                                                selectedItems={selectedTags}
                                                singleInstance={true}
                                                isTags={true}
                                                onChangeItems={selectedTags => setSelectedTags(selectedTags)}
                                                border={false}
                                            />
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" disabled={!isValid || isLoading}>Create Group</Button>
                        <Button variant="outlined" onClick={onClose}>Cancel</Button>
                    </DialogActions>
                </form>
            </Dialog>
            {showDialog && (
                <CreateEditTagDialog
                    onClose={() => setShowDialog(false)}
                    onSubmit={handleAddTag}
                />
            )}
        </>
    );
};

export default CreateGroupDialog;