import React from 'react';
// Material UI
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const ContactsInfo = ({ account }) => {

    return (
        <>
            <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
                <div style={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">Account Contacts</Typography>
                </div>
            </Toolbar>
            <Divider />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Typography variant="button" gutterBottom color="text.secondary">
                                Total Contacts
                            </Typography>
                            <Typography variant="h5" color="primary">
                                {account.contacts[0].total}
                            </Typography>
                        </CardContent>
                        <Divider />
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Typography variant="button" gutterBottom color="text.secondary">
                                Unsubscribed
                            </Typography>
                            <Typography variant="h5">
                                {account.contacts[0].unsubscribed}
                            </Typography>
                        </CardContent>
                        <Divider />
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Typography variant="button" gutterBottom color="text.secondary">
                                Ungrouped
                            </Typography>
                            <Typography variant="h5">
                                {account.contacts[0].ungrouped}
                            </Typography>
                        </CardContent>
                        <Divider />
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Typography variant="button" gutterBottom color="text.secondary">
                                Suppressed
                            </Typography>
                            <Typography variant="h5">
                                {account.contacts[0].suppressed}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default ContactsInfo;
