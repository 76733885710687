import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { colours } from '../../theme/constants';
import useAccount from '../../hooks/useAccount';
import { useAuth0 } from '@auth0/auth0-react';
import Menu from '@mui/material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import ListSubheader from '@mui/material/ListSubheader';
import { Link } from 'react-router-dom';
import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import SuperAdminIcon from '../../svgIcons/SuperAdminIcon';

const iconButton = {
    color: '#FFFFFF',
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: colours.secondary
    }
};

const CustomListItemButton = ({ to, icon: Icon, primaryText, onClick, divider = false }) => (
    <ListItem
        disablePadding
        onClick={onClick}
        divider={divider}
        sx={divider ? {
            paddingBottom: 1,
            marginBottom: 1
        } : undefined}
    >
        <ListItemButton
            component={to ? Link : 'div'}
            to={to}
            sx={{
                py: Icon ? 0 : 0.7,
                ml: Icon ? 0 : 2
            }}
        >
            {Icon && (
                <ListItemIcon sx={{ minWidth: 'unset', pr: 2 }}>
                    <Icon color="primary" />
                </ListItemIcon>
            )}
            <ListItemText
                primary={(
                    <Typography variant="subtitle2" sx={{ fontSize: '0.875rem' }}>
                        {primaryText}
                    </Typography>
                )}
            />
        </ListItemButton>
    </ListItem>
);

const returnTo = 'https://www.newzapp.co.uk/email-marketing/logout-thank-you.php';

const AccountProfile = ({ handleFeatureDialog }) => {
    const { customerId, name, emailAddress, avatarURL, accounts, handleChangeAccount, userPermissions, user: currentUser } = useAccount();
    const { user, logout } = useAuth0();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [settingOpen, setSettingOpen] = useState(false);
    const isSuperAdmin = userPermissions.includes('manage:accounts', 'manage:campaigns', 'manage:templates', 'manage:notifications');

    const currentAccount = accounts.find(account => account.id === customerId);
    const availableAccounts = accounts.filter(account => account.id !== currentAccount.id);
    const sortedAccounts = availableAccounts.sort((a, b) => new Date(b.lastLoggedIn) - new Date(a.lastLoggedIn));
    const [showTooltip, setShowTooltip] = useState(false);

    const handleClose = () => {
        setAnchorEl(null);
        setSettingOpen(false);
    };

    const handleMouseEnter = (event, setTooltip) => {
        const element = event.currentTarget;
        setTooltip(element.scrollWidth > element.clientWidth);
    };

    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const displayName = name ? name : user.name;

    const handleSwitchAccount = async id => {
        if (id === customerId) {
            return;
        }

        await handleChangeAccount(id);
    };

    const UserAvatar = props => {
        return (
            <Avatar
                {...props}
                src={avatarURL}
            >
                {displayName.substring(0, 1)}
            </Avatar>
        );
    };

    return (
        <>
            <IconButton
                edge="end"
                sx={{ ...iconButton, p: 1 }}
                size="large"
                onClick={handleClick}
            >
                <UserAvatar sx={{ width: 32, height: 32 }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: 300,
                        'scrollbar-width': 'thin'
                    }
                }}
            >
                <ListItem sx={{ pb: 0 }}>
                    <Box sx={{ display: 'flex', alignItems: 'stretch', width: '100%' }}>
                        <ListItemAvatar>
                            <Avatar
                                src={avatarURL}
                                sx={{ width: 55, height: 55 }}
                            >
                                {displayName.substring(0, 1)}
                            </Avatar>
                        </ListItemAvatar>
                        <Box sx={{ maxWidth: 270, ml: 2 }}>
                            <ListItemText
                                primary={(
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ wordWrap: 'break-word', padding: 0, lineHeight: 1.25 }}
                                    >
                                        {displayName}
                                    </Typography>
                                )}
                                secondary={(
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ wordWrap: 'break-word', padding: 0, lineHeight: 1.75 }}
                                    >
                                        {emailAddress}
                                    </Typography>
                                )}
                            />
                        </Box>
                    </Box>
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem sx={{ py: 0 }}>
                    <Box sx={{ display: 'flex', alignItems: 'stretch', width: '100%', ml: 1 }}>
                        <ListItemText
                            primary={currentAccount.name}
                            primaryTypographyProps={{
                                style: {
                                    whitespace: 'nowrap',
                                    overflow: 'hidden',
                                    textoverflow: 'ellipsis'
                                }
                            }}
                            secondary={(
                                <>
                                    {`Customer ID: ${currentAccount.id}`}
                                    <Box>Type: {currentAccount.isInternalCommunications ? 'Internal' : 'External'}</Box>
                                </>
                            )}
                        />
                    </Box>
                </ListItem>
                <Divider sx={{ my: 1 }} />
                {availableAccounts.length > 0 && (
                    <ListItem sx={{ pr: 0, pt: 0, pb: 0 }}>
                        <Box sx={{ alignItems: 'center', width: '100%' }}>
                            <Box sx={{ maxHeight: 250, maxWidth: 300, overflowY: 'auto', 'scrollbar-width': 'thin' }}>
                                <Box sx={{ display: 'flex', position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                    <PeopleOutlineIcon color="primary" />
                                    <ListSubheader sx={{ py: 0, lineHeight: 1.5, flexGrow: 1 }}>Switch Account</ListSubheader>
                                </Box>
                                <List dense={true}>
                                    <Box sx={{ display: 'flex', maxWidth: 300 }}>
                                        <Box sx={{ px: 1.5, py: 1 }}>
                                            <Divider orientation="vertical" flexItem sx={{ marginLeft: 0, height: '100%', width: 1 }} />
                                        </Box>
                                        <Box sx={{ ml: 1, flexGrow: 1, overflowX: 'auto', whiteSpace: 'nowrap', pr: 2 }}>
                                            {sortedAccounts.map((user, index) => (
                                                <ListItemButton key={index} sx={{ pt: 0, px: 2 }} onClick={() => handleSwitchAccount(user.id)}>
                                                    <ListItemText
                                                        primary={(
                                                            <Tooltip title={user.name} arrow disableHoverListener={!showTooltip}>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    noWrap
                                                                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }}
                                                                    onMouseEnter={(e) => handleMouseEnter(e, setShowTooltip)}
                                                                >
                                                                    {user.name}
                                                                </Typography>
                                                            </Tooltip>
                                                        )}
                                                        secondary={(
                                                            <Typography variant="body2" color="textSecondary">
                                                                {`Customer ID: ${user.id}`}
                                                                <Box>Type: {user.isInternalCommunications ? 'Internal' : 'External'}</Box>
                                                            </Typography>
                                                        )}
                                                    />
                                                </ListItemButton>
                                            ))}
                                        </Box>
                                    </Box>
                                </List>
                            </Box>
                        </Box>
                    </ListItem>
                )}
                {availableAccounts.length > 0 && <Divider sx={{ my: 1 }} />}
                <Box>
                    <ListItemButton
                        onClick={() => setSettingOpen(!settingOpen)}
                        sx={{ py: 0, '&:hover': { backgroundColor: 'transparent' } }}
                    >
                        <ListItemIcon sx={{ minWidth: 'unset', pr: 2 }}>
                            <SettingsOutlinedIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography variant="subtitle2">Account Management</Typography>}
                        />
                        {!settingOpen ? <KeyboardArrowDown /> : <KeyboardArrowUpIcon />}
                    </ListItemButton>
                    <Collapse in={settingOpen} timeout="auto" unmountOnExit>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ pl: 3.5, py: 1 }}>
                                <Divider orientation="vertical" flexItem sx={{ marginLeft: 0, height: 150, width: 1 }} />
                            </Box>
                            <Stack sx={{ width: '100%' }}>
                                <CustomListItemButton
                                    to="/account"
                                    primaryText="My Account"
                                    onClick={handleClose}
                                />
                                <CustomListItemButton
                                    to="/account/configuration"
                                    primaryText="Configuration"
                                    onClick={handleClose}
                                />
                                <CustomListItemButton
                                    to="/account/auditing"
                                    primaryText="Audit Trail"
                                    onClick={handleClose}
                                />
                                <CustomListItemButton
                                    to="/account/invoices"
                                    primaryText="Invoices"
                                    onClick={handleClose}
                                />
                            </Stack>
                        </Box>
                    </Collapse>
                </Box>
                <Divider sx={{ my: 1 }} />
                <CustomListItemButton
                    to="/account/products"
                    icon={SignalCellularAltOutlinedIcon}
                    primaryText="Usage & Products"
                    onClick={handleClose}
                    divider={true}
                />
                {isSuperAdmin && (
                    <CustomListItemButton
                        to="/superadmin"
                        icon={SuperAdminIcon}
                        primaryText="Super Admin"
                        onClick={handleClose}
                        divider={true}
                    />
                )}
                {(currentUser.userFeature.length > 0 || currentUser.feature.some(feature => feature.level === 1)) && (
                    <CustomListItemButton
                        icon={ToggleOnRoundedIcon}
                        primaryText="Feature Preview"
                        onClick={handleFeatureDialog}
                        divider={true}
                    />
                )}
                <CustomListItemButton
                    icon={LogoutIcon}
                    primaryText="Sign Out"
                    onClick={() => logout({ returnTo })}
                />
            </Menu>
        </>
    );
};

export default AccountProfile;
