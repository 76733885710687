import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

const ContextMenu = ({ onCopy, onEdit, onRename, onDelete }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = action => {
        setAnchorEl(null);
        action();
    };

    return (
        <>
            <IconButton onClick={e => setAnchorEl(e.currentTarget)} size="small">
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
                {onCopy && <MenuItem onClick={() => handleClick(onCopy)}>Copy</MenuItem>}
                {onEdit && <MenuItem onClick={() => handleClick(onEdit)}>Edit</MenuItem>}
                {onRename && <MenuItem onClick={() => handleClick(onRename)}>Rename</MenuItem>}
                {onDelete && <MenuItem onClick={() => handleClick(onDelete)}>Delete</MenuItem>}
            </Menu>
        </>
    );
};

export default ContextMenu;
