import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Utils
import { format } from 'date-fns';
import buildUrl from '../../buildUrl';
import useApi from '../../hooks/useApi';
// MUI
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// Icons
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
// Reusable Components
import ContextMenu from '../shared/components/ContextMenu';
import CreateEmailDialog from '../shared/dialogs/CreateEmailDialog';
import DeleteEmailDialog from '../shared/dialogs/DeleteEmailDialog';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import RenameEmailDialog from '../shared/dialogs/RenameEmailDialog';
import TitleBar from '../../titleBar/TitleBar';

const availableFilters = [
    { field: 'customerId', label: 'CID' },
    { field: 'name', label: 'Name' }
];

const fields = [
    { property: 'customerId', type: 'number', label: 'CID' },
    { property: 'name', type: 'text', label: 'Name' },
    { property: 'status', type: 'text', label: 'Status' },
    { property: 'created', type: 'date', label: 'Created Date' },
    { property: 'modified', type: 'date', label: 'Modified Date' },
    { property: 'hidden', type: 'bool', label: 'Hidden' }
];

const rowsPerPageOptions = [10, 20, 50];
const cellStyles = { whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

const Content = ({ showHamburger, onClickHamburger }) => {
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [selectedField, setSelectedField] = useState('customerId');
    const [tempValue, setTempValue] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [orderBy, setOrderBy] = useState('modified');
    const [isDescending, setIsDescending] = useState(true);
    const [status, setStatus] = useState('Draft');

    const type = 'email';

    const [showCreateEmailDialog, setShowCreateEmailDialog] = useState(false);
    const [showRenameEmailDialog, setShowRenameEmailDialog] = useState(false);
    const [showDeleteEmailDialog, setShowDeleteEmailDialog] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState(null);

    const endpoint = type === 'email' ? 'emails' : 'campaigns';

    const handleFetchCount = async () => {
        const params = {
            type,
            status,
            ...(filterValue && { field: selectedField, value: filterValue })
        };

        const url = buildUrl(`superadmin/${endpoint}/count`, params);

        const response = await handleGet(url);
        const data = await response.json();
        setCount(data);
        setIsLoading(false);
    };

    const handleFetchItems = async () => {
        const params = {
            type,
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending,
            status,
            ...(filterValue && { field: selectedField, value: filterValue })
        };

        const url = buildUrl(`superadmin/${endpoint}`, params);

        const response = await handleGet(url);
        const data = await response.json();
        setItems(data);
    };

    const handleSort = field => {
        if (orderBy === field.property) {
            setIsDescending(!isDescending);
        }
        else {
            setIsDescending(field.type === 'number' || field.type === 'date');
        }

        setOrderBy(field.property);
    };

    // Create Email

    const handleOpenCreateEmailDialog = email => {
        setSelectedEmail(email);
        setShowCreateEmailDialog(true);
    };

    const handleCloseCreateEmailDialog = () => {
        setShowCreateEmailDialog(false);
        setSelectedEmail(null);
    };

    const handleCreateEmail = async ({ name, customerId }) => {
        handleCloseCreateEmailDialog();
        setIsLoading(true);

        const response = await handlePost(`superadmin/${endpoint}`, {
            //type,
            name,
            customerId,
            emailId: selectedEmail.id
        });

        if (!response.ok) {
            console.error(response?.error ?? 'error');
            return;
        }

        const data = await response.json();

        history.push(`/superadmin/${endpoint}/${data.id}`);
    };

    // Rename Email

    const handleOpenRenameEmailDialog = email => {
        setSelectedEmail(email);
        setShowRenameEmailDialog(true);
    };

    const handleCloseRenameEmailDialog = () => {
        setShowRenameEmailDialog(false);
        setSelectedEmail(null);
    };

    const handleRenameEmail = async ({ name }) => {
        handleCloseRenameEmailDialog();
        setIsLoading(true);

        await handlePut(`superadmin/${endpoint}/${selectedEmail.id}`, { name });

        await handleFetchItems();
        setIsLoading(false);
    };

    // Delete Email

    const handleOpenDeleteEmailDialog = email => {
        setSelectedEmail(email);
        setShowDeleteEmailDialog(true);
    };

    const handleCloseDeleteEmailDialog = () => {
        setShowDeleteEmailDialog(false);
        setSelectedEmail(null);
    };

    const handleDeleteEmail = async () => {
        handleCloseDeleteEmailDialog();
        setIsLoading(true);

        await handleDelete(`superadmin/${endpoint}/${selectedEmail.id}`);

        await handleFetchCount();
        await handleFetchItems();
        setIsLoading(false);
    };

    useEffect(() => {
        handleFetchCount();
    }, [status, filterValue, type]);

    useEffect(() => {
        setPage(0);
    }, [status, filterValue, orderBy, isDescending, rowsPerPage, type]);

    useEffect(() => {
        handleFetchItems();
    }, [status, filterValue, orderBy, isDescending, rowsPerPage, page, type]);

    // Reset table

    const handleReset = e => {
        e.preventDefault();

        // clear state
        setPage(0);
        setOrderBy('customerId');
        setIsDescending(true);
        setSelectedField('customerId');
        setFilterValue('');
        setTempValue('');
    };

    const handleSubmitFilter = e => {
        e.preventDefault();
        setFilterValue(tempValue);
    };

    return (
        <>
            <TitleBar showHamburger={showHamburger} onClickHamburger={onClickHamburger}>
                <Breadcrumbs>
                    <Link href="/#/superadmin">Super Admin</Link>
                    <Typography>
                        <strong>Content</strong>
                    </Typography>
                </Breadcrumbs>
            </TitleBar>
            <Paper sx={{ m: 2 }}>
                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={type} onChange={(e, value) => setType(value)}>
                        <Tab label="Emails" value="email" />
                        <Tab label="Landing Pages" value="page" disabled={true} />
                    </Tabs>
                </Box> */}
                <Toolbar disableGutters sx={{ px: 2 }}>
                    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                        <form onSubmit={handleSubmitFilter}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <FormControl margin="none">
                                        <Select
                                            value={status}
                                            onChange={e => setStatus(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            displayEmpty
                                        >
                                            <MenuItem value="All">All</MenuItem>
                                            <MenuItem value="Draft">Draft</MenuItem>
                                            <MenuItem value="Sent">Sent</MenuItem>
                                            <MenuItem value="Scheduled">Scheduled</MenuItem>
                                            <MenuItem value="Sending">Sending</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl margin="none">
                                        <Select
                                            value={selectedField}
                                            onChange={e => setSelectedField(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                        >
                                            {availableFilters.map(({ field, label }) => (
                                                <MenuItem value={field} key={field}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl margin="none">
                                        <TextField
                                            value={tempValue}
                                            onChange={e => setTempValue(e.target.value)}
                                            variant="outlined"
                                            margin="none"
                                            size="small"
                                            placeholder="Search..."
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            disableRipple
                                                            edge="end"
                                                            onClick={handleReset}
                                                            disabled={!Boolean(tempValue)}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <IconButton type="submit" variant="outlined">
                                        <SearchIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Toolbar>
                <Divider />
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {fields.map(field => (
                                    <TableCell
                                        key={field.property}
                                        align={field.align}
                                        component="th"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        <TableSortLabel
                                            active={orderBy === field.property}
                                            direction={isDescending ? 'desc' : 'asc'}
                                            onClick={() => handleSort(field)}
                                        >
                                            {field.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell sx={{ width: '5%' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map(e => (
                                <TableRow key={e.id}>
                                    {fields.map(({ property, type, align }) => {
                                        const value =
                                            type === 'date'
                                                ? e[property]
                                                    ? format(new Date(e[property]), 'dd/MM/yyyy HH:mm')
                                                    : '-'
                                                : type === 'bool'
                                                    ? e[property]
                                                        ? 'Yes'
                                                        : 'No'
                                                    : e[property] || '-';

                                        return (
                                            <TableCell
                                                key={property}
                                                align={align}
                                                {...(type !== 'text' && {
                                                    sx: cellStyles
                                                })}
                                            >
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell align="right" sx={{ width: '5%' }}>
                                        <ContextMenu
                                            onCopy={() => handleOpenCreateEmailDialog(e)}
                                            onEdit={e.status === 'Draft' ? () => history.push(`/superadmin/${type}s/${e.id}`) : null}
                                            onRename={() => handleOpenRenameEmailDialog(e)}
                                            onDelete={() => handleOpenDeleteEmailDialog(e)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                />
            </Paper>

            {isLoading && <LoadingOverlay />}

            {showCreateEmailDialog && (
                <CreateEmailDialog
                    email={selectedEmail}
                    emailText="Email"
                    onClose={handleCloseCreateEmailDialog}
                    onComplete={handleCreateEmail}
                    useSameCID={true}
                />
            )}

            {showRenameEmailDialog && (
                <RenameEmailDialog
                    email={selectedEmail}
                    emailText="Email"
                    onClose={handleCloseRenameEmailDialog}
                    onComplete={handleRenameEmail}
                />
            )}

            {showDeleteEmailDialog && (
                <DeleteEmailDialog
                    email={selectedEmail}
                    emailText="Email"
                    onClose={handleCloseDeleteEmailDialog}
                    onComplete={handleDeleteEmail}
                />
            )}
        </>
    );
};

export default Content;
