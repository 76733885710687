import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import UploadFiles from '../editor/UploadFiles';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog';
import useApi from '../hooks/useApi';

const UploadHtml = () => {
    const history = useHistory();
    const { type } = useParams();
    const { handleFetch, handleGet, handleDelete } = useApi();

    const [uploadResult, setUploadResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteItemDialog, setShowDeleteItemDialog] = useState(false);
    const [item, setItem] = useState(null);

    const supportedTypes = [
        { extension: 'html', contentType: 'text/html' },
        { extension: 'htm', contentType: 'text/html' }
    ];

    const maxSize = 1048576;

    const handleUpload = async acceptedFiles => {
        setIsLoading(true);

        const data = new FormData();
        data.append('file', acceptedFiles[0]);

        const response = await handleFetch(`${type}s/from-file`, {
            method: 'post',
            body: data
        });

        if (!response.ok) {
            console.error(response);
            setIsLoading(false);
            return;
        }

        const result = await response.json();

        result && setUploadResult(result);
        setIsLoading(false);
    };

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: supportedTypes.map(({ extension }) => `.${extension}`).join(', '),
        disabled: isLoading,
        maxSize,
        maxFiles: 1,
        onDropAccepted: acceptedFiles => handleUpload(acceptedFiles)
    });

    const handleDeleteItem = async id => {
        const response = await handleDelete(`${type}s/${id}`);

        if (!response.ok) {
            console.error(response);
            return;
        }

        setUploadResult(null);

        history.push({
            pathname: '/editor',
            state: { type }
        });
    };

    const handleFetchItem = async id => {
        const response = await handleGet(`${type}s/${id}`);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setItem(data);
    };

    const handleGoBack = () => {
        if (item) {
            setShowDeleteItemDialog(true);
        }
        else {
            history.push({
                pathname: '/editor',
                state: { type }
            });
        }
    };

    const handleGoForward = () => {
        history.push(`/editor/${type}/${uploadResult.id}/upload-images`);
    };

    const itemType = type === 'email' ? 'Email' : 'Landing Page';

    useEffect(() => {
        uploadResult?.id && handleFetchItem(uploadResult.id);
    }, [uploadResult]);

    return (
        <>
            <AppBar
                position="sticky"
                color="inherit"
                sx={{ top: 64 }}
            >
                <Toolbar>
                    <div style={{ flexGrow: 1 }}>
                        <Typography component="h1" variant="h6">Upload {itemType}: HTML</Typography>
                    </div>
                    <Button variant="outlined" onClick={handleGoBack} startIcon={<NavigateBeforeIcon />}>Back</Button>
                    <Button variant="outlined" onClick={handleGoForward} endIcon={<NavigateNextIcon />} sx={{ marginLeft: 1 }} disabled={!uploadResult?.succeeded}>Next</Button>
                </Toolbar>
            </AppBar>
            <Box sx={{ p: 2 }}>
                <Paper>
                    <UploadFiles
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        fileRejections={fileRejections}
                        uploadResult={uploadResult}
                        maxSize={maxSize}
                        guidance={(
                            <Typography
                                component="p"
                                variant="body1"
                            >
                                Upload an HTML file with relative paths (e.g. 'file.jpg' or 'images/file.jpg') for any images. You'll be able to upload the images themselves in the next step.
                            </Typography>
                        )}
                        isLoading={isLoading}
                        isDropzoneDisabled={uploadResult?.succeeded}
                    />
                </Paper>
            </Box>
            {showDeleteItemDialog && (
                <ConfirmDeleteDialog
                    onClose={() => setShowDeleteItemDialog(false)}
                    onDelete={handleDeleteItem}
                    item={item}
                    type={type}
                />
            )}
        </>
    );
};

export default UploadHtml;