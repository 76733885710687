import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import enLocale from 'date-fns/locale/en-GB';

const Field = ({ props, disableError, helperText, fullWidth }) => {
    if (disableError) {
        return (
            <TextField helperText={helperText} size="small" margin="none" fullWidth={fullWidth} {...props} error={false} />
        );
    }

    return (
        <TextField helperText={helperText} size="small" margin="none" fullWidth={fullWidth} {...props} />
    );
};

const DateTimeSelector = ({ label, value, setValue, helperText, disabled = false, disablePast = false, minDateTime = null, disableError = false, fullWidth = true }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
            <DateTimePicker
                mask="__/__/____ __:__"
                inputFormat="dd/MM/yyyy HH:mm"
                renderInput={props => <Field props={props} disableError={disableError} helperText={helperText} fullWidth={fullWidth} />}
                label={label}
                value={value}
                onChange={newValue => setValue(newValue)}
                disablePast={disablePast}
                minDateTime={minDateTime}
                disabled={disabled}
            />
        </LocalizationProvider>
    );
};

export default DateTimeSelector;