import React from 'react';
import { Box, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import ReactECharts from 'echarts-for-react';

const Gauge = ({ account, title }) => {
    let totalCredits = 0;
    let totalUsed = 0;

    account.licences.forEach(item => {
        if (item.usageLimit !== item.used) {
            totalCredits += item.usageLimit;
            totalUsed += item.used;
        }
    });

    account.packs.forEach(item => {
        if (item.usageLimit !== item.used) {
            totalCredits += item.usageLimit;
            totalUsed += item.used;
        }
    });

    const availableCredits = totalCredits - totalUsed;

    const max = totalCredits >= 9999
        ? Math.ceil(totalCredits / 1000) * 1000
        : totalCredits === 0
            ? 100
            : totalCredits;

    const options = {
        tooltip: {
            formatter: '{b} : {c}'
        },
        series: [
            {
                name: 'Credits Available',
                type: 'gauge',
                min: 0,
                max: max,
                detail: {
                    formatter: '{value}',
                    textStyle: {
                        fontSize: 18
                    }
                },
                axisLine: {
                    lineStyle: {
                        width: 10,
                        color: [
                            [0.2, '#FD666D'],
                            [0.8, '#37A2DA'],
                            [1, '#67E0E3']
                        ]
                    }
                },
                axisLabel: {
                    distance: -50,
                    formatter: val => totalCredits >= 9999 ? `${Math.floor(val / 1000)}K` : `${val}`
                },
                axisTick: {
                    splitNumber: 10
                },
                splitLine: {
                    show: true,
                    length: 15
                },
                pointer: {
                    show: true
                },
                data: [
                    {
                        value: availableCredits,
                        name: 'Credits Available'
                    }
                ]
            }
        ]
    };

    return (
        <>
            <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
                <div style={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">{title}</Typography>
                </div>
            </Toolbar>
            <Divider />
            <Box>
                <ReactECharts
                    option={options}
                    style={{ height: 350 }}
                    opts={{ renderer: 'svg' }}
                />
            </Box>
        </>
    );
};

export default Gauge;