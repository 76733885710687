import React, { useCallback, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListItem from '@mui/material/ListItem';
import Cropper from 'react-easy-crop';
import useAccount from '../../hooks/useAccount';
import useApi from '../../hooks/useApi';
import Box from '@mui/material/Box';

const EditProfileDialog = ({ displayName, avatarURL, onClose }) => {
    const [crop, setCrop] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [imgSrc, setImgSrc] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const { handlePut } = useApi();
    const { handleRefresh } = useAccount();
    const [showAlert, setShowAlert] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const onCropComplete = useCallback(
        (croppedArea, croppedAreaPixels) => {
            const canvas = document.createElement('canvas');
            const image = new Image();
            image.src = imgSrc;

            const imageFinalSize = 128;
            canvas.width = imageFinalSize;
            canvas.height = imageFinalSize;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(
                image,
                croppedAreaPixels.x,
                croppedAreaPixels.y,
                croppedAreaPixels.width,
                croppedAreaPixels.height,
                0,
                0,
                imageFinalSize,
                imageFinalSize
            );

            setCroppedImage(canvas.toDataURL('image/jpeg'));
        },
        [imgSrc]
    );

    const handleDeselectFile = () => {
        setImgSrc(null);
        setCroppedImage(null);
        setShowAlert(null);
    };

    const handleSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            setShowAlert({ type: 'info', message: 'Select the desired area by dragging the image with your mouse and zooming it with your mouse wheel' });
            const selectedFile = e.target.files[0]; // Gives the user ability to select the same file multiple times without re-opening this dialog
            e.target.value = '';

            if (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png') {
                setCrop({ x: 0, y: 0 });
                setZoom(1);
                const reader = new FileReader();
                reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
                reader.readAsDataURL(selectedFile);
            }
            else {
                handleDeselectFile();
                setShowAlert({ type: 'warning', message: 'Wrong file format selected; only JPEG and PNG images are supported' });
            }
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();

        setIsLoading(true);

        const params = {
            ...(croppedImage && { avatarBase64: croppedImage.split(';base64,')[1] })
        };

        const response = await handlePut('user', params);

        if (!response.ok) {
            setShowAlert({ type: 'error', message: 'Error occurred while attempting to update your details' });
            setIsLoading(false);
            return;
        }

        await handleRefresh();
        setIsLoading(false);

        onClose();
    };

    return (
        <Dialog open={true}>
            <DialogTitle>Update your Avatar</DialogTitle>
            <DialogContent>
                {showAlert && <Alert severity={showAlert.type} sx={{ mb: 2 }}>{showAlert.message}</Alert>}
                {imgSrc && (
                    <>
                        {imgSrc && (
                            <Box display="flex" justifyContent="flex-end" mb={1}>
                                <Button
                                    disabled={isLoading}
                                    variant="contained"
                                    component="label"
                                    size="small"
                                    sx={{ mb: 1 }}
                                >
                                    Update Avatar
                                    <input type="file" accept="image/*" hidden onChange={e => handleSelectFile(e)} />
                                </Button>
                                <Button
                                    disabled={isLoading}
                                    variant="outlined"
                                    onClick={handleDeselectFile}
                                    component="label"
                                    size="small"
                                    sx={{ mb: 1, ml: 1 }}
                                >
                                    Remove
                                </Button>
                            </Box>
                        )}
                        <ListItem sx={{ height: '300px', mb: 2 }}>
                            <Cropper
                                cropShape="round"
                                zoomSpeed={0.5}
                                image={imgSrc}
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </ListItem>
                    </>
                )}
                {!imgSrc && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Avatar src={croppedImage || avatarURL} sx={{ width: 150, height: 150, mb: 1 }}>
                            {name.substring(0, 1)}
                        </Avatar>
                        <Button
                            disabled={isLoading}
                            variant="contained"
                            component="label"
                            size="small"
                            sx={{ mt: 1 }}
                        >
                            Update Avatar
                            <input type="file" accept="image/*" hidden onChange={e => handleSelectFile(e)} />
                        </Button>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    disabled={!croppedImage && name === displayName}
                    onClick={handleSubmit}
                >
                    Save
                </LoadingButton>
                <Button disabled={isLoading} variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditProfileDialog;
