import React, { useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import useAccount from '../../hooks/useAccount';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Typography } from '@mui/material';

const CopyToAccountsDialog = ({ onClose, onSubmit, itemName, type, isCopying = false }) => {
    const [name, setName] = useState(itemName);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const { user } = useAccount();

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(name, selectedAccounts);
    };

    const handleCheckboxChange = useCallback((account) => {
        setSelectedAccounts(prevSelected => {
            const isAlreadySelected = prevSelected.includes(account.id);

            if (isAlreadySelected) {
                return prevSelected.filter(id => id !== account.id);
            }
            else {
                return [...prevSelected, account.id];
            }
        });
    }, [setSelectedAccounts]);

    const handleNameChange = useCallback((e) => {
        setName(e.target.value);
    }, [setName]);

    const item = `${type.charAt(0).toUpperCase()}${type.substring(1)}`;

    return (
        <Dialog onClose={onClose} open={true} maxWidth="md">
            <form onSubmit={handleSubmit}>
                <DialogTitle>Copy {item} to other Account(s): {itemName}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ marginBottom: 2 }}>
                        Name your {item}, by default this will be the same as the source {item}.{type === 'email' && ' This is not the subject line of your Email, only your reference inside NewZapp.'}
                    </DialogContentText>
                    <DialogContentText>
                        Source Account: <strong>{user.account.name}</strong> (<strong>{user.customerId}</strong>)
                    </DialogContentText>
                    <TextField
                        autoFocus
                        onFocus={event => {
                            event.target.select();
                        }}
                        id="name"
                        label={`${item} Name in Destination Account(s)`}
                        type="text"
                        fullWidth
                        value={name}
                        required
                        onChange={handleNameChange}
                        margin="dense"
                    />
                    {user.accounts.length > 1 && (
                        <>
                            <DialogContentText sx={{ marginTop: 2, mb: 1 }}>
                                Destination Account(s):
                            </DialogContentText>
                            <Box sx={{ px: 2 }}>
                                <Grid container spacing={2}>
                                    {user.accounts && user.accounts.filter(account => account.id !== user.customerId).map(account => (
                                        <Grid item xs={6} key={account.id}>
                                            <ListItemButton
                                                dense
                                                disableGutters
                                                disablePadding
                                                onClick={() => handleCheckboxChange(account)}
                                            >
                                                <ListItemIcon sx={{ pl: 2 }}>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={selectedAccounts.some(item => item === account.id)}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={account.name}
                                                    secondary={
                                                        <Typography variant="subtitle2">CID: {account.id}</Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={selectedAccounts.length === 0}
                        type="submit"
                        startIcon={isCopying ? <CircularProgress color="inherit" size={20} /> : null}
                    >
                        {isCopying ? 'Saving' : 'Save'}
                    </Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CopyToAccountsDialog;
