import React, { createContext, useState, forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

Alert.displayName = 'Alert';

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
    const [snackbar, setSnackbar] = useState(null);

    const showSnackbar = (message, severity = 'info') => {
        setSnackbar({ message, severity });
    };

    const handleClose = () => {
        setSnackbar(null);
    };

    const handleClick = event => {
        event.stopPropagation(); // prevents focus from being changed when closing snackbar
        handleClose();
    };

    const isOpen = Boolean(snackbar);

    const getAutoHideDuration = (severity) => {
        const duration = {
            info: 6000,
            error: 8000,
            success: 3000
        };

        return duration[severity] || 6000;
    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}
            {isOpen && (
                <Snackbar
                    open={isOpen}
                    onClose={handleClose}
                    autoHideDuration={getAutoHideDuration(snackbar.severity)}
                    message={snackbar.message}
                    disableWindowBlurListener={true}
                >
                    <Alert onClose={handleClick} severity={snackbar.severity}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            )}
        </SnackbarContext.Provider>
    );
};