import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingBox = () => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ py: 1 }}>
            <CircularProgress />
        </Box>
    );
};

export default LoadingBox;
