import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import BarChartIcon from '@mui/icons-material/BarChart';
import CachedIcon from '@mui/icons-material/Cached';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import DraftsIcon from '@mui/icons-material/Drafts';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import Avatar from '@mui/material/Avatar';
import Statistic from '../components/Statistic';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
// import Sentiment from '../components/Sentiment';
import AutomationEmail from '../components/AutomationEmail';
import { format } from 'date-fns';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditAutomationEmailDialog from '../dialogs/EditAutomationEmailDialog';
import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import useTheme from '@mui/material/styles/useTheme';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const tableCellStyle = {
    borderBottom: { xs: undefined, sm: 0 }
};

const ChipTriggers = ({ triggers }) => triggers.map(e => <Chip key={e.id} label={e.description} size="small" sx={{ marginRight: 1 }} />);

const AutomationMenu = ({ automation, onEdit, onPublish, onDelete }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(e.currentTarget);
    };

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();

        setAnchorEl(null);
    };

    const handleAction = (e, action) => {
        e.stopPropagation();

        action();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                size="small"
                aria-label="Edit Automation"
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={e => handleAction(e, onEdit)} disabled={automation.active}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleAction(e, onPublish)} disabled={!automation.active && automation.emailsCount === 0}>
                    <ListItemIcon>
                        {automation.active ? <PauseIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText>{automation.active ? 'Pause' : 'Publish'}</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleAction(e, onDelete)}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

const Automation = ({ automations, onOpenEditAutomationDialog, onOpenConfirmPublishOrDraftAutomationDialog, onOpenDeleteAutomationDialog, onFetchAutomations, availableTriggers }) => {
    const history = useHistory();
    const { automationId } = useParams();
    const location = useLocation();
    const theme = useTheme();
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();

    const [expanded, setExpanded] = useState(false);
    const [emails, setEmails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [emailToEdit, setEmailToEdit] = useState(null);
    const [emailToDelete, setEmailToDelete] = useState(null);
    const [statistics, setStatistics] = useState(null);
    const [selectedEmailId, setSelectedEmailId] = useState(null);

    const automation = automations.find(e => e.id === parseInt(automationId));

    // const [sentiment, setSentiment] = useState(0);

    const ExpandMore = styled((props) => {
        const { ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    }));

    const handleFetchAutomationEmails = async () => {
        setIsLoading(true);

        const response = await handleGet(`automations/${automation.id}/emails`);

        if (!response.ok) {
            console.error(response?.error);
            setIsLoading(false);
            return;
        }

        setEmails(await response.json());
        setIsLoading(false);
    };

    const handleCalculateStatistics = () => {
        if (!emails) {
            return;
        }

        const sent = emails.map(e => e.sent).reduce((a, b) => a + b, 0);
        const opens = emails.map(e => e.opens).reduce((a, b) => a + b, 0);
        const clicks = emails.map(e => e.clicks).reduce((a, b) => a + b, 0);

        const sentEmails = emails.filter(e => e.sent > 0);
        const openedEmails = emails.filter(e => e.opens > 0);

        //it is *possible* to get clicks without opens, but do we need to handle this?

        const averageOpenRate = sentEmails.length > 0 ? (sentEmails.map(e => (e.opens / e.sent) * 100).reduce((a, b) => (a + b)) / sentEmails.length) : null;
        const averageClickRate = sentEmails.length > 0 ? openedEmails.length > 0 ? (sentEmails.map(e => e.opens ? (e.clicks / e.opens) * 100 : 0).reduce((a, b) => (a + b)) / sentEmails.length) : 0 : null;

        setStatistics({ sent, opens, clicks, averageOpenRate, averageClickRate });
    };

    const handleDeleteAutomationEmail = async id => {
        const response = await handleDelete(`automations/${automation.id}/emails/${id}`);

        if (!response.ok) {
            console.error(response);
        }

        handleFetchAutomationEmails();
        onFetchAutomations();
    };

    const handleUpdateAutomationEmail = async email => {
        const response = await handlePut(`automations/${automation.id}/emails/${email.id}`, email);

        if (!response.ok) {
            console.error(response);
        }

        handleFetchAutomationEmails();
    };

    const handleDuplicateEmail = async email => {
        setIsLoading(true);

        const response = await handlePost(`automations/${automation.id}/emails`, {
            id: email.id,
            name: email.name,
            delay: email.delay,
            subject: email.subject,
            fromEmail: email.fromEmail,
            fromName: email.fromName
        });

        if (!response.ok) {
            //todo handle error with a snackbar
            console.error(response?.error);
            setIsLoading(false);
            return;
        }

        const newEmail = await response.json();
        setIsLoading(false);
        setEmailToEdit(newEmail);
    };

    const handleExpandClick = async () => {
        if (!emails) {
            await handleFetchAutomationEmails();
        }

        setExpanded(!expanded);
    };

    const handleRefreshData = async () => {
        await onFetchAutomations();
        handleFetchAutomationEmails();
    };

    useEffect(() => {
        if (!emails) {
            return;
        }

        handleCalculateStatistics();

        // const sentimentSum = emails.map(e => e.sentiment).reduce((a, b) => a + b, 0);

        // setSentiment(emails.length > 0 ? sentimentSum / emails.length : 0);
    }, [emails]);

    useEffect(() => {
        if (location?.state?.emailId) {
            onFetchAutomations();
            setSelectedEmailId(location.state.emailId);
        }
    }, [location]);

    useEffect(() => {
        selectedEmailId && handleExpandClick();
    }, [selectedEmailId]);

    const triggers = availableTriggers.filter(e => automation.triggerIds.includes(e.id));

    return (
        <>
            <Paper sx={{ paddingX: 3, paddingY: 2 }}>
                <Breadcrumbs>
                    <Link underline="hover" color="inherit" to="/automations">
                        Dashboard
                    </Link>
                    <Typography color="text.primary">
                        {automation.name}
                    </Typography>
                </Breadcrumbs>
            </Paper>
            <Container maxWidth={false} sx={{ marginTop: 3, marginBottom: 2 }}>
                <Card>
                    <CardHeader
                        title={automation.name}
                        titleTypographyProps={{ variant: 'h5' }}
                        sx={{ alignItems: 'start' }}
                        subheader={(
                            <React.Fragment>
                                <Box>
                                    Group: {automation.group?.name ?? 'All Contacts'}
                                </Box>
                                Triggers: <ChipTriggers triggers={triggers} />
                            </React.Fragment>
                        )}
                        avatar={(
                            <Avatar aria-label="Automation" variant="rounded" sx={{ bgcolor: theme.palette.secondary.main }}>
                                <AlarmOnIcon />
                            </Avatar>
                        )}
                        action={(
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Chip
                                    label={automation.active ? 'Running' : automation.dateActive ? 'Paused' : 'Draft'}
                                    color={automation.active ? 'success' : undefined}
                                />
                                <Button
                                    onClick={() => onOpenConfirmPublishOrDraftAutomationDialog(automation)}
                                    startIcon={automation.active ? <PauseIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" />}
                                    disabled={!automation.active && automation.emailsCount === 0}
                                >
                                    {automation.active ? 'Pause' : 'Publish'}
                                </Button>
                                <AutomationMenu
                                    automation={automation}
                                    onEdit={() => onOpenEditAutomationDialog(automation)}
                                    onPublish={() => onOpenConfirmPublishOrDraftAutomationDialog(automation)}
                                    onDelete={() => onOpenDeleteAutomationDialog(automation)}
                                />
                            </Stack>
                        )}
                    />
                    <Divider />
                    <CardContent>
                        <Stack spacing={2}>
                            {statistics ? (
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    {/*
                                     <Grid xs={12} sm={4}>
                                        <Sentiment sentiment={statistics.sent > 0 ? sentiment : null} />
                                    </Grid>
                                    */}
                                    <Grid xs={12} sm={4}>
                                        <Statistic
                                            title="Reach"
                                            subheader="Average open rate for all sent emails in this Automation"
                                            statistic={statistics?.averageOpenRate ?? null}
                                            icon={<DraftsIcon />}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={4}>
                                        <Statistic
                                            title="Engagement"
                                            subheader="Average click rate for all sent emails in this Automation"
                                            statistic={statistics?.averageClickRate ?? null}
                                            icon={<AdsClickIcon />}
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <div>
                                            <Button size="small" onClick={handleRefreshData} startIcon={<CachedIcon />}>
                                                Refresh Statistics
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            ) : (
                                <div>
                                    <Button size="small" onClick={handleRefreshData} startIcon={<BarChartIcon />}>
                                        Load Statistics
                                    </Button>
                                </div>
                            )}
                            <Paper variant="outlined">
                                <Grid container rowSpacing={0} columnSpacing={1}>
                                    <Grid xs={12} sm={6}>
                                        <Table size="small">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell variant="head">
                                                        Total Sent
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {statistics?.sent ?? '-'}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell variant="head">
                                                        Total Opens
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {statistics?.opens ?? '-'}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell variant="head" sx={tableCellStyle}>
                                                        Total Clicks
                                                    </TableCell>
                                                    <TableCell align="right" sx={tableCellStyle}>
                                                        {statistics?.clicks ?? '-'}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <Table size="small">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell variant="head">
                                                        Created
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {format((new Date(automation.created)), 'dd/MM/yyyy HH:mm')}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell variant="head">
                                                        Modified
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {format((new Date(automation.modified)), 'dd/MM/yyyy HH:mm')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Stack>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Box sx={{ flexGrow: 1, marginLeft: 1 }}>
                            {automation.emailsCount} email{automation.emailsCount !== 1 && 's'}
                        </Box>
                        <Button onClick={() => history.push(`/automations/${automation.id}/emails/new`)} disabled={automation.active}>
                            New Email
                        </Button>
                        <ExpandMore
                            expand={emails?.length > 0 && expanded}
                            onClick={handleExpandClick}
                            disabled={automation.emailsCount === 0}
                            aria-expanded={expanded}
                            aria-label="Emails"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </CardActions>
                    <Collapse in={isLoading}>
                        <LinearProgress />
                    </Collapse>
                    {emails?.length === 0 && (
                        <Alert severity="info" square>
                            Create an Email to publish this Automation
                        </Alert>
                    )}
                    {automation.active && (
                        <Alert severity="info" square>
                            A running Automation must be paused before it can be edited.
                        </Alert>
                    )}
                    <Collapse in={selectedEmailId || (emails?.length > 0 && expanded)} timeout={selectedEmailId ? 0 : 'auto'} unmountOnExit>
                        <Stack spacing={2} sx={{ padding: 2, bgcolor: theme.palette.grey[50] }}>
                            {emails?.map(email => (
                                <AutomationEmail
                                    key={email.id}
                                    email={email}
                                    onDeleteEmail={() => setEmailToDelete(email)}
                                    onDuplicateEmail={() => handleDuplicateEmail(email)}
                                    onEditEmail={() => setEmailToEdit(email)}
                                    automationIsActive={automation.active}
                                    selectedEmailId={selectedEmailId}
                                    resetSelected={() => setSelectedEmailId(null)}
                                />
                            ))}
                        </Stack>
                    </Collapse>
                </Card>
            </Container>
            <EditAutomationEmailDialog
                onClose={() => setEmailToEdit(null)}
                onSubmit={handleUpdateAutomationEmail}
                isLoading={isLoading}
                email={emailToEdit}
                isOpen={Boolean(emailToEdit)}
                onNavigateToEditor={() => history.push(`/automations/${automation.id}/emails/${emailToEdit.id}`)}
            />
            {Boolean(emailToDelete) && (
                <ConfirmDeleteDialog
                    onClose={() => setEmailToDelete(null)}
                    onConfirm={handleDeleteAutomationEmail}
                    item={emailToDelete}
                    type="Email"
                />
            )}
        </>
    );
};

export default Automation;