import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import buildUrl from '../../buildUrl';
import { format } from 'date-fns';
import useApi from '../../hooks/useApi';
import ExportCompleteDialog from './ExportCompleteDialog';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import useSnackbar from '../../hooks/useSnackbar';
import DatePicker from '../../DatePicker';

const rowsPerPageOptions = [10, 20, 50];

const Dialogs = { EXPORT_COMPLETE: 1 };

const Auditing = () => {
    const [isInitialising, setIsInitialising] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [actions, setActions] = useState([]);
    const [actionsCount, setActionsCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [sections, setSections] = useState([]);
    const [username, setUsername] = useState(null);
    const [sectionId, setSectionId] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [dialog, setDialog] = useState(null);
    const [exportPath, setExportPath] = useState();
    const [isExporting, setIsExporting] = useState(false);
    const { handleGet, handlePost } = useApi();
    const { showSnackbar } = useSnackbar();

    const getActionsCount = async () => {
        const filterParams = {
            ...(username && { username }),
            ...(sectionId && { sectionId }),
            ...(fromDate && { startDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { endDate: format(toDate, 'yyyy-MM-dd') })
        };

        const url = buildUrl('account/audit-actions/count', filterParams);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setActionsCount(data);
        setIsLoading(false);
    };

    const getActions = async () => {
        const params = {
            ...(username && { username }),
            ...(sectionId && { sectionId }),
            ...(fromDate && { startDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { endDate: format(toDate, 'yyyy-MM-dd') }),

            skip: page * rowsPerPage,
            take: rowsPerPage
        };

        const url = buildUrl('account/audit-actions', params);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setActions(data);
        setIsLoading(false);
    };

    const getUsers = async () => {
        const response = await handleGet('account/audit-actions/users');

        if (!response.ok) {
            return;
        }

        setIsLoading(false);

        return await response.json();
    };

    const getSections = async () => {
        const response = await handleGet('account/audit-actions/sections');

        if (!response.ok) {
            return;
        }

        setIsLoading(false);

        return await response.json();
    };

    const handleInit = async () => {
        setIsInitialising(true);
        setUsers(await getUsers());
        setSections(await getSections());
        await getActionsCount();
        await getActions();
        setIsInitialising(false);
    };

    const handleExport = async () => {
        setIsExporting(true);

        const url = buildUrl('account/audit-actions/export',
            {
                ...(username && { username }),
                ...(sectionId && { sectionId }),
                ...(fromDate && { startDate: format(fromDate, 'yyyy-MM-dd') }),
                ...(toDate && { endDate: format(toDate, 'yyyy-MM-dd') })
            }
        );

        try {
            const response = await handlePost(url);
            const data = await response.json();
            setExportPath(data.downloadPath);
            setDialog(Dialogs.EXPORT_COMPLETE);
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            showSnackbar('Export failed', 'error');
        }
        finally {
            setIsExporting(false);
        }
    };

    useEffect(() => {
        handleInit();
    }, []);

    useEffect(() => {
        if (!isInitialising) {
            getActionsCount();
        }
    }, [username, sectionId, fromDate, toDate]);

    useEffect(() => {
        if (!isInitialising) {
            setPage(0);
        }
    }, [rowsPerPage, username, sectionId, fromDate, toDate]);

    useEffect(() => {
        if (!isInitialising) {
            setIsLoading(true);
        }
    }, [rowsPerPage, username, sectionId, fromDate, toDate, page]);

    useEffect(() => {
        if (!isInitialising && isLoading) {
            getActions();
        }
    }, [isLoading]);

    const AuditActionsTable = () => {
        if (actions.length === 0) {
            return (
                <Typography sx={{ p: 2 }}>
                    No audit activity found.
                </Typography>
            );
        }

        return (
            <>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row">Date/time</TableCell>
                                <TableCell component="th" scope="row">Username</TableCell>
                                <TableCell component="th" scope="row">Section</TableCell>
                                <TableCell component="th" scope="row">Action</TableCell>
                                <TableCell component="th" scope="row">Item</TableCell>
                                <TableCell component="th" scope="row" align="right">IP Address</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {actions.map(action => (
                                <TableRow key={action.id}>
                                    {/* <TableCell>{new Date(action.dateTime).toLocaleString('en-GB')}</TableCell> */}
                                    <TableCell>{action.dateTime ? new Date(action.dateTime).toLocaleString('en-GB') : '-'}</TableCell>
                                    <TableCell>{action.username ? action.username : '-'}</TableCell>
                                    <TableCell>{action.section}</TableCell>
                                    <TableCell>{action.action}</TableCell>
                                    <TableCell>{action.item}</TableCell>
                                    <TableCell align="right">{action.ipAddress}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={actionsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                />
            </>
        );
    };

    if (isInitialising) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64 }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">Audit Trail</Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{ p: 2 }}>
                <Paper>
                    <Toolbar disableGutters={true} sx={{ px: 2, mb: 1 }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <FormControl sx={{ mr: 1 }}>
                                <Select
                                    size="small"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                    displayEmpty={true}
                                    renderValue={value => (value ?? 'All Users')}
                                >
                                    <MenuItem value={null}>All Users</MenuItem>
                                    {users.map(u => <MenuItem key={u.username} value={u.username}>{u.username}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ mr: 1 }}>
                                <Select
                                    size="small"
                                    value={sectionId}
                                    onChange={e => setSectionId(e.target.value)}
                                    displayEmpty={true}
                                    renderValue={value => (value ? sections.find(s => s.id === value).name : 'All Sections')}
                                >
                                    <MenuItem value={null}>All Sections</MenuItem>
                                    {sections.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <DatePicker value={fromDate} onChange={setFromDate} label="From Date" margin="normal" />
                            <DatePicker value={toDate} onChange={setToDate} label="To Date" margin="normal" />
                        </Box>
                        <Button onClick={handleExport}>Export</Button>
                    </Toolbar>
                    <Divider />
                    <AuditActionsTable />
                </Paper>
            </Box>
            {(isExporting || isLoading) && (
                <LoadingOverlay />
            )}
            {dialog === Dialogs.EXPORT_COMPLETE && (
                <ExportCompleteDialog
                    downloadPath={exportPath}
                    onClose={() => setDialog(null)}
                />
            )}
        </>
    );
};

export default Auditing;