import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const DeleteUserDialog = ({ user, onSubmit, onClose }) => {
    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Delete User</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are about to delete the user <strong>{user.emailAddress}</strong>. This user will no longer be able to access this NewZapp account.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSubmit}>OK</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteUserDialog;