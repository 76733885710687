import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import SegmentSelector from '../contacts/SegmentSelector';
import SegmentsFilters from '../contacts/SegmentsFilters';
import useApi from '../hooks/useApi';
import buildUrl from '../buildUrl';

const FilteredSegmentSelector = ({ selectedSegment, setSelectedSegment, limitedHeight = true, openCreateSegmentDialog = null }) => {
    const [segments, setSegements] = useState([]);
    const [unsortedSegments, setUnsortedSegments] = useState([]);
    const [sort, setSort] = useState('alphaAsc');
    const [segmentsSearchValue, setSegmentsSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isCountLoading, setCountIsLoading] = useState(false);
    const [segmentsTempSearchValue, setSegmentsTempSearchValue] = useState('');
    const [selectedSegmentContactsCount, setSelectedSegmentContactsCount] = useState(null);
    const { handleGet } = useApi();

    const limitedHeightStyle = { height: '80vh', overflowY: 'scroll' };

    const handleFetchSegments = async () => {
        setIsLoading(true);

        const response = await handleGet('segments');

        if (!response.ok) {
            setIsLoading(false);
            console.error(response?.error);
            return;
        }

        const unsortedSegments = await response.json();

        setUnsortedSegments(unsortedSegments);
        setIsLoading(false);
    };

    const handleFetchNumberOfSubscribers = async selectedSegment => {
        setCountIsLoading(true);

        const url = buildUrl('campaigns/audience-count', { segmentId: selectedSegment, includeSuppressed: true });

        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const count = await response.json();

        setSelectedSegmentContactsCount(count);
        setCountIsLoading(false);
    };

    useEffect(() => {
        selectedSegment && handleFetchNumberOfSubscribers(selectedSegment);
    }, [selectedSegment]);

    useEffect(() => {
        if (!unsortedSegments || unsortedSegments.length === 0) {
            setSegements(unsortedSegments);
            return;
        }

        let segments = [...unsortedSegments];

        if (sort === 'alphaDesc') {
            segments.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                return nameB.localeCompare(nameA);
            });
        }

        if (sort === 'alphaAsc') {
            segments.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                return nameA.localeCompare(nameB);
            });
        }

        if (sort === 'dateDesc') {
            segments.sort((a, b) => b.id - a.id);
        }

        if (sort === 'dateAsc') {
            segments.sort((a, b) => a.id - b.id);
        }

        if (segmentsSearchValue) {
            segments = segments.filter(e => e.name.toLowerCase().includes(segmentsSearchValue.toLowerCase()));
        }

        setSegements(segments);
    }, [unsortedSegments, sort, segmentsSearchValue]);

    useEffect(() => {
        handleFetchSegments();
    }, []);

    return (
        <>
            <SegmentsFilters
                openCreateSegmentDialog={openCreateSegmentDialog}
                segmentsSort={sort}
                setSegmentsSort={sort => setSort(sort)}
                segmentsTempSearchValue={segmentsTempSearchValue}
                setSegmentsTempSearchValue={setSegmentsTempSearchValue}
                setSegmentsSearchValue={segmentsSearchValue => setSegmentsSearchValue(segmentsSearchValue)}
            />
            <Box sx={limitedHeight ? limitedHeightStyle : null} py={2.5}>
                <Collapse in={isLoading}>
                    <LinearProgress />
                </Collapse>
                {segments.length > 0 && (
                    <SegmentSelector
                        segments={segments}
                        selectedSegment={selectedSegment}
                        setSelectedSegment={setSelectedSegment}
                        selectedSegmentContactsCount={selectedSegmentContactsCount}
                        isCountLoading={isCountLoading}
                    />
                )}
            </Box>
        </>
    );
};

export default FilteredSegmentSelector;