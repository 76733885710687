import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { colours } from '../theme/constants';

const Metric = ({ figure, percentage = null, text, reverse = false }) => {
    return (
        <>
            <Box
                sx={{
                    color: reverse ? '#FFF' : colours.primary,
                    fontWeight: 'bold',
                    fontSize: '2rem',
                    lineHeight: 1,
                    marginBottom: 0.5
                }}
            >
                {figure}
            </Box>
            {percentage && (
                <Box
                    sx={{
                        color: reverse ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)',
                        fontSize: '1.6rem',
                        lineHeight: 1,
                        marginBottom: 0.5
                    }}
                >
                    ({percentage})
                </Box>
            )}
            <Typography
                variant="body2"
            >
                {text}
            </Typography>
        </>
    );
};

export default Metric;