import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LinearProgress from '@mui/material/LinearProgress';
import GroupSelector from '../contacts/GroupSelector';
import GroupsFilters from '../contacts/GroupsFilters';
import useApi from '../hooks/useApi';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CreateGroupDialog from '../contacts/dialogs/CreateGroupDialog';

const SelectedGroups = ({ allGroups, selectedGroups, includeAllContacts, multipleSelection }) => {
    if (!multipleSelection) {
        if (allGroups && selectedGroups) {
            const groupId = selectedGroups[0];
            const groupName = allGroups.find(e => e.id === groupId)?.name;

            return (
                <Alert severity="info" square icon={false}>
                    <AlertTitle>
                        Selected Groups ({selectedGroups?.length ?? 1})
                    </AlertTitle>
                    {groupName || <em>None</em>}
                </Alert>
            );
        }
    }

    else if (allGroups && selectedGroups) {
        const items = allGroups.filter(e => selectedGroups.includes(e.id));

        const groupNames = items.map(e => e.name).join(', ');

        return (
            <Alert severity="info" square icon={false}>
                <AlertTitle>
                    Selected Groups ({items.length})
                </AlertTitle>
                {groupNames || <em>None</em>}
            </Alert>
        );
    }

    if (includeAllContacts && selectedGroups === null) {
        return (
            <Alert severity="info" square icon={false}>
                <AlertTitle>
                    Selected Groups (1)
                </AlertTitle>
                All Contacts
            </Alert>
        );
    }

    return (
        <Alert severity="info" square icon={false}>
            <AlertTitle>
                Selected Groups (0)
            </AlertTitle>
        </Alert>
    );
};

const FilteredGroupsSelector = ({ selectedGroups, setSelectedGroups, popperGutters = 0, spacing = 0, limitedHeight = true, multipleSelection = true, includeAllContacts = false, allContactsCount = 0, onCreateGroup = null, newGroup = true }) => {
    const [groups, setGroups] = useState(null);
    const [unsortedGroups, setUnsortedGroups] = useState([]);
    const [filterTagIds, setFilterTagIds] = useState([]);
    const [groupsSort, setGroupsSort] = useState('alphaAsc');
    const [groupType, setGroupType] = useState(null);
    const [groupsSearchValue, setGroupsSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [allGroups, setAllGroups] = useState(null);
    const [groupsTempSearchValue, setGroupsTempSearchValue] = useState('');
    const { handleGet } = useApi();
    const [showDialog, setShowDialog] = useState(false);

    const limitedHeightStyle = { height: '80vh', overflowY: 'scroll' };

    const handleFetchGroups = async () => {
        setIsLoading(true);

        const response = await handleGet('groups');

        if (!response.ok) {
            setIsLoading(false);
            console.error(response?.error);
            return;
        }

        const unsortedGroups = await response.json();

        setAllGroups(unsortedGroups);
        setUnsortedGroups(unsortedGroups);
        setIsLoading(false);
    };

    const handleCreateGroup = async group => {
        setShowDialog(false);
        await handleFetchGroups();
        setSelectedGroups(multipleSelection ? selectedGroups.concat(group.id) : [group.id]);
        onCreateGroup && onCreateGroup(group);
    };

    const handleResetFilters = () => {
        setFilterTagIds([]);
        setGroupType(null);
        setGroupsTempSearchValue('');
        setGroupsSearchValue('');
    };

    const handleFilterByTag = tagId => {
        if (filterTagIds.includes(tagId)) {
            setFilterTagIds(filterTagIds.filter(e => e !== tagId));
        }
        else {
            setFilterTagIds([...filterTagIds, tagId]);
        }
    };

    useEffect(() => {
        handleFetchGroups();
    }, []);

    useEffect(() => {
        if (!unsortedGroups || unsortedGroups.length === 0) {
            setGroups(unsortedGroups);
            return;
        }

        let sortedGroups = [...unsortedGroups];

        if (groupsSort === 'alphaDesc') {
            sortedGroups.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                return nameB.localeCompare(nameA);
            });
        }

        if (groupsSort === 'alphaAsc') {
            sortedGroups.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                return nameA.localeCompare(nameB);
            });
        }

        if (groupsSort === 'dateDesc') {
            sortedGroups.sort((a, b) => b.id - a.id);
        }

        if (groupsSort === 'dateAsc') {
            sortedGroups.sort((a, b) => a.id - b.id);
        }

        if (groupsSearchValue) {
            sortedGroups = sortedGroups.filter(e => e.name.toLowerCase().includes(groupsSearchValue.toLowerCase()));
        }

        if (groupType) {
            sortedGroups = sortedGroups.filter(e => e.type === groupType);
        }
        else {
            sortedGroups = sortedGroups.filter(e => e.type !== 'archive');
        }

        if (filterTagIds?.length > 0) {
            sortedGroups = sortedGroups.filter(e => filterTagIds.every((tagId) => e.tags.map(t => t.id).includes(tagId)));
        }

        setGroups(sortedGroups);
    }, [groupsSort, groupType, groupsSearchValue, filterTagIds, unsortedGroups]);

    const selectAll = () => {
        let newSelectedGroups = [...selectedGroups];

        if (groups?.every(g => selectedGroups !== null && selectedGroups.includes(g.id))) {
            newSelectedGroups = selectedGroups.filter(id => !groups.map(g => g.id).includes(id));
            setSelectedGroups(newSelectedGroups);
        }
        else {
            groups?.forEach(g => {
                if (!selectedGroups.includes(g.id)) {
                    newSelectedGroups = [...newSelectedGroups, g.id];
                }
            });

            setSelectedGroups(newSelectedGroups);
        }
    };

    return (
        <>
            <Box sx={{
                px: spacing,
                position: 'relative' //required to contain the search popper. Ideally this needs to self-contain
            }}>
                <GroupsFilters
                    disabled = {newGroup ? false : true}
                    openCreateGroupDialog={() => setShowDialog(true)}
                    groupsSort={groupsSort}
                    setGroupsSort={groupsSort => setGroupsSort(groupsSort)}
                    groupType={groupType}
                    setGroupType={groupType => setGroupType(groupType)}
                    onResetFilters={handleResetFilters}
                    filterTagIds={filterTagIds}
                    groupsTempSearchValue={groupsTempSearchValue}
                    setGroupsTempSearchValue={setGroupsTempSearchValue}
                    setGroupsSearchValue={groupsSearchValue => setGroupsSearchValue(groupsSearchValue)}
                    groupsSearchValue={groupsSearchValue}
                    onFilterByTag={handleFilterByTag}
                    popperGutters={popperGutters}
                />
                <SelectedGroups
                    allGroups={allGroups}
                    selectedGroups={selectedGroups}
                    includeAllContacts={includeAllContacts}
                    multipleSelection={multipleSelection}
                />
            </Box>
            <Box sx={limitedHeight ? limitedHeightStyle : null} py={2.5} px={spacing}>
                <Collapse in={isLoading}>
                    <LinearProgress />
                </Collapse>
                {groups ? (
                    <>
                        {multipleSelection && (
                            <ListItem disablePadding>
                                <ListItemButton
                                    dense
                                    onClick={selectAll}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={groups?.every(g => selectedGroups !== null && selectedGroups.includes(g.id))}
                                            indeterminate={!groups?.every(g => selectedGroups !== null && selectedGroups.includes(g.id)) && selectedGroups.length > 0}
                                            tabIndex={-1}
                                            disableRipple
                                            onChange={selectAll}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Select All" />
                                </ListItemButton>
                            </ListItem>
                        )}
                        <GroupSelector
                            groups={groups}
                            selectedGroups={selectedGroups}
                            setSelectedGroups={setSelectedGroups}
                            onFilterByTag={handleFilterByTag}
                            multipleSelection={multipleSelection}
                            includeAllContacts={includeAllContacts}
                            isFiltered={groupsSearchValue !== '' || filterTagIds.length > 0 || groupType !== null}
                            allContactsCount={allContactsCount}
                        />
                    </>
                ) : null}
            </Box>
            {
                // newGroup && (
                showDialog && (
                    <CreateGroupDialog
                        onSubmit={handleCreateGroup}
                        onClose={() => setShowDialog(false)}
                    />
                )
                // )
            }
        </>
    );
};

export default FilteredGroupsSelector;