import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const ConfirmDeleteQRCodeDialog = ({ onClose, onDelete, qrCode }) => {
    const handleDelete = e => {
        e.preventDefault();
        onDelete(qrCode);
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Delete QR Code: {qrCode.name}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please confirm you wish to delete this QR Code.
                </DialogContentText>
                <Alert severity="warning" sx={{ mt: 2 }}>
                    This action cannot be undone.
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDelete}>Confirm</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteQRCodeDialog;
