import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import useApi from '../../hooks/useApi';
import useSnackbar from '../../hooks/useSnackbar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';

import AddCustomFontModal from './modal/AddCustomFontModal';
import EditCustomFontModal from './modal/EditCustomFontModal';

const CustomFonts = ({ account, onRefresh }) => {
    const { id } = useParams();
    const { handlePost, handleDelete, handlePut } = useApi();
    const [customFontDialog, setCustomFontDialog] = useState(false);
    const [editFontDialog, setEditFontDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFont, setSelectedFont] = useState(null);
    const { showSnackbar } = useSnackbar();
    const [fontLoading, setIsFontLoading] = useState(false);

    const handleEditDialog = (row) => {
        setEditFontDialog(true);
        setSelectedFont(row);
    };

    const handleCloseEditDialog = () => {
        setEditFontDialog(false);
        setSelectedFont(null);
    };

    const handleOnSubmit = async (data) => {
        setIsLoading(true);

        const uri = `/superadmin/accounts/${id}/fonts`;
        const response = await handlePost(uri, data);

        setIsLoading(false);

        if (!response.ok) {
            showSnackbar('An error occurred.', 'error');
        }
        else {
            showSnackbar('Font added successfully.', 'success');
            setCustomFontDialog(false);
        }

        onRefresh();
    };

    const handleOnEdit = async (data) => {
        setIsLoading(true);

        const uri = `/superadmin/accounts/${id}/fonts/${selectedFont.id}`;
        const response = await handlePut(uri, data);

        setIsLoading(false);

        if (!response.ok) {
            showSnackbar('An error occurred.', 'error');
        }
        else {
            showSnackbar('Font edited successfully.', 'success');
            setEditFontDialog(false);
        }

        onRefresh();
    };

    const handleDeleteFont = async (row) => {
        setIsLoading(true);

        const uri = `/superadmin/accounts/${id}/fonts/${row.id}`;
        const response = await handleDelete(uri);

        setIsLoading(false);

        if (!response.ok) {
            showSnackbar('An error occurred.', 'error');
        }
        else {
            showSnackbar('Font deleted successfully.', 'success');
            setCustomFontDialog(false);
        }

        onRefresh();
    };

    useEffect(() => {
        if (account.customFonts && account.customFonts.length > 0) {
            setIsFontLoading(true);

            const fontLinks = account.customFonts
                .filter(element => element.url && element.url.length > 0)
                .map(element => {
                    if (element.url.length > 0) {
                        const link = document.createElement('link');
                        link.setAttribute('rel', 'stylesheet');
                        link.href = element.url;
                        return link;
                    }
                });

            if (fontLinks.length === 0) {
                setIsFontLoading(false);
                return;
            }

            let loadedFontsCount = 0;

            const handleFontLoad = () => {
                loadedFontsCount++;

                if (loadedFontsCount === fontLinks.length) {
                    setIsFontLoading(false);
                }
            };

            fontLinks.forEach(link => {
                link.onload = handleFontLoad;
                document.head.appendChild(link);
            });

            return () => {
                fontLinks.forEach(link => {
                    link.onload = null;
                });
            };
        }
        else {
            setIsFontLoading(false);
        }
    }, [account.customFonts]);

    if (fontLoading) {
        return <CircularProgress size={16} color="inherit" />;
    }

    return (
        <>
            <Box>
                <Button variant="contained" onClick={() => setCustomFontDialog(true)}>Add Brand Font</Button>
                {account.customFonts.length > 0 ? (
                    <Box sx={{ flexGrow: 1, mt: 2 }}>
                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {account.customFonts.map((font, index) => (
                                <Grid item xs={4} sm={4} md={3} key={index}>
                                    <Card style={{ overflow: 'hidden' }}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {font.label}
                                            </Typography>
                                            <Typography gutterBottom variant="body1" component="div" style={{ fontFamily: font.fontFamily }}>
                                                {font.name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" style={{ fontFamily: font.fontFamily }}>
                                                The quick brown fox jumps over the lazy dog
                                            </Typography>
                                        </CardContent>
                                        <CardActions sx={{ justifyContent: 'flex-end' }}>
                                            <IconButton size="small" color="primary" onClick={() => handleEditDialog(font)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton size="small" color="primary" onClick={() => handleDeleteFont(font)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ) : (
                    <Alert sx={{ mt: 2 }} severity="info">No Brand Fonts available.</Alert>
                )}
            </Box>
            {customFontDialog && (
                <AddCustomFontModal
                    shown={customFontDialog}
                    onHide={() => setCustomFontDialog(false)}
                    onSubmit={handleOnSubmit}
                    onLoading={isLoading}
                />
            )}
            {editFontDialog && (
                <EditCustomFontModal
                    shown={editFontDialog}
                    onHide={handleCloseEditDialog}
                    onSubmit={handleOnEdit}
                    onLoading={isLoading}
                    data={selectedFont}
                />
            )}
        </>
    );
};

export default CustomFonts;