import React, { useState, useMemo, useEffect, useRef } from 'react';
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, useGridApiContext, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridToolbarDensitySelector, GridToolbarQuickFilter, getGridStringOperators } from '@mui/x-data-grid';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import InputLabel from '@mui/material/InputLabel';
import Badge from '@mui/material/Badge';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import { styled } from '@mui/material/styles';
import { colours } from '../theme/constants';
import NoRows from '../dataTable/NoRows';
import useLocalStorage from '../hooks/useLocalStorage';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ListItemText from '@mui/material/ListItemText';
import ChooseColumnsDialog from '../contacts/dialogs/ChooseColumnsDialog';
import FilteredGroupsSelector from '../contacts/FilteredGroupsSelector';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import useTheme from '@mui/material/styles/useTheme';

const ActionsMenu = ({ selectedGroup, selectedPseudoGroup, onExport, onCopy, onMove, onDeleteFromGroup, onDeleteFromMultipleGroups, onDeleteFromAccount, onSuppress, onUnsuppress, onUnsubscribe, selectedContacts, onWarnNoSelection, onResubscribe, status }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = action => {
        selectedContacts ? action() : onWarnNoSelection();
        handleClose();
    };

    return (
        <>
            <Button
                id="actions-menu-button"
                aria-controls={open ? 'actions-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpen}
                endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            >
                Actions
            </Button>
            <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'actions-menu-button'
                }}
            >
                <MenuItem onClick={() => handleClick(onCopy)}>Copy to Another Group</MenuItem>
                {selectedGroup && <MenuItem onClick={() => handleClick(onMove)}>Move to Another Group</MenuItem>}
                <MenuItem onClick={() => handleClick(onSuppress)}>Suppress</MenuItem>
                <MenuItem onClick={() => handleClick(onUnsuppress)}>Unsuppress</MenuItem>
                {(status !== 'unsubscribed' && status !== 'bounced') && <MenuItem onClick={() => handleClick(onUnsubscribe)}>Unsubscribe</MenuItem>}
                {(status === 'unsubscribed' || status === 'bounced') && <MenuItem onClick={() => handleClick(onResubscribe)}>Resubscribe</MenuItem>}
                {selectedGroup && <MenuItem onClick={() => handleClick(onDeleteFromGroup)}>Delete from This Group</MenuItem>}
                {(selectedPseudoGroup !== 'ungrouped') && <MenuItem onClick={() => handleClick(onDeleteFromMultipleGroups)}>Delete from Multiple Groups</MenuItem>}
                {(status !== 'unsubscribed' && status !== 'bounced') && <MenuItem onClick={() => handleClick(onDeleteFromAccount)}>Delete from Your Account</MenuItem>}
                <MenuItem onClick={() => handleClick(onExport)}>Export</MenuItem>
            </Menu>
        </>
    );
};

const NewViewListButton = ({ onSubmit, views }) => {
    const [isAddingView, setIsAddingView] = useState(false);
    const [label, setLabel] = useState('');

    const handleSubmitForm = e => {
        e.preventDefault();
        onSubmit(label);
        setIsAddingView(false);
        setLabel('');
    };

    const handleLabelChange = (event) => {
        setLabel(event.target.value);
        event.stopPropagation();
    };

    const isNewViewLabelValid = useMemo(() => {
        if (label.length === 0) {
            return false;
        }

        return Object.values(views).every(
            (view) => view.label !== label
        );
    }, [views, label]);

    return (
        <React.Fragment>
            <Button
                startIcon={<AddIcon />}
                size="small"
                onClick={() => setIsAddingView(true)}
                variant="text"
            >
                Save current view
            </Button>
            <Dialog onClose={() => setIsAddingView(false)} open={isAddingView}>
                <form onSubmit={handleSubmitForm}>
                    <DialogTitle>New View</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Saved views will be available on this device until they are deleted.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            value={label}
                            onChange={handleLabelChange}
                            margin="dense"
                            size="small"
                            label="Name"
                            variant="standard"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" type="button" onClick={() => setIsAddingView(false)}>
                            Cancel
                        </Button>
                        <Button type="submit" disabled={!isNewViewLabelValid}>
                            Create View
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
};

const ViewControls = ({ filters, setFilters, views, setViews, activeViewId, setActiveViewId }) => {
    const apiRef = useGridApiContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [viewToDelete, setViewToDelete] = useState(null);
    const theme = useTheme();

    const handleCreateNewView = label => {
        //todo(?) use this to load the most recent saved view by default
        const timestamp = new Date().getTime();

        const { columns, pagination, pinnedColumns, sorting } = apiRef.current.exportState();

        const activeFilters = filters?.filter(e => e.field);

        const value = {
            columns,
            pagination,
            pinnedColumns,
            sorting,
            ...(activeFilters && { filters: activeFilters })
        };

        setViews({
            ...views,
            [timestamp]: { label, value }
        });
        setActiveViewId(timestamp);
    };

    const handleDeleteView = (viewId) => {
        const newViews = Object.fromEntries(
            Object.entries(views).filter(([id]) => id !== viewId)
        );

        let newActiveViewId = activeViewId;

        if (activeViewId === viewId) {
            const viewIds = Object.keys(views);

            if (viewIds.length === 0) {
                newActiveViewId = null;
            }
            else {
                newActiveViewId = viewIds[0];
            }
        }

        setViews(newViews);
        setActiveViewId(newActiveViewId);
    };

    const handleSetActiveView = (viewId) => {
        const viewValue = views[viewId].value;

        apiRef.current.restoreState(viewValue);

        setActiveViewId(viewId);
        setIsMenuOpen(false);

        //if view has filters (with field, ie not just the template), set them as the filters, overriding what was there
        //if no filters, clear filters for consistent behaviour
        const filters = viewValue?.filters?.filter(e => e.field);

        setFilters(filters || null);
    };

    const handlePopperAnchorClick = (event) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopper = () => {
        setIsMenuOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            handleClosePopper();
        }
        else if (event.key === 'Escape') {
            handleClosePopper();
        }
    };

    const canBeMenuOpened = isMenuOpen && Boolean(anchorEl);
    const popperId = canBeMenuOpened ? 'transition-popper' : undefined;

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Button
                    aria-describedby={popperId}
                    variant="text"
                    type="button"
                    size="small"
                    id="view-button"
                    aria-controls={isMenuOpen ? 'view-menu' : undefined}
                    aria-expanded={isMenuOpen ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handlePopperAnchorClick}
                    disabled={Object.keys(views).length === 0}
                >
                    Saved Views ({Object.keys(views).length})
                </Button>
                <ClickAwayListener onClickAway={handleClosePopper}>
                    <Popper
                        id={popperId}
                        open={isMenuOpen}
                        anchorEl={anchorEl}
                        role={undefined}
                        transition
                        placement="bottom-start"
                        sx={{ zIndex: theme.zIndex.modal }}
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper>
                                    <MenuList
                                        autoFocusItem={isMenuOpen}
                                        id="view-menu"
                                        aria-labelledby="view-button"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        {Object.entries(views).map(([viewId, view]) => (
                                            <MenuItem
                                                key={viewId}
                                                selected={viewId === activeViewId}
                                                onClick={() => handleSetActiveView(viewId)}
                                            >
                                                <ListItemText>{view.label}</ListItemText>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    size="small"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setViewToDelete({ id: viewId, label: view.label });
                                                    }}
                                                    sx={{ marginLeft: 2 }}
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </ClickAwayListener>
                <NewViewListButton
                    views={views}
                    onSubmit={handleCreateNewView}
                />
            </Stack>
            <Dialog onClose={() => setViewToDelete(null)} open={Boolean(viewToDelete)}>
                <DialogTitle>Delete View</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete '{viewToDelete?.label ?? 'this view'}'?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" type="button" onClick={() => setViewToDelete(null)}>
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteView(viewToDelete?.id)}>
                        Delete View
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const DataGridTools = ({
    count,
    selectedContacts,
    allContactsSelected,
    onToggleAllContactsSelected,
    selectedGroup,
    selectedPseudoGroup,
    setSelectedSegment,
    onShowFilters,
    filters,
    setFilters,
    status,
    setStatus,
    tab,
    groups,
    selectedSegment,
    allContactsCount,
    onExport,
    onCopy,
    onMove,
    onDeleteFromGroup,
    onDeleteFromMultipleGroups,
    onDeleteFromAccount,
    onSuppress,
    onUnsuppress,
    onUnsubscribe,
    views,
    setViews,
    activeViewId,
    setActiveViewId,
    onWarnNoSelection,
    onResubscribe,
    setShowChooseColumnsDialog,
    onCreateGroup // TODO use a state hook
}) => {
    const showSelectedTools = selectedContacts.length > 0 || allContactsSelected;
    const selectedCount = allContactsSelected ? count : selectedContacts.length;
    const ref = useRef();
    const [hasFocus, setHasFocus] = useState(false);
    const [showChooseSegmentGroupDialog, setShowChooseSegmentGroupDialog] = useState(false);
    const segmentGroupName = selectedSegment?.groupId ? groups.find(e => e.id === selectedSegment?.groupId)?.name : 'All Contacts';

    useEffect(() => {
        if (document.hasFocus() && ref.current?.contains(document.activeElement)) {
            setHasFocus(true);
        }
    }, []);

    //segments
    if (tab === 1) {
        return (
            <>
                <Toolbar sx={selectedSegment ? null : { justifyContent: 'flex-end' }}>
                    {selectedSegment && (
                        <Box sx={{ flexGrow: 1 }}>
                            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                <Button
                                    endIcon={<OpenInNewIcon />}
                                    onClick={() => setShowChooseSegmentGroupDialog(true)}
                                >
                                    Choose Group: {segmentGroupName}
                                </Button>
                                <Button
                                    onClick={onExport}
                                >
                                    Export
                                </Button>
                            </Stack>
                        </Box>
                    )}
                    <Stack direction="row" spacing={1}>
                        <Button
                            variant="text"
                            onClick={() => setShowChooseColumnsDialog(true)}
                            startIcon={<ViewColumnIcon />}
                        >
                            Columns
                        </Button>
                        <GridToolbarDensitySelector variant="text" />
                        <Button
                            variant="text"
                            onClick={onShowFilters}
                            startIcon={<Badge badgeContent={filters?.length} color="primary"><FilterListIcon /></Badge>}
                            disabled={tab === 1 && !selectedSegment}
                        >
                            Filters
                        </Button>
                    </Stack>
                </Toolbar>
                <Divider />
                <Dialog onClose={() => setShowChooseSegmentGroupDialog(false)} open={showChooseSegmentGroupDialog}>
                    <DialogTitle>Choose Group</DialogTitle>
                    <FilteredGroupsSelector
                        selectedGroups={selectedSegment?.groupId ? [selectedSegment?.groupId] : null}
                        setSelectedGroups={selectedGroups => {
                            const groupId = selectedGroups && selectedGroups.length > 0 ? selectedGroups[0] : null;
                            setSelectedSegment({ ...selectedSegment, groupId });
                        }}
                        spacing={3}
                        popperGutters={3}
                        multipleSelection={false}
                        includeAllContacts={true}
                        allContactsCount={allContactsCount}
                        onCreateGroup={onCreateGroup} // TODO use a hook
                    />
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setShowChooseSegmentGroupDialog(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    if (hasFocus) {
        return (
            <>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <GridToolbarQuickFilter
                            debounceMs={1000}
                            sx={{ width: '100%' }}
                            InputProps={{
                                autoFocus: true,
                                onFocus: () => setHasFocus(true),
                                onBlur: () => setHasFocus(false)
                            }}
                            placeholder="Search Email..."
                        />
                    </Box>
                </Toolbar>
                <Divider />
            </>
        );
    }

    return (
        <>
            <Toolbar sx={showSelectedTools ? { backgroundColor: '#E0E0E0' } : null}>
                {showSelectedTools ? (
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ marginRight: 2 }}>
                            {`${selectedCount} contact${selectedCount > 1 ? 's' : ''} selected`}
                        </Typography>
                        <Button
                            variant="outlined"
                            startIcon={allContactsSelected ? <ClearIcon /> : null}
                            onClick={() => onToggleAllContactsSelected(!allContactsSelected)}
                        >
                            {allContactsSelected ? 'Clear selection' : `Select all ${count} contact${count > 1 ? 's' : ''}`}
                        </Button>
                        <ActionsMenu
                            selectedGroup={selectedGroup}
                            selectedPseudoGroup={selectedPseudoGroup}
                            onExport={onExport}
                            onCopy={onCopy}
                            onMove={onMove}
                            onDeleteFromGroup={onDeleteFromGroup}
                            onDeleteFromMultipleGroups={onDeleteFromMultipleGroups}
                            onDeleteFromAccount={onDeleteFromAccount}
                            onSuppress={onSuppress}
                            onUnsuppress={onUnsuppress}
                            onUnsubscribe={onUnsubscribe}
                            selectedContacts={Boolean(selectedContacts.length > 0 || allContactsSelected)}
                            onWarnNoSelection={onWarnNoSelection}
                            onResubscribe={onResubscribe}
                            status={status}
                        />
                    </Stack>
                ) : (
                    <>
                        <Box sx={{ flexGrow: 1 }}>
                            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                                <GridToolbarQuickFilter
                                    debounceMs={1000}
                                    InputProps={{
                                        ref: ref,
                                        onFocus: () => setHasFocus(true),
                                        onBlur: () => setHasFocus(false)
                                    }}
                                    placeholder="Search Email..."
                                />
                                <FormControl size="small">
                                    <InputLabel id="status-label">Status</InputLabel>
                                    <Select
                                        labelId="status-label"
                                        id="status"
                                        value={status}
                                        label="Status"
                                        onChange={event => setStatus(event.target.value)}
                                    >
                                        <MenuItem value="subscribed">Subscribers</MenuItem>
                                        <MenuItem value="unsubscribed">Unsubscribers</MenuItem>
                                        <MenuItem value="bounced">Hard Bounces</MenuItem>
                                    </Select>
                                </FormControl>
                                <ActionsMenu
                                    selectedGroup={selectedGroup}
                                    selectedPseudoGroup={selectedPseudoGroup}
                                    onExport={onExport}
                                    onCopy={onCopy}
                                    onMove={onMove}
                                    onDeleteFromGroup={onDeleteFromGroup}
                                    onDeleteFromMultipleGroups={onDeleteFromMultipleGroups}
                                    onDeleteFromAccount={onDeleteFromAccount}
                                    onSuppress={onSuppress}
                                    onUnsuppress={onUnsuppress}
                                    onUnsubscribe={onUnsubscribe}
                                    selectedContacts={Boolean(selectedContacts.length > 0 || allContactsSelected)}
                                    onWarnNoSelection={onWarnNoSelection}
                                />
                            </Stack>
                        </Box>
                        <Stack direction="row" spacing={1}>
                            <ViewControls
                                filters={filters}
                                setFilters={setFilters}
                                views={views}
                                setViews={setViews}
                                activeViewId={activeViewId}
                                setActiveViewId={setActiveViewId}
                            />
                            <Button
                                variant="text"
                                onClick={() => setShowChooseColumnsDialog(true)}
                                startIcon={<ViewColumnIcon />}
                            >
                                Columns
                            </Button>
                            <GridToolbarDensitySelector variant="text" />
                            <Button
                                variant="text"
                                onClick={onShowFilters}
                                startIcon={<Badge badgeContent={filters?.length} color="primary"><FilterListIcon /></Badge>}
                            >
                                Filters
                            </Button>
                        </Stack>
                    </>
                )}
            </Toolbar>
            <Divider />
        </>
    );
};

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    '.MuiDataGrid-pinnedColumns': {
        backgroundColor: colours.primarySelected
    },
    '.MuiDataGrid-pinnedColumnHeaders': {
        backgroundColor: colours.primarySelected
    },
    '.row-suppressed': {
        color: theme.palette.text.secondary
    }
}));

const ContactsTable = ({
    data,
    count,
    columns,
    rowsPerPageOptions,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    onSort,
    orderBy,
    orderDesc,
    selectedContacts,
    onChangeSelection,
    allContactsSelected,
    onToggleAllContactsSelected,
    selectedGroup,
    selectedPseudoGroup,
    filters,
    setFilters,
    onShowFilters,
    onSearch,
    onOpenContact,
    status,
    setStatus,
    tab,
    groups,
    selectedSegment,
    setSelectedSegment,
    allContactsCount,
    onExport,
    onCopy,
    onMove,
    onDeleteFromGroup,
    onDeleteFromMultipleGroups,
    onDeleteFromAccount,
    onSuppress,
    onUnsuppress,
    onUnsubscribe,
    onWarnNoSelection,
    onResubscribe,
    searchValue,
    onCreateGroup // TODO replace with hooks
}) => {
    const apiRef = useGridApiRef();
    const theme = useTheme();
    const [views, setViews] = useState({});
    const [activeViewId, setActiveViewId] = useState(null);
    const [visibleColumns, setVisibleColumns] = useState(null);
    const [tableHeight, setTableHeight] = useState(0);

    const calculateTableHeight = () => {
        const viewportHeight = window.innerHeight;
        const toolbarHeight = 64; //true if vw > 600. See https://github.com/mui/material-ui/issues/10739 for trouble creating this number
        const themeSpacing = theme.spacing(1).split('px')[0];

        //this is 2 toolbars and table margin. Update if page layout is changed
        setTableHeight(viewportHeight - ((toolbarHeight * 2) + (themeSpacing * 4)));
    };

    const sort = orderDesc ? 'desc' : 'asc';

    const initialGridState = {
        sorting: {
            sortModel: [{ field: orderBy, sort }]
        },
        pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'emailAddress']
        },
        columns: { columnVisibilityModel: {}, dimensions: {}, orderedFields: {} } //MUI quirk: if this model isn't provided, column visibility isn't saved
    };

    const [contactsViews, setContactsViews] = useLocalStorage('contactsViews', views);

    //disable string operator 'isAnyOf', which we currently don't support
    const dataColumns = useMemo(
        () =>
            columns.map(col => {
                if (col.type !== 'string') {
                    return col;
                }

                return {
                    ...col,
                    filterOperators: getGridStringOperators().filter(
                        operator => operator.value === 'contains'
                            || operator.value === 'equals'
                            || operator.value === 'startsWith'
                            || operator.value === 'endsWith'
                            || operator.value === 'isEmpty'
                            || operator.value === 'isNotEmpty'
                    )
                };
            }),
        [columns]
    );

    const handleOpenContact = params => {
        params?.id && onOpenContact(params.id);
    };

    const handleApplyColumns = columns => {
        const convertArrayToObject = (array, key) => {
            const initialValue = {};
            return array.reduce((obj, item) => {
                return {
                    ...obj,
                    [item[key]]: item.visible
                };
            }, initialValue);
        };

        const columnVisibilityModel = convertArrayToObject(columns, 'field');

        //adds checkboxes (visible true). Bit of a workaround, but we don't want users hiding checkboxes
        columnVisibilityModel['__check__'] = true;

        const currentState = apiRef.current.exportState();

        currentState.columns.columnVisibilityModel = columnVisibilityModel;

        apiRef.current.restoreState(currentState);
    };

    const handleOpenColumnPicker = () => {
        const currentState = apiRef.current.exportState();

        let columnsArray = columns.map(c => ({ title: c.headerName, field: c.field, visible: true }));

        if (currentState?.columns.columnVisibilityModel && Object.keys(currentState.columns.columnVisibilityModel).length > 0) {

            const cvmColumns = Object.entries(currentState.columns.columnVisibilityModel).map(([columnName, visible]) => ({ field: columnName, visible }));

            //match fieldnames and reassign
            const updatedColumns = columnsArray.map(col => {
                const matchedColumn = cvmColumns.find(e => e.field === col.field);

                if (matchedColumn) {
                    return { ...col, visible: matchedColumn.visible };
                }

                return col;
            });

            columnsArray = updatedColumns;
        }
        //if no columnVisibilityModel we can assume that all the columns are visible

        setVisibleColumns(columnsArray);
    };

    useEffect(() => {
        setContactsViews(views);
    }, [views]);

    useEffect(() => {
        setViews(contactsViews);
    }, []);

    useEffect(() => {
        calculateTableHeight();

        window.addEventListener('resize', calculateTableHeight);

        return () => window.removeEventListener('resize', calculateTableHeight);
    }, []);

    useEffect(() => {
        searchValue?.length === 0 && apiRef.current.setQuickFilterValues([]);
    }, [searchValue]);

    //todo?: move into a reset function and provide a button on Groups tab too... except this would need to trigger the sort reset in the reducer up a level. Probably have to send up a flag.
    //MUI open issue to make a reset state func: https://github.com/mui/mui-x/issues/4301
    //Until available, this:
    useEffect(() => {
        if (tab === 1) {
            setActiveViewId(null);

            const sortModel = [{ field: 'emailAddress', sort: 'asc' }];

            const convertArrayToObject = (array, key) => {
                const initialValue = {};
                return array.reduce((obj, item) => {
                    return {
                        ...obj,
                        [item[key]]: { width: item.width || 100 }
                    };
                }, initialValue);
            };

            const dimensions = convertArrayToObject(columns, 'field');

            //restoreState does a MERGE with the current state
            const resetState = {
                ...initialGridState,
                sorting: { sortModel },
                columns: {
                    ...initialGridState.columns,
                    dimensions
                }
            };

            apiRef.current.restoreState(resetState);
        }
    }, [tab]);

    return (
        <>
            <div style={{ height: tableHeight, width: '100%' }}>
                <StyledDataGrid
                    apiRef={apiRef}
                    checkboxSelection={tab === 0}
                    disableSelectionOnClick
                    hideFooterSelectedRowCount
                    disableColumnFilter
                    disableColumnSelector
                    disableMultipleColumnsSorting
                    pagination
                    keepNonExistentRowsSelected
                    density="compact"
                    paginationMode="server"
                    sortingMode="server"
                    filterMode="server"
                    onFilterModelChange={filters => onSearch(filters?.quickFilterValues)}
                    rows={data}
                    rowCount={count}
                    columns={dataColumns}
                    pageSize={rowsPerPage}
                    onPageSizeChange={rowsPerPage => setRowsPerPage(rowsPerPage)}
                    rowsPerPageOptions={rowsPerPageOptions}
                    page={page}
                    onPageChange={page => setPage(page)}
                    onSortModelChange={props => onSort(props)}
                    sortingOrder={['desc', 'asc']}
                    selectionModel={allContactsSelected ? data.map(e => e.id) : selectedContacts}
                    onSelectionModelChange={selectedIds => onChangeSelection(selectedIds)}
                    isRowSelectable={() => allContactsSelected === false}
                    getRowClassName={params => params.row.isSuppressed ? 'row-suppressed' : null}
                    onRowClick={(params) => handleOpenContact(params)}
                    components={{
                        NoRowsOverlay: NoRows,
                        Toolbar: () => (
                            <DataGridTools
                                count={count}
                                allContactsSelected={allContactsSelected}
                                onToggleAllContactsSelected={onToggleAllContactsSelected}
                                selectedContacts={selectedContacts}
                                onShowFilters={onShowFilters}
                                filters={filters}
                                setFilters={setFilters}
                                status={status}
                                setStatus={setStatus}
                                tab={tab}
                                groups={groups}
                                selectedSegment={selectedSegment}
                                setSelectedSegment={setSelectedSegment}
                                selectedGroup={selectedGroup}
                                selectedPseudoGroup={selectedPseudoGroup}
                                allContactsCount={allContactsCount}
                                onExport={onExport}
                                onCopy={onCopy}
                                onMove={onMove}
                                onDeleteFromGroup={onDeleteFromGroup}
                                onDeleteFromMultipleGroups={onDeleteFromMultipleGroups}
                                onDeleteFromAccount={onDeleteFromAccount}
                                onSuppress={onSuppress}
                                onUnsuppress={onUnsuppress}
                                onUnsubscribe={onUnsubscribe}
                                views={views}
                                setViews={setViews}
                                activeViewId={activeViewId}
                                setActiveViewId={setActiveViewId}
                                onWarnNoSelection={onWarnNoSelection}
                                onResubscribe={onResubscribe}
                                setShowChooseColumnsDialog={handleOpenColumnPicker}
                                onCreateGroup={onCreateGroup} // TODO replace with hooks
                            />
                        )
                    }}
                    componentsProps={{
                        pagination: {
                            labelDisplayedRows: ({ from, to, count }) => `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`}`
                        }
                    }}
                    initialState={initialGridState}
                />
                {visibleColumns && (
                    <ChooseColumnsDialog
                        open={Boolean(visibleColumns)}
                        onClose={() => setVisibleColumns(null)}
                        onSubmit={handleApplyColumns}
                        columns={visibleColumns}
                    />
                )}
            </div>
        </>
    );
};

export default ContactsTable;