import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AccountSettings from './accountSettings/AccountSettings';
import Products from './products/Products';
import Invoices from './invoices/Invoices';
import Auditing from './auditing/Auditing';
import CompanySettings from './companySettings/CompanySettings';

const Account = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <AccountSettings />
            </Route>
            <Route exact path={`${path}/configuration`}>
                <CompanySettings />
            </Route>
            <Route exact path={`${path}/auditing`}>
                <Auditing />
            </Route>
            <Route exact path={`${path}/products`}>
                <Products />
            </Route>
            <Route exact path={`${path}/invoices`}>
                <Invoices />
            </Route>
        </Switch>
    );
};

export default Account;