import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ConfirmDeleteDocumentDialog = ({ onClose, onDelete, documentName }) => {

    const handleDelete = e => {
        e.preventDefault();
        onDelete(documentName);
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Delete Document: {documentName}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please confirm this action.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDelete}>Confirm delete document</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteDocumentDialog;