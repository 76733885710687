import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

//todo this should be in a config
//this matches allowed chars for naming Groups and Segments
const allowedCharacters = /[^a-zA-Z0-9\s-]/g;

const Search = ({ disabled, onSearch, tempSearchValue, setTempSearchValue, placeholder = null }) => {
    const handleClear = () => {
        setTempSearchValue('');
        onSearch('');
    };

    const handleSubmit = e => {
        e.stopPropagation();
        onSearch(tempSearchValue); //passing this as a value is vital for the workaround above
    };

    const handleKey = e => {
        e.key === 'Enter' && handleSubmit(e);
    };

    const handleChangeValue = value => {
        const newValue = value.replace(allowedCharacters, '');
        setTempSearchValue(newValue);
    };

    return (
        <OutlinedInput
            fullWidth
            disabled={disabled}
            value={tempSearchValue}
            onChange={e => handleChangeValue(e.target.value)}
            placeholder={placeholder ?? 'Search...'}
            size="small"
            margin="none"
            onKeyPress={handleKey}
            endAdornment={(
                <InputAdornment position="end">
                    <IconButton
                        onClick={handleSubmit}
                        edge="end"
                        disabled={!Boolean(tempSearchValue)}
                    >
                        <SearchIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleClear}
                        edge="end"
                        disabled={!Boolean(tempSearchValue)}
                    >
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            )}
        />
    );
};

export default Search;