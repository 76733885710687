import React from 'react';
import SubscribersTable from '../SubscribersTable';
import CampaignSummary from '../CampaignSummary';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Data = ({ campaignId, groups, subscriberStatus, setSubscriberStatus, selectedGroup, setSelectedGroup, campaignSummary }) => {
    return (
        <>
            {(groups && groups.length > 0) && (
                <FormControl margin="none" size="small" sx={{ marginTop: 2, marginLeft: 2 }}>
                    <Select
                        value={selectedGroup}
                        onChange={e => setSelectedGroup(e.target.value)}
                    >
                        <MenuItem value="none">All Groups</MenuItem>;
                        {groups.map(group => <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>)}
                    </Select>
                </FormControl>
            )}
            <CampaignSummary
                campaign={campaignSummary}
                subscriberStatus={subscriberStatus}
                onChangeSubscriberStatus={setSubscriberStatus}
            />
            <SubscribersTable
                selectedGroup={selectedGroup}
                campaignId={campaignId}
                subscriberStatus={subscriberStatus}
                onChangeSubscriberStatus={setSubscriberStatus}
            />
        </>
    );
};

export default Data;