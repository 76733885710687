import React, { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Typography } from '@mui/material';
import Select from '@mui/material/Select';
import useSnackbar from '../../../hooks/useSnackbar';
import FormControl from '@mui/material/FormControl';

const AddFeatureModel = ({ shown, onHide, onSubmit, onLoading }) => {
    const [formData, setFormData] = useState({
        name: '',
        alias: '',
        description: '',
        level: ''
    });

    const { showSnackbar } = useSnackbar();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (formData.name !== '' && formData.description !== '' && formData.level !== '') {
            await onSubmit(formData);
        }
        else {
            showSnackbar('All fields required', 'error');
        }
    };

    useEffect(() => {
        if (formData.name) {
            setFormData(prevState => ({
                ...prevState,
                alias: formData.name
            }));
        }
    }, [formData.name]);

    return (
        <>
            <Dialog
                open={shown}
                onClose={onHide}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>Add New Feature</DialogTitle>
                <DialogContent dividers>
                    <FormGroup fullwidth>
                        <TextField
                            fullwidth
                            type="text"
                            variant="outlined"
                            size="small"
                            label="Feature Name"
                            autoComplete="off"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Feature name"
                            name="name"
                            required
                        />
                        <TextField
                            fullwidth
                            type="text"
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            value={formData.name}
                            name="Alias"
                            label="Feature Alias"
                            disabled
                        />
                        <TextField
                            fullwidth
                            multiline
                            rows={4}
                            type="email"
                            variant="outlined"
                            size="small"
                            label="Description"
                            autoComplete="off"
                            value={formData.description}
                            onChange={handleInputChange}
                            name="description"
                            required
                        />
                        <FormControl fullWidth>
                            <InputLabel id="select-label">Select Level</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select-label"
                                value={formData.level}
                                label="Select Level"
                                name="level"
                                onChange={handleInputChange}
                            >
                                <MenuItem value={0}>Internal Testing</MenuItem>
                                <MenuItem value={1}>Public Testing</MenuItem>
                                <MenuItem value={2}>Production</MenuItem>
                            </Select>
                        </FormControl>
                        <Typography>
                            <strong>Note:</strong> You can upload the feature screenshot later in the feature edit stage.
                        </Typography>
                    </FormGroup>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onHide} variant="outlined">
                        Close
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        endIcon={onLoading ? <CircularProgress size={16} color="inherit" /> : null}
                    >
                        {onLoading ? 'Working' : 'Add Feature'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddFeatureModel;