import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import CustomField from '../../contacts/contact/CustomField';
import CustomFieldBool from '../../contacts/contact/CustomFieldBool';
import CustomFieldDate from '../../contacts/contact/CustomFieldDate';

const CustomFields = ({ customFields, setUnsavedFields, updatedCustomFields, setUpdatedCustomFields, isReverting, setIsReverting }) => {

    const handleChange = ({ id, value, hasChanged }) => {
        const customField = customFields.find(e => e.id === id);

        if (!customField) {
            //todo snackbar error "CF may have been deleted by another user"?
            return;
        }

        const existingUpdatedField = updatedCustomFields.find(e => e.id === id);

        if (hasChanged) {
            setUpdatedCustomFields([...updatedCustomFields.filter(e => e.id !== id), { id, value }]);
        }
        else if (existingUpdatedField) {
            setUpdatedCustomFields(updatedCustomFields.filter(e => e.id !== id));
        }
    };

    useEffect(() => {
        if (!isReverting) return;

        setUpdatedCustomFields([]);
        setUnsavedFields(false);
        setIsReverting(false);
    }, [isReverting, customFields, setUpdatedCustomFields, setUnsavedFields, setIsReverting]);

    useEffect(() => {
        setUnsavedFields(Boolean(updatedCustomFields.length > 0));
    }, [updatedCustomFields]);

    return (
        <Grid container spacing={2}>
            {customFields.map((field, index) => (
                <Grid item xs={4} key={index}>
                    {field.type === 'bool' ? (
                        <CustomFieldBool
                            id={field.id}
                            name={field.name}
                            value={field.value}
                            onChange={handleChange}
                            isReverting={isReverting}
                        />
                    ) : field.type === 'date' ? (
                        <CustomFieldDate
                            id={field.id}
                            name={field.name}
                            value={field.value}
                            onChange={handleChange}
                            isReverting={isReverting}
                        />
                    ) : (
                        <CustomField
                            id={field.id}
                            name={field.name}
                            value={field.value}
                            type={field.type}
                            onChange={handleChange}
                            isReverting={isReverting}
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    );
};

export default CustomFields;