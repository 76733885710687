import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CampaignPreview from '../../campaignPreview/CampaignPreview';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Topic from '../../topics/Topic';
import useApi from '../../hooks/useApi';
import ShoppingList from '../../editor/ShoppingList';
import ToggleConditions from '../../campaignPreview/ToggleConditions';
import useSnackbar from '../../hooks/useSnackbar';
import PublishedDialog from '../dialogs/PublishedDialog';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';

const tableHead = {
    width: '25%',
    minWidth: 150
};

const ToggleDevice = ({ device, onChangeDevice }) => {
    const handleChange = (event, value) => {
        value && onChangeDevice(value);
    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={device}
            exclusive
            onChange={handleChange}
        >
            <ToggleButton value="desktop">Desktop</ToggleButton>
            <ToggleButton value="mobile">Mobile</ToggleButton>
        </ToggleButtonGroup>
    );
};

const Publish = ({ qrCodeContent, page, previewHtml, onBack, drawerWidth, steps, step, titleBarDepth, setStep }) => {
    const history = useHistory();
    const [isSaving, setIsSaving] = useState(false);
    const [showPublishedDialog, setShowPublishedDialog] = useState(false);
    const [report, setReport] = useState(null);
    const [device, setDevice] = useState('desktop');
    const { handlePut, handleGet } = useApi();
    const { showSnackbar } = useSnackbar();

    const [availableConditions, setAvailableConditions] = useState(null);
    const [conditions, setConditions] = useState([]);

    const handleSetAvailableConditions = conditions => {
        setAvailableConditions(conditions);
        setConditions(conditions);
    };

    const handleFetchReport = async () => {
        const response = await handleGet(`pages/reports/${page.id}`);

        if (!response.ok) {
            showSnackbar(response.error, 'error');
            return;
        }

        const data = await response.json();
        setReport(data);
    };

    const handlePublish = async () => {
        setIsSaving(true);

        const response = await handlePut(`pages/${page.id}`, {
            ...page,
            publish: true,
            qrCodeId: qrCodeContent?.qrCodeId,
            qrCodeStartDate: qrCodeContent?.startDate,
            qrCodeEndDate: qrCodeContent?.endDate
        });

        if (!response.ok) {
            setIsSaving(false);
            showSnackbar(response.error, 'error');
            return;
        }

        await handleFetchReport();

        setIsSaving(false);
    };

    const handleClosePublishedDialog = () => {
        setShowPublishedDialog(false);
        history.push({
            pathname: '/editor',
            state: {
                type: 'page'
            }
        });
    };

    useEffect(() => {
        report && setShowPublishedDialog(true);
    }, [report]);

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64, zIndex: titleBarDepth }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">Final Checks</Typography>
                </Toolbar>
            </AppBar>
            <div style={{ marginRight: drawerWidth, flexGrow: 1 }}>
                <Box p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Alert severity="info">Please check all details are correct before publishing.</Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell variant="head" sx={tableHead}>
                                                        Title
                                                    </TableCell>
                                                    <TableCell>
                                                        {page.title}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell variant="head" sx={tableHead}>
                                                        Google Analytics Campaign Name
                                                    </TableCell>
                                                    <TableCell>
                                                        {page.gaCampaign}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell variant="head" sx={tableHead}>
                                                        Topics
                                                    </TableCell>
                                                    <TableCell>
                                                        {page.topics && page.topics.map(e => <Topic key={e.id} name={e.name} colour={e.colour} />)}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell variant="head" sx={tableHead}>
                                                        QR Code
                                                    </TableCell>
                                                    <TableCell>
                                                        {qrCodeContent ? (
                                                            <>
                                                                <Typography variant="body1">{qrCodeContent.name}</Typography>
                                                                <Typography variant="body2">
                                                                    From {format((new Date(qrCodeContent.startDate)), 'HH:mm \'on\' MMMM do yyyy')}{qrCodeContent.endDate ? ` until ${format((new Date(qrCodeContent.endDate)), 'HH:mm \'on\' MMMM do yyyy')}` : ', indefinitely'}
                                                                </Typography>
                                                            </>
                                                        ) : ''}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader
                                    action={(
                                        <Stack direction="row" spacing={2}>
                                            <ToggleConditions
                                                availableConditions={availableConditions}
                                                conditions={conditions}
                                                onChangeConditions={setConditions}
                                            />
                                            <ToggleDevice
                                                device={device}
                                                onChangeDevice={e => setDevice(e)}
                                            />
                                        </Stack>
                                    )}
                                    title="Landing Page Preview"
                                />
                                <CardContent>
                                    <CampaignPreview
                                        html={previewHtml}
                                        device={device}
                                        conditions={conditions}
                                        onChangeConditions={handleSetAvailableConditions}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <ShoppingList
                drawerWidth={drawerWidth}
                stepNumber={step}
                steps={steps}
                onNext={handlePublish}
                onBack={onBack}
                disabled={isSaving}
                campaign={page}
                isProofSent={false}
                audienceName={null}
                setStep={setStep}
                publishText="Publish"
                finalStepButtonText="Publish"
            />
            {showPublishedDialog && (
                <PublishedDialog
                    report={report}
                    onClose={handleClosePublishedDialog}
                />
            )}
            {isSaving && <LoadingOverlay />}
        </>
    );
};

export default Publish;