import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const ConfirmResubscribeContactDialog = ({ onClose, emailAddress, onConfirm }) => {

    const handleConfirm = e => {
        e.preventDefault();
        onConfirm();
        onClose();
    };

    //add loading state and await confirm?

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Resubscribe {emailAddress}</DialogTitle>
            <DialogContent>
                <Alert severity="warning" sx={{ mb: 2 }}>
                    Please consider the implications of this action.
                </Alert>
                <Typography paragraph>
                    This action will enable you to send campaigns to this contact. If the contact themselves initiated the unsubscribe action in the first instance, you could be in breach of UK law if you choose to resubscribe them.
                </Typography>
                <Typography>
                    Please note that NewZapp cannot be held accountable for your actions.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>Confirm Resubscribe</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmResubscribeContactDialog;