import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const RenameEmailDialog = ({ email, emailText, onComplete, onClose }) => {
    const [name, setName] = useState(email.name);
    const [isValid, setIsValid] = useState(false);
    const [form, setForm] = useState(null);

    const onRefChange = useCallback(node => setForm(node), []);

    const handleSubmit = e => {
        e.preventDefault();
        onComplete({ name });
    };

    useEffect(() => {
        setIsValid(form && form.checkValidity());
    }, [form, name]);

    return (
        <Dialog onClose={onClose} open={true}>
            <form ref={onRefChange} onSubmit={handleSubmit}>
                <DialogTitle>Rename {emailText}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Rename {emailText} {email.name}.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        id="name"
                        label={`${emailText} Name`}
                        type="text"
                        fullWidth
                        value={name}
                        required
                        onChange={e => setName(e.target.value)}
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={!isValid}>
                        Rename {emailText}
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default RenameEmailDialog;
