import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const DeleteFieldDialog = ({ field, onSubmit, onClose }) => {

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Delete Custom Field</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are about to delete '{field.name}'.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSubmit}>OK</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteFieldDialog;