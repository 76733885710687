import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const NoContactsSelectedDialog = ({ onClose }) => {
    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
                <Alert severity="error" sx={{ mb: 2 }}>
                    No contacts selected
                </Alert>
                <DialogContentText>
                    Please use the checkboxes to select contacts before performing any action.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export default NoContactsSelectedDialog;