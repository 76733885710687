import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useAccount from '../hooks/useAccount';

const ErrorDialog = ({ message }) => {
    const { handleSignOut } = useAccount();

    return (
        <Dialog open={true}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
                {message && (
                    <Typography paragraph>
                        {message}
                    </Typography>
                )}
                <DialogContentText>
                    Please contact your organisation administrator or NewZapp support on <Link href="tel:01392447200">01392 447 200</Link> or sign out.
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" onClick={handleSignOut}>Sign Out</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorDialog;