import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import ManualList from '../ManualList';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import useAccount from '../../hooks/useAccount';
import useApi from '../../hooks/useApi';
import buildUrl from '../../buildUrl';
import ShoppingList from '../../editor/ShoppingList';
import AllowedRecipientsList from '../../contacts/AllowedRecipientsList';
import SendingError from '../SendingError';

const tableHead = {
    width: '25%',
    minWidth: 150
};

const ProofCompleteDialog = ({ type, isSent, onNext, onClose, onNavigateToEditor, errorMessage = 'We\'ve encountered a problem', postProofNextStepButtonText }) => {
    if (isSent) {
        return (
            <Dialog open={true}>
                <DialogTitle>Proof Sent</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your proof has been sent successfully and is now on its way.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onNavigateToEditor} variant="outlined">
                        Return to Editor
                    </Button>
                    <Button onClick={onNext}>
                        {type === 'template' ? 'Publish Template' : postProofNextStepButtonText ?? 'Progress to Send'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog open={true}>
            <DialogTitle>Proof not sent</DialogTitle>
            <DialogContent>
                <Alert severity="error" sx={{ mb: 2 }}>
                    {errorMessage}
                </Alert>
                <SendingError error={errorMessage} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Proof = ({ email, html, onNext, onBack, emailAddresses, setEmailAddresses, drawerWidth, steps, step, campaign, isProofSent, setIsProofSent, audienceName, titleBarDepth, onNavigateToEditor, type = 'email', setStep, postProofNextStepButtonText = null }) => {
    const { smtpUsername, isInternalComms, allowedRecipients } = useAccount();
    const subject = `PROOF - ${email.subject ? `${email.subject}` : ''}`;
    const fromName = email.fromName;
    const fromEmail = smtpUsername || email.fromEmail;
    const [canSubmit, setCanSubmit] = useState(false);
    const [isInitialising, setIsInitialising] = useState(true);
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [showProofCompleteDialog, setShowProofCompleteDialog] = useState(false);
    const [saveAsProofGroup, setSaveAsProofGroup] = useState(false);
    const { handleGet, handlePost } = useApi();
    const [errorMessage, setErrorMessage] = useState(null);
    const itemType = type === 'pageTemplate' ? 'template' : type;

    const emailAddressesLimit = 20;

    const handleSendProof = async event => {
        event.preventDefault();

        setIsSending(true);

        const response = await handlePost('proofs', {
            html,
            name: email.name,
            subject,
            fromName,
            fromEmail,
            googleAnalyticsCampaign: email.googleAnalyticsCampaign,
            toEmails: emailAddresses,
            saveAsProofGroup
        });

        const isSent = Boolean(response.ok);

        if (!isSent) {
            const error = await response.json();
            console.error(error);
            setErrorMessage(error.message);
        }

        setIsSent(isSent);
        setShowProofCompleteDialog(true);
        setIsSending(false);
    };

    const handleCloseProofDialog = () => {
        setShowProofCompleteDialog(false);
        setErrorMessage(null);
    };

    const handleFetchProofGroup = async () => {
        const response = await handleGet('proofs/proof-group');

        if (response.ok) {
            const data = await response.json();

            if (data) {
                const url = buildUrl('proofs/preview', { groupIDs: [data.id] });

                const response = await handleGet(url);

                const preview = await response.json();

                //If proof group is larger than limit n, take first n
                const emailAddresses = preview.subscribers.length > emailAddressesLimit ? preview.subscribers.slice(0, emailAddressesLimit - 1) : preview.subscribers;

                setEmailAddresses(emailAddresses);
            }
        }

        setIsInitialising(false);
    };

    const handleNext = () => {
        if (itemType === 'template') {
            setShowProofCompleteDialog(false);
        }

        onNext();
    };

    useEffect(() => {
        setCanSubmit((emailAddresses.length > 0 && emailAddresses.length <= emailAddressesLimit));
    }, [emailAddresses]);

    useEffect(() => {
        isSent && setIsProofSent(isSent);
    }, [isSent]);

    useEffect(() => {
        handleFetchProofGroup();
    }, []);

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64, zIndex: titleBarDepth }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">Send Proof</Typography>
                </Toolbar>
            </AppBar>
            <div style={{ marginRight: drawerWidth, flexGrow: 1, height: 'calc(100vh - 130px)', overflowY: 'auto' }}>
                {isInitialising ? <LoadingOverlay /> : (
                    <Container disableGutters>
                        <Paper sx={{ m: 2, p: 3 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        You can skip this step, but we recommend you proof your campaign before sending it.
                                    </Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <form onSubmit={handleSendProof}>
                                        <Stack sx={{ marginBottom: 1 }}>
                                            <Paper>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell variant="head" sx={tableHead}>
                                                                Subject Line
                                                            </TableCell>
                                                            <TableCell>
                                                                {subject}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell variant="head" sx={tableHead}>
                                                                From Name
                                                            </TableCell>
                                                            <TableCell>
                                                                {fromName}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell variant="head" sx={tableHead}>
                                                                From Email Address
                                                            </TableCell>
                                                            <TableCell>
                                                                {fromEmail}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Paper>
                                            <FormControl fullWidth>
                                                <ManualList
                                                    emailAddresses={emailAddresses}
                                                    setEmailAddresses={setEmailAddresses}
                                                    emailAddressesLimit={emailAddressesLimit}
                                                />
                                                <FormHelperText margin="dense">{emailAddressesLimit ? `Send up to ${emailAddressesLimit} addresses. ` : ''}If you have a Proof Group those recipients will be pre-populated, up to {emailAddressesLimit}.</FormHelperText>
                                            </FormControl>
                                            {(isInternalComms && allowedRecipients.length > 0) && (
                                                <AllowedRecipientsList allowedRecipients={allowedRecipients} />
                                            )}
                                            <FormControl fullWidth>
                                                <FormControlLabel
                                                    control={(
                                                        <Switch
                                                            checked={saveAsProofGroup}
                                                            onChange={() => setSaveAsProofGroup(!saveAsProofGroup)}
                                                        />
                                                    )}
                                                    label="Save these recipients as your Proof Group"
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Button
                                            type="submit"
                                            disabled={(isSending || !canSubmit)}
                                            endIcon={isSending ? <CircularProgress sx={{ marginLeft: 1 }} size={20} /> : null}
                                        >
                                            {isSending ? 'Sending Proof' : 'Send Proof'}
                                        </Button>
                                    </form>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                )}
            </div>
            <ShoppingList
                drawerWidth={drawerWidth}
                stepNumber={step}
                steps={steps}
                onNext={onNext}
                onBack={onBack}
                campaign={campaign}
                email={email}
                isProofSent={isProofSent}
                audienceName={audienceName}
                publishText={itemType === 'template' ? 'Publish' : null}
                setStep={setStep}
            />
            {showProofCompleteDialog && (
                <ProofCompleteDialog
                    type={itemType}
                    isSent={isSent}
                    onNext={handleNext}
                    onClose={handleCloseProofDialog}
                    errorMessage={errorMessage}
                    onNavigateToEditor={onNavigateToEditor}
                    postProofNextStepButtonText={postProofNextStepButtonText}
                />
            )}
        </>
    );
};

export default Proof;