import { useState, useEffect } from 'react';

const useSearch = (onSearch, timeout = 500) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchTimeout, setSearchTimeout] = useState();

    useEffect(() => {
        searchTimeout && clearTimeout(searchTimeout);

        if (searchValue) {
            setSearchTimeout(setTimeout(() => onSearch(searchValue), timeout));
            return () => searchTimeout && clearTimeout(searchTimeout);
        }

        onSearch('');
    }, [searchValue]);

    return [searchValue, setSearchValue];
};

export default useSearch;