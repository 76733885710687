import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import CustomFontsPanel from './CustomFontsPanel';
import CustomColourPanel from './CustomColourPanel';

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

const BrandManagementPanel = ({ formData, onFormChange }) => {
    const [tab, setTab] = React.useState(0);

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                    <Tab label="Brand Fonts" />
                    <Tab label="Brand Colours" />
                </Tabs>
            </Box>
            <CustomTabPanel value={tab} index={0}>
                <CustomFontsPanel account={formData} onFormChange={onFormChange} />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={1}>
                <CustomColourPanel account={formData} onFormChange={onFormChange} />
            </CustomTabPanel>
        </Box>
    );
};

export default BrandManagementPanel;