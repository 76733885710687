import React, { useState, useEffect, useRef } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Search from '../contacts/Search';
import Sort from '../contacts/Sort';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Divider, Paper, Popper, Typography } from '@mui/material';
import useAccount from '../hooks/useAccount';
import Tag from '../tags/Tag';
import SearchIcon from '@mui/icons-material/Search';
import useTheme from '@mui/material/styles/useTheme';

const SegmentsFilters = ({
    segmentsSort,
    setSegmentsSort,
    openCreateSegmentDialog = null,
    setSegmentsSearchValue,
    segmentsTempSearchValue,
    setSegmentsTempSearchValue,
    onFilterByTag,
    filterTagIds,
    popperGutters = 0
}) => {

    const theme = useTheme();
    const { tags } = useAccount();
    const [anchorEl, setAnchorEl] = useState(null);
    const ref = useRef();
    const open = Boolean(anchorEl);
    const id = open ? 'search-options' : undefined;

    const handleRemoveTagFilter = (event, id) => {
        event.stopPropagation();
        onFilterByTag(id);
    };

    const handleFilterByTag = (event, id) => {
        event.stopPropagation();
        onFilterByTag(id);
        //clear field for clarity (also closes popper)
        setSegmentsTempSearchValue('');
    };

    //the reason there are two handleSearch functions here:
    //needs to be able to take a value sometimes... but sometimes will be sent an event as the first prop
    //TODO handle this better

    const handleSearch = () => {
        setSegmentsSearchValue(segmentsTempSearchValue);
        setAnchorEl(null);
    };

    const handleSearchValue = value => {
        setSegmentsSearchValue(value);
        setAnchorEl(null);
    };

    const filteredTags = tags?.filter(e => e.name.toLowerCase().includes(segmentsTempSearchValue.toLowerCase()));

    useEffect(() => {
        setAnchorEl(segmentsTempSearchValue ? ref.current : null);
    }, [segmentsTempSearchValue]);

    return (
        <Stack spacing={1} sx={{ marginY: 2, width: '100%' }}>
            <Grid container spacing={1}>
                {openCreateSegmentDialog && (
                    <Grid xs={6}>
                        <Button onClick={openCreateSegmentDialog} fullWidth>
                            Create Segment
                        </Button>
                    </Grid>
                )}
                <Grid xs={6}>
                    <Sort
                        sort={segmentsSort}
                        setSort={segmentsSort => setSegmentsSort(segmentsSort)}
                        fullWidth={true}
                        margin={false}
                    />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex' }} ref={ref}>
                <Search
                    placeholder="Filter by Name or Tag"
                    tempSearchValue={segmentsTempSearchValue}
                    setTempSearchValue={setSegmentsTempSearchValue}
                    onSearch={handleSearchValue}
                />
            </Box>
            {filterTagIds?.length > 0 && (
                <Box>
                    <Typography variant="subtitle2">
                        Filtered by:
                    </Typography>
                    {tags.filter(tag => filterTagIds.includes(tag.id)).map(tag => (
                        <Tag
                            key={tag.id}
                            colour={tag.colour}
                            name={tag.name}
                            onDelete={event => handleRemoveTagFilter(event, tag.id)}
                        />
                    ))}
                </Box>
            )}
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                disablePortal
                placement="bottom-start"
                sx={{ width: '100%', zIndex: theme.zIndex.modal + 1 }}
            >
                <Paper elevation={6} sx={{ p: 1, mx: popperGutters, flexGrow: 1 }}>
                    <Stack spacing={1}>
                        <Box>
                            <Typography variant="subtitle2" sx={{ textAlign: 'center', pb: 1 }}>
                                Filter by Tag
                            </Typography>
                            {(tags && filteredTags?.length > 0) ? filteredTags.map(tag => (
                                <Tag
                                    key={tag.id}
                                    colour={tag.colour}
                                    name={tag.name}
                                    onClick={event => handleFilterByTag(event, tag.id)}
                                />
                            )) : <Typography variant="body2" sx={{ textAlign: 'center' }}>No tags found</Typography>}
                        </Box>
                        <Divider>
                            <Typography variant="body2">
                                OR
                            </Typography>
                        </Divider>
                        <Box sx={{ textAlign: 'center' }}>
                            <Button onClick={handleSearch} startIcon={<SearchIcon />}>
                                Filter by Name
                            </Button>
                        </Box>
                    </Stack>
                </Paper>
            </Popper>
        </Stack>
    );
};

export default SegmentsFilters;