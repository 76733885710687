import React, { useEffect, useState } from 'react';
import { format, endOfDay } from 'date-fns';
import Box from '@mui/material/Box';
import useSearch from '../hooks/useSearch';
import Toolbar from '@mui/material/Toolbar';
import DatePicker from '../DatePicker';
import TablePagination from '@mui/material/TablePagination';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Divider from '@mui/material/Divider';
import buildUrl from '../buildUrl';
import useSnackbar from '../hooks/useSnackbar';
import useApi from '../hooks/useApi';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import CenteredProgress from '../loadingOverlay/CenteredProgress';
import TableBody from '@mui/material/TableBody';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import Tooltip from '@mui/material/Tooltip';
import useTheme from '@mui/material/styles/useTheme';

const rowsPerPageOptions = [10, 25, 50];

const iconStyle = {
    marginRight: 2,
    verticalAlign: 'middle'
};

const SurveyNameStatus = ({ name }) => {
    // Inner fragment used below to get around this issue: https://github.com/mui/material-ui/issues/31261

    return (
        <>
            <Tooltip title="Active" arrow>
                <>
                    <PublishedWithChangesIcon fontSize="small" color="success" sx={iconStyle} />
                </>
            </Tooltip>
            {name}
        </>
    );
};

const ToolbarItem = ({ children }) => {
    return <Box sx={{ display: 'inline-flex', margin: 0.5, marginRight: 0 }}>{children}</Box>;
};

const SurveySelect = ({ onSelect, currentlySelected, showTools = true }) => {
    const [isInitialising, setIsInitialising] = useState(true);
    const [items, setItems] = useState([]);
    const [count, setCount] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState('Modified');
    const [orderDesc, setOrderDesc] = useState(true);
    const [tempSearchValue, setTempSearchValue] = useSearch(setSearchValue);
    const [isLoading, setIsLoading] = useState(true);
    const { showSnackbar } = useSnackbar();
    const { handleGet } = useApi();
    const theme = useTheme();

    const handleSelectSurvey = (e, survey) => {
        e.preventDefault();
        onSelect(survey);
    };

    const handleFetchCount = async () => {
        const surveyParams = {
            search: searchValue,
            status: 'Active',
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(endOfDay(toDate), 'yyyy-MM-dd HH:mm:ss') })
        };

        const url = buildUrl('surveys/count', surveyParams);
        const response = await handleGet(url);

        if (!response.ok) {
            showSnackbar('Error fetching count', 'error');
            return;
        }

        const data = await response.json();

        setCount(data);
    };

    const handleFetchItems = async () => {
        const surveyParams = {
            search: searchValue,
            status: 'Active',
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(endOfDay(toDate), 'yyyy-MM-dd HH:mm:ss') }),
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending: orderDesc
        };

        const url = buildUrl('surveys', surveyParams);
        const response = await handleGet(url);

        if (!response.ok) {
            showSnackbar('Error fetching items', 'error');
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setItems(data);
        setIsLoading(false);
    };

    const handleSort = (field, defaultOrder) => {
        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(defaultOrder);
        }
    };

    const handleInit = async () => {
        await handleFetchItems();
        await handleFetchCount();
        setIsInitialising(false);
    };

    useEffect(() => {
        handleFetchCount();
    }, [searchValue, fromDate, toDate]);

    useEffect(() => {
        setPage(0);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage]);

    useEffect(() => {
        setIsLoading(true);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, page]);

    useEffect(() => {
        handleInit();
    }, []);

    useEffect(() => {
        isLoading && handleFetchItems();
    }, [isLoading]);

    return (
        <Paper variant="outlined">
            {showTools && (
                <>
                    <Toolbar disableGutters sx={{ mx: 1.5, paddingTop: 0.5, paddingBottom: 0.5 }}>
                        <Box sx={{ flexGrow: 1, mr: 1 }}>
                            <ToolbarItem>
                                <OutlinedInput
                                    value={tempSearchValue}
                                    onChange={e => setTempSearchValue(e.target.value)}
                                    placeholder="Search..."
                                    size="small"
                                    sx={{ mr: 1 }}
                                    disabled={isLoading || isInitialising}
                                    endAdornment={(
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setTempSearchValue('')}
                                                edge="end"
                                                disabled={!Boolean(tempSearchValue)}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                />
                            </ToolbarItem>
                            <ToolbarItem>
                                <DatePicker value={fromDate} onChange={setFromDate} label="Modified from date" disabled={isLoading || isInitialising} />
                            </ToolbarItem>
                            <ToolbarItem>
                                <DatePicker value={toDate} onChange={setToDate} label="Modified to date" disabled={isLoading || isInitialising} />
                            </ToolbarItem>
                        </Box>
                    </Toolbar>
                    <Divider />
                </>
            )}
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row" align="left" sx={{ width: '30%' }}>
                                <TableSortLabel
                                    active={orderBy === 'Name'}
                                    direction={orderDesc ? 'desc' : 'asc'}
                                    onClick={() => handleSort('Name', false)}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                                <TableSortLabel>
                                    Created Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                                <TableSortLabel>
                                    Last Modified
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {(isLoading || isInitialising) ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan="3" bgcolor={theme.palette.grey[100]} height="60">
                                    <CenteredProgress colour="primary" />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : items.length > 0 ? (
                        <TableBody>
                            {items.map(e => {
                                return (
                                    <TableRow
                                        key={e.id}
                                        sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' } }}
                                        onClick={(ei) => handleSelectSurvey(ei, e)}
                                        selected={currentlySelected?.id === e.id}
                                    >
                                        <TableCell align="left">
                                            <SurveyNameStatus name={e.name} />
                                        </TableCell>
                                        <TableCell align="right">
                                            {e.created ? format(new Date(e.created), 'dd/MM/yyyy') : '-'}
                                        </TableCell>
                                        <TableCell align="right">
                                            {e.modified ? format(new Date(e.modified), 'dd/MM/yyyy') : '-'}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan="3" height="60" sx={{ textAlign: 'center' }}>
                                    {(searchValue || fromDate || toDate) ? 'No data' : 'You have not created any Surveys yet.'}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
            />
        </Paper>
    );
};

export default SurveySelect;