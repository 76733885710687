import React, { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import DialogActions from '@mui/material/DialogActions';
import useApi from '../../../hooks/useApi';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import CustomerDetailsPanel from '../components/CustomerDetailsPanel';
import LicenceUserPanel from '../components/LicenceUserPanel';
import ConfigurationPanel from '../components/ConfigurationPanel';
import AllowedRecipientsPanel from '../components/AllowedRecipientsPanel';
import AuthenticationPanel from '../components/AuthenticationPanel';
import BrandManagementPanel from '../components/BrandManagementPanel';
import SafeSenderPanel from '../components/SafeSenderPanel';

const CreateAccountDialog = ({ onHide, onConfirm, isLoading }) => {
    const { handleGet } = useApi();
    const [activeStep, setActiveStep] = useState(0);
    const [products, setProducts] = useState([]);
    const [isValid, setIsValid] = useState(false);

    const [accountDetail, setAccountDetails] = useState({
        companyName: '',
        isParentAccount: false,
        parentAccount: null,
        mta: 'AUTO_MTA0',
        type: 'external',
        licence: null,
        users: [],
        allowedRecipients: [],
        domainName: '',
        subDomainName: null,
        customFonts: [],
        customColours: [],
        safeSenders: [],
        isTrustedDelivery: false,
        trustedDeliveryType: 'nhs.net',
        hostname: '',
        port: null,
        username: ''
    });

    const handleCustomerDetailsChange = (field, value) => {
        setAccountDetails({ ...accountDetail, [field]: value });
    };

    const steps = [
        {
            label: 'Customer Detail',
            component: <CustomerDetailsPanel formData={accountDetail} onFormChange={handleCustomerDetailsChange} handleValid={setIsValid} />
        },
        {
            label: 'Licence & User',
            component: <LicenceUserPanel formData={accountDetail} onFormChange={handleCustomerDetailsChange} products={products} handleValid={setIsValid} />
        },
        {
            label: 'Authentication',
            component: <AuthenticationPanel formData={accountDetail} onFormChange={handleCustomerDetailsChange} handleValid={setIsValid} />
        },
        {
            label: 'Brand Management',
            component: <BrandManagementPanel formData={accountDetail} onFormChange={handleCustomerDetailsChange} handleValid={setIsValid} />
        }
    ];

    if (accountDetail.type === 'internal') {
        steps.splice(3, 0, {
            label: 'Safe Senders',
            component: <SafeSenderPanel formData={accountDetail} onFormChange={handleCustomerDetailsChange} handleValid={setIsValid} />
        });

        steps.splice(4, 0, {
            label: 'Allowed Recipients',
            component: <AllowedRecipientsPanel formData={accountDetail} onFormChange={handleCustomerDetailsChange} handleValid={setIsValid} />
        });
    }

    if (accountDetail.isTrustedDelivery) {
        steps.splice(2, 0, {
            label: 'Configuration',
            component: <ConfigurationPanel formData={accountDetail} onFormChange={handleCustomerDetailsChange} handleValid={setIsValid} />
        });
    }

    if (accountDetail.isParentAccount) {
        steps.splice(1, steps.length - 1);
    }

    useEffect(() => {
        if (accountDetail.isParentAccount === true) {
            setAccountDetails((prevAccountDetail) => ({
                ...prevAccountDetail,
                licence: 422
            }));
        }
    }, [accountDetail.isParentAccount]);

    useEffect(() => {
        if (accountDetail.isTrustedDelivery === false) {
            setAccountDetails((prevAccountDetail) => ({
                ...prevAccountDetail,
                hostname: 'send.nhs.net',
                port: '587',
                username: '',
                trustedDeliveryType: 'nhs.net'
            }));
        }
    }, [accountDetail.isTrustedDelivery]);

    useEffect(() => {
        if (accountDetail.type === 'external') {
            setAccountDetails((prevAccountDetail) => ({
                ...prevAccountDetail,
                safeSenders: [],
                allowedRecipients: []
            }));
        }
    }, [accountDetail.type]);

    useEffect(() => {
        if (accountDetail.domainName) {
            const subDomainName = `bounced.${accountDetail.domainName}`;

            setAccountDetails((prevAccountDetail) => ({
                ...prevAccountDetail,
                subDomainName
            }));
        }
    }, [accountDetail.domainName]);

    useEffect(() => {
        setAccountDetails((prevAccountDetail) => ({
            ...prevAccountDetail,
            hostname: '',
            port: '',
            username: ''
        }));
    }, [accountDetail.trustedDeliveryType]);

    useEffect(() => {
        if (accountDetail.trustedDeliveryType === 'nhs.net') {
            setAccountDetails((prevAccountDetail) => ({
                ...prevAccountDetail,
                hostname: 'send.nhs.net',
                port: 587,
                username: ''
            }));
        }
        else {
            setAccountDetails((prevAccountDetail) => ({
                ...prevAccountDetail,
                hostname: '',
                port: 25,
                username: ''
            }));
        }
    }, [accountDetail.trustedDeliveryType]);

    useEffect(() => {
        const fetchProducts = async () => {
            const response = await handleGet('superadmin/accounts/licences');

            if (!response.ok) {
                console.error('error loading products');
            }

            const data = await response.json();
            setProducts(data);
        };

        fetchProducts();
    }, []);

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={true}>
            <Box>
                <DialogTitle>New Account</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onHide}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 12
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent dividers>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        transition: '0.5s linear'
                    }}
                >
                    <Typography variant="h6" gutterBottom>{steps[activeStep].label}</Typography>
                </Box>
                {steps[activeStep].component}
            </DialogContent>
            <DialogActions sx={{ width: '100%', display: 'initial' }}>
                <MobileStepper
                    steps={steps.length}
                    sx={{ backgroundColor: 'inherit' }}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        activeStep === steps.length - 1 ? (
                            <Button
                                variant="contained"
                                onClick={() => onConfirm(accountDetail)}
                            >
                                {isLoading ? 'Working' : 'Submit'}
                            </Button>
                        ) : (
                            <Button
                                size="small"
                                onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                                disabled={!isValid}
                            >
                                Next
                                <KeyboardArrowRight />
                            </Button>
                        )
                    }
                    backButton={(
                        <Button size="small" onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)} disabled={activeStep === 0}>
                            <KeyboardArrowLeft />
                            Back
                        </Button>
                    )}
                />
            </DialogActions>
        </Dialog>
    );
};

export default CreateAccountDialog;
