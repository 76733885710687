import React from 'react';
import List from '@mui/material/List';
import Radio from '@mui/material/Radio';
import NavigationItem from '../contacts/NavigationItem';

const SegmentSelector = ({ segments, selectedSegment, setSelectedSegment, selectedSegmentContactsCount, isCountLoading }) => {
    return (
        <List component="nav" aria-label="Select Segment" disablePadding>
            {segments.map(segment => {
                const selected = selectedSegment === segment.id;

                return (
                    <NavigationItem
                        key={segment.id}
                        item={segment}
                        type="segment"
                        selected={selected}
                        onSetSelectedItem={() => setSelectedSegment(segment.id)}
                        onFilterByTag={null}
                        filtersCount={segment.filters.length}
                        contactsCount={selectedSegmentContactsCount}
                        isLoading={isCountLoading}
                        action={(
                            <Radio
                                edge="start"
                                checked={selected}
                                disableRipple
                                tabIndex={-1}
                                inputProps={{ 'aria-label': segment.name }}
                                onChange={() => setSelectedSegment(segment.id)}
                            />
                        )}
                    />
                );
            })}
        </List>
    );
};

export default SegmentSelector;