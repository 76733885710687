import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import formatFileSize from '../formatFileSize';

const dropzoneLabelStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};

const FileSizeErrorMessage = ({ maxSize }) => {
    return `File is larger than ${formatFileSize(maxSize)}`;
};

const Result = ({ filename, succeeded, result }) => {
    return (
        <Alert
            key={filename}
            severity={succeeded ? 'success' : 'error'}
            iconMapping={{
                error: <HighlightOffIcon fontSize="inherit" />
            }}
        >
            <AlertTitle>{filename}</AlertTitle>
            {result ?? 'Upload successful'}
        </Alert>
    );
};

const RenderResult = ({ result }) => {
    if (Array.isArray(result)) {
        return result.map((e, i) => (
            <Result
                key={i}
                filename={e.fileName}
                succeeded={e.succeeded}
                result={e.result}
            />
        ));
    }

    return (
        <Result
            filename={result.fileName}
            succeeded={result.succeeded}
            result={result.result}
        />
    );
};

const UploadFiles = ({ getInputProps, getRootProps, fileRejections, maxSize, guidance, uploadResult, isLoading, highlightedGuidance = null, isDropzoneDisabled = false, spacing = 3 }) => {
    const dropzoneStyle = {
        textAlign: 'center',
        cursor: (isLoading || isDropzoneDisabled) ? 'default' : 'pointer',
        border: '2px dashed #e5e5e5',
        minHeight: '50vh',
        position: 'relative'
    };

    return (
        <Box sx={{ padding: spacing }}>
            <Grid container spacing={spacing}>
                <Grid item xs={12} sm={6}>
                    {isDropzoneDisabled ? (
                        <Container maxWidth={false} sx={dropzoneStyle}>
                            <Box sx={dropzoneLabelStyle}>
                                <CheckIcon fontSize="large" color="success" />
                                <Typography
                                    component="p"
                                    variant="body1"
                                >
                                    File uploaded successfully
                                </Typography>
                            </Box>
                        </Container>
                    ) : (
                        <Container maxWidth={false} {...getRootProps()} sx={dropzoneStyle}>
                            <input {...getInputProps()} />
                            <Box sx={dropzoneLabelStyle}>
                                {isLoading ? <CircularProgress /> : <CloudUploadIcon fontSize="large" />}
                                <Typography
                                    component="p"
                                    variant="body1"
                                >
                                    {isLoading ? 'Uploading' : 'Drag and drop file, or click to browse'}
                                </Typography>
                            </Box>
                        </Container>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Stack spacing={2} sx={{ marginBottom: 2 }}>
                        {highlightedGuidance && <Alert severity="info">{highlightedGuidance}</Alert>}
                        {guidance}
                        {fileRejections?.length > 0 && fileRejections.map(({ file, errors }, i) => (
                            <Alert key={i} severity="error">
                                Upload failed for '{file.path}'
                                <List dense>
                                    {errors.map(e => (
                                        <ListItem key={e.code}>{(maxSize && e.code === 'file-too-large') ? <FileSizeErrorMessage maxSize={maxSize} /> : e.message}</ListItem>
                                    ))}
                                </List>
                            </Alert>
                        ))}
                        {uploadResult && <RenderResult result={uploadResult} />}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UploadFiles;