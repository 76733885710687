import React, { useState } from 'react';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';

import AddSafeSenderDialog from '../../accountDetails/modals/AddSafeSenderDialog';

const SafeSenderPanel = ({ formData, onFormChange }) => {
    const [safeSenders, setSafeSenders] = useState(formData.safeSenders);
    const [safeSenderDialog, setSafeSenderDialog] = useState(false);

    const handleSafeSenderSubmit = emailAddress => {
        setSafeSenders([...safeSenders, emailAddress]);
        onFormChange('safeSenders', [...safeSenders, emailAddress]);
        setSafeSenderDialog(false);
    };

    const handleDelete = index => {
        const filterdData = safeSenders.filter((_, key) => key !== index);

        setSafeSenders(filterdData);
        onFormChange('safeSenders', filterdData);
    };

    return (
        <>
            <Stack>
                <Button sx={{ mt: 1, width: 'fit-content' }} variant="contained" onClick={() => setSafeSenderDialog(true)}>
                    Add Safe Sender
                </Button>
                {safeSenders.length > 0 && (
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email Address</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {safeSenders.map((e, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>{e}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleDelete(index)} fontSize="small">
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Stack>
            {safeSenderDialog && (
                <AddSafeSenderDialog onSubmit={handleSafeSenderSubmit} onCancel={() => setSafeSenderDialog(false)} />
            )}
        </>
    );
};

export default SafeSenderPanel;