import React, { useState } from 'react';
// Material UI
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveIcon from '@mui/icons-material/Save';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// Components
import SmtpTestResultsDialog from '../../../account/smtpSettings/dialogs/SmtpTestResultsDialog';
import useApi from '../../../hooks/useApi';
import useSnackbar from '../../../hooks/useSnackbar';
import LoadingOverlay from '../../../loadingOverlay/LoadingOverlay';
import AccountDetailsField from '../fields/AccountDetailsField';

const SmtpSettings = ({ account, onRefresh }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [smtpUsername, setSmtpUsername] = useState(account.smtpConfiguration?.username ?? '');
    const [smtpHost, setSmtpHost] = useState(account.smtpConfiguration?.host ?? '');
    const [smtpPort, setSmtpPort] = useState(account.smtpConfiguration?.port ?? 0);
    const [isSmtpTestSubmitting, setIsSmtpTestSubmitting] = useState(false);
    const [smtpTestResult, setSmtpTestResult] = useState();
    const [smtpTestResultDialogIsOpen, setSmtpTestResultDialogIsOpen] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const { handlePut, handlePost, handleDelete } = useApi();
    const { showSnackbar } = useSnackbar();

    const onTestSmtpSettings = async () => {
        setIsSmtpTestSubmitting(true);

        const response = await handlePost(`superadmin/accounts/${account.id}/test-smtp-settings`, { smtpHost, smtpPort, smtpUsername });

        if (!response.ok) {
            return;
        }

        const data = await response.json();

        setIsSmtpTestSubmitting(false);
        setSmtpTestResult(data);
        setSmtpTestResultDialogIsOpen(true);
    };

    const handleDeleteSmtpConfig = async e => {
        e.preventDefault();

        setIsSubmitting(true);

        const response = await handleDelete(`superadmin/accounts/${account.id}/smtp-settings`);

        if (!response.ok) {
            setIsSubmitting(false);
            showSnackbar('An error occurred.', 'error');
            return;
        }

        showSnackbar('SMTP Configuration updated', 'success');

        setSmtpUsername('');
        setSmtpHost('');
        setSmtpPort(0);

        await onRefresh();

        setIsSubmitting(false);
    };

    const handleSubmit = async e => {
        e.preventDefault();

        setIsSubmitting(true);

        const response = await handlePut(`superadmin/accounts/${account.id}/smtp-settings`, {
            host: smtpHost,
            port: smtpPort,
            username: smtpUsername
        });

        if (!response.ok) {
            setIsSubmitting(false);
            showSnackbar('An error occurred.', 'error');
            return;
        }

        showSnackbar('SMTP Configuration updated', 'success');

        await onRefresh();

        setIsSubmitting(false);
    };

    return (
        <>
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Toolbar
                    variant="dense"
                    disableGutters
                    sx={{ px: 2 }}
                >
                    <div style={{ flexGrow: 1 }}>
                        <Typography variant="subtitle2">
                            SMTP
                        </Typography>
                    </div>
                    <Button
                        onClick={() => setCanEdit(!canEdit)}
                        size="small"
                        startIcon={ canEdit ? <EditOutlinedIcon />: <EditOffOutlinedIcon />}
                        color="warning"
                    >
                        Allow Edit
                    </Button>
                    <Button
                        onClick={onTestSmtpSettings}
                        size="small"
                        sx={{ mx: 1 }}
                    >
                        {isSmtpTestSubmitting ? 'Testing' : 'Test'}
                    </Button>
                    <Button
                        size="small"
                        type="submit"
                        startIcon={<SaveIcon />}
                        disabled={!canEdit}
                    >
                        Save
                    </Button>
                </Toolbar>
                <Divider />
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item>
                        <AccountDetailsField
                            value={smtpHost}
                            placeholder="Not set"
                            onChange={e => setSmtpHost(e.target.value)}
                            required
                            type="text"
                            label="Host"
                            fullWidth={true}
                            disabled={!canEdit}
                        />
                    </Grid>
                    <Grid item>
                        <AccountDetailsField
                            value={smtpPort}
                            onChange={e => setSmtpPort(e.target.value)}
                            type="number"
                            label="Port"
                            fullWidth={true}
                            disabled={!canEdit}
                        />
                    </Grid>
                    <Grid item>
                        <AccountDetailsField
                            value={smtpUsername}
                            placeholder="Not set"
                            onChange={e => setSmtpUsername(e.target.value)}
                            required
                            type="email"
                            label="Username" fullWidth={true}
                            disabled={!canEdit}
                        />
                    </Grid>
                    {account.smtpConfiguration && (
                        <Grid item>
                            <Button
                                startIcon={<DeleteIcon />}
                                onClick={handleDeleteSmtpConfig}
                                color="error"
                                disabled={!canEdit}
                            >
                                Delete SMTP Config
                            </Button>
                        </Grid>
                    )}
                </Grid>
                {canEdit && <Alert severity="warning">Editing enabled</Alert>}
            </form>
            {smtpTestResult && (
                <SmtpTestResultsDialog
                    open={smtpTestResultDialogIsOpen}
                    onClose={() => setSmtpTestResultDialogIsOpen(false)}
                    succeeded={smtpTestResult.succeeded}
                    message={smtpTestResult.message}
                />
            )}
            {(isSubmitting || isSmtpTestSubmitting) && (
                <LoadingOverlay />
            )}
        </>
    );
};

export default SmtpSettings;
