import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import useApi from '../../../hooks/useApi';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const AddPacksModal = ({ shown, onHide, onRefresh, customerId }) => {
    const { handlePost } = useApi();
    const [packs, setPacks] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        setPacks(event.target.value);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);

        const data = {
            productID: packs === 1000 ? 65 : 8
        };

        try {
            await handlePost(`superadmin/accounts/${customerId}/products`, data);

            await onRefresh();

            onHide();
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }

    };

    return (
        <Dialog
            open={shown}
            onClose={onHide}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Add Packs</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Please select the pack you want to add to the account.
                </DialogContentText>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Pack value</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={packs}
                            label="packs"
                            onChange={handleChange}
                        >
                            <MenuItem value={1000}>1000 Credits</MenuItem>
                            <MenuItem value={5000}>5000 Credits</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onHide} variant="outlined">
                    Close
                </Button>
                <Button
                    onClick={handleSubmit}
                    endIcon={isLoading ? <CircularProgress size={16} style={{ color: '#FFF' }} /> : null}
                >
                    {isLoading ? 'Adding' : 'Add Pack'}
                </Button>
            </DialogActions>
        </Dialog>
    );

};

export default AddPacksModal;