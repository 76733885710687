import { useState } from 'react';

const useInputDate = initialValue => {
    const [value, setValue] = useState(initialValue);

    return {
        value,
        onChange: value => setValue(value),
        onRevert: () => setValue(initialValue),
        hasChanged: value !== initialValue
    };
};

export default useInputDate;