import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import buildUrl from '../../buildUrl';
import useApi from '../../hooks/useApi';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useSnackbar from '../../hooks/useSnackbar';

const DownloadExportedFileDialog = ({ campaignId, open, onClose, search, status, groupId, device, client, linkId, orderBy, isDescending, count }) => {
    const { handleGet } = useApi();
    const [fileUrl, setFileUrl] = useState(null);
    const { showSnackbar } = useSnackbar();

    const params = {
        search,
        status,
        groupId,
        device,
        client,
        linkId,
        orderBy,
        isDescending
    };

    const url = buildUrl(`reports/${campaignId}/subscribers/export`, params);

    const handleGetFile = async () => {
        const response = await handleGet(url);

        if (!response) {
            showSnackbar('Export failed', 'error');
            return;
        }

        const data = await response.json();

        setFileUrl(data.downloadPath);
    };

    useEffect(() => {
        handleGetFile();
    }, []);

    if (!fileUrl) {
        return <LoadingOverlay />;
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Export contact{count > 1 && 's'} ({count})</DialogTitle>
            <DialogContent>
                Your export is complete. This link will be valid for the next 24hrs.
            </DialogContent>
            <DialogActions>
                <Button component="a" href={fileUrl} target="_blank" sx={{ marginRight: 1 }}>Download</Button>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DownloadExportedFileDialog;