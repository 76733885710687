import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ExportCompleteDialog = ({ downloadPath, onClose }) => {
    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Export Complete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your export is complete. This link will be valid for the next 24hrs.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button component="a" href={downloadPath} sx={{ marginRight: 1 }}>Download</Button>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExportCompleteDialog;