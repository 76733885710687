import React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { drawerWidth } from '../theme/constants';

const NavigationContainer = ({ children, width = null, isSmallScreen, open, onClose }) => {
    return (
        <Drawer
            variant={isSmallScreen ? 'temporary' : 'permanent'}
            open={isSmallScreen ? open : true}
            onClose={onClose}
            sx={{ width: width ?? drawerWidth, flexShrink: 0 }}
            PaperProps={{
                sx: { width: width ?? drawerWidth }
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto', height: '100%' }}>
                {children}
            </Box>
        </Drawer>
    );
};

export default NavigationContainer;