import { useState } from 'react';

const useInput = initialValue => {
    const reset = initialValue ?? '';
    const [value, setValue] = useState(reset);

    return {
        value,
        onChange: event => setValue(event.target.value),
        onRevert: () => setValue(reset),
        hasChanged: value !== reset
    };
};

export default useInput;