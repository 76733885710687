import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const ConfirmRevertToDraftDialog = ({ onClose, onRevertToDraft, item, type }) => {
    const handleRevertToDraft = e => {
        e.preventDefault();
        onRevertToDraft(type === 'email' ? item.campaignId : item.id);
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>{type === 'email' ? 'Cancel & ' : ''}Revert to Draft: {item.name}</DialogTitle>
            <DialogContent>
                {type === 'email' ? (
                    <>
                        <DialogContentText>
                            Please confirm you wish to cancel the scheduled send for this campaign. It will revert to a draft state.
                        </DialogContentText>
                        <Alert severity="warning" sx={{ mt: 2 }}>
                            This will stop your campaign from being sent but not delete the campaign.
                        </Alert>
                    </>
                ) : (
                    <Alert severity="warning" sx={{ mt: 2 }}>
                        A draft Page is not accessible outside of NewZapp.
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRevertToDraft}>Confirm</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmRevertToDraftDialog;
