import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

const AddSafeSenderDialog = ({ onSubmit, onCancel }) => {
    const [emailAddress, setEmailAddress] = useState('');

    return (
        <Dialog
            open={true}
            onClose={onCancel}
            maxWidth="sm"
        >
            <DialogTitle>Add Safe Sender</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Please enter the email address of the new safe sender.
                </DialogContentText>
                <FormGroup>
                    <TextField
                        autoFocus
                        fullwidth
                        type="email"
                        variant="outlined"
                        size="small"
                        label="Email Address"
                        autoComplete="off"
                        value={emailAddress}
                        onChange={e => setEmailAddress(e.target.value)}
                        placeholder="name@example.com"
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="outlined">
                    Close
                </Button>
                <Button onClick={() => onSubmit(emailAddress)}>
                    Add Safe Sender
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddSafeSenderDialog;