import React from 'react';
import { Box, Typography } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import { gaugeColour } from '../../../theme/constants';

const Gauge = ({ total, quota }) => {

    const max = total >= 9999
        ? Math.ceil(total / 1000) * 1000
        : total === 0
            ? 100
            : total;

    const options = {
        tooltip: {
            formatter: '{b} : {c}'
        },
        series: [
            {
                name: 'Credits Available',
                type: 'gauge',
                min: 0,
                max: max,
                detail: {
                    formatter: '{value}',
                    textStyle: {
                        fontSize: 18
                    }
                },
                axisLine: {
                    lineStyle: {
                        width: 10,
                        color: [
                            [0.2, gaugeColour.dark],
                            [0.8, gaugeColour.medium],
                            [1, gaugeColour.light]
                        ]
                    }
                },
                axisLabel: {
                    distance: -50,
                    formatter: val => total >= 9999 ? `${Math.floor(val / 1000)}K` : `${val}`
                },
                axisTick: {
                    splitNumber: 10
                },
                splitLine: {
                    show: true,
                    length: 15
                },
                pointer: {
                    show: true
                },
                data: [
                    {
                        value: quota,
                        name: 'Credits Available'
                    }
                ]
            }
        ]
    };

    return (
        <>
            <Box>
                <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
                    <div style={{ flexGrow: 1 }}>
                        <Typography variant="subtitle2">Credit Usage</Typography>
                    </div>
                </Toolbar>
                <Divider />
                <ReactECharts
                    option={options}
                    style={{ height: 350 }}
                    opts={{ renderer: 'svg' }}
                />
            </Box>
        </>
    );
};

export default Gauge;