import React, { useState, useEffect, useRef, useReducer } from 'react';
import Toolbar from '@mui/material/Toolbar';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import buildUrl from '../buildUrl';
import useSearch from '../hooks/useSearch';
import SubscriberDialog from './dialogs/SubscriberDialog';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadExportedFileDialog from './dialogs/DownloadExportedFileDialog';
import GroupSubscribersDialog from './dialogs/GroupSubscribersDialog';
import UnsubscribeSubscribersDialog from './dialogs/UnsubscribeSubscribersDialog';
import ResubscribeSubscribersDialog from './dialogs/ResubscribeSubscribersDialog';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DataColumn from '../dataTable/DataColumn';
import CenteredProgress from '../loadingOverlay/CenteredProgress';
import useApi from '../hooks/useApi';

const reducer = (prevState, newState) => {
    let { subscribers, count, orderBy, orderDesc, isLoading } = { ...prevState, ...newState };

    return {
        subscribers,
        count,
        orderBy,
        orderDesc,
        isLoading
    };

};

const initialState = {
    subscribers: [],
    count: 0,
    orderBy: 'Opens',
    orderDesc: true,
    isLoading: true
};

const rowsPerPageOptions = [10, 20, 50];

const columns = [
    { name: 'emailAddress', label: 'Email Address', orderDesc: false, dataType: 'text' },
    { name: 'opens', label: 'Total Opens', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'firstOpen', label: 'First Opened', align: 'right', orderDesc: false, dataType: 'dateTime' },
    { name: 'clicks', label: 'Total Clicks', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'firstClick', label: 'First Clicked', align: 'right', orderDesc: false, dataType: 'dateTime' },
    { name: 'deliveryStatus', label: 'Delivery Status', orderDesc: false, dataType: 'text' },
    { name: 'bounceCategory', label: 'Bounce Category', orderDesc: false, dataType: 'text' },
    { name: 'bounceMessage', label: 'Bounce Message', orderDesc: false, dataType: 'text' },
    { name: 'unsubscribeDate', label: 'Unsubscribed', align: 'right', orderDesc: false, dataType: 'dateTime' }
];

const ActionsMenu = ({ onExport, onGroup, onUnsubscribe, onResubscribe, showResubscribe, count }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExport = () => {
        onExport();
        handleClose();
    };

    const handleGroup = () => {
        onGroup();
        handleClose();
    };

    const handleUnsubscribe = () => {
        onUnsubscribe();
        handleClose();
    };

    const handleResubscribe = () => {
        onResubscribe();
        handleClose();
    };

    return (
        <>
            <Button
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                sx={{ mr: 1 }}
                disabled={count === 0}
            >
                Actions
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleExport}>Export Contact{count > 1 && 's'}</MenuItem>
                <MenuItem onClick={handleGroup}>Group Contact{count > 1 && 's'}</MenuItem>
                <MenuItem onClick={handleUnsubscribe}>Unsubscribe Contact{count > 1 && 's'}</MenuItem>
                {showResubscribe && <MenuItem onClick={handleResubscribe}>Resubscribe Contact{count > 1 && 's'}</MenuItem>}
            </Menu>
        </>
    );
};

const SubscribersTable = ({ campaignId, subscriberStatus = null, device = null, client = null, onChangeSubscriberStatus = null, linkId = null, enableJumping = false, selectedGroup = 'none' }) => {
    const [state, setState] = useReducer(reducer, initialState);
    const ref = useRef();
    const [searchValue, setSearchValue] = useState('');
    const [tempSearchValue, setTempSearchValue] = useSearch(setSearchValue);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [selectedSubscriber, setSelectedSubscriber] = useState(null);
    const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
    const [isGroupDialogOpen, setIsGroupDialogOpen] = useState(false);
    const [isUnsubscribeDialogOpen, setIsUnsubscribeDialogOpen] = useState(false);
    const [isResubscribeDialogOpen, setIsResubscribeDialogOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const { handleGet, handlePut } = useApi();

    const filterColumns = () => {
        const defaultColumns = columns.filter(e => e.name !== 'bounceMessage' && e.name !== 'bounceCategory' && e.name !== 'deliveryStatus' && e.name !== 'unsubscribeDate');

        const bouncedColumns = columns.filter(e => e.name === 'emailAddress' || e.name === 'bounceMessage' || e.name === 'bounceCategory' || e.name === 'deliveryStatus');

        const unsubscribedColumns = columns.filter(e => e.name === 'emailAddress' || e.name === 'unsubscribeDate');

        return subscriberStatus === 'bounced' ? bouncedColumns : subscriberStatus === 'unsubscribed' ? unsubscribedColumns : defaultColumns;
    };

    const tableColumns = filterColumns();

    const handleFetchSubscribersCount = async () => {
        const params = {
            search: searchValue,
            status: subscriberStatus,
            groupId: selectedGroup === 'none' ? null : selectedGroup,
            device,
            client,
            linkId
        };

        setState({ isLoading: true });

        const url = buildUrl(`reports/${campaignId}/subscribers/count`, params);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const count = await response.json();
        setState({ count });
    };

    const handleFetchSubscribersPage = async () => {
        const params = {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            search: searchValue,
            status: subscriberStatus,
            groupId: selectedGroup === 'none' ? null : selectedGroup,
            device,
            client,
            linkId,
            orderBy: state.orderBy,
            isDescending: state.orderDesc
        };

        setState({ isLoading: true });

        const url = buildUrl(`reports/${campaignId}/subscribers`, params);
        const response = await handleGet(url);

        if (!response.ok) {
            setState({ isLoading: false });
            return;
        }

        const subscribers = await response.json();
        setState({ subscribers, isLoading: false });
    };

    const handleSort = (field, defaultOrder) => {
        if (field === state.orderBy) {
            setState({ orderDesc: !state.orderDesc });
        }
        else {
            setState({ orderBy: field, orderDesc: defaultOrder });
        }
    };

    const handleGroup = async options => {
        setIsGroupDialogOpen(false);

        const query = {
            search: searchValue,
            status: subscriberStatus,
            device,
            client,
            linkId
        };

        setState({ isLoading: true });

        const url = buildUrl(`reports/${campaignId}/subscribers/groups`, query);

        const response = await handlePut(url, options);

        setState({ isLoading: false });

        if (response.ok) {
            setSnackbarMessage({ message: 'Contact(s) successfully added to group(s)', severity: 'success' });
        }
        else {
            setSnackbarMessage({ message: 'Error: Unable to group contacts', severity: 'error' });
        }
    };

    const handleUnsubscribe = async () => {
        setIsUnsubscribeDialogOpen(false);

        const query = {
            search: searchValue,
            status: subscriberStatus,
            device,
            client,
            linkId
        };

        setState({ isLoading: true });

        const url = buildUrl(`reports/${campaignId}/subscribers`, query);

        const response = await handlePut(url, { unsubscribed: true });

        setState({ isLoading: false });

        if (response.ok) {
            setSnackbarMessage({ message: 'Contact(s) successfully unsubscribed', severity: 'success' });
        }
        else {
            setSnackbarMessage({ message: 'Error: Unable to unsubscribe. Contact(s) may already be unsubscribed', severity: 'error' });
        }
    };

    const handleResubscribe = async () => {
        setIsResubscribeDialogOpen(false);

        const query = {
            search: searchValue,
            status: subscriberStatus,
            device,
            client,
            linkId
        };

        setState({ isLoading: true });

        const url = buildUrl(`reports/${campaignId}/subscribers`, query);

        const response = await handlePut(url, { unsubscribed: false });

        setState({ isLoading: false });

        if (response.ok) {
            setSnackbarMessage({ message: 'Contact(s) successfully resubscribed', severity: 'success' });
        }
        else {
            setSnackbarMessage({ message: 'Error: Unable to resubscribe. Contact(s) may already be resubscribed', severity: 'error' });
        }
    };

    useEffect(() => {
        if (enableJumping && !state.isLoading) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [state.isLoading]);

    useEffect(() => {
        handleFetchSubscribersCount();
    }, [subscriberStatus, searchValue, device, client, linkId]);

    useEffect(() => {
        setPage(0);
    }, [subscriberStatus, searchValue, device, client, linkId, state.orderBy, state.orderDesc, rowsPerPage]);

    useEffect(() => {
        setState({ isLoading: true });
    }, [searchValue, device, client, linkId, state.orderBy, state.orderDesc, rowsPerPage, page]);

    useEffect(() => {
        if (subscriberStatus === 'bounced' || subscriberStatus === 'failed' || subscriberStatus === 'unsubscribed') {
            setState({ orderBy: 'emailAddress', orderDesc: false, isLoading: true });
        }
        else if (subscriberStatus === 'clicked') {
            setState({ orderBy: 'clicks', orderDesc: true, isLoading: true });
        }
        else {
            setState({ orderBy: 'opens', orderDesc: true, isLoading: true });
        }
    }, [subscriberStatus]);

    useEffect(() => {
        state.isLoading && handleFetchSubscribersPage();
    }, [state.isLoading]);

    return (
        <>
            <div ref={ref}>
                <Paper sx={{ margin: 2 }}>
                    <Toolbar disableGutters sx={{ mx: 1 }}>
                        <Box sx={{ flexGrow: 1 }}>
                            {(subscriberStatus && onChangeSubscriberStatus) && (
                                <FormControl margin="dense" size="small" sx={{ ml: 1 }}>
                                    <Select
                                        value={subscriberStatus}
                                        onChange={e => onChangeSubscriberStatus(e.target.value)}
                                    >
                                        <MenuItem value={'selected'}>All Contacts</MenuItem>
                                        <MenuItem value={'sent'}>Sent</MenuItem>
                                        <MenuItem value={'delivered'}>Delivered</MenuItem>
                                        <MenuItem value={'opened'}>Unique Opens</MenuItem>
                                        <MenuItem value={'clicked'}>Unique Clicks</MenuItem>
                                        <MenuItem value={'unopened'}>Unopened</MenuItem>
                                        <MenuItem value={'bounced'}>Bounced</MenuItem>
                                        <MenuItem value={'failed'}>Failed</MenuItem>
                                        <MenuItem value={'unsubscribed'}>Unsubscribed</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                            <OutlinedInput
                                value={tempSearchValue}
                                onChange={e => setTempSearchValue(e.target.value)}
                                placeholder="Search Contacts..."
                                size="small"
                                sx={{ m: 1 }}
                                endAdornment={(
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setTempSearchValue('')}
                                            edge="end"
                                            disabled={!Boolean(tempSearchValue)}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            />
                        </Box>
                        <ActionsMenu
                            onExport={() => setIsExportDialogOpen(true)}
                            onGroup={() => setIsGroupDialogOpen(true)}
                            onUnsubscribe={() => setIsUnsubscribeDialogOpen(true)}
                            onResubscribe={() => setIsResubscribeDialogOpen(true)}
                            showResubscribe={subscriberStatus === 'bounced'}
                            count={state.count}
                        />
                    </Toolbar>
                    <Divider />
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map(col => (
                                        <TableCell key={col.name} component="th" scope="row" align={col.align}>
                                            <TableSortLabel
                                                active={state.orderBy === col.name}
                                                direction={state.orderDesc ? 'desc' : 'asc'}
                                                onClick={() => handleSort(col.name, col.orderDesc)}
                                                sx={{ whiteSpace: 'pre' }}
                                            >
                                                {col.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.subscribers.map(item => (
                                    <TableRow
                                        key={item.id}
                                        hover={true}
                                        onClick={() => setSelectedSubscriber(item)}
                                    >
                                        {tableColumns.map(col => (
                                            <DataColumn
                                                key={col.name}
                                                align={col.align}
                                                dataType={col.dataType}
                                                value={item[col.name]}
                                            />
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={state.count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => setPage(newPage)}
                        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                    />
                </Paper>
                {state.isLoading && (
                    <CenteredProgress />
                )}
            </div>
            {selectedSubscriber && (
                <SubscriberDialog
                    open={Boolean(selectedSubscriber)}
                    onClose={() => setSelectedSubscriber(null)}
                    subscriber={selectedSubscriber}
                    campaignId={campaignId}
                />
            )}

            {isExportDialogOpen && (
                <DownloadExportedFileDialog
                    open={isExportDialogOpen}
                    onClose={() => setIsExportDialogOpen(false)}
                    campaignId={campaignId}
                    search={searchValue}
                    status={subscriberStatus}
                    groupId={selectedGroup === 'none' ? null : selectedGroup}
                    device={device}
                    client={client}
                    linkId={linkId}
                    orderBy={state.orderBy}
                    isDescending={state.orderDesc}
                    count={state.count}
                />
            )}

            {isGroupDialogOpen && (
                <GroupSubscribersDialog
                    open={isGroupDialogOpen}
                    onClose={() => setIsGroupDialogOpen(false)}
                    onSubmit={handleGroup}
                    count={state.count}
                />
            )}

            {isUnsubscribeDialogOpen && (
                <UnsubscribeSubscribersDialog
                    open={isUnsubscribeDialogOpen}
                    onClose={() => setIsUnsubscribeDialogOpen(false)}
                    onSubmit={handleUnsubscribe}
                    count={state.count}
                />
            )}

            {isResubscribeDialogOpen && (
                <ResubscribeSubscribersDialog
                    open={isResubscribeDialogOpen}
                    onClose={() => setIsResubscribeDialogOpen(false)}
                    onSubmit={handleResubscribe}
                    count={state.count}
                />
            )}

            {snackbarMessage && (
                <Snackbar open={snackbarMessage} autoHideDuration={6000} onClose={() => setSnackbarMessage(null)}>
                    <Alert onClose={() => setSnackbarMessage(null)} severity={snackbarMessage.severity}>
                        {snackbarMessage.message}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

export default SubscribersTable;