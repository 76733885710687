import React, { forwardRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Documents from '../../documents/Documents';
import CampaignSelect from '../../campaign/CampaignSelect';
import PageSelect from '../../page/PageSelect';
import SurveySelect from '../../survey/SurveySelect';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

Transition.displayName = 'Transition';

const ContentLinkDialog = ({ onClose, onSelectLink }) => {
    const [type, setType] = useState('document');

    return (
        <Dialog
            onClose={onClose}
            open={true}
            fullScreen
            TransitionComponent={Transition}
            onClick={e => e.stopPropagation()}
        >
            <AppBar position="sticky" color="inherit">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Link to Document, Campaign, Landing Page or Survey
                    </Typography>
                </Toolbar>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={type} onChange={(e, value) => setType(value)}>
                        <Tab label="Documents" value="document" />
                        <Tab label="Campaigns" value="email" />
                        <Tab label="Landing Pages" value="page" />
                        <Tab label="Surveys" value="survey" />
                    </Tabs>
                </Box>
            </AppBar>
            {type === 'document' && (
                <Documents
                    onSelectDocument={onSelectLink}
                />
            )}
            <Box padding={2}>
                {type === 'survey' && (
                    <SurveySelect
                        onSelect={survey => onSelectLink(survey.shareLink)}
                    />
                )}
                {type === 'email' && (
                    <CampaignSelect
                        onSelect={campaign => onSelectLink(campaign.shareLink)}
                    />
                )}
                {type === 'page' && (
                    <PageSelect
                        onSelect={page => onSelectLink(page.viewLink)}
                    />
                )}
            </Box>
        </Dialog>
    );
};

export default ContentLinkDialog;