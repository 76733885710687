import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import ManualList from '../ManualList';
import useApi from '../../hooks/useApi';
import useAccount from '../../hooks/useAccount';
import FilteredGroupsSelector from '../../contacts/FilteredGroupsSelector';
import FilteredSegmentSelector from '../../contacts/FilteredSegmentSelector';
import ShoppingList from '../../editor/ShoppingList';
import AllowedRecipientsList from '../../contacts/AllowedRecipientsList';

const emailAddressesLimit = 20;

const Audience = ({ audience, onChangeAudience, onNext, onBack, drawerWidth, steps, step, campaign, isProofSent, audienceName, titleBarDepth, setStep }) => {
    const [isInitialising, setisInitialising] = useState(true);
    const [segments, setSegments] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const { handleGet } = useApi();
    const { isInternalComms, allowedRecipients } = useAccount();

    const handleFetchSegments = async () => {
        const response = await handleGet('segments');

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const data = await response.json();
        setSegments(data);
    };

    const handleClickSegment = id => {
        if (audience.selectedSegment === id) {
            onChangeAudience({ selectedSegment: null });
        }
        else {
            onChangeAudience({ selectedSegment: id, groupIds: [], emailAddresses: [] });
        }
    };

    const handleToggleSelectedGroups = groupIds => {
        onChangeAudience({ groupIds, selectedSegment: null, emailAddresses: [] });
    };

    const handleChangeAudience = audienceType => {
        onChangeAudience({
            audienceType,
            ...(audience.audienceType === 0 && { selectedSegment: null, groupIds: [] }),
            ...(audience.audienceType === 2 && { selectedSegment: null, emailAddresses: [] }),
            ...(audience.audienceType === 4 && { selectedSegment: null, groupIds: [], emailAddresses: [] })
        });
    };

    const handleNext = () => {
        onNext({
            audience: audience.audienceType,
            ...(audience.audienceType === 0 && { emailAddresses: audience.emailAddresses, groupIds: [], segmentId: null }),
            ...(audience.audienceType === 2 && { groupIds: audience.groupIds, emailAddresses: [], segmentId: null }),
            ...((audience.audienceType === 3 && audience.selectedSegment) && { segmentId: audience.selectedSegment, emailAddresses: [], groupIds: [] }),
            ...(audience.audienceType === 4 && { segmentId: null, emailAddresses: [], groupIds: [] }),
            sendToAll: audience.audienceType === 4
        });
    };

    const handleCheckIsValid = () => {
        return ((audience.audienceType === 0 && audience.emailAddresses.length > 0 && audience.emailAddresses.length <= emailAddressesLimit)
            || (audience.audienceType === 2 && audience.groupIds && audience.groupIds.length > 0)
            || (audience.audienceType === 3 && audience.selectedSegment)
            || audience.audienceType === 4);
    };

    useEffect(() => {
        (async () => {
            await handleFetchSegments();

            setisInitialising(false);
        })();

        setIsFormValid(handleCheckIsValid());
    }, []);

    useEffect(() => {
        setIsFormValid(handleCheckIsValid());
    }, [audience]);

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64, zIndex: titleBarDepth }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">Audience</Typography>
                </Toolbar>
            </AppBar>
            <div style={{ marginRight: drawerWidth, flexGrow: 1, height: 'calc(100vh - 130px)', overflowY: 'auto' }}>
                {isInitialising ? <LoadingOverlay /> : (
                    <Container disableGutters>
                        <Paper sx={{ m: 2, p: 3 }}>
                            <FormControl fullWidth sx={{ marginBottom: 3 }}>
                                <Select
                                    value={audience.audienceType}
                                    onChange={e => handleChangeAudience(e.target.value)}
                                >
                                    <MenuItem value={2}>Groups</MenuItem>
                                    <MenuItem value={3}>Segment</MenuItem>
                                    <MenuItem value={4}>All</MenuItem>
                                    <MenuItem value={0}>Manual List</MenuItem>
                                </Select>
                            </FormControl>
                            <div hidden={audience.audienceType !== 0}>
                                <Typography paragraph>If any of these email addresses are not currently in your account, they will be automatically added. After you have sent your campaign they will appear as ungrouped Contacts.</Typography>
                                <FormControl fullWidth>
                                    <ManualList
                                        emailAddresses={audience.emailAddresses}
                                        setEmailAddresses={emailAddresses => onChangeAudience({ ...audience, emailAddresses })}
                                        emailAddressesLimit={20}
                                    />
                                    <FormHelperText margin="dense">Send up to 20 addresses.</FormHelperText>
                                </FormControl>
                                {(isInternalComms && allowedRecipients.length > 0) && (
                                    <Box marginTop={2}>
                                        <AllowedRecipientsList allowedRecipients={allowedRecipients} />
                                    </Box>
                                )}
                            </div>
                            <div hidden={audience.audienceType !== 2}>
                                {audience.audienceType === 2 && (
                                    <FilteredGroupsSelector
                                        selectedGroups={audience.groupIds}
                                        setSelectedGroups={groupIds => handleToggleSelectedGroups(groupIds)}
                                        limitedHeight={false}
                                    />
                                )}
                            </div>
                            <div hidden={audience.audienceType !== 3}>
                                {(segments && segments.length > 0) ? (
                                    <FilteredSegmentSelector
                                        selectedSegment={audience.selectedSegment}
                                        setSelectedSegment={id => handleClickSegment(id)}
                                        limitedHeight={false}
                                    />
                                ) : (
                                    'No segments found'
                                )}
                            </div>
                            <div hidden={audience.audienceType !== 4}>
                                <Typography paragraph>Choose this option to send to ALL contacts in your account.</Typography>
                            </div>
                        </Paper>
                    </Container>
                )}
            </div>
            <ShoppingList
                drawerWidth={drawerWidth}
                stepNumber={step}
                steps={steps}
                onNext={handleNext}
                onBack={onBack}
                disabled={!isFormValid}
                campaign={campaign}
                isProofSent={isProofSent}
                audienceName={audienceName}
                setStep={setStep}
            />
        </>
    );
};

export default Audience;