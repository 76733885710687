import React, { useState, useEffect } from 'react';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import useSnackbar from '../../hooks/useSnackbar';
import useApi from '../../hooks/useApi';
import AlertBar from '../../alertBar/AlertBar';
import Paper from '@mui/material/Paper';
import { Stack } from '@mui/material';

const BrandSettings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [account, setAccount] = useState();
    const [company, setCompany] = useState('');
    const [logo, setLogo] = useState();
    const { handleFetch, handleGet, handlePut, handleDelete } = useApi();
    const { showSnackbar } = useSnackbar(null);

    const handleFetchAccount = async () => {
        const response = await handleGet('account');

        if (!response.ok) {
            setIsLoading(false);
            setIsSubmitting(false);
            return;
        }

        const data = await response.json();

        setAccount(data);
        setCompany(data.company);
        setLogo(data.logo);

        setIsLoading(false);
        setIsSubmitting(false);
    };

    const onSubmit = async e => {
        e.preventDefault();

        setIsSubmitting(true);

        const response = await handlePut('account', {
            company
        });

        if (response.ok) {
            await handleFetchAccount();
        }
        else {
            showSnackbar('Error loading account', 'error');
        }
    };

    const onChangeLogo = async e => {
        setIsSubmitting(true);

        const data = new FormData();
        data.append('file', e.target.files[0]);

        const response = await handleFetch('account/company-logo', {
            method: 'post',
            body: data
        });

        if (!response.ok) {
            showSnackbar('Error uploading logo', 'error');
            setIsSubmitting(false);
        }

        await handleFetchAccount();
    };

    const onDeleteLogo = async () => {
        const response = await handleDelete('account/company-logo');

        if (response.ok) {
            await handleFetchAccount();
        }
        else {
            showSnackbar('Failed to delete logo', 'error');
        }
    };

    useEffect(() => {
        handleFetchAccount();
    }, []);

    useEffect(() => {
        setHasUnsavedChanges(company !== account?.company);
    }, [account, company]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AlertBar
                shown={isLoading || hasUnsavedChanges}
                action={(
                    <Button
                        variant="outlined"
                        size="small"
                        disabled={isLoading}
                        onClick={onSubmit}
                    >
                        Save Changes
                    </Button>
                )}
                positionTop={128}
                mb={2}
            >
                {isLoading ? 'Updating...' : 'You have unsaved changes.'}
            </AlertBar>
            <Stack spacing={2}>
                <Paper sx={{ p: 3 }}>
                    <Typography component="h2" variant="h6" gutterBottom>Company Branding</Typography>
                    <TextField value={company} onChange={e => setCompany(e.target.value)} type="text" label="Company Name" fullWidth={true} />
                </Paper>

                <Card sx={{ mt: 2 }}>
                    <CardHeader
                        sx={{ marginRight: 1 }}
                        action={(
                            <>
                                <label htmlFor="upload-company-logo">
                                    <Button component="span">Upload</Button>
                                </label>
                                {logo && (
                                    <Button onClick={onDeleteLogo} sx={{ marginLeft: 2 }} color="error">Delete</Button>
                                )}
                            </>
                        )}
                        title={<Typography component="h1" variant="h6" sx={{ flex: 1 }}>Company Logo</Typography>}
                    />
                    <CardContent sx={{ paddingTop: 0 }}>
                        <Alert severity="info" sx={{ marginBottom: 2 }}>
                            Images greater than 300px wide are automatically resized to a max width of 300px on upload.<br />
                            Supported file types: .JPG, .JPEG, .PNG
                        </Alert>
                        {logo && (
                            <Box component="img" alt={company} src={logo} sx={{ maxWidth: 300, mb: 1 }} />
                        )}
                        <FormGroup row>
                            <Box sx={{ display: 'none' }}>
                                <Box component="input" type="file" accept="image/*" onChange={onChangeLogo} id="upload-company-logo" />
                            </Box>
                        </FormGroup>
                    </CardContent>
                </Card>
            </Stack>

            {(isSubmitting) && (
                <LoadingOverlay />
            )}
        </>
    );
};

export default BrandSettings;