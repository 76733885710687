import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { TimePicker } from '@mui/x-date-pickers-pro';
import { DatePicker } from '@mui/x-date-pickers-pro';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { format } from 'date-fns';
import ShoppingList from '../../editor/ShoppingList';
import enGbLocale from 'date-fns/locale/en-GB';
import dayjs from 'dayjs';

const Schedule = ({ schedule, onToggleSchedule, sendDateTime, onChangeSendDateTime, onNext, onBack, drawerWidth, steps, step, campaign, isProofSent, audienceName, titleBarDepth, setStep }) => {
    const [date, setDate] = useState(sendDateTime ? dayjs(sendDateTime) : dayjs().add(1, 'hour'));
    const [time, setTime] = useState(sendDateTime ? dayjs(sendDateTime) : dayjs().add(1, 'hour'));

    const handleToggle = value => {
        value !== null && onToggleSchedule(value);
    };

    useEffect(() => {
        if (dayjs(date).isValid()) {
            if (dayjs(time).isValid()) {
                //overwrite time part of date
                const dateAndTime = dayjs(date).set('hour', time.get('hour')).set('minute', time.get('minute'));
                onChangeSendDateTime(dateAndTime);
            }
            else {
                onChangeSendDateTime(date);
            }
        }
    }, [time, date]);

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64, zIndex: titleBarDepth }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">
                        Schedule
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{ marginRight: drawerWidth, flexGrow: 1 }}>
                <Container disableGutters>
                    <Paper sx={{ m: 2, p: 3 }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <ToggleButtonGroup
                                value={schedule}
                                exclusive
                                onChange={(e, value) => handleToggle(value)}
                                aria-label="Time to send"
                                color="primary"
                            >
                                <ToggleButton value={false} aria-label="immediately">
                                    Immediately
                                </ToggleButton>
                                <ToggleButton value={true} aria-label="schedule">
                                    Schedule
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Typography variant="h5" sx={{ pt: 3, pb: 2 }}>
                                Time to Send: {schedule ? format((new Date(sendDateTime)), 'HH:mm \'on\' MMMM do yyyy') : 'Immediately'}
                            </Typography>
                        </Box>
                        {schedule && (
                            <Stack direction="row" spacing={2} justifyContent="center">
                                <Box>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={enGbLocale}>
                                        <DatePicker
                                            label="Date"
                                            mask="__/__/____"
                                            inputFormat="DD/MM/YYYY"
                                            openTo="day"
                                            disablePast
                                            value={date}
                                            onChange={date => setDate(date)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={enGbLocale}>
                                        <TimePicker
                                            label="Time"
                                            mask="__:__"
                                            inputFormat="HH:mm"
                                            value={time}
                                            onChange={time => setTime(time)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Stack>
                        )}
                    </Paper>
                </Container>
            </div>
            <ShoppingList
                drawerWidth={drawerWidth}
                stepNumber={step}
                steps={steps}
                onNext={() => onNext({ sendDateTime: schedule ? sendDateTime : null })}
                onBack={onBack}
                campaign={campaign}
                isProofSent={isProofSent}
                audienceName={audienceName}
                disabled={schedule && (sendDateTime < new Date())}
                setStep={setStep}
            />
        </>
    );
};

export default Schedule;