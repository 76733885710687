import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import buildUrl from '../../buildUrl';
import useApi from '../../hooks/useApi';
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import NoRows from '../../dataTable/NoRows';
import usePrevious from '../../hooks/usePrevious';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { grey } from '@mui/material/colors';

const rowsPerPageOptions = [10, 20, 50];

const calculateRowHeight = (message) => {
    const baseHeight = 44; // Base height of row
    const lineHeight = 24; // Line height for text
    const maxLines = 8; // Maximum lines before extra space is added
    const lines = Math.min(Math.ceil(message.length / 100), maxLines); // Approximation for text lines
    return baseHeight + (lineHeight * lines);
};

const columns = [
    { field: 'subject', headerName: 'Campaign', orderDesc: false, type: 'string', width: 300,
        renderCell: (params) => {
            const subject = params.row.subject || '';
            return (
                <Box sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {subject}
                </Box>
            );
        }
    },
    { field: 'sent', headerName: 'Send Date', orderDesc: true, type: 'dateTime', width: 200, valueGetter: ({ value }) => value && new Date(value) },
    { field: 'opened', headerName: 'Opened', orderDesc: false, type: 'boolean' },
    { field: 'clicked', headerName: 'Clicked', orderDesc: false, type: 'boolean' },
    { field: 'shared', headerName: 'Shared', orderDesc: false, type: 'boolean' },
    { field: 'bounced', headerName: 'Bounced', orderDesc: false, type: 'boolean' },
    { field: 'bounceMessage', headerName: 'Bounce Message', orderDesc: false, type: 'string', width: 600,
        renderCell: (params) => {
            const message = params.row.bounceMessage || '';
            return message ? (
                <Box sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {message}
                </Box>
            ) : <ClearOutlinedIcon sx={{ fontSize: 20, color: grey[500] }} />;
        }
    }
];

const SendHistory = ({ id }) => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [rowHeight, setRowHeight] = useState(null);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState('sent');
    const [orderDesc, setOrderDesc] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const prevRowsPerPage = usePrevious(rowsPerPage);
    const prevOrderBy = usePrevious(orderBy);
    const prevOrderDesc = usePrevious(orderDesc);
    const prevPage = usePrevious(page);
    const { handleGet } = useApi();
    const theme = useTheme();

    const handleFetchContactSendHistory = async () => {
        const params = {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            field: orderBy,
            isDescending: orderDesc
        };

        const url = buildUrl(`contacts/${id}/campaigns`, params);

        const response = await handleGet(url);

        if (!response.ok) {
            setIsLoading(false);
            console.error(response?.error);
            return;
        }

        const data = await response.json();

        setData(data);
        setIsLoading(false);
    };

    const handleFetchContactSendHistoryCount = async () => {
        const response = await handleGet(`contacts/${id}/campaigns/count`);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const count = await response.json();

        setCount(count);
    };

    const handleSort = props => {
        const { field, sort } = props[0];

        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(sort === 'desc');
        }
    };

    useEffect(() => {
        if (orderBy !== prevOrderBy ||
            orderDesc !== prevOrderDesc ||
            rowsPerPage !== prevRowsPerPage) {
            setPage(0);
            setIsLoading(true);
        }
        else if (page !== prevPage) {
            setIsLoading(true);
        }
    }, [orderBy, orderDesc, rowsPerPage, page]);

    useEffect(() => {
        // Update row height dynamically based on the longest message
        const maxMessage = data.reduce((max, row) => row.bounceMessage && row.bounceMessage.length > max.length ? row.bounceMessage : max, '');
        setRowHeight(calculateRowHeight(maxMessage));
    }, [data]);

    useEffect(() => {
        handleFetchContactSendHistory();
        handleFetchContactSendHistoryCount();

        if (isLoading) {
            handleFetchContactSendHistory();
            handleFetchContactSendHistoryCount();
        }
    }, [isLoading]);

    const sort = orderDesc ? 'desc' : 'asc';

    return (
        <Box
            sx={{
                '& .MuiDataGrid-booleanCell[data-value="true"]': {
                    color: theme.palette.success.light
                },
                '& .MuiDataGrid-booleanCell[data-value="false"]': {
                    color: theme.palette.error.light
                }
            }}
        >
            <DataGridPro
                autoHeight
                disableSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableMultipleColumnsSorting
                hideFooterSelectedRowCount
                pagination
                paginationMode="server"
                sortingMode="server"
                rows={data}
                rowCount={count}
                rowHeight={rowHeight ?? undefined}
                columns={columns}
                loading={isLoading}
                pageSize={rowsPerPage}
                onPageSizeChange={rowsPerPage => setRowsPerPage(rowsPerPage)}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageChange={page => setPage(page)}
                page={page}
                onSortModelChange={props => handleSort(props)}
                sortingOrder={['desc', 'asc']}
                initialState={{
                    sorting: {
                        sortModel: [{ field: orderBy, sort }]
                    }
                }}
                components={{
                    NoRowsOverlay: NoRows
                }}
            />
        </Box>
    );
};

export default SendHistory;