const stringOperators = [
    { name: 'equals', displayName: 'Equals' },
    { name: 'notEquals', displayName: 'Does Not Equal' },
    { name: 'contains', displayName: 'Contains' },
    { name: 'notContains', displayName: 'Does Not Contain' }
];

const numberOperators = [
    { name: 'equals', displayName: 'Equals' },
    { name: 'notEquals', displayName: 'Does Not Equal' },
    { name: 'greaterThanOrEqual', displayName: 'Equal or Greater Than' },
    { name: 'lessThanOrEqual', displayName: 'Equal or Less Than' }
];

const dateOperators = [
    { name: 'equals', displayName: 'Equals' },
    { name: 'greaterThanOrEqual', displayName: 'Equal or After' },
    { name: 'lessThanOrEqual', displayName: 'Equal or Before' }
];

export { stringOperators, numberOperators, dateOperators } ;