import React from 'react';
import Box from '@mui/material/Box';

const NoRows = () => {
    return (
        <Box pt={3} sx={{ textAlign: 'center' }}>
            No data
        </Box>
    );
};

export default NoRows;