import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useRouteMatch, useLocation } from 'react-router-dom';
import Templates from './Templates';
import EditTemplate from './EditTemplate';
import Accounts from './accounts/Accounts';
import AccountDetails from './accountDetails/AccountDetails';
import Campaigns from './campaigns/Campaigns';
import Content from './content/Content';
import EmailEditor from './content/EmailEditor';
import Notifications from './notifications/Notifications';
import Auditing from './auditing/Auditing';
import Imports from './imports/Imports';
import SuperAdminNavigation from './SuperAdminNavigation';
import Features from './features/Features';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { useMediaQuery } from '@mui/material';

const drawerWidth = 160;

const SuperAdmin = () => {
    const { path } = useRouteMatch();
    const theme = useTheme();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const boxStyles = { marginLeft: isSmallScreen ? '0' : `${drawerWidth}px`, flexGrow: 1 };

    const toggleDrawer = (newOpen) => {
        setOpen(newOpen);
    };

    useEffect(() => {
        setOpen(false);
    }, [location]);

    const isExcludedRoute = location.pathname.includes(`${path}/templates/`) || location.pathname.includes(`${path}/emails/`);

    return (
        <>
            <Box sx={boxStyles}>
                {!isExcludedRoute && (
                    <>
                        {!isSmallScreen && <SuperAdminNavigation width={drawerWidth} />}
                        {isSmallScreen && <SuperAdminNavigation width={drawerWidth} isSmallScreen={isSmallScreen} open={open} onClose={() => toggleDrawer(false)} />}
                    </>
                )}
            </Box>
            <Switch>
                <Route exact path={`${path}/templates`}>
                    <Box sx={boxStyles}>
                        <Templates showHamburger={isSmallScreen} onClickHamburger={toggleDrawer} />
                    </Box>
                </Route>
                <Route exact path={`${path}/templates/:templateId`}>
                    <EditTemplate />
                </Route>
                <Route path={`${path}/campaigns`}>
                    <Box sx={boxStyles}>
                        <Campaigns showHamburger={isSmallScreen} onClickHamburger={toggleDrawer} />
                    </Box>
                </Route>
                <Route exact path={`${path}/accounts`}>
                    <Box sx={boxStyles}>
                        <Accounts showHamburger={isSmallScreen} onClickHamburger={toggleDrawer} />
                    </Box>
                </Route>
                <Route path={`${path}/accounts/:id`}>
                    <Box sx={boxStyles}>
                        <AccountDetails showHamburger={isSmallScreen} onClickHamburger={toggleDrawer} />
                    </Box>
                </Route>
                <Route path={`${path}/notifications`}>
                    <Box sx={boxStyles}>
                        <Notifications showHamburger={isSmallScreen} onClickHamburger={toggleDrawer} />
                    </Box>
                </Route>
                <Route path={`${path}/auditing`}>
                    <Box sx={boxStyles}>
                        <Auditing showHamburger={isSmallScreen} onClickHamburger={toggleDrawer} />
                    </Box>
                </Route>
                <Route path={`${path}/imports`}>
                    <Box sx={boxStyles}>
                        <Imports showHamburger={isSmallScreen} onClickHamburger={toggleDrawer} />
                    </Box>
                </Route>
                <Route exact path={`${path}/content`}>
                    <Box sx={boxStyles}>
                        <Content showHamburger={isSmallScreen} onClickHamburger={toggleDrawer} />
                    </Box>
                </Route>
                <Route exact path={`${path}/emails/:id`}>
                    <Box>
                        <EmailEditor />
                    </Box>
                </Route>
                <Route exact path={`${path}/features`}>
                    <Box sx={boxStyles}>
                        <Features showHamburger={isSmallScreen} onClickHamburger={toggleDrawer} />
                    </Box>
                </Route>
                <Redirect to={`${path}/accounts`} />
            </Switch>
        </>
    );
};

export default SuperAdmin;
