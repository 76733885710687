import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

const AddFeaturesModal = ({ shown, onHide, onSubmit, isLoading, data }) => {
    const [checked, setChecked] = React.useState([]);

    const handleSubmit = async e => {
        e.preventDefault();

        const selecteFeatureObject = checked.map(selectedFeature => data.features.find((feature) => feature.name === selectedFeature));
        await onSubmit(selecteFeatureObject);
        onHide();
    };

    // for toggle features
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        }
        else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    useEffect(() => {
        if (data.userFeatures.length > 0) {
            const userFeaturesNames = data.userFeatures.map(item => item.name);
            setChecked(userFeaturesNames);
        }
    }, [data.userFeatures]);

    return (
        <Dialog
            open={shown}
            onClose={onHide}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Assign Features</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Toggle one or more features you want to add to this account.
                </DialogContentText>
            </DialogContent>
            {data.features.some((e) => e.level === 0) ? (
                <>
                    <FormControl sx={{ m: 1 }}>
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                        >
                            {data.userFeatures.length > 0
                                ? data.features
                                    .filter((feature) => feature.level === 0)
                                    .map((feature, i) => (
                                        <ListItem key={i}>
                                            <ListItemText id={`switch-list-label-${feature.id}`} primary={feature.name} />
                                            <Switch
                                                edge="end"
                                                onChange={handleToggle(feature.name)}
                                                checked={checked.indexOf(feature.name) !== -1}
                                                inputProps={{
                                                    'aria-labelledby': `switch-list-label-${feature.name}`
                                                }}
                                            />
                                        </ListItem>
                                    ))
                                : data.features
                                    .filter((feature) => feature.level === 0)
                                    .map((feature, i) => (
                                        <ListItem key={i}>
                                            <ListItemText id={`switch-list-label-${feature.id}`} primary={feature.name} />
                                            <Switch
                                                edge="end"
                                                onChange={handleToggle(feature.name)}
                                                checked={checked.indexOf(feature.name) !== -1}
                                                inputProps={{
                                                    'aria-labelledby': `switch-list-label-${feature.name}`
                                                }}
                                            />
                                        </ListItem>
                                    ))
                            }
                        </List>
                    </FormControl>

                    <DialogActions>
                        <Button onClick={onHide} variant="outlined">
                            Close
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            endIcon={isLoading ? <CircularProgress size={16} color="inherit" /> : null}
                        >
                            {isLoading ? 'Working' : 'Save Changes'}
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <Box style={{ alignItems: 'center', justifyContent: 'center', height: '100%', padding: 3 }}>
                        <Alert severity="info">No Features to enable.</Alert>
                    </Box>
                    <DialogActions>
                        <Button onClick={onHide} variant="outlined">
                            Close
                        </Button>
                    </DialogActions>
                </>
            )}

        </Dialog>
    );
};

export default AddFeaturesModal;