import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ConfirmSuppressContactDialog = ({ onClose, emailAddress, onConfirm }) => {

    const handleConfirm = e => {
        e.preventDefault();
        onConfirm();
        onClose();
    };

    //add loading state and await confirm?

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Suppress {emailAddress}</DialogTitle>
            <DialogContent>
                <Typography paragraph>
                    Suppress this contact? This action is only temporary.
                </Typography>
                <Typography>
                    This contact will no longer receive any campaigns from your account. The contact can be unsuppressed at any time.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>Confirm Suppress</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmSuppressContactDialog;