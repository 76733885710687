import React, { useEffect, useState, useRef } from 'react';
import 'survey-analytics/survey.analytics.min.css';
import { setLicenseKey } from 'survey-core';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'survey-analytics/survey.analytics.tabulator.min.css';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import { Model } from 'survey-core';
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import config from '../../config';

const { SURVEY_API_KEY } = config;

setLicenseKey(SURVEY_API_KEY);

const SurveyTable = ({ surveyJson, surveyResults }) => {
    const [surveyModel, setSurveyModel] = useState(null);
    const [surveyDataTable, setSurveyDataTable] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const surveyDataTableRef = useRef(null);

    useEffect(() => {
        if (surveyJson !== null) {
            const newSurvey = new Model(surveyJson);

            setSurveyModel(newSurvey);
        }
    }, [surveyJson]);

    useEffect(() => {
        if (!!surveyModel && surveyResults !== null) {
            setIsLoading(true);

            const newSurveyDataTable = new Tabulator(
                surveyModel,
                surveyResults
            );

            setSurveyDataTable(newSurveyDataTable);

            newSurveyDataTable.options.downloadButtons = ['xlsx', 'csv'];
            setIsLoading(false);
        }
    }, [surveyModel, surveyResults]);

    useEffect(() => {
        if (surveyDataTable !== null && surveyDataTableRef.current) {
            surveyDataTable.render(surveyDataTableRef.current);

            return () => {
                if (surveyDataTableRef.current) {
                    surveyDataTableRef.current.innerHTML = '';
                }
            };
        }
    }, [surveyDataTable]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <Paper>
            <Box id="surveyDataTable" ref={surveyDataTableRef} />
        </Paper>
    );
};

export default SurveyTable;