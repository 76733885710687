import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import html2pdf from 'html2pdf.js';

const rows = [
    {
        'name': 'nz-k1._domainkey.[customerdomain]',
        'key': 'nz-k1.dkim.newzapp.co.uk'
    },
    {
        'name': 'nz-k2._domainkey.[customerdomain]',
        'key': 'nz-k2.dkim.newzapp.co.uk'
    },
    {
        'name': 'nz-k3._domainkey.[customerdomain]',
        'key': 'nz-k3.dkim.newzapp.co.uk'
    }
];

const customDkimRows = [
    {
        'name': 'key1._domainkey.[customerdomain]',
        'key': 'nz-k1.dkim.newzapp.co.uk'
    }
];

const CollapsibleAlert = ({ title, info, children, isOpen = false }) => {
    const [isExpanded, setExpanded] = useState(false);

    useEffect(() => {
        if (isExpanded !== isOpen) {
            setExpanded(isOpen);
        }
    }, [isOpen]);

    return (
        <Alert
            action={(
                <KeyboardArrowDownIcon
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setExpanded(!isExpanded);
                    }}
                    sx={{ cursor: 'pointer' }}
                >
                    <KeyboardArrowUpIcon fontSize="inherit" />
                </KeyboardArrowDownIcon>
            )}
            severity={info}
            sx={{ mb: 2 }}
        >
            <AlertTitle>{title}</AlertTitle>
            {isExpanded && children}
        </Alert>
    );
};

const InstructionAlert = ({ account, isOpen }) => {
    const dynamicRows = rows.map(row => ({
        ...row,
        name: account.sendingDomain
            ? row.name.replace('[customerdomain]', account.sendingDomain.substring(account.sendingDomain.indexOf('.') + 1))
            : row.name
    }));

    const dynamicDkimRows = customDkimRows.map(row => ({
        ...row,
        name: account.sendingDomain
            ? row.name.replace('[customerdomain]', account.sendingDomain.substring(account.sendingDomain.indexOf('.') + 1))
            : row.name
    }));

    return (
        <>
            <Stack id="pdf-content" sx={{ width: '100%', padding: 1 }} spacing={1}>
                <Alert severity="info">
                    <AlertTitle>Email Authentication</AlertTitle>
                    <Typography variant="body2" gutterBottom>
                        To maximize delivery and, in an increasing number of cases, to get delivery at all, your sending domain must be fully authenticated. Each NewZapp account can have only one authenticated domain; it is not possible to have more than one authenticated domain in an account.
                        To get your domain authenticated, you need to set up SPF, DKIM, and DMARC records.
                        Once set up, click on "Verify".
                    </Typography>
                </Alert>

                <CollapsibleAlert title="Bounce Handler" info={account.mxcheck ? 'success' : 'warning'} isOpen={isOpen}>
                    <Typography variant="body2" gutterBottom>
                        To ensure NewZapp can effectively handle bounced emails, unsubscribes, non-deliveries, and other returned emails from your campaigns, please follow these steps to add a DNS record to point to our Bounce Handler MX record for your sending sub-domain. This process won't impact your corporate or organizational email.
                        <br />
                        <br />
                        Please add a CNAME DNS Record with the following detail:
                        <br />
                        <br />
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Host Name</TableCell>
                                        <TableCell align="right">Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {account.sendingDomain}
                                        </TableCell>
                                        <TableCell align="right">bounced.newzapp.co.uk</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Typography>
                </CollapsibleAlert>

                <CollapsibleAlert title="SPF" info={account.spf ? 'success' : 'warning'} isOpen={isOpen}>
                    <Typography variant="body2" gutterBottom>
                        When sending through NewZapp you need to add an SPF to your full sending domain in order to achieve Full Alignment of DMARC, SPF & DKIM.
                        <br /><br />Add <strong>include:_spf.newzapp.co.uk</strong> to your existing record.
                        <br />
                        <br />
                        If you do not have an SPF record then create one as follows:
                        <br />
                        <br />
                        Type: <strong>TXT</strong>
                        <br />
                        Value:<strong> v=spf1 include:_spf.newzapp.co.uk -all</strong>
                        <br />
                    </Typography>
                </CollapsibleAlert>
                <div className="html2pdf__page-break" style={{ display: isOpen ? 'block' : 'none' }}></div>
                <CollapsibleAlert title="DKIM" info={account.dkim ? 'success' : 'warning'} isOpen={isOpen} className="page-break-after">
                    <Typography variant="body2" gutterBottom>
                        DKIM, short for DomainKeys Identified Mail, serves as an email authentication method aimed at confirming the authenticity of an email's sender address, ensuring it is not being spoofed by a third party. Inboxes see DKIM-signed messages as more trustworthy, lowering bounce rates and enhancing email deliverability.
                        <br />
                        <br />
                        To set up DKIM on your account, you will need to create three new CNAME DNS records for the sending domain above as follows:
                    </Typography>
                    <br />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Host Name</TableCell>
                                    <TableCell align="right">Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {account.customDKIM ? (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {dynamicDkimRows[0].name}
                                        </TableCell>
                                        <TableCell align="right">{dynamicDkimRows[0].key}</TableCell>
                                    </TableRow>
                                ) : (
                                    dynamicRows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.key}</TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CollapsibleAlert>

                <CollapsibleAlert title="DMARC" info={account.dmarc ? 'success' : 'warning'} isOpen={isOpen}>
                    <Typography variant="body2" gutterBottom>
                        A DMARC (Domain-based Message Authentication, Reporting, and Conformance) record is a DNS (Domain Name System) record that provides instructions to email receivers on how to handle emails from a specific domain. DMARC is used to enhance email security by helping prevent email spoofing and phishing attacks.
                        <br /><br />DMARC records can have lots of different options. If you do not currently have a DMARC record, we recommend the following is added to your DNS:
                        <br />
                        <br />
                        <strong>Type: TXT</strong>
                        <br />
                        <strong>Hostname: _dmarc</strong>
                        <br />
                        <strong>Value: v=DMARC1; p=none;</strong>
                    </Typography>
                </CollapsibleAlert>
            </Stack>
        </>
    );
};

const Instruction = ({ account }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isPrint, setIsPrint] = useState(false);

    const generatePDF = () => {
        setIsPrint(true);
        setIsOpen(true);
    };

    const options = {
        jsPDF: {
            format: 'a4'
        },
        pagebreak: { after: '.page-break-after' },
        html2canvas: { scale: 2, letterRendering: true, useCORS: true, logging: true },
        margin: [12, 5, 5, 5],
        image: { type: 'jpeg', quality: 1 },
        filename: 'Email-Authentication-Instruction.pdf'
    };

    useEffect(() => {
        if (isPrint && isOpen) {
            const element = document.getElementById('pdf-content');

            if (element) {
                html2pdf().set(options).from(element).save();
                setIsOpen(false);
                setIsPrint(false);
            }
            else {
                console.error('Element not found for PDF generation');
            }
        }
    }, [isPrint, isOpen]);

    return (
        <Box>
            <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">Instructions</Typography>
                </Box>
                <Button variant="text" endIcon={<DownloadIcon />} onClick={generatePDF}>
                    Get Started (PDF)
                </Button>
            </Toolbar>
            <Divider />
            <InstructionAlert account={account} isOpen={isOpen} />
        </Box>
    );
};

export default Instruction;
