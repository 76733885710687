import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import useApi from '../../../hooks/useApi';
import useSnackbar from '../../../hooks/useSnackbar';

const Domain = ({ account, onRefresh }) => {
    const [domain, setDomain] = useState(account.sendingDomain ? account.sendingDomain.substring(account.sendingDomain.indexOf('.') + 1) : '');
    const [subDomain, setSubDomain] = useState(account.sendingDomain ?? '');
    const { handlePut } = useApi();
    const { showSnackbar } = useSnackbar();

    const handleChange = (e) => {
        setDomain(e);
        setSubDomain(`bounced.${e}`);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await handlePut(`account/${account.id}/sending-domain`, {
            domain: domain,
            sendingDomain: `bounced.${domain}`
        });

        if (!response.ok) {
            showSnackbar('An error occurred.', 'error');
            return;
        }

        showSnackbar('Details saved', 'success');
        onRefresh();
    };

    useEffect(() => {
        onRefresh();
    }, [domain]);

    return (
        <>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{ px: 2 }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">
                        Sending Domain
                    </Typography>
                </Box>
            </Toolbar>
            <Divider />
            <Box sx={{ width: '100%', py: 1 }}>
                <form>
                    <Box sx={{ py: 0.25, px: 2, marginRight: 1, margin: 1, display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1, margin: 1 }}>
                            <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
                                Sending Domain
                            </Typography>
                            <TextField
                                label=""
                                variant="outlined"
                                size="small"
                                value={domain}
                                onChange={(e) => handleChange(e.target.value)}
                                sx={{ width: 300, marginLeft: 2 }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={handleSubmit} edge="end">
                                            <SearchIcon />
                                        </IconButton>
                                    )
                                }}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1, margin: 1 }}>
                            <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap', marginTop: 1 }}>Sending Sub-Domain</Typography>
                            <Typography variant="body2" sx={{ whiteSpace: 'nowrap', mx: 2, marginTop: 1 }}>{subDomain}</Typography>
                        </Box>

                        <Button variant="contained" onClick={handleSubmit} sx={{ marginTop: 1 }}>
                            Submit
                        </Button>
                    </Box>
                </form>
            </Box>
        </>
    );
};

export default Domain;