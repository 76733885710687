import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import { Stack } from '@mui/material';
import Switch from '@mui/material/Switch';

const CustomerDetailsPanel = ({ formData, onFormChange, handleValid }) => {
    const [customerDetails, setCustomerDetails] = useState({
        companyName: formData.companyName,
        isParentAccount: formData.isParentAccount,
        parentAccount: formData.parentAccount,
        mta: formData.mta,
        type: formData.type,
        isTrustedDelivery: formData.isTrustedDelivery
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        let updatedValue = value;

        if (event.target.type === 'checkbox') {
            updatedValue = event.target.checked;
        }

        setCustomerDetails({ ...customerDetails, [name]: updatedValue });
        onFormChange(name, updatedValue);
    };

    useEffect(() => {
        handleValid(customerDetails.companyName !== '' && customerDetails.type !== '');
    }, [customerDetails]);

    return (
        <Stack>
            <TextField
                label="Company Name"
                name="companyName"
                value={customerDetails.companyName}
                onChange={handleChange}
                required
            />
            <FormControlLabel control={<Switch checked={customerDetails.isParentAccount} name="isParentAccount" onChange={handleChange} />} label="Parent Account?" />
            {!customerDetails.isParentAccount && (
                <>
                    <TextField
                        label="Parent Account CID"
                        name="parentAccount"
                        value={customerDetails.parentAccount}
                        onChange={handleChange}
                    />
                    <TextField
                        label="MTA"
                        name="mta"
                        value={customerDetails.mta}
                        onChange={handleChange}
                    />
                    <FormLabel required>Account Type</FormLabel>
                    <RadioGroup
                        row
                        name="type"
                        value={customerDetails.type}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="external" control={<Radio />} label="External" />
                        <FormControlLabel value="internal" control={<Radio />} label="Internal" />
                    </RadioGroup>
                    <FormControlLabel control={<Switch checked={customerDetails.isTrustedDelivery} name="isTrustedDelivery" onChange={handleChange} />} label="Trusted Delivery" />
                </>
            )}
        </Stack>
    );
};

export default CustomerDetailsPanel;