import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Snackbar from '@mui/material/Snackbar';

const PublishedDialog = ({ onClose, report }) => {
    const [snackbarMessage, setSnackbarMessage] = useState(null);

    const copyToClipboard = value => {
        navigator.clipboard.writeText(value);
        setSnackbarMessage('Link copied to clipboard');
    };

    return (
        <>
            <Dialog
                open={true}
                onClose={onClose}
            >
                <DialogTitle>Congratulations - Landing Page Published</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                        Your Landing Page is now available to view online
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Button
                            variant="outlined"
                            href={report.viewLink}
                            target="_blank"
                            title={report.title}
                            startIcon={<OpenInNewIcon />}
                        >
                            Open Page
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => copyToClipboard(report.viewLink)}
                            startIcon={<ContentPasteIcon />}
                        >
                            Copy Link to Clipboard
                        </Button>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={Boolean(snackbarMessage)}
                onClose={() => setSnackbarMessage(null)}
                autoHideDuration={3000}
                message={snackbarMessage}
            />
        </>
    );
};

export default PublishedDialog;